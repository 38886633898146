import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { CreditCardService } from 'src/app/services/creditCard-service';
import { DataService } from 'src/app/services/data.service';
import { LoginComponent } from '../login/login.component';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { PopupLogOutComponent } from '../popup-log-out/popup-log-out.component';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  innerWidth: any;
  logInOrOut="התנתק"
  token="";

  constructor(private dataService:DataService,private matDialog:MatDialog, private router: Router,private proxy:AppProxy,private creaditCardService:CreditCardService) { }

  ngOnInit(): void {
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    sessionStorage.deeplink2="";
    sessionStorage.deeplink="";
    window.scroll(0,0);
    this.dataService.showSearch.next(false);
    this.changByFood.emit();
    let priceTotal="0 - פריטים";
    localStorage.PRICE=0;
    localStorage.PRICE_TOTAL=priceTotal;
    this.dataService.price.next(priceTotal);
    this.changePrice.emit();
    this.token = this.dataService.token.getValue() || {};
    this.dataService.token.subscribe(data=>{
      this.token=data;
    });
    if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="" || localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
    this.logInOrOut="התחבר";
    else
    this.logInOrOut="התנתק";
  }

  termsOfUse()
  {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth<=480)
    this.router.navigate(['../termsUse']);
    else
    this.openDialog();
  }

  openDialog() {
    this.matDialog.open(TermsOfUseComponent,{ disableClose: true });
  }

  main()
  {
    // if(localStorage.SMS=="")
    // this.proxy.showError("חובה להכניס קוד","");
    // else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    //  this.proxy.showError("אינך רשום למערכת","");
    //  else
    //  {
     this.router.navigate(['../mainHome']);
    // }
  }

  search()
  {
    if(localStorage.SMS=="")
    this.proxy.showError("חובה להכניס קוד","");
    else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.openEnterDialog();
     else
     {
     this.router.navigate(['../search']);
     }
  }

  editUser()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    {
    this.router.navigate(['../register']);
    }
  }

  inOrOut()
  {
    if(sessionStorage.ACCESS_TOKEN != "null" &&  localStorage.oneTimeOrderOrOrder!='oneTimeOrder')
    {

      this.matDialog.open(PopupLogOutComponent, {
      });
      // let reset = confirm("האם אתה בטוח שברצונך להתנתק מהאתר ");
      // if (reset)
      // {
      //   this.dataService.state.next("התחבר");
      //   this.state.emit();
      //   sessionStorage.ACCESS_TOKEN = null;
      
      // }
    }
    else
       this.matDialog.open(LoginComponent, { disableClose: true });
     // this.router.navigate(['../login']);
  }

  orderHistory()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    {
    this.router.navigate(['../orderHistory']);
    }
  }

  shippingHistory(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../shippingHistory']);
  }

  paymentInfo(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../paymentInfo']);
  }

  reservedRestaurants(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../reservedRestaurants']);
  }

  editProfil()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['/register']);
  }

  giftCard()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
      this.openEnterDialog();
    else
    {
      this.creaditCardService.IsPaymentByGiftCard=false;
    this.router.navigate(['/giftCard']);
    }
  }


  openEnterDialog() {
    if(this.innerWidth<=480)
    this.matDialog.open(PopupEnter2Component, { disableClose: true });
    else
    this.matDialog.open(PopupEnterComponent, { disableClose: true });
  }
}
