<div class="d-flex" style="font-family: OpenSansHebrewRegular; width: 100%; font-weight: bold; font-size: 16px;">
  <div class="d-flex" style="margin-right: 5px; display: none;">
     <img  style="width: 90px; height: 70px; display: none;"/>
    </div>
    <div  class="flex-fill bd-highlight">
      <div>
        <div class="d-flex bd-highlight location">
  <div style="font-size: 17px;" class="col-md-5">{{orderItem.Description}}</div>
  <!-- <div class="col-md-6" style="text-align: center;">כתובת מוצא : {{orderItem.Origin}}        כתובת יעד : {{orderItem.Destination}}</div> -->
    <div class="col-md-7" style="text-align: end; 
    ">{{orderItem.JoinDate| date:'dd/MM/yyyy hh:MM'}}</div>
        </div>

        <div class="d-flex bd-highlight top">
  <span style="font-size: 17px; direction: rtl;" class="col-6">כתובת מוצא : {{orderItem.Origin}}</span>
  <!-- <div class="col-md-6" style="text-align: center;">כתובת מוצא : {{orderItem.Origin}}        כתובת יעד : {{orderItem.Destination}}</div> -->
    <div class="col-6" style="text-align: end; flex: 0 0 79%;
    ">כתובת יעד : {{ (orderItem.Destination.length>35)? (orderItem.Destination | slice:0:35)+'..':(orderItem.Destination) }}</div>
        </div>
        <div class="d-flex bd-highlight" style="margin-top: 5px;">
          <div class="col-md-4"> 
              <div style="display: none;" >
            <div class="keep"  (click)="orderAgain() " style="margin-right: 15px;">
              <img src="assets/images/Order_Button_1.png">               
              <span style="color: white; font-size: 13px;" class="btn">הזמן מחדש</span>
            </div>
           </div></div>
          <div class="col-md-4" style="color:#b0b0b0"></div>
            <!-- <div class="col-md-4" style="text-align: left;color:#b0b0b0; margin-top: 7px;"> 
            <div class=" align-self-end  p-2 flex-fill bd-highlight" style="
              text-align: left;"> 
                <span (click)="downloadPdf('http://maven.apache.org/maven-1.x/maven.pdf','maven.pdf')" style=" cursor: pointer; color: #41c777; margin-left: 5px;">הורד חשבונית</span>
                <img  src="assets/images/History_Invoice_Down_Icon.png"/>
                </div></div> -->
        </div>     
</div>
<div >
</div>
</div>
</div>
<img width="99%" style="height: 1px; " class="line" src="assets/images/History_Line_2.png"/>

