import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';




@Component({
  selector: 'app-iframe-credit-card',
  templateUrl: './iframe-credit-card.component.html',
  styleUrls: ['./iframe-credit-card.component.css']
})
export class IFrameCreditCardComponent implements OnInit {
 

  showsIframe : boolean = true
  showsButton : boolean = false
  isSuccess : boolean = false
  title : string = ""
  myText : string = ""
  urlSafe: SafeResourceUrl = "";
  constructor(  @Inject(MAT_DIALOG_DATA) private data:  {myurl: string 
   //2Sucsses
    //, onSucsses:any
  } , public sanitizer: DomSanitizer
    , private router: Router , private zone: NgZone ,  public dialogRef: MatDialogRef<IFrameCreditCardComponent> ) { }

    ngOnInit(): void {

 

      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.data.myurl);
    
      // Function to handle success or failure
      const handleResponse = (eventData: any) => {

        //console.log(eventData.value)
        const searchStringSuccess = "Success.";
        const searchStringError = "Error";
    
        this.zone.run(() => {
          this.isSuccess = eventData.value.includes(searchStringSuccess);
          const isErorr = eventData.value.includes(searchStringError)

          if (this.isSuccess == true)
        {
          this.title = ""
        this.myText = "העסקה בוצעה בהצלחה\nלחץ להמשך"
        this.backPress()

//3Sucsses
       // this.data.onSucsses(this.isSuccess)

        }
          if (isErorr == true)
          {
            
          this.title = "לא בוצע חיוב"
          this.myText = "יתכן שיש טעות בפרטי הכרטיס / סירוב מחברת האשראי"
          }

          this.showsButton = this.isSuccess || isErorr
            
            this.showsIframe = !this.showsButton
         
        });
      };
    
      window.addEventListener('message', event => {
        this.zone.run(() => {
          handleResponse(event.data);
        });
      });
  
      // Listen for router navigation start
      window.addEventListener('popstate', () => {
        //console.log('Back button clicked');
        // Perform your desired action when the back button is clicked
        // For example, close the dialog
        this.dialogRef.close("false");
      });

      var myIframe =( document.getElementById('myIframe')) as HTMLIFrameElement;
      myIframe.contentWindow.addEventListener('message', function(event) {
          //console.log(JSON.parse(event.data));
      }, false);
      
      
     
    
    }
 
    

  onPopState(event) {
    //console.log('Back button pressed');
  }
  setIframeHeight(frame) {
            
    var iframeElement = frame as HTMLIFrameElement;
    var iframeHeight = iframeElement.contentWindow.document.documentElement.scrollHeight;
         var windowHeight = iframeHeight + 50; // Adding additional padding to the window height\n    
       window.resizeTo(window.outerWidth, windowHeight);
  
  }

   getDocHeight(doc) {
    doc = doc || document;
    var body = doc.body,
      html = doc.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height;
  }

  height: number = 0;



  /** Hide the scrollbar of the iFrames content and set to full height to it */
  onHtmlDataLoad(event: Event): void {
   

    }


   
    backPress()
    {
        if (this.isSuccess == true)

      this.dialogRef.close("true")
      else
      this.dialogRef.close("false")
    }
    
  }