export class User
{
IDUser:number;
userFullName:string;
userPassword:string; 
userPasswordAgain:string;
userEmail:string; 
userPhone:string; 
userAddress:string; 
userEnter:string;
userFloor:number;
userNumberHoush:number;
userLatitude:number;
userLongitude:number;
ImageFile:string;
}

export class UserEditProfile
{
    runtimeDate:string;
    IDUser:number;
    DeviceID:string;
    userFullName:string;
    userEmail:string;
    userPhone:string;
    ImageFile:string;
    userAddress:string;
    JoinDate:string;
    LastEntrance:string;
    Latitude:number;
    Longitude:number;
    error:boolean;
    responseMessage:string;
}

export class LoginObj{

    nvUserName:string;
    nvPassword:string;
}

export class Address{
    nvCity: string;
    nvStreet: string;
    nvHouseNumber:string;
    nvEnter: string;
    nvfloor : string;
    nvPhone: string;
    nvOrderName: string;
    nvEmail: string;
}

export class Payment
{
    CardDate:string;
    month:string;
    year:string;
    card_cvv:number;
    CardNumber:string;
  //  user_identity:string;
    UserIdentity:string;
    UserName:string;
    card_token:string;
}

export class SignUp{
    signature:String;
    userEmail:String;
    userPassword:String;
    userFullName:String;
    userAddress:String;
    userPhone:String;
    ImageFile:File;
}