<div class="card">
    <div class="d-flex" style="direction: rtl; margin-top: 20px;">
        <span (click)="main()" style="cursor: pointer;"  class="nav-link" href="#">דף הבית</span>
        <img  class="icon" src="assets/images/Left_Arrow.png"/>
    </div>
    <div class="d-flex" style="direction: rtl;">
        <span (click)="search()" style="cursor: pointer;"  class="nav-link" href="#">חיפוש</span>
        <img  class="icon" src="assets/images/Left_Arrow.png"/>
    </div>
    <div *ngIf="token == 'order'" class="d-flex" style="direction: rtl;">
    <span (click)="orderHistory()" style="cursor: pointer;"  class="nav-link" href="#">היסטורית הזמנות</span>
    <img  class="icon" src="assets/images/Left_Arrow.png"/>
</div>
<img class="line" src="assets/images/Restorant_Page_Food_Big_Line.png">
<div *ngIf="token == 'order'" class="d-flex" style="direction: rtl;">
    <span (click)="reservedRestaurants()" style="cursor: pointer;" class="nav-link" href="#">בתי עסק שמורים</span>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
</div>
<!-- <div *ngIf="token == 'order'" class="d-flex" style="direction: rtl;">
    <span (click)="shippingHistory()" style="cursor: pointer;"  class="nav-link" href="#">היסטורית משלוחים</span>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
</div> -->

<div *ngIf="token == 'order'" class="d-flex" style="direction: rtl;">
    <span (click)="paymentInfo()" style="cursor: pointer;" class="nav-link" href="#"> אמצעי התשלום שלי</span>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
</div>
<div *ngIf="token == 'order'" class="d-flex" style="direction: rtl;">
    <span (click)="giftCard()" style="cursor: pointer;" class="nav-link" href="#">Gift Card</span>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
</div>
<img *ngIf="token == 'order'" class="line" src="assets/images/Restorant_Page_Food_Big_Line.png">
<div class="d-flex" style="direction: rtl;">
    <span (click)="termsOfUse()" style="cursor: pointer;" class="nav-link" href="#">מדיניות פרטיות ותקנון</span>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
</div>
<div class="d-flex" style="direction: rtl;">
    <a [routerLink]="'../contact'" style="cursor: pointer;" class="nav-link" href="#">צור קשר</a>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
</div>

<!-- <div *ngIf="token == 'order'" class="d-flex" style="direction: rtl;">
<a [routerLink]="'../delivery'" style="cursor: pointer;" class="nav-link" href="#">הזמן משלוח</a>
<img class="icon" src="assets/images/Left_Arrow.png"/>
</div> -->
<div *ngIf="token == 'order'" (click)="editUser()" class="d-flex" style="direction: rtl;">
<span  style="cursor: pointer;" class="nav-link" href="#">ערוך פרופיל</span>
<img class="icon" src="assets/images/Left_Arrow.png"/>
</div>
<img class="line" src="assets/images/Restorant_Page_Food_Big_Line.png">
<div (click)="inOrOut()" class="d-flex" style="direction: rtl;">
    <span  style="cursor: pointer;" class="nav-link" href="#">{{logInOrOut}}</span>
    <img class="icon" src="assets/images/Left_Arrow.png"/>
    </div>
    <div style="margin-top: 20px;" [ngStyle]="token != 'order' && {'margin-top':'78px'}">
       <div style="text-align: center;">
        <a style="margin-right: 10px; margin-left: 10px;" href="https://apps.apple.com/us/app/%D7%91%D7%90-%D7%9C%D7%99-%D7%91%D7%A7%D7%9C%D7%99%D7%A7/id1582050632">
          <img style="cursor: pointer;" src="assets/images/Apple_Store_Button.png" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.appgate.balihazmanot">
          <img  style="cursor: pointer;"  src="assets/images/Google_Store_Button.png"/></a>
          </div>
    </div>
</div>