<div #list class="page" style="position: relative;"  [ngStyle]="popup==1 && {'height': innerHeight1 + 'px'}">
  <!-- <div style="display: flex; justify-content: center; position: relative;cursor: pointer;font-family: OpenSansHebrewRegular;">
</div> -->
<!-- <div class="page2"> -->
<img class="img400" [src]="Image400" style="display: none; width: 100%; top: 0;">
<!-- <img src="assets/images/back_button_1.png" style="position: absolute; right: 15px; top: 15px; height: 20px;" (click)="close()"> -->
    <div  style="right: 0; left: 0; margin: auto;">
    <div style="font-size: 18px; text-align: left; color: #373737;  font-weight: bold; 
    max-width: 25%;" [ngStyle]="popup==1 && {'height': innerHeight2 + 'px'}" class="dFlex col3">
      <div class="header" style="width: 73%;  right: 0; left: 0; margin: auto;" >
        <div class="foodName" style="font-size: 18px; text-align: center; color: #373737; direction: rtl;
        font-weight: bold; position: relative; padding-bottom: 10px;">{{foodName}}</div>
        <div class="foodDescription" style="font-size: 14px; text-align: center; direction: rtl; margin-top: 10px;
        white-space: pre-line; font-weight: 500; "> {{ foodDescription }}</div>
      </div>
<div class="d-flex img400 img4" style="margin-top: 20px;">
      <div  class="keep" >
        <img class="img400" style="height: 40px;" src="assets/images/price_box_food.png">               
        <span class="priceText img400" style="color: #29c065; direction: rtl;
        font-size: 16px; position: absolute; left: 33%; top: 25%;">{{totalFoodAmount}} ₪</span>
      </div> 
      <!-- <div *ngIf="toppings.length>2" class="d-flex col-12" style="position: absolute; justify-content: center; direction: rtl;">
        <div *ngFor="let item of toppings ;let i = index" >
              <div class="mr-lg-auto border2 img400" *ngIf="i%2==0" >
                <img class="button align-self-center"  [src]="i+2<=position || i+1<=position ? pointOn : pointOff" >  
                <img *ngIf="i+1!=toppings.length && i+2!=toppings.length" style="width: 15px;" class="align-self-center" src="assets/images/point_line.png"/>
              </div>
            </div>
          </div> -->
  </div>
    

    <div class="border"  style="width: 95%; color:#373737; font-weight: bold; height: 385px;
        float: center; margin-left: 28%; float: center; direction: rtl;
    border: 2px solid #e2e2e2; border-radius: 12px; box-shadow: inset 0 1px 1px #ededed;" >
    
 <div class="img400">
<div style="position: relative;" class="scroller" [ngClass]="continuedNum==1 ? innerWidth<=480 ? 'listHeight' : 'listHeight2': 'listHeight'" [ngStyle]="popup==1 && {'height': innerHeight2 + 'px'}">
          <div  *ngFor="let item of toppings ;let i = index" style="text-align: right;" [ngStyle]="popup==1 && {'width':'100%'}">
            <div *ngIf="item!=null"  style="text-align: center;" [ngStyle]="i==1 && {'margin-top': '10px'}">
<span style="font-size: 15px; line-height: 1.8;">{{item.TypeTxt}}</span>
<div style="font-size: 10px; color: #29c065;" *ngIf="item!=null && item.CountAdditionFree>0 && item.CountAdditionFree!=null">מקבלים {{item.CountAdditionFree}} חינם</div>  
<div class="d-flex" *ngFor="let topping of item.listA; let ind=index" style="margin-top: 5px; text-align: right;" >
  <div class="col-md-12 text d-flex" style="cursor: pointer; position: relative; direction: rtl; ">
    <div>
    <img (click)="selectItem(i,ind,true)" class="button align-self-center" style="margin-bottom: 4px;" [src]="topping.IsChoose==0 ? checkboxOff : checkboxOn">          
  </div>
    <span (click)="selectItem(i,ind,true)" style="overflow: hidden; align-self: center!important;
    text-overflow: ellipsis; margin-right: 10px; margin-bottom: 3px;font-size: 12px;
    font-family: 'OpenSansHebrewRegular'; ">&#x200F; {{ topping.Food }}</span>
    <span (click)="selectItem(i,ind,true)" *ngIf="topping.Price!=null && topping.Price!=0 && (item.CountAdditionFree<=item.CountChoose || item.CountAdditionFree==0 )" style="margin-right: 5px; font-size: 12px; font-family:'OpenSansHebrewRegular'; white-space: nowrap;">{{topping.PriceText}}</span>
      <!-- <span (click)="selectItem(i,ind,true)" *ngIf="topping.Price!=null && topping.Price!=0 && (item.CountAdditionFree<item.CountChoose || item.CountAdditionFree==0 )" style="margin-right: 5px; font-size: 12px;font-family: 'OpenSansHebrewRegular';">{{topping.PriceText}}</span> -->


</div>
<div *ngIf="item.IsFree==1" style="position: absolute; font-size: 12px; font-family: 'OpenSansHebrewRegular';" class="mr-lg-auto" [ngClass]="item.listA.length<=scrollList ? 'left4' : 'left3'">
  <img (click)="minus(i,ind)" class="align-self-center"  src="assets/images/minus_button.png" style="cursor: pointer; margin-left: 5px;"/>
  <span style="margin-right: 5px; margin-left: 8px;">{{topping.CountChoose}}</span>
  <img (click)="plus(i,ind)" class="align-self-center" src="assets/images/plus_button.png" style="cursor: pointer;"/>
</div>
</div>
          </div>
        </div>
        <div *ngIf="showProgress==true" style="position: absolute; right: 0; left: 0;">
          <mat-spinner class="mat-spinner-color" style="justify-content: center; 
            align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
            </div>
        <div class="continued_ img400 alert-dismissible" style="margin-top: 80px; left: 0;" >
   <span style="font-size: 13px;">שם למנה:</span>
   <input type="text" #inputas class="container" name="inviteName2"  (blur)="onBlur()" [(ngModel)]="inviteName2" style="color: black;">
         </div> 
        <!-- <div (click)="continue()" class="continued img400" style="left: 0; bottom: -2px; margin-top: 10px;" >
         <img class="button cdk-overlay" style="margin-top: 20px;" src="assets/images/Tosafot_Next_Button.png">               
         <span class="buttonText" style="color: white; font-size: 16px; position: relative; left: 28% !important;">סיום</span>
         </div>  -->

         <div class="container-sm img2  " (click)="continue()" style="font-family: OpenSansHebrewRegular; width: max-content; bottom: -8%;">
          <img src="assets/images/Tosafot_Next_Button.png">               
          <span class="btn" style="font-size: 17px; font-family: 'OpenSansHebrewBold'; color: white;">סיום</span>
  </div> 
         <div style="height: 40px; background: white; width: 100%;"></div>
         <div *ngIf="keyboard" style="background-color: white; height: 350px;" ></div>
      </div>
      <div *ngIf="keyboard" style="margin-top: 46px; background-color: white; height: 300px;"></div>
            </div>
  
            <!-- <div > -->
             <!-- <div *ngIf="continuedNum==1" class="img2 carousel-indicators" >
<span style="font-size: 13px;">שם למנה:</span>
<input class="container" name="inviteName2" [(ngModel)]="inviteName2" style="color: black;">

             </div> -->

             <!-- <div  *ngIf="continuedNum==1" class="continued_ img400 alert-dismissible" style="margin-top: 80px;
             left: 0;" >
        <span style="font-size: 13px;">שם למנה:</span>
        <input type="text" #inputas class="container" name="inviteName2" (focus)="scrollTo()" (blur)="onBlur()" [(ngModel)]="inviteName2" style="color: black;">
              </div> 
             <div (click)="continue()" class="continued img400 " style="margin-top: 10px;
             left: 0;" >
              <img  class="button" src="assets/images/Tosafot_Next_Button.png">               
              <span   class="buttonText"  style="color: white;
              font-size: 16px; position: absolute; left: 40%; top: 25%;">{{continued}}</span>
              </div> 
              <div *ngIf="keyboard" style="background-color: white; height: 350px;" ></div> -->
            <!-- </div> -->
      </div>
</div>
</div>
</div>
<!-- </div> -->
    
    
    


