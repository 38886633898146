import { Component, OnInit ,EventEmitter ,Input ,Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IgxListLineTitleDirective } from 'igniteui-angular';
import { CategoryItem ,Category} from 'src/app/models/category';
import { MyOrder } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import { OrdersService } from 'src/app/services/order-service';
import { PopupOrderComponent } from '../popup-order/popup-order.component';
import { OpenningHours} from 'src/app/models/restaurant'
import { PopupErrorComponent } from '../popup-error/popup-error.component';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss']
})
export class CategoryItemComponent implements OnInit {
  stars:number[]=[1,2,3,4,5];
  @Input() categoryItem:CategoryItem;
  @Input() category:Category;
  @Input() indexList:number;
  @Input() restaurantCategory:number;
  @Output()
  change: EventEmitter<number> = new EventEmitter<number>();
  endToppings: EventEmitter<number> = new EventEmitter<number>();
  categoryType:number=1;
  clickFevorite=false;
  fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
  fevoritActive='assets/images/Restorant_Page_Fevorit_Button_On.png';
  order:MyOrder;
  openningHours:OpenningHours[]=[];
  localId;
  Rate= new Array<number>(3);
  isClick=false;
  dayTime="";
 innerWidth:any;

  constructor(private router: Router,private dataService:DataService,private matDialog:MatDialog, private proxy: AppProxy,private bussinssService: BussinssService ,private orderService:OrdersService) {  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
    this.fevoritActive='assets/images/Restorant_Page_Fevorit_Button.png';
    this.localId=localStorage.ID;
    this.clickFevorite=false;
    if(this.categoryItem !=null && this.categoryItem.ImageFile!=null && this.category!=null)
    if(this.category.categoryType==3 )
    this.categoryType=1;
    else if(this.category.categoryType==2)
    this.categoryType=2;
   // if(this.categoryItem.IsFevorite!=0)
    //this.fevorit='assets/images/Restorant_Page_Fevorit_Button_On.png';
   // else
   // this.fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
    if(this.categoryItem.Rate==0 ||this.categoryItem.Rate==null)
    this.categoryItem.Rate=3;
  }

  onClickCategory(CategoryNumber:number)
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    //localStorage.TEXT="";
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   // if(sessionStorage.ACCESS_TOKEN  == "null" || localStorage.SMS_CODE=="")
   // this.proxy.showError("אינך מחובר נא להתחבר","");
    else
    {
      let id;
      if(this.categoryItem.ID>0)
      id=this.categoryItem.ID;
      else
      id=this.categoryItem.RstaurantID;
      this.bussinssService.getOpenningHours(id).subscribe((res:any[])=>{
        if(this.categoryItem.RstaurantID==null )
      this.dayTime="";
      else
      {
        this.openningHours=res;
        let b=0;
        for(let i=0;i<this.openningHours.length;i++)
        {
        
          if(i==new Date().getDay())
          {
            if(new Date().getHours()<=5)
            {
               if(i>0)
               {
              i=i-1
              b=1;
               }
               else
               {
               i=6;
               b=7;
               }
            }
            if(this.openningHours[i] !=null && this.openningHours[i].DayTime!='' && this.openningHours[i].DayTime!='0:00-0:00' && this.openningHours[i].DayTime!='00:00-00:00')
         {
           var fields = this.openningHours[i].DayTime.split('-');
           if(this.proxy.isOpen(fields[0],fields[1],b))
            this.dayTime=this.openningHours[i].DayTime;
            else
            this.dayTime="סגור";
         }
          else
          this.dayTime="סגור";
          i=this.openningHours.length;
          }
        }}
        if(this.dayTime=="סגור")
        this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
       //this.openDialog(); 
        else{
      this.dataService.search.next("");
      this.orderService.restaurant=null;
      localStorage.PRICE=0;
      this.isClick=true;
  if(this.clickFevorite==false)
  {
    let id=this.categoryItem.ID;
   // if(location.hash!="#/mainHome")
    {
    if(this.categoryItem.RstaurantID==0 || this.categoryItem.RstaurantID==undefined)
     id=this.categoryItem.ID;
    else
    id=this.categoryItem.RstaurantID;
    }
    if(CategoryNumber==1 || this.restaurantCategory==8)
    {
   
      if(sessionStorage.ACCESS_TOKEN  == "null" || sessionStorage.ACCESS_TOKEN  == undefined)
      {
        localStorage.MENU_ID=0;
              
        if(id!=null)
        {
          this.orderService.order=null;
          sessionStorage.CATEGORY_FILTER="";
          localStorage.ID=id;
          this.dataService.endToppings.next(true);
          this.endToppings.emit();
          let url: string = "/restaurant/" + this.categoryItem.NameEn
          this.router.navigateByUrl(url);
        //this.router.navigate(['../restaurant']);
        }
        else
        {
         // localStorage.ID=CategoryNumber;
        
          this.dataService.categoryId.next(CategoryNumber);
          this.change.emit();
          localStorage.ID_CATEGORY=CategoryNumber;
          let url: string = "/restaurantCategory/" + CategoryNumber
          this.router.navigateByUrl(url);
         // this.router.navigate(['../restaurantCategory']);
          this.dataService.categoryId.next(0);
          CategoryNumber=0;
        }
      }
      else
      {
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        if(this.order.OrderID==0 && (this.order.list==null || this.order.list.length==0)) 
              {
                localStorage.MENU_ID=0;
              
                if(id!=null)
                {
                  this.orderService.order=null;
                  sessionStorage.CATEGORY_FILTER="";
                  localStorage.ID=id;
                  this.dataService.endToppings.next(true);
                  this.endToppings.emit();
                  let url: string = "/restaurant/" + this.categoryItem.NameEn
                  this.router.navigateByUrl(url);
                //this.router.navigate(['../restaurant']);
                }
                else
                {
                 // localStorage.ID=CategoryNumber;
                
                  this.dataService.categoryId.next(CategoryNumber);
                  this.change.emit();
                  localStorage.ID_CATEGORY=CategoryNumber;
                  let url: string = "/restaurantCategory/" + CategoryNumber
                  this.router.navigateByUrl(url);
                 // this.router.navigate(['../restaurantCategory']);
                  this.dataService.categoryId.next(0);
                  CategoryNumber=0;
                }
              }
          else if(CategoryNumber<=1)
          {
            if(this.isClick==true)
             {
              if(this.order.list==null || this.order.list.length==0 )
              {
              this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
              null,null,0,null,1,null,null,null,null,null,null,null,null,0).subscribe((res:any)=>{
                this.orderService.order=null;
                sessionStorage.CATEGORY_FILTER="";
                this.dataService.endToppings.next(true);
                this.endToppings.emit();
                let url: string = "/restaurant/" + this.categoryItem.CategoryEn;
                this.router.navigateByUrl(url);
              //this.router.navigate(['../restaurant']);
                 });
              }
              else
            {
              localStorage.ID=id;
          this.matDialog.open(PopupOrderComponent, {
            data: { OrderID: this.order.OrderID },
          });
        }
        }
         
        }
      });
    }
    }
    else if(CategoryNumber==2)
    {
      localStorage.TEXT="";
     // localStorage.ID=id;
     localStorage.ID_CATEGORY=id;
      let url: string = "/restaurantCategory/" + this.categoryItem.CategoryEn;
      this.router.navigateByUrl(url);
      //this.router.navigate(['../restaurantCategory']);
    }}
      }
});}
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PopupErrorComponent, { disableClose: true });
  }

  addFevorit()
  {
    if(sessionStorage.ACCESS_TOKEN  == "null")
    this.router.navigate(['../mainHome']);
    else
    {
    this.clickFevorite=true;
   this.bussinssService.setFavoriteBusiness(this.categoryItem.ID).subscribe((res:any)=>{
    //  if(this.category.Name=="מסעדות שמורות")
    //  this.router.navigate(["../reservedRestaurants"]);
    //  else  if(this.category.ID==33)
    //  {
      if(this.categoryItem.IsFevorite===0)
      this.categoryItem.IsFevorite=1;
      else
      this.categoryItem.IsFevorite=0;
      this.change.emit();
   //  this.router.navigate(["../restaurantCategory"]);
  //    }
  //  else
  //  this.change.emit();
   //this.router.navigate(["../mainHome"]);
  });
}
}
}
