import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  url: string = "https://baliapp.co.il/textfile/tos.htm";
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer,private matDialog:MatDialog) { }

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  backPress()
  {
    this.matDialog.closeAll();
  }

}
