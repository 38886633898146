import { Injectable } from '@angular/core';
import { AppProxy, HttpMethod } from "./app-proxy";
import { ServerResponse } from 'http';
import { OrderAdding ,AdditonOrder, OrderAddingItemFood ,OrderHistory, MyOrder, HistoryDelivery} from '../models/order';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ChangeFood, Restaurant, ToppingsItemFood } from '../models/restaurant';
import { DialogOutOfBoundsComponent } from '../components/dialog-out-of-bounds/dialog-out-of-bounds.component';
import { MatDialog } from '@angular/material/dialog';
import { UserEditProfile } from '../models/user';
import { request } from 'https';

@Injectable()
export class OrdersService 
{
orderAddingList:OrderAdding[];
orderHistoryList:OrderHistory[];
historyDeliveryList:HistoryDelivery[];
public order:MyOrder;
pos:number=-1;
position:number=-1;
orderDelivery:boolean=false;
deliveryOk:boolean=false;
deliveryCancel:boolean=false;
delivery:boolean=false;
restaurant:Restaurant;
animal: string;
name: string;
oneTimeOrderOrOrder:string="Order";

    constructor(private proxy: AppProxy ,private router: Router,public dialog: MatDialog){}  

    getOrderDetails(nextPage:boolean) 
    {
      this.order=null;
        var url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/getOrderDetails";
        if(sessionStorage.ACCESS_TOKEN == null || sessionStorage.ACCESS_TOKEN == "null" )
        {
          url= this.proxy.baseQAUrl+'oneTimeOrder'+"/getOrderDetails";
        }
        return new Observable(observer => {
        this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
        .subscribe(
            result => {
                let res: any = result;
                if (res.error==false)
                 {
                  let resList: MyOrder = res;
                  this.order=resList; 
                    
                  observer.next(this.order)              
                }
                else
                {
                  observer.next(this.order)
                }
              },
            error =>  observer.next(this.order));
        });
    }

    addToBagOrder(MenuID:number,OrderID:number ,DelNum:number)
    {
        let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&MenuID="+MenuID+"&DelNum="+DelNum;
        return new Observable(observer => {
        this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
        .subscribe(
            result => {
                let res: any = result;
                if (res.error==false)
                 {
                  let resList: ServerResponse = res;
                  observer.next(resList);
                }
              },
            error => console.log('oops', error));  
        });
    }

    addToBagOrder_(MenuID:number,RestaurantID:number,Count:number)
    {
        let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/addToBagOrder?MenuID="+MenuID+"&RestaurantID="+RestaurantID+
        "&Count="+Count;
        return new Observable(observer => {
        this.proxy.execute<ServerResponse>(url,HttpMethod.Post)
        .subscribe(
            result => {
                let res: any = result;
                if (res.error==false)
                 {
                  let resList: ServerResponse = res;
                  observer.next(resList);
                }
              },
            error => console.log('oops', error));  
        });
    }

    // openOutOfBoundsDialog(): void {
    //   const dialogRef = this.matDialog.open(DialogOutOfBoundsComponent, {
    //     data: {name: this.name, animal: this.animal},
    //   });
  
    //   dialogRef.afterClosed().subscribe(result => {
    //     //console.log('The dialog was closed');
    //     this.matDialog.closeAll();
    //     this.animal = result;
    //   });
    // }

    setUpdateOrderEmploye(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number
      ,Status:number,Note:string,IsAddress:number,Address:string,Latitude:string,Longitude:string,
      IsEmploye:number,BudgetCode:string, IsFromLink:number){
        let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude+"&IsEmploye="+IsEmploye+
      "&BudgetCode="+BudgetCode+"&IsFromLink="+IsFromLink;
    //   if(IsAddress==1)
    //  url= this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
    //   "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
    //   +"&Note="+Note+"&IsAddress="+IsAddress+"&IsEmploye="+IsEmploye+
    //   "&BudgetCode="+BudgetCode;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
              
              }
              observer.next(res);
            //   else if(res.error==true && res.responseMessage=="the delivery out of bounds")
            //   this.proxy.showError("הכתובת שלך מחוץ לטווח","");
            //   else
            // {
            //   this.matDialog.
            //   this.proxy.showError("קוד עובד שגוי","");
            // }
              // else if(IsGiftCard==1)
               // observer.next(res);
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,MenuID:number
      ,Count:number,Status:number,Note:string,IsAddress:number,Address:string,Latitude:string,Longitude:string,IsGiftCard:number,
      GiftNumber:number,SumGift:number,card_mask:string,card_validity:string ,IsFromLink:number){
        let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&MenuID="+MenuID+"&Count="+Count+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude+"&IsGiftCard="
      +IsGiftCard+"&GiftNumber="+GiftNumber+"&SumGift="+SumGift+"&card_mask="+card_mask+
      "&card_validity="+card_validity+"&IsFromLink="+IsFromLink;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
        
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                //if(Status==2 && PaymentType==1)
               // this.router.navigate(['../endOrder']);
                observer.next(res);
              }
               else if(IsGiftCard==1)
                observer.next(res);
               else if(res.error==true && Status==2)
               this.proxy.showError("שגיאה בסיום ההזמנה ",""); 
               else  if(res.responseMessage=="the delivery out of bounds")
               observer.next(res);
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder1(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,Status:number,Note:string,IsAddress:number){
      let url;
      if(Note!=null)
        url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&Status="+Status+"&IsAddress="+IsAddress+"&Note="+Note;
      else
      url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&Status="+Status+"&IsAddress="+IsAddress;
      
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              observer.next(res);
              if (res.error==false)
               {
                let resList: ServerResponse = res;
              
                if(IsDelivery>0 || IsPickUp>0 || IsSitting>0)
                if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
                this.router.navigate(["../payment"]);
              
                else
                this.router.navigate(['../securePayment']); 
              }
           //  else if(res.error==true && res.responseMessage=="the delivery out of bounds")
           //{
           //  this.openDialog();
            // this.proxy.showError("כתובת המשלוח מחוץ לתחום","");
         //  }
            },
          error => console.log('oops', error));   
      });
    }

    setOrderAddress(Address:string,Latitude	:string,Longitude:string)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setOrderAddress?Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude;
     return new Observable(observer => {
     this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
       .subscribe(
         result => {
             let res: any = result;
             if (res.error==false)
              {
               observer.next(res);
          
             }
             else this.proxy.showError(res.responseMessage,"");
           },
         error => 
         {
           //console.log('oops', error)
       });
       
     });
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(DialogOutOfBoundsComponent, {
        data: {name: this.name, animal: this.animal},
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        this.dialog.closeAll();
        this.animal = result;
      });
    }

    setUpdateOrder2(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number,Note:string,IsAddress:number,IsFromLink:number){
      let url;
      if(Note!=null)
        url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress+"&IsFromLink="+IsFromLink;
      else
      url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
      "&IsAddress="+IsAddress+"&IsFromLink="+IsFromLink;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              //if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);             
              }       
            },
          error => console.log('oops', error));   
      });
    }

    getOrderAdditon(MenuID,MenuNumber,pos,position)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/getOrderAdditon?MenuID="+MenuID+"&MenuNumber="+MenuNumber;
      return new Observable(observer => {
      this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                  let resList: AdditonOrder = res;
                  this.orderAddingList=resList.list;
                  observer.next(resList);
                  let orderAddingItemFood=new OrderAddingItemFood();
                  orderAddingItemFood.ID=0;
                  orderAddingItemFood.Price=0;
                  orderAddingItemFood.Select=false;
                  if(this.restaurant!=null && this.restaurant.MenuList!=null && this.restaurant.MenuList[pos].ChangeFood!=null)
                 {
                  let changeFood=new ChangeFood();
                  if(this.restaurant.MenuList[pos].ChangeFood!=null)
                  {
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Text!=null)
                  changeFood.Text=this.restaurant.MenuList[pos].ChangeFood[position].Text;
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Name!=null)
                  changeFood.Name=this.restaurant.MenuList[pos].ChangeFood[position].Name;
                  let top:OrderAdding[]=[];
                  changeFood.toppings=top;
                  this.restaurant.MenuList[pos].ChangeFood[position]=changeFood;
                  this.restaurant.MenuList[pos].ChangeFood[position].toppings=this.orderAddingList;
                 }}
                 if(this.orderAddingList.length>0)
                 {
                   if(position>=0)
                   {
             //    if(this.orderAddingList.length==1 && this.orderAddingList[0].listSelect.length>0 )
               //  this.router.navigate(['../toppings',{pos, position}]); 
               //  else
               //  this.router.navigate(['../toppings',{pos, position}]);   
                   }
                 }
                    //else this.proxy.showError("אין רשימת תוספות ","");           
              }
            },
          error => console.log('oops', error));
      });  
    }

    getOrderAdditon1(MenuID,MenuNumber,pos,position)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/getOrderAdditon1?MenuID="+MenuID+"&MenuNumber="+MenuNumber;
      return new Observable(observer => {
      this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                  let resList: AdditonOrder = res;
                  this.orderAddingList=resList.list;
                  observer.next(resList);
                  let orderAddingItemFood=new OrderAddingItemFood();
                  orderAddingItemFood.ID=0;
                  orderAddingItemFood.Price=0;
                  orderAddingItemFood.Select=false;
                  if(this.restaurant!=null && this.restaurant.MenuList!=null && this.restaurant.MenuList[pos].ChangeFood!=null)
                 {
                  let changeFood=new ChangeFood();
                  if(this.restaurant.MenuList[pos].ChangeFood!=null)
                  {
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Text!=null)
                  changeFood.Text=this.restaurant.MenuList[pos].ChangeFood[position].Text;
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Name!=null)
                  changeFood.Name=this.restaurant.MenuList[pos].ChangeFood[position].Name;
                  let top:OrderAdding[]=[];
                  changeFood.toppings=top;
                  this.restaurant.MenuList[pos].ChangeFood[position]=changeFood;
                  this.restaurant.MenuList[pos].ChangeFood[position].toppings=this.orderAddingList;
                 }}
                 if(this.orderAddingList.length>0)
                 {
                   if(position>=0)
                   {
              //   if(this.orderAddingList.length==1 && this.orderAddingList[0].listSelect.length>0 )
                // this.router.navigate(['../toppings',{pos, position}]); 
               //  else
               //  this.router.navigate(['../toppings',{pos, position}]);   
                   }
                 }
                   // else this.proxy.showError("אין רשימת תוספות ","");           
              }
            },
          error => console.log('oops', error));
      });  
    }

    setOrderAdditionSite(ID:number,AdditionList:string,CountNumber:number)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setOrderAdditionSite?ID="+ID+"&CountNumber="+CountNumber+"&AdditionList="+AdditionList;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בבחירת תוספת","");
            },
          error => console.log('oops', error));
      }); 
    }

    setOrderAddition(ID:number,AdditionType:number,AdditionList:string,CountNumber:number)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setOrderAddition?ID="+ID+"&AdditionType="+AdditionType+"&AdditionList="+AdditionList+"&CountNumber="+CountNumber;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בבחירת תוספת","");
            },
          error => console.log('oops', error));
      }); 
    }

    setOrderAddition2(ID:number,AdditionType:number,AdditionList:string,CountNumber:number,AdditionType2:number)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setOrderAddition?ID="+ID+"&AdditionType="+AdditionType+"&AdditionList="+AdditionList+"&CountNumber="+CountNumber+"&AdditionType2="+AdditionType2;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בבחירת תוספת","");
            },
          error => console.log('oops', error));
      }); 
    }

    setInviteName(MenuID:number,MenuNumber:number,InviteName:string)
    {
      let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setInviteName?MenuID="+MenuID+"&MenuNumber="+MenuNumber+"&InviteName="+InviteName;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בהוספת שם למנה ","");
            },
          error => console.log('oops', error));
      }); 
    }

   GetHistoryOrders(Search:string)
   {
    let url;
     if(Search!=null)
     url= this.proxy.baseQAUrl+"Order/GetHistoryOrders?Search="+Search;
    else
    url= this.proxy.baseQAUrl+"Order/GetHistoryOrders";
    return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
                let resList: OrderHistory[] = res.list;
                this.orderHistoryList=resList;
                if(this.orderHistoryList.length>0)
                observer.next(this.orderHistoryList)
                else this.proxy.showError("אין הזמנות בהיסטוריה","");
            }
          },
        error => console.log('oops', error)); 
    });
   }

   getDownloadInvoice(ID:number)
   {
   let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/getDownloadInvoice?ID="+ID;
   return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)  
        {
              observer.next(res)  
        }
          },
        error => console.log('oops', error)); 
    });
   }

  setNewDelivery(Origin:string,Destination:string,DeliveryTime:string,Phone:string,Description:string)
  {
    let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setNewDelivery?Origin="+Origin+"&Destination="+Destination+
    "&DeliveryTime="+DeliveryTime+"&Phone="+Phone+"&Description="+Description;
    return new Observable(observer => {
    this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
              let resList: ServerResponse = res;
              this.orderDelivery=true;
              observer.next(this.orderDelivery);
            }
            else this.proxy.showError("שגיאה בהוספת משלוח","");
          },
        error => console.log('oops', error)); 
      }); 
  }

  setDeliveryStatus(Status:number)
  {
    let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setDeliveryStatus?Status="+Status;
    return new Observable(observer => {
    this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
              let resList: ServerResponse = res;
            this.orderDelivery=true;
            observer.next(this.orderDelivery);
            }
            else this.proxy.showError("שגיאה בעידכון סטטוס משלוח","");
          },
        error => console.log('oops', error)); 
    });  
  }

 getHistoryDelivery()
 {
  let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/getHistoryDelivery";
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
              let resList: HistoryDelivery[] = res.list;
              this.historyDeliveryList=resList;
              if(this.historyDeliveryList.length>0)
              observer.next(this.historyDeliveryList);
              else this.proxy.showError("אין משלוחים בהיסטוריה","");   
          }
          else this.proxy.showError("אין משלוחים בהיסטוריה","");
        },
      error => console.log('oops', error)); 
  });
 }

 setOrderRating(Rate:number)
 {
  let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setOrderRating?Rate="+Rate;
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
          }
          else this.proxy.showError("שגיאה בעידכון דירוג","");
        },
      error => console.log('oops', error));  
 }

setNewOrderByHistory(ID:number)
{
  let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setNewOrderByHistory?ID="+ID;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(resList)
           // this.getOrderDetails(true);
          }
          else this.proxy.showError("שגיאה באחזור הזמנה מההיסטוריה","");
        },
      error => console.log('oops', error));  
  });
}

// setOrderPayment(card_mask:string,card_validity:string,card_cvv:string,user_identity:string,invoice_name:string,TotalPrice:number)
// {

//  let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setOrderPayment?card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name
//   +"&TotalPrice="+TotalPrice;
//   return new Observable(observer => {
//   this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
//     .subscribe(
//       result => {
//           let res: any = result;
//          // if (res.error==false)
//            {
//             let resList: UserEditProfile = res;
//             observer.next(res);
       
//           }
//         //  else this.proxy.showError(res.responseMessage,"");
//         },
//       error => 
//       {
//         //console.log('oops', error)
//        this.proxy.showError("סירוב כרטיס אשראי","");
//     });
    
//   });
// }

setUpdateOrder22(OrderID:number,UserName:string,UserPhone:string,
  userEmail:string,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number,Note:string,IsAddress:number){
  let url;
  if(Note!=null)
    url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&UserName="+UserName+"&UserPhone="+UserPhone+"&userEmail="+userEmail+"&IsDelivery="+IsDelivery+
  "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
  +"&Note="+Note+"&IsAddress="+IsAddress;
  else
  url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&UserName="+UserName+"&UserPhone="+UserPhone+"&userEmail="+userEmail+"&IsDelivery="+IsDelivery+
  "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
  "&IsAddress="+IsAddress;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(res);             
          }       
        },
      error => console.log('oops', error));   
  });
}

setUpdateOrder3(OrderID:number,UserName:string,UserPhone:string,
  userEmail:string,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number
  ,Count:number,Status:number,Note:string,Address:string,Latitude:string,Longitude:string){
    let url= this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&UserName="+UserName+"&UserPhone="+UserPhone+"&userEmail="+userEmail+"&IsDelivery="+IsDelivery+
  "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Count="+Count+"&Status="+Status
  +"&Note="+Note+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(res);
          }
           else if(res.error==true && Status==2)
           this.proxy.showError("שגיאה בסיום ההזמנה ",""); 
           else  if(res.responseMessage=="the delivery out of bounds")
           observer.next(res);
        },
      error => console.log('oops', error));   
  });
}

setUpdateOrder4(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number,IsFromLink:number){
  let url;
 // url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&Status="+Status;
  url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
  "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status+"&IsFromLink="+IsFromLink;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(res);             
          }       
        },
      error => console.log('oops', error));   
  });
}

setUpdateOrder5(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number,Address:string,Latitude:string,Longitude:string,IsFromLink:number){
  let url;
  url = this.proxy.baseQAUrl+localStorage.oneTimeOrderOrOrder+"/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
  "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude+"&IsFromLink="+IsFromLink;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(res);             
          }       
        },
      error => console.log('oops', error));   
  });
}
}