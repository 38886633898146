<div style="
background-size: contain;
height: 2688px;">

    <form class="form-signin">   
        <div style="text-align: center; ">
            <img class="icon" src="assets/images/Popup_Logo.png">
            <br>
            <br>
            <span class="header" style="color: #41c777; font-size: 20px; font-weight:bold ;" >!שימו לב - לא ניתן לספק את ההזמנה באזורך</span>
            <br>
            <span class="text" style="color: #41c777; font-size: 20px; font-weight:bold ;" >עליך להזין כתובת באילת וסביבתה</span>
            <br>

      </div>
                  <br>
                    <div (click)="ok()" class="btm" style="margin-top: 30%;  font-size: 15px; ">
                      <p  class="p" style="cursor: pointer;color: #29c066; font-weight: bold ;">אישור</p>
                
                    </div>          
    </form>
</div>
