<div class="d-flex textSize" style="font-family: OpenSansHebrewRegular; width: 100%; font-weight: bold;">
  <div  class="d-flex">

     <img style="width: 90px; border-radius: 10px;
    height: 90px;"  [src]="orderItem.ImgFile"/></div>
    <div  class="flex-fill bd-highlight">
        <div class="d-flex bd-highlight" 
        style=" height: 27px; text-align: right; ">
   
  <div style="text-align: right ;" class="col-md-6">{{orderItem.RestaurantName}}</div>
  <div class="col-md-4" style="text-align: right;">{{orderItem.Price}} ₪</div>
    <div class="col-md-2" style="text-align: left; margin-right: 5px;">{{orderItem.CreatedDate| date:'dd/MM/yyyy'}}</div>
        </div>
        <div class="d-flex bd-highlight">
  <div class="numOrder" style="text-align: right ;margin-top: 5px; padding-right: 15px;" >הזמנה מספר: {{orderItem.OrderID}}</div>
        </div>
        <div class="d-flex bd-highlight" style="margin-top: 18px;" [ngStyle]="orderItem.PaymentType!=0 && {'margin-top':'35px'}">
          <div class="col-md-4">
             <div >
            <div class="keep"  (click)="orderAgain() " style="margin-right: 15px;"   [ngStyle]="orderItem.PaymentType!=0 && {'bottom':'2px'}">
              <img src="assets/images/Order_Button_1.png">               
              <span style="color: white; font-size: 13px;" class="btn">הזמן מחדש</span>
            </div>
           </div></div>
          <div class="col-md-4" style="color:#b0b0b0"></div>
            <div class="col-md-4" style="text-align: left;color:#b0b0b0; margin-top: 7px;" > 
            <div class=" align-self-end  p-2 flex-fill bd-highlight" style="
              text-align: left;"> 
                <span  *ngIf="orderItem.PaymentType==0" class="invoiceDown" (click)="downloadPdf()" style=" cursor: pointer; color: #41c777; margin-left: 5px;">הורד חשבונית</span>
                <img *ngIf="orderItem.PaymentType==0"  src="assets/images/History_Invoice_Down_Icon.png"/>
                </div></div>
        </div>     
<div >
</div>
</div>
</div>
<mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
<img width="99%" style="height: 1px;" src="assets/images/History_Line_2.png"/>
