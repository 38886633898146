<div *ngIf="header" class="topBar" style="background-image: url(assets/images/Topbar_Green_2.png); font-family: OpenSansHebrewBold;">
<div class="toolbar" style="margin-left: auto; margin-right: auto; height: auto;">
  
<div class="d-flex col-12" style="align-items: center;">
 
  <img *ngIf="!deepLink" style="margin-top: 2px;" class="icon display" (click)="menu()" src="assets/images/menu_button.png"/>
    <img *ngIf="!deepLink" style="margin-top: 5px;" class="icon header" (click)="mainHome()" src="assets/images/Topbar_Icon.png"/>
    <div class="d-flex col-10 " style="padding-right: 0px; padding-left: 0px; margin-top: 5px;">
    <a *ngIf="!deepLink" style="font-size: 16px;" (click)="search()" style="margin-right: 0px;" class="nav-link textHeader header">מצא מסעדות</a>
    <!-- <a *ngIf="token == 'order'" style="font-size: 16px;" (click)="delivery()"   class="nav-link textHeader header" >הזמן שליח</a> -->
    <a *ngIf="token == 'order'" style="font-size: 16px;" (click)="orderHistory()"  class="nav-link textHeader header" >היסטורית הזמנות</a>
    <a  *ngIf="token == 'order'" style="font-size: 16px;" (click)="reservedRestaurants()" class="nav-link textHeader header" >בתי עסק שמורים</a>
    <!-- <a  *ngIf="token == 'order'" style="font-size: 16px;" (click)="shippingHistory()" class="nav-link textHeader header" >היסטורית משלוחים</a> -->
    <a style="font-size: 16px;" (click)="inOrOut()" class="nav-link textHeader header" >{{logInOrOut}} </a>
    <div *ngIf="show" [class.isDisabled]="clickPayment==false" (click)="payment()" class="txt-input priceWidth" style="position: relative; left: -5%; background-image: url(assets/images/Price_Box.png);
    height: 30px; width: 190px; margin-top: 2px;
    background-size: 100% 100%; background-repeat: no-repeat;">
      <a style="font-size: 14px; color: white; width: 100%; position: absolute; padding-right: 0;
      padding-left: 0; height: 100%; margin-top: 7px;" class="nav-link textHeader priceText">{{priceTotal}} </a> 
     </div>
    <!-- <div  class="nav-link textHeader keep">
      <img src="assets/images/Button_Off.png">               
      <span style="font-size: 20px;"  class="nav-link textHeader" >0-פריטים</span>
    </div> -->
    
  
  </div>
      <div *ngIf="!searchByFood"  class="txt-input d-flex col3 " style="background-image: url(assets/images/Topbar_Search_Box.png); position: absolute;
      left: 0;">
          <img (click)="searchByText()" style="cursor: pointer; padding-right: 25px;" src="assets/images/Topbar_Search_Icon.png"/>
          <input [(ngModel)]="text"  (keydown.enter)="searchByText()" name="searchText"  style="height: 30px; font-weight: normal; font-size: 14px; color: black; font-family: OpenSansHebrewRegular;"  [style.color]="'black'" placeholder="מה בא לי לאכול"/>
      </div>
      <div (click)="searchByTextFood()" (keydown.enter)="searchByTextFood()" *ngIf="searchByFood && showSearch" class="txt-input d-flex col3 " style="background-image: url(assets/images/Topbar_Search_Box.png); position: absolute;
      left: 0;">
          <img  style="cursor: pointer; padding-right: 25px;" src="assets/images/Topbar_Search_Icon.png"/>
          <input [(ngModel)]="text" name="searchText" style="height: 30px; font-weight: normal; font-size: 14px; color: black; font-family: OpenSansHebrewRegular;" [style.color]="'black'" placeholder="חיפוש מנה"/>
      </div>
  </div>
  <mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style="justify-content: center; align-items: center; margin: 0px auto; width: 70px;
  position: absolute; height: 70px; right: 0; left: 0; top: 365%;" [diameter]="70" mode="indeterminate"></mat-spinner>
  <!-- <div style="margin-top: 8px;">
    <div *ngIf="price==0" class="amount" style="background-image: url(assets/images/Button_Off.png);">
      <span style="font-size: 20px; padding-top: 8px;"  class="nav-link textHeader" >{{priceTotal}}</span>
    </div>
      <div (click)="payment()" *ngIf="price>0" class="amount" style="background-image: url(assets/images/Button_On.png);">
      <span style="font-size: 20px; padding-top: 8px;"  class="nav-link textHeader" >{{priceTotal}}</span>
    </div>
    <br>
    <br>
        </div>  -->
  
      <!-- <div style="margin: auto;
      margin-left: initial; margin-top: 21px;" >
        <img style="margin-left:5px;     vertical-align: text-bottom;"  src="assets/images/Person_Icon.png"/>
        <span (click)="connect()" style="cursor: pointer; margin-bottom: 7px; color: #b0b0b0;margin-left:30px; font-size: 14px;">התחבר/הרשם</span>
        </div> -->
  </div>
</div>
     