import { Component, OnInit ,EventEmitter  ,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoryDelivery } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';
import { DataService } from 'src/app/services/data.service';
import { OrdersService } from 'src/app/services/order-service';

@Component({
  selector: 'app-shipping-history',
  templateUrl: './shipping-history.component.html',
  styleUrls: ['./shipping-history.component.scss']
})
export class ShippingHistoryComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  orders:HistoryDelivery[]= new Array();
  filteredItems:HistoryDelivery[]= new Array();
  searchText:String;
  text: string;

  constructor(private dataService:DataService ,private proxy: AppProxy, private route: ActivatedRoute,private orderService:OrdersService) { }

  ngOnInit(): void {
    this.proxy.scrollToTop();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.orderService.getHistoryDelivery().subscribe((res:any)=>{
      this.orders=res;
      this.assignCopy();
  });
    this.orderService.getHistoryDelivery().subscribe;
    this.orders==this.orderService.historyDeliveryList;
    this.assignCopy();
    }

    assignCopy(){
      this.filteredItems = Object.assign([], this.orders);
   }
  
    filterItem(value){
      if(!value){
          this.assignCopy();
      } // when nothing has typed
      this.filteredItems = Object.assign([], this.orders).filter(
         item => item.Description.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
   }
}
