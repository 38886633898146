<div >
           <div class="d-flex flex-wrap container page" style="font-family: OpenSansHebrewRegular;">
                <div class="d-flex" style="width: 100%; margin-top: 10px;" >
                <div class="imgs logo">     
                    <label  for="fileInput">
                        <img  *ngIf="url==null" class="imgA1" src="assets/images/Register_Profile_Image_Avatar.png"  >
                      <div *ngIf="url!=null" [ngClass]="width > height ? 'circular--portrait' : 'circular-portrait'">    
                        <img [src]="url"  />
                      </div>
                        <img  class="imgB1" src="assets/images/Register_Edit_Profile_Image.png" (click)="onSelectFile($event)">
                    </label>
                      <br/>                
                    <input id="fileInput" type='file' (change)="onSelectFile($event)" accept="image/png,image/jpg,image/jpeg">
                   <!-- <span class="logout" *ngIf="isEditProfile==true" style="font-weight: bold; font-size: 13px;"> {{user.userFullName}}</span> -->
                  </div>
                  <div class="col-md-6 frame">
    <form class="form-signin form" [formGroup]="registerForm" style="display: inline-block; left: 28%; position: absolute; font-size: 12px;"> 
        <div >
    <div class="txt-input">
        <img style="padding-left: 10px;" class="align-self-center " src="assets/images/Contact_Icon_1.png"/>
        <input style="font-weight: bold; width: 350px;"  matInput name="userFullName" formControlName="userFullName" [(ngModel)]="user.userFullName" placeholder="שם מלא" type="text"  #ft01 (keyup.enter)="keytab($event)" autofocus   required/>
    </div>
    <br>
    <br>
        <div class="txt" [ngClass]="!isValidInput('userFullName') ? 'line-gray_' : 'line-pink'"></div>
        <span *ngIf="isValidInput('userFullName')" style="color: #ff8585; font-size: 9px; display: flex;">שדה חובה</span> 
        </div> 
        <br *ngIf="!(isValidInput('userFullName'))">  
        <div>
            <div class="txt-input" >
                <img style="padding-left: 10px;" class="align-self-center" src="assets/images/Contact_Icon_2.png"/>
                <input  style="font-weight: bold; width: 350px;" matInput name="userEmail" formControlName="userEmail" [(ngModel)]="user.userEmail" placeholder="מייל (אופציונלי)" type="email"  #ft01 (keyup.enter)="keytab($event)" autofocus/>
            </div>
            <br>
            <br >        
            <div class="txt" [ngClass]="!isValidInputMail('userEmail') ? 'line-gray_' : 'line-pink'"></div>
            <span *ngIf="isValidInputMail('userEmail')" style="color: #ff8585; font-size: 9px; display: flex;">{{errorMail}}</span> 
                </div>  
                         <br *ngIf="isEditProfile==false && !(isValidInputMail('userEmail'))">  
                         <div *ngIf="isEditProfile==false">
                                <div class="txt-input" >
                                    <img style="padding-left: 7px;" class="align-self-center" src="assets/images/Contact_Icon_4.png"/>
                                    <input style="font-weight: bold; width: 350px;" matInput name="userPassword" formControlName="userPassword" placeholder="סיסמא" [(ngModel)]="user.userPassword" type="password"  #ft01 (keyup.enter)="keytab($event)" autofocus required/>
                                </div>
                                <br>                  
                                <br>
                                <div class="txt" [ngClass]="!isValidInputPassword('userPassword') ? 'line-gray_' : 'line-pink'"></div>
                                <span *ngIf="isValidInputPassword('userPassword')" style="color: #ff8585; font-size: 9px; display: flex;">{{errorPassword}}</span> 

                                    </div>  
                                    <br *ngIf="isEditProfile==false && !(isValidInputPassword('userPassword'))">
                                    <div *ngIf="isEditProfile==false">
                                        <div class="txt-input" >
                                            <img style="padding-left: 5px;" class="align-self-center" src="assets/images/Contact_Icon_4.png"/>
                                            <input  style="font-weight: bold; width: 350px;"  matInput name="userPasswordAgain" formControlName="userPasswordAgain" [(ngModel)]="user.userPasswordAgain" placeholder="הזן סיסמא שנית" type="password" required  #ft01 (keyup.enter)="keytab($event)"  ng-pattern="userPassword"/>
                                        </div>
                                        <br>
                                        <br>
                                        <div class="txt" [ngClass]="!isValidInputPasswordAgain('userPasswordAgain') ? 'line-gray_' : 'line-pink'"></div>
                                        <span *ngIf="isValidInputPasswordAgain('userPasswordAgain')" style="color: #ff8585; font-size: 9px; display: flex;">{{errorPasswordAgain}}</span> 
                                            </div>  
                                            <br *ngIf="!(isValidInputPasswordAgain('userPasswordAgain'))">
                                            <div>
                                                <div class="txt-input" >
                                                    <img  style="padding-left: 9px;" class="align-self-center" src="assets/images/Contact_Icon_3.png"/>
                                                    <input style="font-weight: bold; width: 350px;" matInput pattern="^05\d{8}$" name="userPhone" minlength="9" maxlength="10" formControlName="userPhone" placeholder="טלפון"  #ft01 (keyup.enter)="keytab($event)" [(ngModel)]="user.userPhone" type="tel" required/>
                                                  
                                                  </div>
                                                <br>
                                                <br>
                                                <div class="txt" [ngClass]="!isValidInputPhone('userPhone') ? 'line-gray_' : 'line-pink'"></div>
                                                <span *ngIf="isValidInputPhone('userPhone')" style="color: #ff8585; font-size: 9px; display: flex;">{{errorPhone}}</span>
                                                    </div>  
                                                    <br *ngIf="!(isValidInputPhone('userPhone'))" >
                                                    <div>
                                                        <div class="txt-input" >
                                                            <img style="padding-left: 7px;" class="align-self-center" src="assets/images/Delivery_Popup_Icon_1.png"/>                                    
                                                                <!-- <div class="map-container">
                                                                  <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                                                                    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                                                                  </agm-map>
                                                                </div> -->
                                                                
                                                                <div id="pac-container">
                                                                  <input id="pac-input" (click)="openMap()" style="font-weight: bold; width: 350px;" matInput name="userAddress" formControlName="userAddress" placeholder="רחוב כולל מספר בית"  #ft01 (keyup.enter)="keytab($event)" [(ngModel)]="user.userAddress" type="text" required>
                                                                </div>
                                                              </div>
                                                         <br>                           
                                                        <br>
                                                        <div class="txt" [ngClass]="!isValidInput('userAddress') ? 'line-gray_' : 'line-pink'"></div>
                                                        <span *ngIf="isValidInput('userAddress')" style="color: #ff8585; font-size: 9px; display: flex;">שדה חובה</span> 
                                                      </div>  
                                                      <br *ngIf="!(isValidInput('userAddress'))">  
                                                             <div class="d-flex address2" >
                                                               <div >
                                                            <div id="pac-container">
                                                              <input class="address" style="font-weight: bold; width: 100px; padding: 4px;" matInput name="userEnter" formControlName="userEnter" placeholder="כניסה" [(ngModel)]="user.userEnter" type="text" >
                                                            </div>
                                                  
                                                            <div  class="txt2" [ngClass]="!isValidInput('userEnter') ? 'line-gray_' : 'line-pink'"></div>
                                                          </div>
                                                          <div class="addressLine" style="margin-right: 10px;">
                                                            <div id="pac-container">
                                                              <input class="address" style="font-weight: bold; width: 100px; padding: 4px;" matInput name="userFloor" formControlName="userFloor" placeholder="קומה" [(ngModel)]="user.userFloor" type="number" >
                                                            </div>
                                                  
                                                            <div class="txt2" [ngClass]="!isValidInput('userFloor') ? 'line-gray_' : 'line-pink'"></div>
                                                          </div>

                                                          <div class="addressLine" style="margin-right: 10px;">
                                                            <div id="pac-container">
                                                              <input class="address"  style="font-weight: bold; width: 100px; padding: 4px;" (keydown.enter)="handleKeyDown()" matInput name="userNumberHoush" formControlName="userNumberHoush" placeholder="דירה" [(ngModel)]="user.userNumberHoush" type="number" >
                                                            </div>
                                                                               
                                                            <div class="txt2" [ngClass]="!isValidInput('userNumberHoush') ? 'line-gray_' : 'line-pink'"></div>
                                                          </div>
                                                          </div>
                                                          <!-- <div> -->
                                                          <div style="position: absolute; margin: auto; right: 0; left: 6%;
                                                          text-align: center;" [ngClass]="isEditProfile==true ? 'keep' : 'keepEdit'" (click)="keep()"  [class.isDisabled]="registerForm.valid" >
                                                            <img class="img" [src]="registerForm.valid == true ? keepEnabled : keepUnEnabled" style="margin-top: 20px;">               
                                                            <span [ngClass]="isEditProfile==true ? 'centered1' : 'centered' "   [ngStyle]="registerForm.valid && {'color':'white'}">שמור</span>
                                                          </div>
                                                          <!-- <div  style="left: -50px; position: absolute;" [ngClass]="isEditProfile==true ? 'logout' : 'logoutEdit'"  (click)="logOut()"  [class.isDisabled]="registerForm.valid" >
                                                            <img class="img" [src]="keepEnabled">               
                                                            <span class="centered"  style="color:white">התנתק</span>
                                                          </div> -->
                                                        <!-- </div> -->
                                                          <span class="termsUse" (click)="TermsUse()" style="color: black; position: absolute; margin-top: 75px; cursor: pointer;
                                                          width: 100%; left: 0; font-size: 14px;">בהרשמה לאתר זה, הנני לאשר את <b><u>מדיניות פרטיות ותקנון</u></b></span>
                                                        </form>           
                                                    </div>    
                                                    </div>                                                                 
                                    </div>
                                    </div>
                            
                                
                                 
                                    

     
                        
                
          