<div style="background-image: url(assets/images/BG_2.png);
background-size: contain;
height: 2688px; font-family: OpenSansHebrewRegular; width: 100vw;">
    <form *ngIf="orderFinal==false" class="form-signin"> 
        <img src="assets/images/Close_Popup_Button.png" />
        <div style="text-align: center; ">
            <img src="assets/images/Popup_Logo.png">
            <br>
            <br>
            <span style="color:#41c777; font-size: 11px;" > !דעתך חשובה לנו</span>
            <br>
            <br>
      <span >חשוב לנו לדעת אם נהנתם </span>
      <br>
      <span >מההזמנה, או אם יש צורך בשיפור</span>
      <br>
      <span >סמנו את הכוכבים לדירוג</span>
      </div>  
      <div  style="position: absolute; left: 15%; margin-top: 7px;" class="d-flex">
      <div style="margin-right: 7px;" *ngFor="let s of stars;index " >
        <img (click)="star()"[src]="s" src="assets/images/Star_Full_Small.png" style="cursor: pointer;"/>
    </div>
</div>     
                  <br>
                    <div class="btm" style="margin-top: 30%;">
                      <p class="p" style="color: #29c066; font-weight: bold ;">מגניב תודה!</p>
                    </div>          
    </form>
    <form *ngIf="orderFinal==true" class="form-signin">   
        <div style="text-align: center; ">
            <img src="assets/images/Popup_Logo.png">
            <br>
            <br>
            <span style="color: #41c777; font-size: 20px; font-weight:bold ;" > !איזה כיף</span>
            <br>
            <br>
      <span >משלוח טעים בדרך אליך וצפוי </span>
      <br>
      <span > .להגיע תוך 50 דקות</span>
      </div>
                  <br>
                    <div (click)="Continued()" class="btm" style="margin-top: 30%;">
                      <p  class="p" style="cursor: pointer;color: #29c066; font-weight: bold ;">מגניב:)</p>
                    </div>          
    </form>
</div>

