import { Component, OnInit , Output ,EventEmitter ,HostListener} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user-service';
import { LoginPhoneVarificationComponent } from '../login-phone-varification/login-phone-varification.component';
import {MatDialog, MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';
import { AppProxy } from 'src/app/services/app-proxy';
import { filter } from 'rxjs/operators';
import { Map2Component } from '../map2/map2.component';

@Component({
  selector: 'app-main-home',
  templateUrl: './main-home.component.html',
  styleUrls: ['./main-home.component.scss']
})
export class MainHomeComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  previousUrl:string;
  
  innerWidth:any;
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }
  
   constructor(private proxy: AppProxy, public router:Router,private bussinssService:BussinssService,private dataService:DataService ,private matDialog:MatDialog) {
    router.events
  .pipe(filter(event => event instanceof NavigationEnd))
  .subscribe((event: NavigationEnd) => {
    //console.log('prev:', event.url);
    this.previousUrl = event.url;
    if(localStorage.back=='true')
    {
      localStorage.back='false';
    if(this.previousUrl.startsWith("/mainHome") && this.router.url=="/mainHome")
    {
 
      this.router.navigate(["../mainHome"])
    }
    // if(this.previousUrl.startsWith("/restaurantCategory") && this.router.url=="/restaurantCategory/")
    // {
    //  let url: string = "/restaurantCategory/" + localStorage.ID;
    //  this.router.navigateByUrl(url);
    // }
    
   
  }
 });
  }

  ngOnInit(): void {
    window.scroll(0,0);
    this.proxy.scrollToTop(); 
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    localStorage.Delivery=-1;
    // let page=location.href;
    // let a=page.split('/')[3]
    let a=location.pathname;
    if(a=="/codeWorker")
    this.router.navigate(["../mainHome"])
    if(localStorage.SMS_CODE=="")
this.openDialog();
else{
     this.bussinssService.getLastMenu();
     //if(localStorage.ACCESS_TOKEN  == "null")
    // this.dataService.state.next("התחבר");
    // else
    // this.dataService.state.next("התנתק");
    // this.state.emit();
}
    }

    backPress()
    {
      this.router.navigate(["../mainHome"])
    }

openDialog(): void {
  const dialogRef = this.matDialog.open(LoginPhoneVarificationComponent, {
    backdropClass: 'cdk-overlay-transparent-backdrop',
    hasBackdrop: false,
  });
}
}
