import { Component, OnInit ,EventEmitter  ,Output  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { Search } from 'src/app/models/restaurant';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import { SectionsMetadata } from 'src/app/services/sections_metadata';
import { SEOService } from 'src/app/services/seo_service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
searchList:Search[];
@Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
readonly articles$ = this.route.data.pipe(pluck('search'));
  
constructor(private proxy:AppProxy,private SEOService: SEOService,
  private route: ActivatedRoute, private dataService:DataService ,private router: Router,private bussinssService:BussinssService) { }

  ngOnInit(): void {
    SectionsMetadata.meta.description =  this.route.snapshot.data.description;
  //  const { meta } = this.route.snapshot.data;
    this.SEOService.updateTitle(SectionsMetadata.meta.description);
    this.SEOService.updateDescription(SectionsMetadata.meta.description);
    this.dataService.search.next("");
    this.changByFood.emit();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.bussinssService.getGlobalSearch().subscribe((res:any)=>{
      this.searchList=res;
    });
  };

  category(c:string){
    localStorage.TEXT=c;
    localStorage.TEXT_SEARCH=c;
    localStorage.ID="0";
    localStorage.ID_CATEGORY="0";
   // this.router.navigate(['../restaurantCategory']);
    let url: string = "/restaurantCategory/" + c
    this.router.navigateByUrl(url);
  }
}
