<!-- <div style="display: flex; justify-content: center; position: relative;
top: -27px;">
<div (click)="payment()" class="amount d-flex" style="background-image: url(assets/images/Button__Green_On.png);">
  <span style="font-size: 20px; padding-top: 8px;"  class="nav-link textHeader" >{{priceTotal}}</span>
</div>
</div> -->
<div class="page" >
<div style="width: 100vw; direction: rtl;font-family: OpenSansHebrewRegular; margin-bottom: 20px;">
  <div class="m-auto widthPage" *ngFor="let c of categores ;let i = index" >
 <div  class="d-flex" style="width:100%; margin-top: 30px;">
   <span  class="name" style="color: black; margin-right: 2px;">{{c.Name}}</span>
      <div *ngIf="c.categoryType!=2"  style="margin: auto; cursor: pointer; margin-left: 19px;">
        <span class="name" *ngIf="i>0"(click)="more(i)" style="margin-left:5px; margin-top: 5px; color: black;
        margin-top: 5px;">הכל</span>
        <!-- <img class="align-baseline" style="margin-left: 0px; font-size: 2.5rem;"  src="assets/images/Left_Arrow.png"/>   -->
        </div>   
        </div>
        <mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
        align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
     <div *ngIf="c.categoryType==0" class="d-flex m-auto" >
       <ngb-carousel>
         <ng-template ngbSlide *ngFor="let ca of categoresItemLastMenu ;let i = index" >
             <div class="d-flex" (click)="imgSlide(i)" style="direction: rtl; cursor: pointer; margin-top: 10px;" >                    
                     <div class="image0"> 
                       <div style="position: relative; ">                              
                     <div style="height: 170px; position: absolute; left: 3%; top:0;">
                 <!-- <div style="font-size: 2rem; font-weight: 550;  margin-top: 20px; font-family: OpenSansHebrewBold;">{{c.Name}}</div>
                 <div style="margin-top: 18px; font-size: 12px; font-size: 1.8rem; font-weight: 540; " >{{c.Category}}</div>
      
                 <div class="d-flex">
      
                   <div class="d-flex" style="position: absolute; margin-top: 4px;">
            
                   <span style="font-size: 15px; font-family: OpenSansHebrewBold; margin-top: 4px; color: black;">{{c.Header}}</span>
                 </div>
                 </div> -->
                     <!-- <div class="keep"  (click)="orderNow(i)">
                       <img src="assets/images/Order_Button_1.png">               
                       <span style="font-size: 1.8rem; font-weight: 540; " class="btn">הזמן עכשיו</span>
                     </div> -->
                   </div>   
                   <img class="m-auto" style="width: 100%; height: auto;
                       max-height: 232px;  border-radius: 14px 14px 14px 14px;" [src]="ca.ImageFile" />                                    
             </div>  
               </div>
               </div>
         </ng-template>
       </ngb-carousel>
     </div>
     <div *ngIf="c.categoryType==2" class="d-flex flexWrap m-auto">
       <div  *ngFor="let i of c.categoryItems; let ind = index" style="margin-top: 10px; margin-left: 21px;" class="categoryItem">
         <app-category-item (change)="ngOnInit()" style="cursor: pointer;"  [categoryItem]="i" [category]="c" [indexList]="ind"></app-category-item>
           </div>
       </div>
     <div *ngIf="c.categoryType!=0 && c.categoryType!=2" class="d-flex m-auto flexWrap">
         <div style="display: flex;">  
             <div *ngFor="let i of c.categoryItems; let ind = index"  [ngStyle]="ind!=0 && {'margin-right':'20px'}">
               <div *ngIf="c.categoryType==2">
               <app-category-item (change)="ngOnInit()" style="cursor: pointer;" [categoryItem]="i" [category]="c" [indexList]="ind"></app-category-item>
             </div> 
             <div *ngIf="c.categoryType>2">
               <app-resraurant-item  (change)="ngOnInit()" *ngIf="innerWidth>480 && ind < 3 "  style="cursor: pointer;"  [categoryItem]="i" [category]="c" [indexList]="ind"></app-resraurant-item>
               <app-resraurant-item  (change)="ngOnInit()" *ngIf="innerWidth<=480 "  style="cursor: pointer;"  [categoryItem]="i" [category]="c" [indexList]="ind"></app-resraurant-item>

              </div>
               </div>
         </div>
      </div>
     </div>  
 </div>
 </div>

 
 
