import { Component, OnInit, Input , Output , EventEmitter, HostListener} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { LoginComponent } from '../app/components/login/login.component' 
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from './services/data.service';
import { OrdersService } from './services/order-service';
import { AppProxy } from './services/app-proxy';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserInactivityPopupComponent } from './components/user-inactivity-popup/user-inactivity-popup.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { url } from 'inspector';
import { PopupEnter2Component } from './components/popup-enter2/popup-enter2.component';
import { PopupEnterComponent } from './components/popup-enter/popup-enter.component';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppComponent {
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  showFooter:boolean=true;
  login:boolean=true;
  static priceTotal="פריטים-0"
  static price=0;
  userActivity;
  userInactive: Subject<any> = new Subject();
  innerWidth:any;
  idUrl:number=-1;
  private routeSub: Subscription;
  id:number=0;
  num=0;

  constructor(private activatedRoute: ActivatedRoute,private route: ActivatedRoute, private metaService:Meta, private router: Router ,private matDialog:MatDialog ,private dataService: DataService,private orderService:OrdersService, private proxy:AppProxy , private titleService: Title) {
    //this.setTimeout();
    this.metaService.updateTag({ content: 'Angular 4 meta service'} , 'name="description"' );
    //console.log('user has been inactive');
    this.userInactive.subscribe(() =>
    {
    
     // if (sessionStorage.ACCESS_TOKEN == "null" || sessionStorage.ACCESS_TOKEN == null || sessionStorage.ACCESS_TOKEN == "")
      ////console.log('');
     // else
      {
       //console.log('user has been inactive for 15min');
      //if(sessionStorage.refrash=="false" || sessionStorage.refrash==undefined)
       {
        sessionStorage.refrash="true"
        this.matDialog.closeAll();
        this.router.navigate(["mainHome"]);
       // this.matDialog.open(UserInactivityPopupComponent)
       }
      }
    });
  
  }

   addTag(){
    this.metaService.addTags([
      { name: 'description', 
        content: 'Article Description' 
      },
      { name: 'robots', content: 'index,follow' },
      { property: 'og:title', content: 'Content Title' }
   ]);
  }

  ngOnInit() {
    this.addTag();
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    // )
      // .subscribe(() => {
 
      //   var rt = this.getChild(this.activatedRoute)
 
      //   rt.data.subscribe(data => {
      //     //console.log(data);
      
      //     // if (data.title) {
      //     //   this.titleService.setTitle(data.title)
      //     // } 
      //     // else {
      //     //   this.titleService.setTitle("בא לי בקליק - משלוחי אוכל, מסעדות, אטרקציות ועוד באילת")
      //     // }

 
      //     if (data.descrption) {
      //       this.metaService.updateTag({ name: 'description', content: data.descrption })
      //     } 
      //     else {
      //       this.metaService.updateTag({ name: 'description', content: "בא לי בקליק - משלוח מהיר ממגוון מסעדות וחנויות באילת, משלוחי אוכל, המבורגר, סושי, פיצה, שווארמה, פרחים, בלונים, סופר ורכישת כרטיסים לאטרקציות באילת. משלוחים מהירים באילת !" })
      //       //this.metaService.removeTag("name='description'")
      //     }
 
      //     if (data.robots) {
      //       this.metaService.updateTag({ name: 'robots', content: data.robots })
      //     } else {
      //       this.metaService.updateTag({ name: 'robots', content: "follow,index" })
      //     }
 
      //     if (data.ogUrl) {
      //       this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
      //     } else {
      //       // this.metaService.updateTag({ property: 'og:url', content: this.router.url })
      //       this.metaService.updateTag({ property: 'og:url', content: "https://balibclick.co.il" })
           
      //     }
 
      //     if (data.ogTitle) {
      //       this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
      //     } 
      //     else {
      //       this.metaService.updateTag({ property: 'og:title', content: "בא לי בקליק" })
      //     }
 
      //     if (data.ogDescription) {
      //       this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
      //     } else {
      //       this.metaService.updateTag({ property: 'og:description', content:"בא לי בקליק - משלוח מהיר ממגוון מסעדות וחנויות באילת, משלוחי אוכל, המבורגר, סושי, פיצה, שווארמה, פרחים, בלונים, סופר ורכישת כרטיסים לאטרקציות באילת. משלוחים מהירים באילת !"})
      //     }
 
      //     if (data.ogImage) {
      //       this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
      //     } else {
      //       this.metaService.updateTag({ property: 'og:image', content: "http://balibclick.co.il/baliIcon.png" })
      //     }
 
 
      //   })
 
      // })
    this.innerWidth = window.innerWidth;
    if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
    {
    this.dataService.token.next('oneTimeOrder');
    this.dataService.state.next("התחבר");
    this.state.emit();
    }
    else
  {
    this.dataService.token.next('order');
    this.dataService.state.next("התנתק");
    this.state.emit();
  }
    if(localStorage.oneTimeOrderOrOrder==undefined)
    this.dataService.token.next('oneTimeOrder');
    //let a=location.hash;

    let a=location.pathname;
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.orderService.oneTimeOrderOrOrder="Order";

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //     if (!!event.url && event.url.match(/^\/#/)) {
    //       this.router.navigate([event.url.replace('/#', '')]);
    //     }
    //   }
    // });
    let b=0;
    // if(sessionStorage.refrash2=="true" ||sessionStorage.refrash2==undefined)
    // {
    //   this.router.navigate(["../mainHome"]);
    //   sessionStorage.refrash2="false"
     
    // }

    // else
     if(a.startsWith("/restaurant") && sessionStorage.ACCESS_TOKEN  == "null")
    {
    
        //console.log("PopupEnterComponent5"+location.pathname+"="+sessionStorage.num);
        // if (this.innerWidth <= 480)
        //   this.matDialog.open(PopupEnter2Component, { disableClose: true });
        // else
        //   this.matDialog.open(PopupEnterComponent, { disableClose: true });
        //}
      }
     else if(a.startsWith("/restaurantCategory"))
      {
      
          //console.log("PopupEnterComponent5"+location.pathname+"="+sessionStorage.num);
          // if (this.innerWidth <= 480)
          //   this.matDialog.open(PopupEnter2Component, { disableClose: true });
          // else
          //   this.matDialog.open(PopupEnterComponent, { disableClose: true });
          //}
        }
  
        // let urlId=a.split('/')[2];
        //     let url: string = "/restaurant/" + urlId
        //     this.router.navigateByUrl(url);
      
  
      else if(a=="/mainHome" || a=="/"  || a=="" || a=="/angular/"  || (!(a.startsWith("/restaurant")) && sessionStorage.ACCESS_TOKEN  == "null")
       || (!(a.startsWith("/restaurantCategory")) && sessionStorage.ACCESS_TOKEN  == "null"))
    {
    //  if(sessionStorage.deeplink!='' && sessionStorage.deeplink!=undefined)
      //this.router.navigate(["../yourOrder"])
    //  if(a=="/mainHome" || a=="/"  || a=="" || a=="/angular/")
   //  sessionStorage.deeplink='';
     //this.matDialog.closeAll();
     if(a=="/" && sessionStorage.deeplink!='' && sessionStorage.deeplink!=undefined)
     {
     if(sessionStorage.backYourOrder=="true")
  {
    this.dataService.endToppings.next(true);
    this.state.emit();
    let url: string = "/restaurant/" + sessionStorage.deeplink2;
    this.router.navigateByUrl(url);
  }
    // else
    // this.router.navigate(["../yourOrder"]);
     else
     {
      sessionStorage.deeplink2='';
      sessionStorage.deeplink='';
      this.router.navigate(["mainHome"]);
     }
    }
    else
    {
      sessionStorage.deeplink2='';
      sessionStorage.deeplink='';
      this.router.navigate(["mainHome"]);
    }
  }

  //   else
  // {
  //   let b=a.split('/',2);
  //   this.router.navigate(["../"+b[1]]);
  // }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  setTimeout() {
     this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1000*60*15);
   // this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1000*10);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
