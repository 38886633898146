import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { LoginComponent } from '../login/login.component';
import { UserService } from 'src/app/services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { DataService } from 'src/app/services/data.service';
import { CreditCardService } from 'src/app/services/creditCard-service';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { PopupLogOutComponent } from '../popup-log-out/popup-log-out.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  confirm = null;
  outOrIn="התנתק";
  innerWidth: any;
  token="";

  constructor(private router: Router,private creaditCardService:CreditCardService,private proxy:AppProxy,private dataService:DataService ,private matDialog:MatDialog,private userService:UserService) { }

  ngOnInit(): void {
    this.token = this.dataService.token.getValue() || {};
    this.dataService.token.subscribe(data=>{
      this.token=data;
    });
    this.outOrIn = this.dataService.state.getValue() || {};
    this.dataService.state.subscribe(data=>{
      this.outOrIn=data;
      //if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
      //this.outOrIn="התחבר"
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(TermsOfUseComponent,{ disableClose: true });
  }

  openEnterDialog() {
    if(this.innerWidth<=480)
    this.matDialog.open(PopupEnter2Component, { disableClose: true });
    else
    this.matDialog.open(PopupEnterComponent, { disableClose: true });
  }

  openAppleStore()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
   
  }

  menu()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    this.router.navigate(["../menu"])
  }

  eat()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    this.router.navigate(["../mainHome"])
  }

  search()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    this.router.navigate(["../search"])
  }

  orderHistory()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    {
    this.router.navigate(['../orderHistory']);
    }
  }

  shippingHistory(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../shippingHistory']);
  }

  paymentInfo(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../paymentInfo']);
  }

  reservedRestaurants(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../reservedRestaurants']);
  }

  editProfil()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['/register']);
  }

  contact(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['/contact']); 
  }

  giftCard()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    {
      this.creaditCardService.IsPaymentByGiftCard=false;
    this.router.navigate(['/giftCard']);
    }
  }

  logOut(){
    if(sessionStorage.ACCESS_TOKEN  != "null" &&  localStorage.oneTimeOrderOrOrder!='oneTimeOrder')
  {
    this.matDialog.open(PopupLogOutComponent, {
    });
  }
  else{
    if( localStorage.oneTimeOrderOrOrder!='oneTimeOrder')
    this.openEnterDialog();
    else
    this.openDialogLogin();
  }
}

 openDialogLogin() {
  this.innerWidth = window.innerWidth;
 // if(this.innerWidth<=480)
 // this.router.navigate(['../login']);
  //else
 // {
  this.userService.LoginOBJ=false;
    this.matDialog.open(LoginComponent, { disableClose: true });
 // }
  }
}
