import { Component, OnInit ,EventEmitter  ,Output ,HostListener ,NgZone, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router ,ActivatedRoute, NavigationEnd, RouterEvent, NavigationStart} from '@angular/router';
import { fromEvent, Observable, of, Subscription } from "rxjs";
import { CategoryItem,Category,} from 'src/app/models/category';
import { AdditonOrder, MyOrder, OrderAdding, OrderHistory } from 'src/app/models/order';
import { ChangeFood, ToppingsItem, ToppingsItemFood ,Restaurant ,RestaurantItem} from 'src/app/models/restaurant';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { BussinssService } from 'src/app/services/bussinss-service';
import { PlatformLocation ,LocationStrategy} from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-topping2',
  templateUrl: './topping2.component.html',
  styleUrls: ['./topping2.component.css']
})
export class Topping2Component implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()header: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('list')public listElementRef: ElementRef;
  @ViewChild('inputas',{static: true}) inputas: ElementRef;
  buttonText="המשך לתשלום"
  continued="המשך"
  continuedNum=0;
  selected:any="לחץ לבחירה";
  filtered :any;
  totalItems:number=0;
  totalAmount:number=0;
  totalFoodAmount:number=0;
  toppings:OrderAdding[]=[];
  toppingsSelect:OrderAdding[];
  toppingsPage:OrderAdding[]=[];
  //toppingsPage2:OrderAdding[]=[];
  categores:Category[];
  categoresItem:CategoryItem[];
  restaurantItem:RestaurantItem[];
  toppingsItemFood:ToppingsItemFood[];
  toppingsItemFood1:ToppingsItemFood[];
  toppingsItemFood2:ToppingsItemFood[];
  toppingsItemFood3:ToppingsItemFood[];
  toppingsItemFood4:ToppingsItemFood[];
  toppingsItemFood5:ToppingsItemFood[];
  toppingsItemFood6:ToppingsItemFood[];
  toppingsItemFood7:ToppingsItemFood[];
  toppingsItemFood8:ToppingsItemFood[];
  toppingsItemFood9:ToppingsItemFood[];
  toppingsItem:ToppingsItem[];
  toppingsItem1:ToppingsItem[];
  toppingsItem2:ToppingsItem[];
  toppingsItem3:ToppingsItem[];
  toppingsItem4:ToppingsItem[];
  toppingsItem5:ToppingsItem[];
  toppingsItem6:ToppingsItem[];
  toppingsItem7:ToppingsItem[];
  restaurant:Restaurant;
  ordersToShow:OrderHistory[]= new Array();
  orders:OrderHistory[]= new Array();
  foodName:string;
  foodDescription:string;
  ImageFood:string;
  position=4;
  //position2=2;
  public positionPage=1;
  selectedItem="";
  pos1:number;
  posi2:number;
  private selectOption: any;
  priceTotal="0-פריטים";
  clickToPay="לחץ לתשלום - ";
  priceAdding=0;
  order:MyOrder;
  Image="";
  Image400="";
  heightAdding:number;
  heightAdding2:number;
  additonOrder:AdditonOrder;
  AdditionList;
  AdditionList2;
  countNumber;
  batta = false;
  //TypeID;
  //TypeID2=null;
  //TypeID3=null;
  //TypeID4=null;
  id=0;
  num=4;
  scrollList:number=4;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  pointOff="assets/images/point_off.png";
  pointOn="assets/images/point_on.png";
  checkboxOn="assets/images/chack_box_on.png";
  checkboxOff="assets/images/chack_box_off.png"
  listLength:number;
  full=false;
  innerWidth: any;
  innerHeight: any;
  innerHeight1: any;
  innerHeight2: any;
  inviteName:string="";
  inviteName2:string="";
   loc=-1;
   mySubscription;
   key;
   renderer: Renderer2;
   keyboard=false;
   popup=1;
   showProgress=false;
    private routerSubscription: Subscription;
    private isNavigatingBack3 = false;
    previousUrl:string


  ngAfterContentInit() {
    if(this.renderer && this.renderer.selectRootElement(this.inputas["nativeElement"])!=null)
    this.renderer.selectRootElement(this.inputas["nativeElement"]).focus();
}

@HostListener('keydown', ['$event']) onKeyDown(e) {
  if ((e.which == 13 || e.keyCode == 13)) {
      e.preventDefault();
      if (e.srcElement.nextElementSibling) {
          e.srcElement.nextElementSibling.focus();
      }
      else{
          //console.log('close keyboard');
          this.proxy.hideKeyboard(e);
          e.srcElement.nextElementSibling.blur();
      
      }
     // this.onBlur();
      return;
  }
}

  constructor(private zone: NgZone,private matDialog:MatDialog, private locationStrategy: LocationStrategy,private router: Router ,private dataService:DataService,location: PlatformLocation,private orderService:OrdersService,private bussinssService: BussinssService,private proxy: AppProxy,private route: ActivatedRoute) 
  {  


}

  ngOnInit(): void {

       //naomi change
       this.routerSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = event.url;
        }
      });
    window.addEventListener('popstate', this.onPopState3.bind(this));

     this.dataService.header.next(false);
     this.header.emit();
    this.dataService.endToppings.next(true);
    this.dataService.notEndToppings=true;
    this.header.emit();
    this.keyboard=false;
    this.position=4;
    //this.listElementRef.nativeElement.scrollTop(0,0)
    this.proxy.scrollToTop(); 
    //window.scrollTo(0,0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      //console.log("getOrderDetails10","getOrderDetails");    
      this.order=res;
     if(this.order.list.length==0)
     {
      localStorage.ID_CATEGORY=this.order.RestaurantID;
      let url: string = "/restaurantCategory/" + this.order.RestaurantID
      this.router.navigateByUrl(url);
      //this.matDialog.closeAll();
      //this.router.navigate(['../restaurantCategory']);
     }
     else
     {
      this.innerWidth = window.innerWidth;

     this.innerHeight1 = Number(window.innerHeight-40);
      this.innerHeight2 = Number(this.innerHeight-294);
      if(this.innerWidth<=480)
      {
           this.num=2;
           this.scrollList=3;
           this.position=2;
          // this.listElementRef.nativeElement.scrollTop(0,0)
          //  this.dataService.header.next(false);
          //  this.header.emit();    
      }
           else
           {
           this.num=4;
           this.scrollList=4;
           this.position=4;
       
  
          }
          // this.dataService.showSearch.next(false);
          // this.changByFood.emit();
  
      localStorage.PAGE_BACK="";
      // this.dataService.show.next(false);
      // this.changeShowPrice.emit();
   
     // window.scroll(0,0);
      this.priceAdding=0;
      this.posi2=localStorage.position;
      this.pos1=localStorage.pos;
      // this.pos1= this.route.snapshot.params.pos;
      // this.posi2= this.route.snapshot.params.position;
      this.loc=-1;
      for(let y=0;y<this.order.list.length;y++)
      {
      if(this.order.list[y].MenuID==localStorage.MENU_ID)
      {
      this.loc=y;
      y=this.order.list.length;
      }
      }
      if(Number(this.posi2)>1)
      this.loc=Number(this.loc)+Number(this.posi2)-1;
      if(this.order.list[this.loc].InviteName!=undefined && this.order.list[this.loc].InviteName!=null && this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].listAddition.length>0)
      {
      if(this.num==2)
      this.inviteName2=this.order.list[this.loc].InviteName;
      else
      this.inviteName=this.order.list[this.loc].InviteName;
      }
      if(this.order.list[this.loc]!=null && this.order.list[this.loc].listAddition!=null && this.order.list[this.loc].listAddition.length>0)
      this.full=true;
      this.totalAmount=this.order.list[this.loc].Price;
      this.totalFoodAmount=this.order.list[this.loc].Price;
   for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
   {
    for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
    {
    this.totalAmount=Number(this.totalAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
   }
  }
           
 if(this.orderService.restaurant!=null)
   {
 this.restaurant=this.orderService.restaurant;
 if(this.restaurant.LongImage!=null)
 this.Image=this.restaurant.LongImage;
   }
  {
    this.orderService.getOrderAdditon1(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
      this.additonOrder=res;
     
      if(this.additonOrder.ImgFile!=null)
      this.Image400=this.order.list[this.loc].BigImgFile;
      this.orderService.orderAddingList=this.additonOrder.list;
      this.toppings=this.additonOrder.list;
      for(let j=0;j<this.toppings.length;j++)
      {
        for(let m=0;m<this.toppings[j].listA.length;m++)
        if(this.toppings[j].listA[m].Price!=null && this.toppings[j].listA[m].Price>0)
        this.toppings[j].listA[m].PriceText='(+ '+ '₪' + this.toppings[j].listA[m].Price +')' ;
      }
      if(this.toppings!=null)
         if(this.toppings.length<=this.num)
         {
         this.continued="סיום";
         this.continuedNum=1;
         }
         else
         {
         this.continued="המשך";
         this.continuedNum=0;
         }
      this.listLength=Number(this.toppings.length/2);
      if(Number(Number(this.toppings.length/2)%2)!=0)
      this.listLength=Number(Number(this.listLength)+1)-(Number(this.toppings.length/2)%2);
      this.foodName=this.additonOrder.Name;
      if(this.additonOrder.Description!="")
      this.foodDescription=this.additonOrder.Description;
      else
      this.foodDescription=this.additonOrder.Ingredients;
      this.ImageFood=this.additonOrder.ImgFile;
      if(this.toppings!=null && this.toppings.length>0 )
      {
      //   this.toppingsPage.push(this.toppings[0]);
      //   if(this.toppings.length>1)
      // {
      //   this.toppingsPage.push(this.toppings[1]);
      // }
      // if(this.num>2)
      // {
      //   if(this.toppings.length>2)
      //   this.toppingsPage.push(this.toppings[2]);
      //   if(this.toppings.length>3)
      //   this.toppingsPage.push(this.toppings[3]);
      // }
      this.toppingsPage=this.toppings;
      //this.listElementRef.nativeElement.scrollTop(0,0)
        if(this.full==true)
        {
for(let m=0;m<this.toppingsPage.length;m++)
{
  for(let q=0;q<this.toppingsPage[m].listA.length;q++)
  {
    this.toppingsPage[m].listA[q].PriceTotal=this.toppingsPage[m].listA[q].Price;
    let count= Number(Number(this.toppingsPage[m].listA[q].CountChoose)- Number(this.toppingsPage[m].CountAdditionFree))
    this.toppingsPage[m].listA[q].PriceTotal=Number(Number(this.toppingsPage[m].listA[q].Price)*Number(count))
    if(this.toppingsPage[m].CountChoose==null || this.toppingsPage[m].CountChoose==undefined)
    this.toppingsPage[m].CountChoose=0;
    this.toppingsPage[m].CountChoose+=this.toppingsPage[m].listA[q].CountChoose;
    if(this.toppingsPage[m]!=null && this.toppingsPage[m].listA!=null && this.toppingsPage[m].listA[q].Price>0  && this.toppingsPage[m].listA[q].CountChoose>0)
{
if(this.toppingsPage[m].listA[q].PriceTotal==null)
this.toppingsPage[this.mySubscription].listA[q].PriceTotal=Number(this.toppingsPage[m].listA[q].Price);
else
{
if(this.toppingsPage[m].listA[q].PriceTotal==-1)
this.toppingsPage[m].listA[q].PriceTotal=0;
this.toppingsPage[m].listA[q].PriceTotal+=Number(this.toppingsPage[m].listA[q].Price);
}
  }
  }  
}
      }
  
        this.position=this.toppingsPage.length;
        this.AdditionList="";
        this.AdditionList2="";
//         this.TypeID=this.toppingsPage[0].TypeID;
// if(this.toppingsPage.length>1)
// this.TypeID2=this.toppingsPage[1].TypeID;
// if(this.toppingsPage.length>2)
// this.TypeID3=this.toppingsPage[2].TypeID;
// if(this.toppingsPage.length>3)
// this.TypeID4=this.toppingsPage[3].TypeID;
   this.heightAdding=0;
   this.heightAdding2=0;
   for(let j=0;j<this.toppingsPage.length;j++)
    if (!this.toppingsPage[j].CountChoose)
   this.toppingsPage[j].CountChoose=0;
    }
     
         if(this.restaurant==undefined)
     this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
       this.restaurant=res;
       this.restaurantItem=this.restaurant.MenuList;
       for(let i=0; i<this.order.list.length;i++)
       {
         if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
         this.id=this.order.list[i].ID;
       }
     });
    
  });
  }
  if(this.restaurantItem!=null)
 for(let j=0;j<this.restaurantItem.length;j++)
 if(this.restaurantItem[j].Amount>0)
    {
    this.totalItems+=this.restaurantItem[j].Amount;
    this.totalAmount+=Number(this.totalItems)* Number(this.restaurantItem[j].Price);
    }
    
   if(this.toppings!=null && this.toppings.length>0 )
   {
// this.toppingsPage.push(this.toppings[0]);
// if(this.toppings.length>1)
// {
// this.toppingsPage.push(this.toppings[1]);
// }
// if(this.toppings.length>2)
// this.toppingsPage.push(this.toppings[2]);
// if(this.toppings.length>3)
// this.toppingsPage.push(this.toppings[3]);
this.toppingsPage=this.toppings;
this.heightAdding=0;
for(let j=0;j<this.toppingsPage.length;j++)
{
if(this.heightAdding<Number(Number(this.toppingsPage[j].Count)+2)*48 && Number(this.heightAdding)<450)
    this.heightAdding=Number(Number(this.toppingsPage[j].Count)+2)*48;
    if(Number(this.heightAdding)>450)
    this.heightAdding=450;
    if(Number(this.heightAdding2)>450)
    this.heightAdding2=450;
    this.toppingsPage[j].CountChoose=0;
   }}
  if(this.toppings!=null)

    if(this.bussinssService.restaurant!=null)
    this.restaurant=this.bussinssService.restaurant;
    if(this.restaurant!=null && this.restaurant.LongImage!=null)
    this.Image=this.restaurant.LongImage;
  }
  });
  window.scroll(0,0);

  }


  onBlur()
  {
    this.keyboard=false;
   // this.listElementRef.nativeElement.scrollTop(0,0)
    //console.log('onBlur', "onBlur");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
      //console.log('onBlur2', "onBlur");
    }
    //this.innerHeight = window.innerHeight;
   // this.innerHeight = Number(this.innerHeight-100);
  }

  clickList(pos:number,position:number,toppingText:string,newValue)
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    let posi=pos;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    for(let i=0;i< this.toppings.length;i++)
    {
      if(this.toppings[i].Type!=null)
       if(this.toppings[i].Type==this.toppingsPage[pos].Type)
       {
       posi=i;
       break
       }
      }
        let OrderAddingItem=this.toppings[posi].listSelect[position];
      }
  }

  selectItem_(pos:number,position:number,toppingText:string,Type:string,inde:number,TypeID:number)
  {
    if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    let posi=pos;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    for(let i=0;i<this.toppings.length;i++)
    {
    if(this.toppings[i].Type==Type)
                  posi=i;
            }

    this.toppings[posi].listSelect[position].Text=toppingText;
    this.toppings[posi].listSelect[position].Select=true
    if(this.order!=null && this.order.list!=null)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
      this.id=this.order.list[i].ID;
    }
             let pos_=Number(this.posi2);
          let y=0;
          this.AdditionList ="";
          this.AdditionList2="";
          if(this.toppingsPage.length>0)
          for(let i=0;i<2;i++)
          if(this.toppingsPage.length>i)
              {
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];
                       if (orderAddingItemFood.IsChoose==1)
                       {
                            y+=1;
                            if(y>1)
                                this.AdditionList +=",";
                            this.AdditionList += orderAddingItemFood.ID;
                            for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList +=",";
                              this.AdditionList += orderAddingItemFood.ID;
                            }
                            p=this.toppingsPage[i].listA.length;
                      }
                   }
                  }
                   y=0;
                  if(this.toppingsPage.length>2)
          for(let i=2;i<4;i++)
          if(this.toppingsPage.length>i)
              {
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];     
                       if (orderAddingItemFood.IsChoose==1){
                            y+=1;
                            if(y>1)
                                this.AdditionList2 +=",";
                            this.AdditionList2 += orderAddingItemFood.ID;
                            for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList2 +=",";
                              this.AdditionList2 += orderAddingItemFood.ID;
                            }
                            p=this.toppingsPage[i].listA.length;
                      }
                   }
                  }
          let a:number=1;
          pos_=Number(pos_);
   
          }
  }

  selectItem(pos:number,position:number,IsChoose:boolean)
  {
   // let posi=pos;
   // let TypeID=this.toppingsPage[pos].TypeID;
    if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
      let posi=pos;
      // for(let i=0;i<this.toppings.length;i++)
      // {
      // if(this.toppings[i].TypeID==this.toppingsPage[pos].TypeID)
      //               posi=i;
      //         }
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
   
if(IsChoose==true)
{
if(this.toppings[posi].listA[position].IsChoose==1)
{
this.toppings[posi].listA[position].IsChoose=0;
let count=this.toppings[posi].listA[position].CountChoose;

this.toppingsPage[pos].CountChoose-=this.toppings[posi].listA[position].CountChoose;
if(this.toppings[posi].IsFree==1 && count>=this.toppingsPage[pos].CountAdditionFree && this.toppingsPage[pos].CountChoose<this.toppingsPage[pos].CountAdditionFree)
{
  this.totalAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
this.totalFoodAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
if(this.totalAmount<this.order.list[this.loc].Price)
{
  this.totalAmount=this.order.list[this.loc].Price;
  this.totalFoodAmount=this.order.list[this.loc].Price;
}
}
if(this.toppings[posi].listA[position].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0))
{
  if(this.toppingsPage[pos].CountAdditionFree>0)
  {
this.toppings[posi].listA[position].PriceTotal=0;
  }
this.totalAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
this.totalFoodAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
}

this.toppings[posi].listA[position].CountChoose=0; 
}
else{
  this.toppingsPage[pos].CountChoose=0;
 
          for(let m=0;m<this.toppings[posi].listA.length;m++)
          this.toppingsPage[pos].CountChoose+=this.toppingsPage[pos].listA[m].CountChoose;
          if(this.toppingsPage[pos].Count==1)
          {
            for(let m=0;m<this.toppingsPage[pos].listA.length;m++)
            {
              if(this.toppingsPage[pos].listA[m].IsChoose==1)
              {
          
              if(this.toppingsPage[pos].listA[m].Price>0)
              {
                this.totalAmount-=Number(this.toppingsPage[pos].listA[m].Price);
                this.totalFoodAmount-=Number(this.toppingsPage[pos].listA[m].Price);
              }
              }
              this.toppingsPage[pos].listA[m].IsChoose=0;
            }
            this.toppingsPage[pos].listA[position].IsChoose=1;
            this.toppingsPage[pos].listA[position].CountChoose=1;
            if(this.toppingsPage[pos].listA[position].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 ) )
{
this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[pos].listA[position].Price);
this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[pos].listA[position].Price);
if(this.toppingsPage[pos].listA[position].PriceTotal==null)
this.toppingsPage[pos].listA[position].PriceTotal=Number(this.toppingsPage[pos].listA[position].Price);
else
{
  if(this.toppingsPage[pos].listA[position].PriceTotal==-1)
  this.toppingsPage[pos].listA[position].PriceTotal=0;
this.toppingsPage[pos].listA[position].PriceTotal+=Number(this.toppingsPage[pos].listA[position].Price);
}
          }
          this.toppingsPage[pos].CountChoose+=1;
          }
          else if(this.toppingsPage[pos].CountChoose==0 || (this.toppingsPage[pos].CountChoose<this.toppingsPage[pos].Count && this.toppingsPage[pos].listA!=null))
          {
           
this.toppingsPage[pos].listA[position].CountChoose+=1;
this.toppingsPage[pos].listA[position].IsChoose=1;
if(this.toppingsPage[pos].listA[position].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 ) )
{
this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[pos].listA[position].Price);
this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[pos].listA[position].Price);
if(this.toppingsPage[pos].listA[position].PriceTotal==null)
this.toppingsPage[pos].listA[position].PriceTotal=Number(this.toppingsPage[pos].listA[position].Price);
else
{
  if(this.toppingsPage[pos].listA[position].PriceTotal==-1)
  this.toppingsPage[pos].listA[position].PriceTotal=0;
this.toppingsPage[pos].listA[position].PriceTotal+=Number(this.toppingsPage[pos].listA[position].Price);
}
          }
          this.toppingsPage[pos].CountChoose+=1;
        }
        else
        this.proxy.showError(" לא ניתן לבחור תוספת \n  נוספת","");
}
}
}
    if(this.order!=null && this.order.list!=null)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i]!=null && this.restaurant!=null)
      if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
      this.id=this.order.list[i].ID;
    }
             let pos_=Number(this.posi2);
          let y=0;
          this.AdditionList ="";
          this.AdditionList2="";
          if(this.toppingsPage.length>0)
          for(let i=0;i<2;i++)
          if(this.toppingsPage.length>i)
              {
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];
                      if (orderAddingItemFood.IsChoose==1)
                       {
                            y+=1;
                            if(y>1)
                                this.AdditionList +=",";
                            this.AdditionList += orderAddingItemFood.ID;
                            for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList +=",";
                              this.AdditionList += orderAddingItemFood.ID;
                            }
          
                      }
                   }
                  }
                   y=0;
                  if(this.toppingsPage.length>2)
          for(let i=2;i<4;i++)
          if(this.toppingsPage.length>i)
              {
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];     
                      if (orderAddingItemFood.IsChoose==1) {
                            y+=1;
                            if(y>1)
                                this.AdditionList2 +=",";
                            this.AdditionList2 += orderAddingItemFood.ID;
                             for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList2 +=",";
                              this.AdditionList2 += orderAddingItemFood.ID;
                            }
                          
                      }
                   }
                  

                  }
               
          let a:number=1;
          pos_=Number(pos_);

          
  }

  plus(pos,i:number)
   {
    let posi=pos;
    this.toppingsPage[pos].CountChoose=0;
    // for(let j=0;j<this.toppings.length;j++)
    // {
    // if(this.toppings[j].TypeID==this.toppingsPage[pos].TypeID)
    //               posi=j;
    //         }
            //this.toppingsPage[posi].CountChoose=0;
            for(let m=0;m<this.toppings[posi].listA.length;m++)
            this.toppingsPage[pos].CountChoose+=this.toppingsPage[pos].listA[m].CountChoose;
            if(this.toppingsPage[pos].CountChoose==0 || (this.toppingsPage[pos].CountChoose<this.toppingsPage[pos].Count && this.toppingsPage[pos].listA!=null))
            {
             
  this.toppingsPage[pos].listA[i].CountChoose+=1;
  this.toppingsPage[pos].listA[i].IsChoose=1;
  if(this.toppingsPage[pos].listA[i].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 ) )
  {
              //if(this.toppingsPage[posi].listA[i].CountChoose>this.toppingsPage[posi].Count)
             // this.toppingsPage[posi].listA[i].CountChoose=this.toppingsPage[posi].Count;
  this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[pos].listA[i].Price);
  this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[pos].listA[i].Price);
  if(this.toppingsPage[pos].listA[i].PriceTotal==null)
  this.toppingsPage[pos].listA[i].PriceTotal=Number(this.toppingsPage[pos].listA[i].Price);
  else
  {
    if(this.toppingsPage[pos].listA[i].PriceTotal==-1)
    this.toppingsPage[pos].listA[i].PriceTotal=0;
  this.toppingsPage[pos].listA[i].PriceTotal+=Number(this.toppingsPage[pos].listA[i].Price);
  }
            }
            this.toppingsPage[pos].CountChoose+=1;
          }
          else
          this.proxy.showError(" לא ניתן לבחור תוספת \n  נוספת","");
  }

  minus(pos,i:number)
  {
    let posi=pos;
            if(this.toppingsPage[pos].listA[i].CountChoose>0)
            {
    this.toppingsPage[pos].listA[i].CountChoose-=1;
    if(this.toppingsPage[pos].listA[i].CountChoose<=0)
    this.toppings[pos].listA[i].IsChoose=0;
    if(this.toppingsPage[pos].listA[i].Price>0 && (this.toppingsPage[pos].CountAdditionFree<this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 || this.toppingsPage[pos].CountChoose==0) )
    {
      this.toppingsPage[pos].CountChoose-=1;
    this.totalAmount-=Number(this.toppingsPage[pos].listA[i].Price);
    this.totalFoodAmount-=Number(this.toppingsPage[pos].listA[i].Price);
    this.toppingsPage[pos].listA[i].PriceTotal-=Number(this.toppingsPage[pos].listA[i].Price);
    if(this.toppingsPage[pos].listA[i].CountChoose<=0)
    this.toppings[posi].listA[i].IsChoose=0;
            }
            else
            {
              this.toppingsPage[pos].CountChoose-=1;
            this.toppingsPage[pos].listA[i].PriceTotal=0;
            }
          }
  }

  changeOnFood(i,j,o)
  {
    
    this.toppings[i].listSelect[j].Select=false;
  }

  selectClick(i,j){ 
    for(let p=0;p<this.toppings[i].listSelect.length;p++)
    {
      this.toppings[i].listSelect[p].Select=false;
    }
    this.toppings[i].listSelect[j].Select=true;
  }

  payment(){
    this.dataService.show.next(false);
     this.changeShowPrice.emit();
    this.router.navigate(["../yourOrder"])
  }

  // preventBackButton() {
  //   history.pushState(null, null, location.href);
  //   this.locationStrategy.onPopState(() => {
  //     history.pushState(null, null, location.href);
  //   })
  // }
  

  back14()
  {
    if(this.toppings.length>this.num && this.positionPage>1)
    {
      this.positionPage-=1;
    this.toppingsPage=[];
    let p;
//if(this.position==this.toppings.length)
{
  if(this.position%2==0)
  this.position=this.position-4;
  else
  this.position=this.position-3;
}
//else
//this.position=this.position-2;
    this.continuedNum=0;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    for(let j=0;j<this.num;j++)
    this.toppingsPage.push(this.toppings[this.position+j]);
    this.position+=2;
    this.continued="המשך"  
    //window.scrollTo(0,0);
    //this.proxy.scrollToTop(); 
  }
    else
  {
    let pos=this.order.list.length;
    pos=Number(Number(pos)-1);
    if(this.order.list.length>0)
    {
    let amount =this.order.list[pos].Count;
    amount=Number(Number(amount)-1);
    }
    this.totalItems=0;
    let p:number=0;
    if(this.order.list[pos]!=null && this.order.list[pos].Price!=null)
    p=this.order.list[pos].Price;
    this.totalAmount=(Number(this.totalAmount)- Number(p));
    let count=0;
    for(let i=0;i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==this.order.list[pos].MenuID)
       count+=1;
    }
    count-=1;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList.length>this.pos1)
    this.orderService.restaurant.MenuList[this.pos1].Rate=count;
    // this.order.list[pos].Rate=this.order.list[pos].Count;
    // this.order.list[pos].Rate-=1;
  // this.totalAmount-= this.restaurantItem[i].Price;
  if(this.totalAmount>0)
  for(let i=0;i<this.order.list.length;i++)
  {
    if(Number(this.order.list[i].Rate)>0)
    this.totalItems+=Number(this.order.list[i].Rate);
  }
  // if(this.totalItems>0)
  // {
  //  this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
  //  localStorage.PRICE_TOTAL=this.priceTotal;
  //  this.dataService.price.next(this.priceTotal);
  //  this.changePrice.emit();
  // }
  //  else
  //  {
  //  this.totalAmount=0;
  //  this.totalFoodAmount=0;
  //  this.priceTotal="0 - פריטים";
  //  localStorage.PRICE=0;
  //  localStorage.PRICE_TOTAL=this.priceTotal;
  //  this.dataService.price.next(this.priceTotal);
  //  this.changePrice.emit();
  //  }
   localStorage.PRICE_TOTAL=this.priceTotal;

    let location:number;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null
       && this.orderService.restaurant.MenuList.length>this.pos1 && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null)
    location = this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length-1;
    location-=1;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null)
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.splice(this.posi2,1);
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      if(this.order.list!=null && this.order.list.length!=0 && localStorage.edit=="false")
      this.orderService.addToBagOrder(this.order.list[pos].ID,this.order.OrderID,this.order.list[pos].MenuNumber).subscribe((res:any)=>{
        if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>location)
    delete this.orderService.restaurant.MenuList[this.pos1].ChangeFood[location]; 
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null &&this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>0 )
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length=location;
    //if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList[this.pos1].Rate==0)
   // this.orderService.restaurant.MenuList[this.pos1].ChangeFood=null;
   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    let url: string = "/restaurant/" + (this.orderService.restaurant ? this.orderService.restaurant.NameEn :this.restaurant.NameEn) ;
 this.router.navigateByUrl(url);
 //this.matDialog.closeAll();
 
//history.back();
   });
    });
    else
    {
      let url: string = "/restaurant/" + (this.orderService.restaurant ? this.orderService.restaurant.NameEn :this.restaurant.NameEn) ;
     this.router.navigateByUrl(url);
     //this.matDialog.closeAll();
    //history.back();
    }
    //this.router.navigate(['../restaurant']);
  });
    }
  }

  isBack()
  {
    // let url: string = "/restaurant/" + this.orderService.restaurant.NameEn;
    // this.router.navigateByUrl(url);
    //history.back();
  }

  selectRequired2() {
    let isValid = true;
    this.toppingsPage.forEach(topping => {
      if (topping.CountChoose < topping.RequiredCount) {
        isValid = false;
      }
    });
    return isValid;
  }

  selectRequired():boolean
  {
    let selectRequired=true;

   let requiredCount=0;
    for(let j=0;j<this.toppingsPage.length;j++)
       if(this.toppingsPage[j].IsRequired>0 )
       {
       selectRequired=true;
       requiredCount=0;
  
         for(let m=0;m<this.toppingsPage[j].listA.length;m++)
         if(this.toppingsPage[j].listA[m].IsChoose==1)
         requiredCount+=this.toppingsPage[j].listA[m].CountChoose; 
      
       if(this.toppingsPage[j]!=undefined)
       if(requiredCount<this.toppingsPage[j].RequiredCount)
       {
       selectRequired=false;
       j=this.toppingsPage.length;
       }
    }
     return selectRequired;
  }

  continue()
  {
    if(this.id==0)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==localStorage.MENU_ID)
      this.id=this.order.list[i].ID;
    }
  //  if(this.num==2)
   // {
     this.continue2();
  }
  

  continue2() {
    if (this.selectRequired2()) {
      this.showProgress = true;
      this.positionPage += 1;
  
      let additionList = "";
      let additionList2 = "";
      let y = 0;
  
      if (this.toppingsPage.length > 0) {
        for (let i = 0; i < this.toppingsPage.length; i++) {
          if (this.toppingsPage.length > i) {
            for (let p = 0; p < this.toppingsPage[i].listA.length; p++) {
              let orderAddingItemFood = this.toppingsPage[i].listA[p];
              if (orderAddingItemFood.IsChoose === 1) {
                y += 1;
                if (y > 1) {
                  additionList += ",";
                }
                additionList += orderAddingItemFood.ID;
                if (orderAddingItemFood.CountChoose > 1) {
                  for (let m = 1; m < orderAddingItemFood.CountChoose; m++) {
                    additionList += ",";
                    additionList += orderAddingItemFood.ID;
                  }
                }
              }
            }
          }
        }
      }
  
        y = 0;
  
        if (this.orderService.order != null) {
          for (let i = 0; i < this.orderService.order.list.length; i++) {
            if (this.orderService.order.list[i].MenuID == this.restaurant.MenuList[this.pos1].ID) {
              this.id = this.orderService.order.list[i].ID;
            }
          }
        }
  
        if (this.id === 0 || this.id === null) {
          this.id=Number(localStorage.MENU_ID)
        }
  
        if (additionList !== "") {
          this.AdditionList = additionList;
        }
          this.orderService.setOrderAdditionSite(this.id, this.AdditionList, Number(this.posi2)).subscribe((res: any) => {
            if ((this.inviteName2 !== "" && this.inviteName2 !== null) ||
                (this.order.list[this.loc].InviteName !== undefined && this.order.list[this.loc].InviteName !== "" && this.order.list[this.loc].InviteName !== null)) {
              this.orderService.setInviteName(this.id, Number(this.posi2), this.inviteName2).subscribe((res: any) => {
                this.getOrderDetails3(false);
              });
            } else {
              this.getOrderDetails3(false);
            }
          });
        
      this.isNavigatingBack3 = true
      this.batta = true
  
    } else {
      this.proxy.showError("לא בחרת את כל \n שדות החובה", "");
      this.heightAdding = 0;
      this.heightAdding2 = 0;
  
      for (let j = 0; j < this.toppingsPage.length; j++) {
        for (let q = 0; q < this.toppingsPage[j].listA.length; q++) {
          if (this.toppingsPage[j].listA[q].IsChoose === 1) {
            this.selectItem(j, q, false);
          }
        }
      }
    }
  
    for (let m = 0; m < this.toppingsPage.length; m++) {
      for (let q = 0; q < this.toppingsPage[m].listA.length; q++) {
        this.toppingsPage[m].listA[q].PriceTotal = this.toppingsPage[m].listA[q].Price;
        let count = Number(this.toppingsPage[m].listA[q].CountChoose) - Number(this.toppingsPage[m].CountAdditionFree);
        this.toppingsPage[m].listA[q].PriceTotal = Number(this.toppingsPage[m].listA[q].Price) * count;
  
        if (this.toppingsPage[m].CountChoose == null || this.toppingsPage[m].CountChoose === undefined) {
          this.toppingsPage[m].CountChoose = 0;
        }
        this.toppingsPage[m].CountChoose += this.toppingsPage[m].listA[q].CountChoose;
      }
    }
  }
  


  

 

   getOrderDetails3(back:boolean)
   {
    //this.listElementRef.nativeElement.scrollTo( 0, 0 );
     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
       this.order=res;
       this.totalAmount=Number(this.order.TotalPrice);
       this.totalFoodAmount=Number(this.order.list[this.loc].Price);
       for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
       {
        for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
        {
    
    this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
       }
       }
       localStorage.PRICE=this.totalAmount;
       this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
       this.dataService.price.next(this.priceTotal);
      this.changePrice.emit();
     this.showProgress=false;
   
  
    
    
      let a=location.pathname;
      if(a.startsWith("/yourOrder") ||  localStorage.edit == 'true')
      {
        this.dataService.updateYourOrder.next(true);
        this.changByFood.emit();
      }

      this.dataService.endToppings.next(false);
      this.header.emit();
      this.dataService.notEndToppings=false;
       let url: string = "/restaurant/" + (this.orderService.restaurant ? this.orderService.restaurant.NameEn : this.restaurant.NameEn) ;
       this.router.navigateByUrl(url);
     
    
     });
   }

   getOrderDetails(back:boolean)
{
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    this.totalAmount=Number(this.order.TotalPrice);
    this.totalFoodAmount=Number(this.order.list[this.loc].Price);
    for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
    {
     for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
     {
   
 this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
    }
    }
    localStorage.PRICE=this.totalAmount;
    this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();

  this.router.navigate(['../restaurant']);

  });
}

getOrderDetails2(back:boolean)
{
 
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    for(let j=0;j<this.toppingsPage.length;j++)
    {
      for(let q=0;q<this.toppingsPage[j].listA.length;q++)
      {
        if(this.toppingsPage[j].listA[q].IsChoose==1)
          this.selectItem(j,q,false);
      }
    }
    this.totalAmount=Number(this.order.TotalPrice);
    this.totalFoodAmount=Number(this.order.list[this.loc].Price);
    for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
    {
     for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
     {   
 this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
    }
    }
    localStorage.PRICE=this.totalAmount;
    this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();

  });
}


ngOnDestroy(): void {
  window.removeEventListener('popstate', this.onPopState3);
  if (this.routerSubscription) {
    //console.log("trtr")
    this.routerSubscription.unsubscribe();
  }
}

private onPopState3 = (event: PopStateEvent) => {
  if (this.isNavigatingBack3) {
    //console.log('Navigating Back: Server response');
    return;
  }

  //console.log('Navigating Back: Server response 44');
  event.preventDefault();
  this.isNavigatingBack3 = true;
  //console.log('Server response 44 ')
  this.makeServerCall().subscribe(response => {
    //console.log('Server response:', response);
    //this.isNavigatingBack3 = false;
   // this.router.navigate([this.previousUrl]);
  }, error => {
    console.error('Server error:', error);
    this.isNavigatingBack3 = false;
  });
}

private makeServerCall(): Observable<any> {
  this.dataService.notEndToppings = false;
  if (this.batta == true)
  {
    //console.log("בה")
    return of(false);
  }
  //console.log("בטטה" , this.batta)
  this.batta = true

  
  let pos = this.order.list.length;
  pos = Number(pos) - 1;

  if (this.order.list.length > 0) {
    let amount = this.order.list[pos].Count;
    amount = Number(amount) - 1;
  }
  this.totalItems = 0;
  let p: number = 0;
  if (this.order.list[pos] != null && this.order.list[pos].Price != null)
    p = this.order.list[pos].Price;
  this.totalAmount = (Number(this.totalAmount) - Number(p));
  let count = 0;
  for (let i = 0; i < this.order.list.length; i++) {
    if (this.order.list[i].MenuID == this.order.list[pos].MenuID)
      count += 1;
  }
  count -= 1;
  if (this.orderService.restaurant != null && this.orderService.restaurant.MenuList != null && this.orderService.restaurant.MenuList.length > this.pos1)
    this.orderService.restaurant.MenuList[this.pos1].Rate = count;

  if (this.totalAmount > 0)
    for (let i = 0; i < this.order.list.length; i++) {
      if (Number(this.order.list[i].Rate) > 0)
        this.totalItems += Number(this.order.list[i].Rate);
    }

  localStorage.PRICE_TOTAL = this.priceTotal;

  let location: number;
  if (this.orderService.restaurant != null && this.orderService.restaurant.MenuList != null
    && this.orderService.restaurant.MenuList.length > this.pos1 && this.orderService.restaurant.MenuList[this.pos1].ChangeFood != null)
    location = (this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length) - 2;
  //location -= 1;
  if (this.orderService.restaurant != null && this.orderService.restaurant.MenuList != null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood != null)
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.splice(this.posi2, 1);

  if (this.order.list != null && this.order.list.length != 0 && localStorage.edit == "false") {
    //this.loc naomiChange
    return new Observable(observer => {  this.orderService.addToBagOrder(this.order.list[pos].ID, this.order.OrderID, this.order.list[this.loc].MenuNumber).subscribe((res:any)=>
      {
    
        if (this.orderService.restaurant != null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood != null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length > location)
          delete this.orderService.restaurant.MenuList[this.pos1].ChangeFood[location];
        if (this.orderService.restaurant != null && this.orderService.restaurant.MenuList != null && this.orderService.restaurant.MenuList.length > this.pos1 && this.orderService.restaurant.MenuList[this.pos1].ChangeFood != null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length > 0)
          this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length = location;

         this.orderService.getOrderDetails(false).subscribe(
          (res2: any) => {
            if (this.orderService.order == null)
            {
              return null ;
            }
            this.order = res2;
            localStorage.ID = this.orderService.restaurant.ID;
           let price=Number(this.orderService.order.TotalPrice);


    //console.log("getOrderDetails16",price);
    //this.orderService.order.TotalPrice=price+"";
    localStorage.PRICE=price;
    this.priceTotal=this.clickToPay+price+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
            this.dataService.header.next(true);
            this.header.emit();
            this.dataService.byFood.next(true);
            this.changByFood.emit();
            this.dataService.show.next(true);
            this.changeShowPrice.emit();
            this.dataService.showSearch.next(true);
            this.changByFood.emit();
            localStorage.SHOW = true;
            //this.isNavigatingBack3 = false;
            //this.router.navigate([this.previousUrl]);
            let url = "/restaurant/" + this.orderService.restaurant.NameEn;
            this.router.navigateByUrl(url);
           
            observer.next(true);
          });
      }
    );
  })
  } else {
    return of(true);
  }
}
}





