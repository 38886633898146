export class Order{
ID:number;
MenuID:number;
Name:string;
Ingredients:string;
Rate:number;
Price:number;
Count:number;
InviteName:string;
IsAddition:number;
IsAdditionRequired:number;
MenuNumber:number;
ImgFile:string;
BigImgFile:string;
listAddition:MyOrderAdding[];  
//list:MenuCount[];
}

export class MenuCount{
   // Count:number;
    listAddition:MyOrderAdding[];      
}

export class AdditonOrder
{
    runtimeDate:string;
    ID:number;
    Name:string;
    Ingredients:string;
    Description:string;
    Rate:number;
    Price:string;
    Count:number;
    ImgFile:string;
    error:boolean;
    responseMessage:string;
    list:OrderAdding[];
}

export class MyOrder
{
 runtimeDate:string;
 OrderID:number;
 RestaurantID:number;
 RestaurantName:string;
 RestaurantNameEn:string;
 //RestaurantImg:string;
 SmallImage:string;
 BigImage:string;
 TotalPrice:string;
 DeliveryPrice:string;
 BudgetSum:string;
 Note:string;
 IsDelivery:number;
 IsPickup:number;
 IsSitting:number;
 Lat:number;
 Lng:number;
 Address:string;
 IsAddress:number;
 error:boolean;
 responseMessage:string;
 list:Order[];  
 Balance: string;
}

export class MyOrderAdding
{
    Type:string;
    TextType:string;
    listA:OrderAddingItemFood[];
}

export class Gift{
    Price:string;
    Number:number;
}

export class OrderAdding{
TypeID:number
Type:string;
TypeTxt:string;
Count:number;
CountChoose:number=0;
IsFree:number;
CountAdditionFree:number
IsRequired:number;
RequiredCount:number;
listSelect:OrderAddingItem[];
listA:OrderAddingItemFood[];
}

export class OrderAddingItem{
Select:boolean;
Text:string="לחץ לבחירה";
//listA:OrderAddingItemFood[];
}

export class OrderAddingItemFood{
ID:number;
AdditionID:number;
Food:string;
Price:number;
PriceText:string;
PriceTotal:number;
countNumber:number;
CountChoose:number;
Select:boolean;
IsChoose:number;
}

export class OrderHistory{
    OrderID:number;
    RestaurantName:string;
    RestaurantID:number;
    ImgFile:string;
    Price:string;
    CreatedDate:string;
    PaymentType:number;
    ReceptionFile:File;
   DocLink : string ;
}

export class HistoryDelivery{
    DeliveryID:number;
    Origin:string;
    Destination:string;
    Description:string;
    Price:string;
    JoinDate:string;
    FileInvoice:string;
    AddressFrom:string;
    AddressTo:string;
}