import { Component, OnInit ,HostListener ,EventEmitter ,Input ,Output } from '@angular/core';
import { Category ,CategoryItem, AboutRestaurant, FoodItem} from 'src/app/models/category';
import { ChangeFood,OrderAdditon , RestaurantItem, Restaurant, CategoryRestaurant} from 'src/app/models/restaurant'
import { Router ,ActivatedRoute, NavigationEnd, RouterEvent, NavigationStart} from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder, OrderAdding } from 'src/app/models/order';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { AppComponent } from 'src/app/app.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BigImageComponent } from '../big-image/big-image.component';
import { DataService } from 'src/app/services/data.service';
import { PopupErrorComponent } from '../popup-error/popup-error.component';
import { Session } from 'protractor';
import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { ToppingsComponent } from '../toppings/toppings.component';
import { Topping2Component } from '../topping2/topping2.component';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PopupOrderComponent } from '../popup-order/popup-order.component';
// import * as cheerio from 'cheerio';


@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changText: EventEmitter<number> = new EventEmitter<number>();
    @Output()header: EventEmitter<number> = new EventEmitter<number>();
    @Output()clickPayment:EventEmitter<number>=new EventEmitter<number>();
    @Output()deepLink: EventEmitter<boolean> = new EventEmitter<boolean>();
  stars:number[]=[1,2,3,4,5];
  aboutRestaurant:AboutRestaurant[];
  categores:CategoryRestaurant[];
  categoresItem:CategoryItem[];
  restaurantItem:RestaurantItem[]=[];
  changeFood:ChangeFood[];
  toppingsDish:OrderAdditon[];
  foodItem:FoodItem[];
  showChangeFood:boolean=false;
  aboutRestaurantCheck='assets/images/Circle_On.png';
  buttonText="העגלה ריקה";
  totalItems:number=0;
  totalAmount:number=0.0;
  amountFood:number[];
  restaurant:Restaurant=new Restaurant;
  order:MyOrder;
  id:number;
  orderAddingList:OrderAdditon[];
  Rate= new Array<number>(3);
  clickFevorite=false;
  fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
  fevoritActive='assets/images/Restorant_Page_Fevorit_Button_On.png';
  Name:string="";
  priceTotal="0 - פריטים"
  price=0;
  clickToPay="לחץ לתשלום - "
  categoryToFilter="";
  foodName:string;
  dayTime="סגור";
  num=0;
  page:number=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  text:string;
  Image:string="";
  MenuList:RestaurantItem[]=[];
  nameLength:number;
  headerLength:number;
  isNameLength:boolean=true;
  isHeaderLength:boolean=true;
  showProgress=false;
  categoryFilter=0;
  innerWidth:any;
  previousUrl:string;
  idUrl:number=-1;
  numPage=0;
  endToppings=true;
  private routeSub: Subscription;
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //  // this.onBackPress();
  //   history.back();
  // }

  // continue(){
  //   this.proxy.restaurant=this.restaurant;
  //   this.router.navigate(["../yourOrder"])
  // }
  constructor( private location: Location ,private titleService:Title,private route: ActivatedRoute,private dataService:DataService,private matDialog:MatDialog,private router: Router,private orderService:OrdersService,private proxy :AppProxy,private bussinssService: BussinssService , private metaService: Meta) {
   // this.addTag();
   
    router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.matDialog.closeAll())
    )   .subscribe(() => {
      this.initializePage();
    });
}

private initializePage(): void {
  // Your initialization logic here
  if (this.router.url.startsWith("/yourOrder") && localStorage.edit != "true")
 {
  console.log(this.categoryToFilter , "categoryToFilterInitPage" )
  this.restaurantItem = null;
   this.showProgress=true;
  this.category((localStorage.ID_CATEGORY)- 1)
 }

}
    // router.events.pipe(
    //   filter((event: RouterEvent) => event instanceof NavigationStart),
    //   tap(() => this.dialogRef.close()),
    //   take(1),
    // ).subscribe();
    // console.log(this.router.getCurrentNavigation().previousNavigation.finalUrl.toString());
    // router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   console.log('prev:', event.url);
    //   this.previousUrl = event.url;
    //   if(localStorage.back=='true')
    //   {
    //     //localStorage.back='false';
    //   if(this.previousUrl.startsWith("/restaurant") && this.router.url.startsWith("/restaurant"))
    //   {
    //    let url: string = "/restaurant/" + localStorage.ID;
    //    this.router.navigateByUrl(url);
    //   }
    //   if(this.previousUrl.startsWith("/mainHome") && this.router.url.startsWith("/mainHome"))
    //   this.router.navigate(["../mainHome"]);
    // }
    // });
  // }
  
   ngOnInit(): void
      {

        // const $ = cheerio.load('<ul></ul>') 
        // $.html();
        if(sessionStorage.num==undefined)
        sessionStorage.num=location.pathname;
        else
        sessionStorage.num="2";
        this.matDialog.closeAll();
        localStorage.DELIVERY=0;
        this.dataService.clickPayment.next(true);
        this.clickPayment.emit();
        this.innerWidth = window.innerWidth;
        this.proxy.scrollToTop();
        this.dataService.header.next(true);
        this.header.emit();
        this.showProgress=true;
        this.dataService.byFood.next(true);
        this.changByFood.emit();
        this.dataService.show.next(true);
        this.changeShowPrice.emit();
        this.dataService.showSearch.next(true);
        this.changByFood.emit();
        localStorage.SHOW=true;
          // {
        this.orderService.orderAddingList=null;
        if(localStorage.ID!="0")
      if(this.categoryToFilter=="")
      {
        this.id=localStorage.ID;
        console.log("categoryToFilter 5")
        console.log(localStorage.ID , "0")
      }
        else if(this.restaurant.ID!=undefined)
        {
        this.id=this.restaurant.ID;
        console.log("categoryToFilter 6")
        }
        else
        {
        this.id=localStorage.ID_CATEGORY;
        console.log("categoryToFilter 7")
        }
      
        this.categoryFilter=sessionStorage.CATEGORY_FILTER;
        console.log("categoryToFilter 8" )
        console.log( this.categoryFilter)
        if(this.categoryToFilter==null)
        {
        this.categoryToFilter="";
        console.log("categoryToFilter 9")
        }

        if ( localStorage.ACCESS_TOKEN != "null" && localStorage.ACCESS_TOKEN != null &&(this.orderService.order == null 
          || this.orderService.order.OrderID == 0 
          ||  this.innerWidth>480 ||  this.innerWidth<480 )
          )
          {
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{

          console.log("yyy" )
           
         
        

          
        //this.orderService.getOrderDetails(false).subscribe((res:any)=>{
         // console.log("getOrderDetails1","getOrderDetails");
          this.order=this.orderService.order;
         // this.order=res;
           if(this.order !=null && Number(this.order.TotalPrice)>0)
            {
              console.log("yyy" )
            this.priceTotal =  this.orderService.order.TotalPrice+" ₪"
              //naomi ניסיון לשנות
          this.priceTotal=this.clickToPay+" "+this.order.TotalPrice+" ₪";
     
         

            }
           else
              this.priceTotal="0 - פריטים";
              if(this.order!=null && this.order.list!=null && this.order.list.length!=0 && localStorage.edit=="false")
              {
                let pos=this.order.list.length;
                if(pos>0)
                {
                pos=Number(Number(pos)-1);
        }
        }
     
         //to chack here
         localStorage.PRICE_TOTAL=this.priceTotal;
         this.dataService.price.next(this.priceTotal);
         this.changePrice.emit();
         console.log("אני פה1" )
      

         this.gg()
    });
  }
 //_______________________________________________________________
  else {

         
           
     
     

       
     //this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      // console.log("getOrderDetails1","getOrderDetails");
       this.order=this.orderService.order;
      // this.order=res;
        if(this.order !=null && Number(this.order.TotalPrice)>0)
         {
        this.priceTotal =  this.orderService.order.TotalPrice+" ₪"
           //naomi ניסיון לשנות
       this.priceTotal=this.clickToPay+" "+this.order.TotalPrice+" ₪";
  
      

         }
        else
           this.priceTotal="0 - פריטים";
           if(this.order!=null && this.order.list!=null && this.order.list.length!=0 && localStorage.edit=="false")
           {
             let pos=this.order.list.length;
             if(pos>0)
             {
             pos=Number(Number(pos)-1);
     }
     }
  
      //to chack here
      localStorage.PRICE_TOTAL=this.priceTotal;
      this.dataService.price.next(this.priceTotal);
      this.changePrice.emit();
      console.log("אני פה2")
      this.gg()
 }

}
 //_______________________________________________________________

       gg()
       {
        console.log(this.bussinssService.restaurant , "1357")
           this.routeSub = this.route.params.subscribe(params => {
            console.log(params) //log the entire params object
           // params['id']="821123";
            let urlId=params['id'];
       

            if(sessionStorage.deeplink!=undefined && sessionStorage.deeplink!="" && this.orderService.order!=undefined)
            {
              this.dataService.deepLink.next(true);
           this.deepLink.emit();
            this.id=sessionStorage.deeplink;
            let category=0;
            if(sessionStorage.CATEGORY_FILTER!="" && sessionStorage.CATEGORY_FILTER!=undefined)
            category=sessionStorage.CATEGORY_FILTER;
            if(this.order==null ||this.order.OrderID==0 ||this.order==undefined)
            this.category(category);
            else
            {
              console.log(this.bussinssService.restaurant , "1234")
            this.getRestaurantDetails(this.bussinssService.restaurant,-1);
            }
            }
           else if(urlId!=undefined && urlId.length>=5)
            {
              console.log("יש יוזר והוא גדול מ5" , urlId)
              sessionStorage.deeplink2=urlId;
            this.idUrl = urlId.substring(0, urlId.length- 4);
            if(this.idUrl>-1)
            {
              this.id=this.idUrl//log the value of id
              sessionStorage.deeplink=this.id;
              this.dataService.deepLink.next(true);
              this.deepLink.emit();
              if(this.order==null ||this.order.OrderID==0 ||this.order==undefined)
              {
                  let i=0;
                  localStorage.TEXT_SEARCH="";
                  sessionStorage.CATEGORY_FILTER=i;
                  console.log("2היגיע לפה " ,i)
                  if(this.categores!=null)
                  for(let j=0;j<this.categores.length;j++)
                  {
                    if(j!=i)
                    this.categores[j].isCheck=0;
                  }
                  if(i>-1)
                  {
                  if(this.categores!=null && this.categores.length>0)
                  {
                     this.categores[i].isCheck=1;
                     this.categoryToFilter=this.categores[i].Category;
                     console.log("categoryToFilter 10")
                  }
                    if(sessionStorage.deeplink=="")
                    {
                    if(localStorage.ID!="0") 
                    if(this.categoryToFilter=="")
                    {
                      this.id=localStorage.ID;
                      console.log("categoryToFilter 11")
                    }
                      else
                      {
                      this.id=this.restaurant.ID;
                      console.log("categoryToFilter 12")
                      }
                      else
                      {
                      this.id=this.restaurant.ID;
                      console.log("categoryToFilter 13")
                      }
                    }
                    if(this.categoryToFilter==null)
                      
                    this.categoryToFilter="";
                    


                    this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
                      this.restaurant=res;
                      this.restaurantItem=this.restaurant.MenuList;
                      
                      let KashrutLength=0;
                      if (this.restaurant.Kashrut!=null)
                      KashrutLength=this.restaurant.Kashrut.length;
                      if(this.innerWidth<=480)
                      {
                      this.nameLength=Number(Number(this.restaurant.Name.length)+Number(KashrutLength))
                      this.isNameLength=this.nameLength>22;
                      this.headerLength=Number(this.restaurant.Header.length)
                      this.isHeaderLength=this.headerLength>25;
                      }
                      else
                      {
                        this.isNameLength=false; 
                        this.isHeaderLength=false;
                      }
                      this.orderService.restaurant=this.restaurant;
                      if(this.restaurant.LongImage!=null)
                      this.Image=this.restaurant.LongImage;
                      this.restaurantItem=this.restaurant.MenuList;
                      if(this.restaurantItem!=null)
                      for(let i=0;i<this.restaurantItem.length;i++)
                        {
                          if(this.restaurantItem[i].Rate==undefined)
                          this.restaurantItem[i].Rate=0;
                          else if(this.restaurantItem[i].Rate>0)
                          {
                            this.buttonText="המשך לתשלום";
                          this.totalItems+=this.restaurantItem[i].Rate;
                         let p=0.0;
                         p=this.restaurantItem[i].Price;
                         this.totalAmount=Number(this.totalItems)*Number(p);
                          }
                        }
                       this.order=this.orderService.order;
                       if(this.restaurant.MenuList.length>0)
                       {
                        if(this.order!=null && this.order.list!=null)
                         for(let i=0;i<this.order.list.length;i++)
                         {
                           for(let j=0;j<this.restaurantItem.length;j++)
                           {
                           if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
                           if(this.order.list[i].Count>0)
                           this.restaurantItem[j].Rate+=this.order.list[i].Count;
                           }
                         }
                         let amount=0;
                         
                            for(let j=0;j<this.restaurantItem.length;j++)
                     {
                     if(this.restaurantItem[j].IsAddition==1)
                     {
                     this.restaurantItem[j].ChangeFood=[];
                     if(this.restaurantItem[j].ChangeFood==null)
                        this.restaurantItem[j].ChangeFood=[];
                     for(let q=0;q<this.restaurantItem[j].Rate;q++)
                     {
                      let changeFood = new ChangeFood();
                      if(this.restaurantItem[j].Rate!=null)
                         amount+=1;
                       changeFood.Text="לחץ לשינויים ";
                      changeFood.toppings =null; 
                        changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
                        this.restaurantItem[j].ChangeFood.push(changeFood);
                     }
                   }
                   }
                        if(localStorage.PRICE>0)
                        this.totalAmount=Number(localStorage.PRICE)
                        else
                        {
                         this.totalAmount=0;
                       for(let i=0;i<this.restaurant.MenuList.length;i++)
                       {
                         if(this.restaurant.MenuList[i].Rate>0)
                         {
                           let p:number=0;
                           p=this.restaurant.MenuList[i].Price;
                           this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
                           this.totalItems+=this.restaurantItem[i].Rate;
                         }
                       }
                     }
                  
                   //   {
                   //   let changeFood = new ChangeFood();
                   //   let amount=0;
                   //   if(this.restaurantItem[j].Rate!=null)
                   //      amount=this.restaurantItem[j].Rate;
                   //    changeFood.Name =(amount)+'-'+ this.restaurantItem[j].Name;
                   //    changeFood.Text="לחץ לשינויים ";
                   //   changeFood.toppings =null; 
                   //   this.restaurantItem[j].ChangeFood=[];
                   //   for(let q=0;q<this.restaurantItem[j].Rate;q++)
                   //   {
                   //   if(this.restaurantItem[j].ChangeFood==null)
                   //      this.restaurantItem[j].ChangeFood=[];
                   //      this.restaurantItem[j].ChangeFood.push(changeFood);
                   //   }
                   // }
                    //   if(this.totalAmount>0 && this.order.list.length>0)
                    //   this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
                    //   else
                     //  this.priceTotal="0 - פריטים";
                     //  this.dataService.price.next(this.priceTotal);
                    // this.changePrice.emit();
                     //   this.dataService.price.next(this.priceTotal);
                     //   this.changePrice.emit();
                   //    localStorage.PRICE_TOTAL=this.priceTotal;
                     }
                       if(this.order!=null && this.order.OrderID!=0)
                         {
                           let haveList=false;
                           let pos=-1;
                           for(let j=0;j<this.restaurantItem.length;j++)
                         
                         {
                            haveList=false;
                            pos=-1;
                            for(let i=0;i<this.order.list.length;i++)
                           {
                       
                           if(this.restaurantItem[j].Rate>0)
                           {
                             pos=j;
                           if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
                           {
                              haveList=true;
                              i=this.order.list.length;
                           }
                         }
                           }
                           if(pos>0 && haveList==false)
                           {
                           this.restaurantItem[pos].Rate=0;
                           let location = this.restaurantItem[pos].ChangeFood.length-1;
                           this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
                           delete this.restaurantItem[pos].ChangeFood[location]; 
                           this.restaurantItem[pos].ChangeFood.length=location;
                           let p:number=0;
                           p=this.restaurantItem[pos].Price;
                           this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
                           this.totalItems-=this.restaurantItem[pos].Rate;
                         }
                         }
                         } 
                   
                    });
                  }
                 
              this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
             if(res==null)
             {
              this.showProgress=false;
                  this.proxy.showError("אין כזו מסעדה פעילה","");
                        this.router.navigate(["../mainHome"])
             }
             else{
                console.log("getRestaurantDetails","getOrderDetails");
               this.getRestaurantDetails(res,0);
             }
       });
      }
      else
      {
        console.log("0987")
      this.getRestaurantDetails(this.bussinssService.restaurant,-1);
      
      }
              // let category=0;
              // if(sessionStorage.CATEGORY_FILTER!="" && sessionStorage.CATEGORY_FILTER!=undefined)
              // category=sessionStorage.CATEGORY_FILTER;
           //   this.category(0);
          //       this.orderService.getOrderDetails(false).subscribe((res:any)=>{
          //         this.order=res;
          //         this.orderService.order=null;
          //       if(this.order.list!=null && this.order.list.length>0 )
          //       {
          //         this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
          //           null,null,0,null,1,null,null,null,null,null,null,null,null,0).subscribe((res:any)=>{
          //        // this.orderService.order=null;
              
          //      // this.router.navigate(['../restaurant']);
          //          });
                
     
          //   }
          
          // });
        }
        else{
          console.log("היוזר הוא לא מספר")
          this.bussinssService.getBusinessIDByEnName(urlId).subscribe((res:any)=>{
            console.log(res,"res")
            this.id=Number(res);
       if(this.id==undefined || this.id==null)
        {
        if(localStorage.ID!="0")
        if(this.categoryToFilter=="")
        {
          this.id=localStorage.ID;
          console.log("categoryToFilter 18")
        }
          else if(this.restaurant.ID!=undefined)
          {
          this.id=this.restaurant.ID;
          console.log("categoryToFilter 17")
          }
          else
        {
          this.id=localStorage.ID_CATEGORY;
          console.log("categoryToFilter 16")
        }
        }
     else 
       if(this.order==null ||this.order.OrderID==0 ||this.order==undefined)
        {
          console.log("order null")
            let i=0;
            localStorage.TEXT_SEARCH="";
            sessionStorage.CATEGORY_FILTER=i;
            console.log("3היגיע לפה " ,i)
            if(this.categores!=null)
            for(let j=0;j<this.categores.length;j++)
            {
              if(j!=i)
              this.categores[j].isCheck=0;
            }
            if(i>-1)
            {
            if(this.categores!=null && this.categores.length>0)
            {
               this.categores[i].isCheck=1;
               this.categoryToFilter=this.categores[i].Category;
            }
              if(sessionStorage.deeplink=="")
              {
              if(localStorage.ID!="0") 
              if(this.categoryToFilter=="")
                this.id=localStorage.ID;
                else
                this.id=this.restaurant.ID;
                else
                this.id=this.restaurant.ID;
              }
              if(this.categoryToFilter==null)
              this.categoryToFilter="";
              this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
                this.restaurant=res;
                //////todo
               // this.titleService.setTitle("Some title");
                //this.metaService.updateTag({ name: 'description', content: "תאור מסעדה" })
                this.restaurantItem=this.restaurant.MenuList;
                let KashrutLength=0;
                if (this.restaurant.Kashrut!=null)
                KashrutLength=this.restaurant.Kashrut.length;
                if(this.innerWidth<=480)
                {
                this.nameLength=Number(Number(this.restaurant.Name.length)+Number(KashrutLength))
                this.isNameLength=this.nameLength>22;
                this.headerLength=Number(this.restaurant.Header.length)
                this.isHeaderLength=this.headerLength>25;
                }
                else
                {
                  this.isNameLength=false; 
                  this.isHeaderLength=false;
                }
                this.orderService.restaurant=this.restaurant;
                if(this.restaurant.LongImage!=null)
                this.Image=this.restaurant.LongImage;
                this.restaurantItem=this.restaurant.MenuList;
                if(this.restaurantItem!=null)
                for(let i=0;i<this.restaurantItem.length;i++)
                  {
                    if(this.restaurantItem[i].Rate==undefined)
                    this.restaurantItem[i].Rate=0;
                    else if(this.restaurantItem[i].Rate>0)
                    {
                      this.buttonText="המשך לתשלום";
                    this.totalItems+=this.restaurantItem[i].Rate;
                   let p=0.0;
                   p=this.restaurantItem[i].Price;
                   this.totalAmount=Number(this.totalItems)*Number(p);
                    }
                  }
                 this.order=this.orderService.order;
                 if(this.restaurant.MenuList.length>0)
                 {
                  if(this.order!=null && this.order.list!=null)
                   for(let i=0;i<this.order.list.length;i++)
                   {
                     for(let j=0;j<this.restaurantItem.length;j++)
                     {
                     if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
                     if(this.order.list[i].Count>0)
                     this.restaurantItem[j].Rate+=this.order.list[i].Count;
                     }
                   }
                   let amount=0;
                   
                      for(let j=0;j<this.restaurantItem.length;j++)
               {
               if(this.restaurantItem[j].IsAddition==1)
               {
               this.restaurantItem[j].ChangeFood=[];
               if(this.restaurantItem[j].ChangeFood==null)
                  this.restaurantItem[j].ChangeFood=[];
               for(let q=0;q<this.restaurantItem[j].Rate;q++)
               {
                let changeFood = new ChangeFood();
                if(this.restaurantItem[j].Rate!=null)
                   amount+=1;
                 changeFood.Text="לחץ לשינויים ";
                changeFood.toppings =null; 
                  changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
                  this.restaurantItem[j].ChangeFood.push(changeFood);
               }
             }
             }
                  if(localStorage.PRICE>0)
                  this.totalAmount=Number(localStorage.PRICE)
                  else
                  {
                   this.totalAmount=0;
                 for(let i=0;i<this.restaurant.MenuList.length;i++)
                 {
                   if(this.restaurant.MenuList[i].Rate>0)
                   {
                     let p:number=0;
                     p=this.restaurant.MenuList[i].Price;
                     this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
                     this.totalItems+=this.restaurantItem[i].Rate;
                   }
                 }
               }
            
             //   {
             //   let changeFood = new ChangeFood();
             //   let amount=0;
             //   if(this.restaurantItem[j].Rate!=null)
             //      amount=this.restaurantItem[j].Rate;
             //    changeFood.Name =(amount)+'-'+ this.restaurantItem[j].Name;
             //    changeFood.Text="לחץ לשינויים ";
             //   changeFood.toppings =null; 
             //   this.restaurantItem[j].ChangeFood=[];
             //   for(let q=0;q<this.restaurantItem[j].Rate;q++)
             //   {
             //   if(this.restaurantItem[j].ChangeFood==null)
             //      this.restaurantItem[j].ChangeFood=[];
             //      this.restaurantItem[j].ChangeFood.push(changeFood);
             //   }
             // }
              //   if(this.totalAmount>0 && this.order.list.length>0)
              //   this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
              //   else
               //  this.priceTotal="0 - פריטים";
               //  this.dataService.price.next(this.priceTotal);
              // this.changePrice.emit();
               //   this.dataService.price.next(this.priceTotal);
               //   this.changePrice.emit();
             //    localStorage.PRICE_TOTAL=this.priceTotal;
               }
                 if(this.order!=null && this.order.OrderID!=0)
                   {
                     let haveList=false;
                     let pos=-1;
                     for(let j=0;j<this.restaurantItem.length;j++)
                   
                   {
                      haveList=false;
                      pos=-1;
                      for(let i=0;i<this.order.list.length;i++)
                     {
                 
                     if(this.restaurantItem[j].Rate>0)
                     {
                       pos=j;
                     if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
                     {
                        haveList=true;
                        i=this.order.list.length;
                     }
                   }
                     }
                     if(pos>0 && haveList==false)
                     {
                     this.restaurantItem[pos].Rate=0;
                     let location = this.restaurantItem[pos].ChangeFood.length-1;
                     this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
                     delete this.restaurantItem[pos].ChangeFood[location]; 
                     this.restaurantItem[pos].ChangeFood.length=location;
                     let p:number=0;
                     p=this.restaurantItem[pos].Price;
                     this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
                     this.totalItems-=this.restaurantItem[pos].Rate;
                   }
                   }
                   } 
             
              });
            }
           
        this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
         console.log("getRestaurantDetails","getOrderDetails");
         this.getRestaurantDetails(res,0);
 });
}
else
{
  if (this.bussinssService.restaurant != null)
  {
  console.log("8787" ,this.bussinssService.restaurant.ID)
this.getRestaurantDetails(this.bussinssService.restaurant,-1);
  }
  else
  {
    this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
      console.log("getRestaurantDetails","getOrderDetails");
      this.getRestaurantDetails(res,0);
    });
  }
}
              // this.category(0);
              // this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categores[0].Category,null).subscribe((res:any)=>{
              //  console.log("getRestaurantDetails","getOrderDetails");
              //  this.getRestaurantDetails(res,0);
      //          if(sessionStorage.ACCESS_TOKEN != undefined)
      //           this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      //             this.order=res;
      //             this.orderService.order=null;
      //           if(this.order.list!=null && this.order.list.length>0 )
      //           {
      //             this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
      //               null,null,0,null,1,null,null,null,null,null,null,null,null,0).subscribe((res:any)=>{
      //            // this.orderService.order=null;
              
      //          // this.router.navigate(['../restaurant']);
      //              });
                
                  
      //       }
          
      //  //   });
      //   });
              
            //   if (sessionStorage.ACCESS_TOKEN == "null" || sessionStorage.ACCESS_TOKEN == null || sessionStorage.ACCESS_TOKEN == "") {
            //     sessionStorage.ACCESS_TOKEN = "null";
            //     this.matDialog.closeAll();
            //   if (this.innerWidth <= 480)
            //   this.matDialog.open(PopupEnter2Component, { disableClose: true });
            // else
            //   this.matDialog.open(PopupEnterComponent, { disableClose: true });
            // }
          });
        }
        }
//         if(this.id==undefined || this.id==null)
//         {
//         if(localStorage.ID!="0")
//         if(this.categoryToFilter=="")
//           this.id=localStorage.ID;
//           else if(this.restaurant.ID!=undefined)
//           this.id=this.restaurant.ID;
//           else
//           this.id=localStorage.ID_CATEGORY;
//         }
      else
      {
        if(this.order==null ||this.order.OrderID==0 ||this.order==undefined)
         {
         this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
          console.log("getRestaurantDetails","getOrderDetails");
          console.log("8736")
          this.getRestaurantDetails(res,0);
  });
 }
 else
 {
  console.log("8734")
this.getRestaurantDetails(this.bussinssService.restaurant,-1);
 }
           }
});
     
   // else
    //this.getRestaurantDetails(this.bussinssService.restaurant,-1);

//4444
    //  }

      //to chack
      //naomi change 01/08/24
      localStorage.edit="false"
   
    }

    addTag() {
      this.metaService.addTag({ name: 'description', content: 'Article Description' });
      this.metaService.addTag({ name: 'title', content: 'index,follow' });
      this.metaService.addTag({ property: 'og:title', content: 'Content Title for social media' });
    }

    getRestaurantDetails(restaurant,num)
    {
     
        this.restaurant=restaurant;
        this.restaurantItem=this.restaurant.MenuList;
      let KashrutLength=0;
      if (this.restaurant!= null && this.restaurant.Kashrut!=null)
      KashrutLength=this.restaurant.Kashrut.length;
      if(this.innerWidth<=480)
      {
      this.nameLength=Number(Number(this.restaurant.Name.length)+Number(KashrutLength))
      this.isNameLength=this.nameLength>22;
      this.headerLength=Number(this.restaurant.Header.length)
      this.isHeaderLength=this.headerLength>25;
      }
      else
      {
        this.isNameLength=false; 
        this.isHeaderLength=false;
      }
      this.categores=this.restaurant.categoryList;
      let cc=0;
      if(this.categoryToFilter=="")
      cc=1;
      this.categoryToFilter=this.categores[0].Category;
      console.log("categoryToFilter 19" )
      console.log( this.categoryToFilter)
      
   let pos:number=Number(sessionStorage.CATEGORY_FILTER);
   console.log( pos , "pos")
  if( pos>-1 && Number(this.categores.length-1)>= pos)
  {
  this.categoryToFilter=this.categores[pos].Category;
  
  console.log("categoryToFilter 20" )
  console.log( this.categoryToFilter)
  console.log( num , "num")
  }
  if(num>-1)
  {
    let b=0;
    for(let i=0;i<this.restaurant.ListDay.length;i++)
    {
      if(i==new Date().getDay())
      {
      if(new Date().getHours()<=5)
      {
         if(i>0)
         {
        i=i-1
        b=1;
         }
         else
         {
         i=6;
         b=7;
         }
      }
  
    if(this.restaurant.ListDay[i].DayTime!='' && this.restaurant.ListDay[i].DayTime!='0:00-0:00' && this.restaurant.ListDay[i].DayTime!='00:00-00:00')
     {
       var fields = this.restaurant.ListDay[i].DayTime.split('-');
       if(this.proxy.isOpen(fields[0],fields[1],b))
        this.dayTime=this.restaurant.ListDay[i].DayTime;
        else
        this.dayTime="סגור";
     }
      else
      this.dayTime="סגור";
      if(this.dayTime=="סגור")
    {
      this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
      this.router.navigate(["../mainHome"])
    }
      i=this.restaurant.ListDay.length;
      }
    }
    console.log("7733")
      this.category(pos);
      this.showProgress=false;
      
    }

    
    else
    {
      console.log("6787")
      //for(let j=0;j<this.categores.length;j++)
     // {
     //   this.categores[j].isCheck=0;
     // }
     // this.categores[Number(sessionStorage.CATEGORY_FILTER)].isCheck=1;
      this.showProgress=false;   
      if(this.restaurant.LongImage!=null)
      this.Image=this.restaurant.LongImage;
    //  if(this.restaurant==null)
   // localStorage.ID_CATEGORY= localStorage.ID;
  {
    if(this.MenuList.length!=0)
    this.restaurantItem= this.MenuList;
   if(this.restaurant.MenuList!=null)
      this.restaurantItem=this.restaurant.MenuList; 
      // for(let i=0;i<this.categores.length;i++)
      // {
      //   this.categores[i].isCheck=0;
      //   if(this.categoryToFilter!="" && this.categoryToFilter!=null)
      //   if(this.categores[i].Category==this.categoryToFilter)
      //   this.categores[i].isCheck=1;
      // }

     
      if(this.restaurantItem!=null)
      for(let i=0;i<this.restaurantItem.length;i++)
        {
          if(this.restaurantItem[i].Rate==undefined)
          this.restaurantItem[i].Rate=0;
          else if(this.restaurantItem[i].Rate>0)
          {
            this.buttonText="המשך לתשלום";
          this.totalItems+=this.restaurantItem[i].Rate;
         let p=0.0;
         p=this.restaurantItem[i].Price;
         this.totalAmount=Number(this.totalItems)*Number(p);
     //    if(this.totalAmount>0 && this.order.list.length>0)
     // this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
     // else
    //  this.priceTotal="0 - פריטים";
     // this.dataService.price.next(this.priceTotal);
     // this.changePrice.emit();
     // localStorage.PRICE_TOTAL=this.priceTotal;
          }
        }}
        if(this.restaurant!=null)
        {
        //  if(this.restaurant.categoryList!=null)
        //  this.categores=this.restaurant.categoryList;
        let b=0;
          for(let i=0;i<this.restaurant.ListDay.length;i++)
          {
            if(i==new Date().getDay())
            {
            if(new Date().getHours()<=5)
            {
               if(i>0)
               {
              i=i-1
              b=1;
               }
               else
               {
               i=6;
               b=7;
               }
            }
        
              if(this.restaurant.ListDay[i].DayTime!='' && this.restaurant.ListDay[i].DayTime!='0:00-0:00' && this.restaurant.ListDay[i].DayTime!='00:00-00:00')
           {
          
             var fields = this.restaurant.ListDay[i].DayTime.split('-');
             if(this.proxy.isOpen(fields[0],fields[1],b))
              this.dayTime=this.restaurant.ListDay[i].DayTime;
              else
              this.dayTime="סגור";
           }
            else
            this.dayTime="סגור";
            i=this.restaurant.ListDay.length;
            }
          }
          if(localStorage.PRICE>0)
          this.totalAmount=Number(localStorage.PRICE);
         // if(this.totalAmount>0 && this.order.list.length>0)
         // this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
         // else
         // this.priceTotal="0 - פריטים";
          //this.dataService.price.next(this.priceTotal);
          //this.changePrice.emit();
          //localStorage.PRICE_TOTAL=this.priceTotal;

          //if(this.restaurant.Rate!=null)
         // this.Rate=this.restaurant.Rate;
         // else
         // if(this.restaurant.Rate==0 ||this.restaurant.Rate==null)
         // this.restaurant.Rate=3;
         if(this.restaurant.Name!=null)
        this.Name=this.restaurant.Name;
        }
        else{
          //localStorage.ID=localStorage.ID_CATEGORY;
          //this.proxy.showError("שגיאה בקבלת פרטים ","");
        }
       
    if(this.restaurantItem!=null && this.restaurantItem.length>0)
    this.buttonText="המשך לתשלום"
        if(this.restaurant!=null)
      {

      }
      else{
       // localStorage.ID=localStorage.ID_CATEGORY;
    
      }
     if(this.restaurant.MenuList.length>0)
     {
    //  if(this.order!=null && this.order.list!=null)
      //  for(let i=0;i<this.order.list.length;i++)
      //  {
      //    for(let j=0;j<this.restaurantItem.length;j++)
      //    {
      //    if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
      //    if(this.order.list[i].Count>0)
      //    this.restaurantItem[j].Rate+=this.order.list[i].Count;
      //    }
      //  }
       let amount=0;
          for(let j=0;j<this.restaurantItem.length;j++)
   {
    if(this.restaurantItem[j].IsAddition==1)
   {
    this.restaurantItem[j].ChangeFood=[];
    if(this.restaurantItem[j].ChangeFood==null)
       this.restaurantItem[j].ChangeFood=[];
      
   for(let q=0;q<this.restaurantItem[j].Rate;q++)
   {
    let changeFood = new ChangeFood();
  
    if(this.restaurantItem[j].Rate!=null)
       amount+=1;
       //this.restaurantItem[j].Rate;
    // changeFood.Name =(Number(j+1))+'-'+ this.restaurantItem[j].Name;
     changeFood.Text="לחץ לשינויים ";
    changeFood.toppings =null; 

      changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
      this.restaurantItem[j].ChangeFood.push(changeFood);
   }
  }
 }
       
      // this.restaurantItem=this.restaurant.MenuList;
      
      if(localStorage.PRICE>0)
      this.totalAmount=Number(localStorage.PRICE)
      else
      {
       this.totalAmount=0;
     for(let i=0;i<this.restaurant.MenuList.length;i++)
     {
       if(this.restaurant.MenuList[i].Rate>0)
       {
         let p:number=0;
         p=this.restaurant.MenuList[i].Price;
         this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
         this.totalItems+=this.restaurantItem[i].Rate;
       }
     }
    
   }
 //   for(let j=0;j<this.restaurantItem.length;j++)
 //   {
 //   let changeFood = new ChangeFood();
 //   let amount=0;
 //   if(this.restaurantItem[j].Rate!=null)
 //      amount=this.restaurantItem[j].Rate;
 //    changeFood.Name =(amount)+'-'+ this.restaurantItem[j].Name;
 //    changeFood.Text="לחץ לשינויים ";
 //   changeFood.toppings =null; 
 //   this.restaurantItem[j].ChangeFood=[];
 //   for(let q=0;q<this.restaurantItem[j].Rate;q++)
 //   {
 //   if(this.restaurantItem[j].ChangeFood==null)
 //      this.restaurantItem[j].ChangeFood=[];
 //      this.restaurantItem[j].ChangeFood.push(changeFood);
 //   }
 // }
    // if(this.totalAmount>0 && this.order.list.length>0)
    // this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
    // else
    // this.priceTotal="0 - פריטים";
    // this.dataService.price.next(this.priceTotal);
    // this.changePrice.emit();
    // localStorage.PRICE_TOTAL=this.priceTotal;
   }
    // if( this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null )
    // this.restaurantItem=this.orderService.restaurant.MenuList;
     //if(this.orderService.restaurant.categoryList!=null)
    // this.categores=this.restaurant.categoryList;
     if(this.order!=null && this.order.OrderID!=0)
       {
         let haveList=false;
         let pos=-1;
         for(let j=0;j<this.restaurantItem.length;j++)
       
       {
          haveList=false;
          pos=-1;
          for(let i=0;i<this.order.list.length;i++)
         {
     
         if(this.restaurantItem[j].Rate>0)
         {
           pos=j;
         if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
         {
            haveList=true;
            i=this.order.list.length;
         }
       }
         }
         if(pos>0 && haveList==false)
         {
          console.log(pos, "yuiu")
         this.restaurantItem[pos].Rate=0;
         let location = this.restaurantItem[pos].ChangeFood.length-1;
         this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
         delete this.restaurantItem[pos].ChangeFood[location]; 
         this.restaurantItem[pos].ChangeFood.length=location;
         let p:number=0;
         p=this.restaurantItem[pos].Price;
         this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
         this.totalItems-=this.restaurantItem[pos].Rate;
         localStorage.PRICE= Number(this.totalAmount);
    //      if(this.totalAmount>0)
    //      {
    //        if(this.order!=null && this.order.TotalPrice!=null)
    //        {
    //      this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
    //      localStorage.PRICE_TOTAL=this.priceTotal;
    //      this.dataService.price.next(this.priceTotal);
    //      this.changePrice.emit();
    //      }
    //      else
    //      {
    //  this.priceTotal="0 - פריטים";
    //  this.dataService.price.next(this.priceTotal);
    //          this.changePrice.emit();
    //      }
       
    //      this.totalAmount=Number(this.order.TotalPrice);
    //    }
       }
       else
       {
        if (this.order.OrderID != 0)
          {
        console.log(pos, "yuyu")
        let i=0
        for(i;i<this.categores.length;i++)
        {
          if (this.categores[i].isCheck == 1)
          {
            return
          }

        }

        if (i == this.categores.length)

       {
          this.categores[0].isCheck=1;
          this.categoryToFilter=this.categores[0].Category;
          this.category(0)
        }
       // this.category(this.categores)

       }
      }
       }
       // for(let i=0;i<this.order.list.length;i++)
       // {
       //   for(let j=0;j<this.restaurantItem.length;j++)
       //   {
       //   if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
       //   if(this.order.list[i].Count>0)
       //   this.restaurantItem[j].Amount=this.order.list[i].Count;
       //   }
       // }
         
 
     //  if(haveList==false)
      // this.restaurantItem[pos].Amount=0;
     
       }
      
      }
      // this.categores[sessionStorage.CATEGORY_FILTER].isCheck=1;
  // });

  //this.endToppings = this.dataService.endToppings.getValue() || {};
  this.endToppings = this.dataService.notEndToppings
 // this.dataService.endToppings.subscribe(data=>{
  //  this.endToppings=data;
    if(this.endToppings==true)
    {
     return;
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        // if(this.order.list!=null && this.order.list.length!=0 && localStorage.edit=="false")
        // {}
      let pos=this.order.list.length-1;
      this.orderService.addToBagOrder(this.order.list[pos].ID,this.order.OrderID,this.order.list[pos].MenuNumber).subscribe((res:any)=>{
        //window.location.reload();
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{
          this.order=res;
          this.showProgress=false;
          this.dataService.notEndToppings=false;
          this.restaurantItem[Number(localStorage.pos)].Rate=0;
          this.ngOnInit();
    
        });
        // let currentUrl = this.router.url;
        // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        //     this.router.navigate([currentUrl]);
        //     this.dataService.notEndToppings=false;
        //     //this.dataService.endToppings.next(true);
        //    // this.header.emit();
        // });
      });
    //     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    // this.order=res;
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // Trick the Router into believing it's last link wasn't previously loaded
    //     this.router.navigated = false;
    //   }
    // });
   //  let url: string = "/restaurant/" + this.orderService.restaurant.NameEn;
 //this.router.navigateByUrl(url);
 //this.matDialog.closeAll();
 
//history.back();
  // });
    }); 
    }
 // });
 console.log("7777")
  this.text = this.dataService.search.getValue() || {};
  this.dataService.search.subscribe(data=>{
    this.text=data;
  
  if(this.text!="")
  {
    console.log("7772")
    if(sessionStorage.CATEGORY_FILTER!="")
    this.categoryFilter=sessionStorage.CATEGORY_FILTER;
    else
    this.categoryToFilter="";
    console.log("categoryToFilter 21")
    if(localStorage.ID!="0")
    if(this.categoryToFilter=="")
    {
      this.id=localStorage.ID;
      console.log("categoryToFilter 22")
    }
      else
      {
      this.id=this.restaurant.ID;
      console.log("categoryToFilter 23")
      }
       if(this.id==undefined)
       {
       this.id=this.restaurant.ID;
       console.log("categoryToFilter 24")
       }
       let id_:number=localStorage.ID
       if(id_ !=undefined &&   localStorage.TEXT_SEARCH!="")
    this.bussinssService.getBusinessMenuBySearch(id_,this.text,1).subscribe((res:any[])=>{
      this.restaurant.MenuList=res;
      if(this.restaurant.MenuList!=null)
      {
      this.restaurantItem=this.restaurant.MenuList;
      this.MenuList=[];
      //this.category(-1);
      this.dataService.text.next("");
      this.changText.emit();
      }
      else
      {
      this.proxy.showError("לא נמצאו מנות\n בקטגוריה זו","");
      this.dataService.text.next("");
      this.changText.emit();
      }
    });

  }
  if(sessionStorage.deeplink==this.id )
  {
  if (sessionStorage.ACCESS_TOKEN == "null" || sessionStorage.ACCESS_TOKEN == null || sessionStorage.ACCESS_TOKEN == "") {
    sessionStorage.ACCESS_TOKEN = "null";
  this.openDialogEnter();
  }
  }
});

    }

    openDialogEnter() {
      let num=0;
      if(localStorage.num!=undefined && localStorage.num!=null && !localStorage.num.startsWith("NaN"))
      num=localStorage.num;
      num+=1;
      localStorage.num=num;
      if(sessionStorage.dialog=="false" || sessionStorage.dialog==undefined)
      {
       sessionStorage.dialog="true"
      console.log("PopupEnterComponent2"+location.pathname+"-"+num);
      if( num>1)
      {
        this.proxy.num=0;
      if (this.innerWidth <= 480)
        this.matDialog.open(PopupEnter2Component);
      else
        this.matDialog.open(PopupEnterComponent);
      }
      }
      //}
    }

    onBackPress()
    {
      if(sessionStorage.deeplink=="" || sessionStorage.deeplink==undefined)
      {
      localStorage.back='true'
      //localStorage.ID=localStorage.ID_CATEGORY;
          if(localStorage.Category=="2")
          {
            let url: string;
          //  if(location.pathname=="/yourOrder")
           // this.router.navigate(["yourOrder"])
          // else 
        //   if(localStorage.TEXT!='null' && localStorage.TEXT!=undefined)
           // {
             url= "/restaurantCategory/" + sessionStorage.Category;
            // localStorage.TEXT='null';
          //  }
           //  else 

            // url= "/restaurantCategory/" + localStorage.ID_CATEGORY;
            this.router.navigateByUrl(url);
         
          }
          else
          this.router.navigate(["../mainHome"]);
        }
    }

  payment()
  {
    this.restaurant.MenuList=this.restaurantItem;
    this.orderService.restaurant=this.restaurant;
    if(this.totalAmount>0)
    {
      let canContinue = true;
           this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            console.log("getOrderDetails2","getOrderDetails");    
       this.order=res;
      if(this.order!=null && this.order.list!=null)
      for(let i=0;i<this.order.list.length;i++)
      {
        let orderList=this.order.list[i];
          for(let j=0;j<this.restaurant.MenuList.length;j++) {
              let categoryItemFood = this.restaurant.MenuList[j];
              if (orderList.IsAddition == 1)
                  if (orderList.MenuID == categoryItemFood.ID)
                      if(categoryItemFood.IsAdditionRequired==1) {
                  
                          if (orderList.listAddition.length==0) {
                              canContinue = false;
                              j=this.restaurant.MenuList.length;
                          }
                      }
          }
      }
      if(canContinue==true)
      {
    this.router.navigate(["../yourOrder"]);
    localStorage.SHOW=false;
      }
    else
    this.proxy.showError("לא בחרת \n את כל תוספות החובה","");
    });
}
    else
    this.proxy.showError("ההזמנה ריקה","");
  }

  restaurantType(i)
  {
// if(this.aboutRestaurant[i].isCheck==true)
// {
//   this.aboutRestaurant[i].isCheck=false;
//  // this.aboutRestaurantImg='assets/images/Restorant_Page_Address_Icon.png';
// }
// else{
//   {
//     this.aboutRestaurant[i].isCheck=true;
//     //this.aboutRestaurantImg='assets/images/Restorant_Page_Kosher_Icon.png';
//   }
// }
  }

  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    this.addItems();
  }

  appendItems() {
    this.addItems();
  }

  addItems() {
    this.page=Number(this.page)+1;
    if(this.id>-1)
    {
      this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
        if(this.restaurantItem==null)
        this.restaurantItem=[];
        for(let i=0;i<res.length;i++)
        {
        this.restaurantItem.push(res[i]);
        }
    });
  }
  else
  {
    if(this.restaurant!=undefined)
    this.bussinssService.getBusinessMenuBySearch(this.restaurant.ID,this.text,this.page).subscribe((res:any[])=>{
   // this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
      if(this.restaurantItem==null)
      this.restaurantItem=[];
      for(let i=0;i<res.length;i++)
      {
      this.restaurantItem.push(res[i]);
      }
    });
  }
  }

  backPress()
  {
    //window.history.back();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PopupErrorComponent, { disableClose: true });
  }

 plus(MenuID:number, pos:number ,position:number)
  {
    localStorage.TEXT_SEARCH="";
    if(sessionStorage.ACCESS_TOKEN  != undefined && sessionStorage.ACCESS_TOKEN  != "null"  &&  localStorage.SMS_CODE!="")
    {

      if (this.order != null && this.order.OrderID != null && this.order.OrderID != 0)
      {
      if (this.order.RestaurantID != this.restaurant.ID)
      {

        let CurrentDialog = this.matDialog.open(PopupOrderComponent, {
          data: { OrderID: this.order.OrderID },
          
        });
          CurrentDialog.beforeClosed().subscribe(result => {

            this.location.back();
          });
        
        
      }
    }

      {
      //this.bussinssService.restaurant=this.restaurant;
      this.totalItems+=1;
     this.restaurantItem[pos].Rate+=1;
      // let p=0.0;
      // p=this.restaurantItem[pos].Price;
      // this.totalAmount=Number(this.totalAmount)+Number(p);
      // AppComponent.price=this.totalAmount;
      // this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
      // localStorage.PRICE_TOTAL=this.priceTotal;
      // this.dataService.price.next(this.priceTotal);
      // this.changePrice.emit();
   // this.buttonText="המשך לתשלום";
    this.showChangeFood=true;
    let changeFood = new ChangeFood();
    let amount=0;
    if(this.restaurantItem[pos].Rate!=null)
       amount=this.restaurantItem[pos].Rate;
     changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
     changeFood.Text="לחץ לשינויים ";
     changeFood.toppings =null; 
     if(this.restaurantItem[pos].ChangeFood==null)
       this.restaurantItem[pos].ChangeFood=[];
    {
    this.orderService.addToBagOrder_(this.restaurantItem[pos].ID,this.restaurant.ID,this.restaurantItem[pos].Rate).subscribe((res:any)=>{
     
      if(this.restaurantItem[pos].IsAddition==1)
      {    
        let changeFood = new ChangeFood();
        let amount=0;
        if(this.restaurantItem[pos].Rate!=null)
           amount=this.restaurantItem[pos].Rate;
        changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
        changeFood.Text="לחץ לשינויים ";
       changeFood.toppings =null; 
        // if(this.restaurantItem[pos].ChangeFood==null)
        //   this.restaurantItem[pos].ChangeFood=[];
        this.restaurantItem[pos].ChangeFood.push(changeFood);
        
        this.restaurant.MenuList=this.restaurantItem;
    //this.bussinssService.restaurant=this.restaurant;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    if(this.restaurant!=null && this.restaurant.MenuList!=null)
    this.orderService.restaurant.MenuList=this.restaurant.MenuList;
    localStorage.PRICE=Number(this.totalAmount);
    localStorage.ITEM=Number(this.totalItems);
    localStorage.MENU_ID=MenuID;
    localStorage.MENU_NUMBER=position;
    let a:number=1;
    position=Number(position+a);
    this.dataService.show.next(true);
     this.changeShowPrice.emit();
     localStorage.position=position;
     localStorage.pos=pos;
     localStorage.edit="false";
    //  if(this.orderService.restaurant!=null)
    //  this.orderService.restaurant.MenuList=this.restaurantItem;
    //  }
  
    if(window.innerWidth<=480)
 
         this.router.navigate(['toppings2']);
         else
         this.router.navigate(['toppings']);
     }
    
     else{
     // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        console.log("getOrderDetails3","getOrderDetails");    
      // this.order=res;
      let p=0.0;
      p=this.restaurantItem[pos].Price;
      if(this.order==undefined || this.order==null)
      {
        this.order=new MyOrder();
      this.order.TotalPrice="0";
      }
      this.totalAmount=Number(Number(this.order.TotalPrice)+Number(p));
      this.order.TotalPrice = String(this.totalAmount);
      this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
      localStorage.PRICE_TOTAL=this.priceTotal;
      this.dataService.price.next(this.priceTotal);
      this.changePrice.emit();
      if(this.orderService.restaurant!=null)
      this.orderService.restaurant.MenuList=this.restaurantItem;
   //  });
     }
    }); 
}
//למחוק
} 
}
else{
  if(sessionStorage.deeplink=="")
  {
 // this.proxy.showError("אינך רשום למערכת נא להרשם","");
  if (window.innerWidth <= 480)
      this.matDialog.open(PopupEnter2Component);
    else
      this.matDialog.open(PopupEnterComponent);
     
  //this.router.navigate(['../mainHome']);
  }
  else
  {
    if (window.innerWidth <= 480)
    this.matDialog.open(PopupEnter2Component);
  else
    this.matDialog.open(PopupEnterComponent);
  }
}
  }

  minus(i)
  {
    localStorage.TEXT_SEARCH="";
    if(this.restaurantItem[i].Rate>0)
    {
     this.totalItems=0;
     let p:number=0;
     p=this.restaurantItem[i].Price;
     this.totalAmount=(Number(this.totalAmount)- Number(p));
    this.restaurantItem[i].Rate-=1;
   if(this.totalAmount>0)
   for(let i=0;i<this.restaurantItem.length;i++)
   {
     if(Number(this.restaurantItem[i].Rate)>0)
     this.totalItems+=Number(this.restaurantItem[i].Rate);
   }
    let changeFood = new ChangeFood();
    let location:number;
    if(this.restaurantItem[i].ChangeFood!=null)
    {
    location = this.restaurantItem[i].ChangeFood.length-1;
    this.restaurantItem[i].ChangeFood.splice(this.restaurantItem.length-1,1);
    }
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      console.log("getOrderDetails4","getOrderDetails");    
      this.order=res;
      let menuId=-1;
      for(let n=0;n<this.order.list.length;n++)
      {
if(this.order.list[n].MenuID==this.restaurantItem[i].ID)
menuId=n;
      }
    this.orderService.addToBagOrder(this.order.list[menuId].ID,this.order.OrderID,this.order.list[menuId].MenuNumber).subscribe((res:any)=>{

    if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length>location)
    delete this.restaurantItem[i].ChangeFood[location]; 
    if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length<0)
    this.restaurantItem[i].ChangeFood.length=location;
    if(this.restaurantItem[i].Rate==0)
    this.restaurantItem[i].ChangeFood=null;
    if(this.order.list[menuId].MenuNumber==1)
    {
     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      console.log("getOrderDetails5","getOrderDetails");    
       this.order=res;
       if(this.order.list.length==0)
       {
    this.totalAmount=0;
    this.priceTotal="0 - פריטים";
    localStorage.PRICE=0;
    localStorage.PRICE_TOTAL=this.priceTotal;
    this.dataService.price.next(this.priceTotal);
    this.changePrice.emit();
       }
       else{
        this.totalAmount=Number(this.order.TotalPrice);
        this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
        localStorage.PRICE_TOTAL=this.priceTotal;
        this.dataService.price.next(this.priceTotal);
        this.changePrice.emit();
       }
    // if(this.order!=null)
    // this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
    //   this.restaurantItem[i].ID,this.restaurantItem[i].Amount,1,null,1,null,null,null,null,null,null).subscribe((res:any)=>{
       });
      }
      else{
        this.order.list[menuId].MenuNumber-=1;
        this.totalAmount=Number(Number(this.order.TotalPrice)-Number(this.order.list[menuId].Price));
        this.order.TotalPrice=String(this.totalAmount);
        this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
        localStorage.PRICE_TOTAL=this.priceTotal;
        this.dataService.price.next(this.priceTotal);
        this.changePrice.emit();
      }
     });
    });
  }
  }

  category(i){
    if(i==Number(NaN) || Number.isNaN(i))
    i=0;
    localStorage.TEXT_SEARCH="";
    console.log("היגיע לפה " ,i)
    sessionStorage.CATEGORY_FILTER=i;
    if(this.categores!=null)
    for(let j=0;j<this.categores.length;j++)
    {
      if(j!=i)
      this.categores[j].isCheck=0;
    }
    if(i>-1)
    {
    if(this.categores!=null && this.categores.length>0)
    {
       this.categores[i].isCheck=1;
       this.categoryToFilter=this.categores[i].Category;
       
       console.log("categoryToFilter 25")
       console.log( this.categoryToFilter)
    }
      if(sessionStorage.deeplink=="")
      {
      if(localStorage.ID!="0") 
      if(this.categoryToFilter=="")
      {
        this.id=localStorage.ID;
        console.log("categoryToFilter 2")
      }
        else
        {
        this.id=this.restaurant.ID;
        console.log("categoryToFilter 3")
        console.log(this.id , "ID" )
        }
        else
        {
        this.id=this.restaurant.ID;
        console.log("categoryToFilter 4")  
      }
      }
      if(this.categoryToFilter==null)
      {
      this.categoryToFilter="";
      console.log("categoryToFilter 1")
      }
      if(this.id==undefined || this.id==null)
      this.id=this.idUrl;
      this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
        this.restaurant=res;
        this.restaurantItem=this.restaurant.MenuList;
        let KashrutLength=0;
        this.showProgress=false;
        if (this.restaurant.Kashrut!=null)
        KashrutLength=this.restaurant.Kashrut.length;
        if(this.innerWidth<=480)
        {
        this.nameLength=Number(Number(this.restaurant.Name.length)+Number(KashrutLength))
        this.isNameLength=this.nameLength>22;
        this.headerLength=Number(this.restaurant.Header.length)
        this.isHeaderLength=this.headerLength>25;
        }
        else
        {
          this.isNameLength=false; 
          this.isHeaderLength=false;
        }
        this.orderService.restaurant=this.restaurant;
        if(this.restaurant.LongImage!=null)
        this.Image=this.restaurant.LongImage;
        this.restaurantItem=this.restaurant.MenuList;
        if(this.restaurantItem!=null)
        for(let i=0;i<this.restaurantItem.length;i++)
          {
            if(this.restaurantItem[i].Rate==undefined)
            this.restaurantItem[i].Rate=0;
            else if(this.restaurantItem[i].Rate>0)
            {
              this.buttonText="המשך לתשלום";
            this.totalItems+=this.restaurantItem[i].Rate;
           let p=0.0;
           p=this.restaurantItem[i].Price;
           this.totalAmount=Number(this.totalItems)*Number(p);
            }
          }
         this.order=this.orderService.order;
         if(this.restaurant.MenuList.length>0)
         {
          if(this.order!=null && this.order.list!=null)
           for(let i=0;i<this.order.list.length;i++)
           {
             for(let j=0;j<this.restaurantItem.length;j++)
             {
             if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
             if(this.order.list[i].Count>0)
             this.restaurantItem[j].Rate+=this.order.list[i].Count;
             }
           }
           let amount=0;
           
              for(let j=0;j<this.restaurantItem.length;j++)
       {
       if(this.restaurantItem[j].IsAddition==1)
       {
       this.restaurantItem[j].ChangeFood=[];
       if(this.restaurantItem[j].ChangeFood==null)
          this.restaurantItem[j].ChangeFood=[];
       for(let q=0;q<this.restaurantItem[j].Rate;q++)
       {
        let changeFood = new ChangeFood();
        if(this.restaurantItem[j].Rate!=null)
           amount+=1;
         changeFood.Text="לחץ לשינויים ";
        changeFood.toppings =null; 
          changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
          this.restaurantItem[j].ChangeFood.push(changeFood);
       }
     }
     }
          if(localStorage.PRICE>0)
          this.totalAmount=Number(localStorage.PRICE)
          else
          {
           this.totalAmount=0;
         for(let i=0;i<this.restaurant.MenuList.length;i++)
         {
           if(this.restaurant.MenuList[i].Rate>0)
           {
             let p:number=0;
             p=this.restaurant.MenuList[i].Price;
             this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
             this.totalItems+=this.restaurantItem[i].Rate;
           }
         }
       }
    
     //   {
     //   let changeFood = new ChangeFood();
     //   let amount=0;
     //   if(this.restaurantItem[j].Rate!=null)
     //      amount=this.restaurantItem[j].Rate;
     //    changeFood.Name =(amount)+'-'+ this.restaurantItem[j].Name;
     //    changeFood.Text="לחץ לשינויים ";
     //   changeFood.toppings =null; 
     //   this.restaurantItem[j].ChangeFood=[];
     //   for(let q=0;q<this.restaurantItem[j].Rate;q++)
     //   {
     //   if(this.restaurantItem[j].ChangeFood==null)
     //      this.restaurantItem[j].ChangeFood=[];
     //      this.restaurantItem[j].ChangeFood.push(changeFood);
     //   }
     // }
      //   if(this.totalAmount>0 && this.order.list.length>0)
      //   this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
      //   else
       //  this.priceTotal="0 - פריטים";
       //  this.dataService.price.next(this.priceTotal);
      // this.changePrice.emit();
       //   this.dataService.price.next(this.priceTotal);
       //   this.changePrice.emit();
     //    localStorage.PRICE_TOTAL=this.priceTotal;
       }
         if(this.order!=null && this.order.OrderID!=0)
           {
             let haveList=false;
             let pos=-1;
             for(let j=0;j<this.restaurantItem.length;j++)
           
           {
              haveList=false;
              pos=-1;
              for(let i=0;i<this.order.list.length;i++)
             {
         
             if(this.restaurantItem[j].Rate>0)
             {
               pos=j;
             if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
             {
                haveList=true;
                i=this.order.list.length;
             }
           }
             }
             if(pos>0 && haveList==false)
             {
             this.restaurantItem[pos].Rate=0;
             let location = this.restaurantItem[pos].ChangeFood.length-1;
             this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
             delete this.restaurantItem[pos].ChangeFood[location]; 
             this.restaurantItem[pos].ChangeFood.length=location;
             let p:number=0;
             p=this.restaurantItem[pos].Price;
             this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
             this.totalItems-=this.restaurantItem[pos].Rate;
           }
           }
           } 
     
      });
    }
   }

  changeOnFood(MenuID:number, pos:number ,position:number){
    localStorage.TEXT_SEARCH="";
    this.restaurant.MenuList=this.restaurantItem;
   // this.bussinssService.restaurant=this.restaurant;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    if(this.restaurant!=null && this.restaurant.MenuList!=null)
    this.orderService.restaurant.MenuList=this.restaurant.MenuList;
    localStorage.PRICE=Number(this.totalAmount);
    localStorage.ITEM=Number(this.totalItems);
    //let pos=position+1;
    localStorage.MENU_ID=MenuID;
    localStorage.MENU_NUMBER=position;
    let a:number=1;
    let b=Number(position-1);
    let have=false;
    if(b>=0)
    for(let i=0;i<this.restaurant.MenuList[pos].ChangeFood.length;i++)
    {
    if(this.restaurant.MenuList[pos].ChangeFood[i].Name==null || this.restaurant.MenuList[pos].ChangeFood[i].Name=="" )
    have=true;
    }
    if(have==true)
    position=Number(position-1);
    position=Number(position+a);
    this.dataService.show.next(true);
     this.changeShowPrice.emit();
     localStorage.position=position;
     localStorage.pos=pos;
     localStorage.edit="false";
     if(this.innerWidth<=480)
     this.router.navigate(['toppings2']);

     
    // this.matDialog.open(Topping2Component
      // ,{
      //   panelClass: 'fullscreen-dialog'
      // }
   // );
     else
     {
     // let url: string = "/toppings/" + MenuID
     // this.router.navigateByUrl(url);
      this.router.navigate(['toppings']);
    // this.matDialog.open(ToppingsComponent
      // ,{
      //   panelClass: 'fullscreen-dialog'
      // }
   // );
      // this.matDialog.open(ToppingsComponent, { disableClose: true });
     }
    //this.router.navigate(['../toppings',{position,pos}], { relativeTo: this.route });
  //  }
  }

  addFevorit()
  {
    if(sessionStorage.ACCESS_TOKEN  == "null")
    this.router.navigate(['../mainHome']);
    else
    {
    this.clickFevorite=true;
   this.bussinssService.setFavoriteBusiness(this.restaurant.ID).subscribe((res:any)=>{
     if(this.restaurant.IsFevorite==1)
     this.restaurant.IsFevorite=0;
     else
     this.restaurant.IsFevorite=1;
  });
}
  }

   bigImage(i)
   {
      // const dialogConfig = new MatDialogConfig();
      // this.matDialog.open(BigImageComponent, { disableClose: true });
      //  this.matDialog.open(BigImageComponent, {
      //   width: '330px',
      //   height: '400px',
      //   data: {
      //     dataKey: this.restaurantItem[i].Image
      //   }
        
      // });
      let dialogRef = this.matDialog.open(BigImageComponent, {
        disableClose: true,
    });
dialogRef.componentInstance.url = this.restaurantItem[i].Image;
   }
}


