<!-- <div class="fontSize" style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold; font-size: 18px;">
  <form class="form-signin"> 
    <div class="form-group">
    <div class="header" style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;" >רענון</div>
    <br>
    <br class="br">

      <div class="body" style="padding: 15px; text-align: center; font-size: 16px;
      color: black;"> עבר זמן רב מאז הפעילות האחרונה עליך לעדכן את הדף בכדי להיות מעודכן</div>
              <br> 
      
                  <div (click)="ok()" style="cursor: pointer;color: #41c777; font-weight: bold ; text-align: center;" class="p2">רענון</div>
                       
                </div>         
  </form>
</div> -->

<div class="txt-input borderGreen"
 style="background-image: url(assets/images/Popup_BG.png); background-repeat: no-repeat;">

  <div class="body"> עבר זמן רב מאז הפעילות האחרונה עליך לעדכן את הדף בכדי להיות מעודכן</div>
          <br> 
  

          <div (click)="ok()"class="p2 refrush img">
            <img src="assets/images/Search_Word_Green.png"  class="img">               
            <span (click)="ok()" class="centered">רענון</span>
            </div>
         

   <!-- <img src="assets/images/Popup_Doanlaod_App_Button.png"> -->
   <!-- <div class="d-flex d-lg-block">
    <img style="cursor: auto;" src="assets/images/Popup_Apple_Icon.png" />
    <img style="margin-right:40px; cursor: auto;" src="assets/images/Popup_Google_Icon.png"/> -->
    <!-- <a href="https://apps.apple.com/us/app/%D7%91%D7%90-%D7%9C%D7%99-%D7%91%D7%A7%D7%9C%D7%99%D7%A7/id1582050632"></a>
    <a href="https://play.google.com/store/apps/details?id=com.appgate.balihazmanot"></a> -->
   <!-- </div> -->

</div>





