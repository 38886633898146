import { Component, OnInit, Output , EventEmitter, HostListener} from '@angular/core';
import { Category } from 'src/app/models/category';
import { ChangeFood, Restaurant, RestaurantItem} from 'src/app/models/restaurant'
import { NavigationEnd, Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder, Order } from 'src/app/models/order';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogOutOfBoundsComponent } from 'src/app/components/dialog-out-of-bounds/dialog-out-of-bounds.component';
import { SCREEN_SIZE } from 'src/app/services/screen-size.enum';
import { filter } from 'rxjs/operators';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { Topping2Component } from '../topping2/topping2.component';
import { ToppingsComponent } from '../toppings/toppings.component';

@Component({
  selector: 'app-your-order',
  templateUrl: './your-order.component.html',
  styleUrls: ['./your-order.component.scss']
})
export class YourOrderComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()header: EventEmitter<number> = new EventEmitter<number>();
  @Output()clickPayment:EventEmitter<number>=new EventEmitter<number>();
  categores:Category[];
  categoresItem:RestaurantItem[];
  changeFood:ChangeFood[];
  totalPrice:number=0;
  allPrice=0;
  totalAmount:number=0;
  IsDelivery:number=0;
  isPickUp:number=0; 
  IsSetting:number=0;
  IsDeliverySelect:number=1;
  isPickUpSelect:number=0; 
  IsSettingSelect:number=0;
  checkBox=0;
  collectionFormCheckedOn='assets/images/Order_Food_Chack_Box_On.png';
  collectionFormCheckedOff='assets/images/Order_Food_Chack_Box_Off.png';
  order:MyOrder;
  orderList:Order[]=[];
  note:string;
  Name="";
  Image="";
  restaurant:Restaurant=new Restaurant;
  restaurantItem:RestaurantItem[]=[];
  priceDelivery:number=0;
  animal: string;
  name: string;
  token="";
  innerWidth:any;
  showProgress=false;
  previousUrl:string;
  updateYourOrder=false;

  // @HostListener('window:popstate', ['$event'])
  // onBrowserBackBtnClose(event: Event) {
  //     //console.log('back button pressed');
  //     event.preventDefault(); 
  //     let url: string = "/restaurant/" + this.orderService.restaurant.ID
  //        this.router.navigateByUrl(url);
  // }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //    this.backPress();
  //   // window.history.back();
  // }

    @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == KEY_CODE.DOWN_ARROW){
      // Your row selection code
      //console.log(event);
      window.scroll(0,0);
    }
  }

  //constructor(private router: Router,public dialog: MatDialog,private dataService:DataService ,private proxy: AppProxy,private orderService:OrdersService,private bussinssService: BussinssService) {
   // //console.log(this.router.getCurrentNavigation().previousNavigation.finalUrl.toString());
    // router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   //console.log('prev:', event.url);
    //   this.previousUrl = event.url;
    //   if(localStorage.back=='true')
    //   {
    //     //localStorage.back='false';
    //   if(this.previousUrl.startsWith("/restaurant") && this.router.url.startsWith("/restaurant"))
    //  {
    //   let url: string = "/restaurant/" + this.orderService.restaurant.ID
    //   this.router.navigateByUrl(url);
    //  }
    //  if(this.previousUrl.startsWith("/yourOrder") && this.router.url.startsWith("/yourOrder"))
    //  {
    //   let url: string = "/restaurant/" + this.orderService.restaurant.ID
    //   this.router.navigateByUrl(url);
    //  }
    // }
    // });
  //}

  constructor(private router: Router, private matDialog: MatDialog , public dialog: MatDialog,private dataService:DataService ,private proxy: AppProxy,private orderService:OrdersService,private bussinssService: BussinssService) {
    // history.pushState(null, null, window.location.href);
    // // check if back or forward button is pressed.
    // this.location.onPopState(() => {
    //     history.pushState(null, null, window.location.href);
    //    // this.stepper.previous();
    // });
    // router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   //console.log('prev:', event.url);
    //   this.previousUrl = event.url;
    //   if(event.url=="/yourOrder" && sessionStorage.back=="true")
    //   {
    //   this.router.navigate(["../restaurant"], { replaceUrl: true });
    //   sessionStorage.back=="false"
    //   }
    //   else if(event.url=="/restaurant" && sessionStorage.back=="true")
    //   {
    //   this.router.navigate(["../restaurant"], { replaceUrl: true });
    //   sessionStorage.back=="false"
    //   }
    // });
  }

  ngOnInit(): void {
    if(window.innerWidth<=480)
    {
    this.dataService.show.next(true);
    this.changByFood.emit();
    }
    else{
      {
        this.dataService.show.next(false);
        this.changByFood.emit();
        }
    }
    this.updateYourOrder = this.dataService.updateYourOrder.getValue() || {};
    this.dataService.updateYourOrder.subscribe(data=>{
      this.updateYourOrder=data;
      if(this.updateYourOrder==true)
      {
        this.updateYourOrder=false;
        this.orderList=[];
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{
          this.order=res;
          {
            if(this.IsDelivery==1)
            this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
            else
            this.allPrice=Number(this.order.TotalPrice);
          let clickToPay="לחץ לתשלום - "
          let priceTotal=clickToPay+" "+this.order.TotalPrice+" ₪";
           localStorage.PRICE_TOTAL=this.order.TotalPrice;
           localStorage.PRICE=this.order.TotalPrice;
           this.dataService.price.next(priceTotal);
           this.changePrice.emit();
           this.priceDelivery=Number(this.order.DeliveryPrice);
          this.totalPrice=Number(this.order.TotalPrice);
         
          this.changePrice.emit();
          this.Name=this.order.RestaurantName;
         // this.Image=this.order.BigImage;
          if(this.order.Note!=null && this.order.Note!="null")
             this.note=this.order.Note;
        if(this.order!=null && this.order.list!=null && this.order.list.length>0)
        {
          for(let i=0;i<this.order.list.length;i++)
          {
            this.orderList.push(this.order.list[i]);
          }
        this.totalAmount=0;
      for( let j=0;j<this.orderList.length;j++)
      {   
    {
    this.totalAmount+=Number(this.orderList[j].Count);
    //this.totalPrice=Number(this.order.TotalPrice);
    }
      
      }
     
    //this.bussinssService.getRestaurantDetails_(this.order.RestaurantID).subscribe((res:any)=>{
      this.restaurant=this.bussinssService.restaurant;
      if(this.restaurant!=null && this.restaurant.LongImage!=null)
      this.Image=this.restaurant.LongImage;
      {
        for(let i=0;i<this.restaurant.listSetting.length;i++)
      {
        if(this.restaurant.listSetting[i].Text=="משלוח")
        {
          if(this.restaurant.listSetting[i].Show==1)
          {
          localStorage.DELIVERY=1;
          this.checkBox=1;
          this.IsDelivery=1;
          }
        }
        if(this.restaurant.listSetting[i].Text=="איסוף עצמי")
        {
          if(this.restaurant.listSetting[i].Show==1)
          {
          if(this.checkBox==0)
          {
          this.checkBox=2;
          localStorage.DELIVERY=2;
          }
          this.isPickUp=1;
          }
        }
        if(this.restaurant.listSetting[i].Text=="ישיבה במקום")
        {
          if(this.restaurant.listSetting[i].Show==1)
          {
          if(this.checkBox==0)
          {
          this.checkBox=3;
          localStorage.DELIVERY=3;
          }
          this.IsSetting=1;
          }
        }
      }
      }
      this.restaurantItem=this.restaurant.MenuList;
      this.startCollectionType();
      this.showProgress=false;
      //  });
    }
          
    else
    {
    this.showProgress=false;
    this.proxy.showError("ההזמנה שלך ריקה","");
    this.orderService.restaurant=null;
    this.dataService.price.next("0 - פריטים");
    this.changePrice.emit();
    sessionStorage.CATEGORY_FILTER="";
    let url: string = "/restaurant/" + this.orderService.restaurant.NameEn;
    this.router.navigateByUrl(url);
    //this.router.navigate(['../restaurant']);
    }}
    this.dataService.clickPayment.next(true);
        this.clickPayment.emit();
        });
      }
    });
   
    this.dataService.byFood.next(false);
    this.changByFood.emit();
   if(sessionStorage.deepLink!='')
   localStorage.Category="2"
    document.addEventListener('focusout', function(e) 
    {
      window.scrollTo(0, 0)
    });
    localStorage.TEXT_SEARCH="";
    this.showProgress=true;
    localStorage.DELIVERY=0;
    this.dataService.clickPayment.next(false);
    this.clickPayment.emit();
    // this.dataService.clickPayment.next(true);
    // this.clickPayment.emit();
    this.innerWidth = window.innerWidth;
    this.token = this.dataService.token.getValue() || {};
    this.dataService.token.subscribe(data=>{
      this.token=data;
    });
    this.dataService.header.next(true);
    this.header.emit();
    localStorage.NOTE=null;
    // if(window.matchMedia("(max-width: 480px)").matches==true)
    // {
    //   this.dataService.show.next(true);
    //   this.changeShowPrice.emit();
    // }
    // else
    // {
    //   this.dataService.show.next(false);
    //   this.changeShowPrice.emit();
    // } 
   
    window.scroll(0,0);
    localStorage.ADDRESS="";
    this.orderList=[];
   // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
     // this.order=res;
     this.order=this.orderService.order;
      {
        if(this.IsDelivery==1)
        this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
        else
        this.allPrice=Number(this.order.TotalPrice);
      let clickToPay="לחץ לתשלום - "
      let priceTotal=clickToPay+" "+this.order.TotalPrice+" ₪";
       localStorage.PRICE_TOTAL=this.order.TotalPrice;
       localStorage.PRICE=this.order.TotalPrice;
       this.dataService.price.next(priceTotal);
       this.changePrice.emit();
       this.priceDelivery=Number(this.order.DeliveryPrice);
      this.totalPrice=Number(this.order.TotalPrice);
     
      this.changePrice.emit();
      this.Name=this.order.RestaurantName;
     // this.Image=this.order.BigImage;
      if(this.order.Note!=null && this.order.Note!="null")
         this.note=this.order.Note;
    if(this.order!=null && this.order.list!=null && this.order.list.length>0)
    {
      for(let i=0;i<this.order.list.length;i++)
      {
        this.orderList.push(this.order.list[i]);
      }
    this.totalAmount=0;
  for( let j=0;j<this.orderList.length;j++)
  {   
{
this.totalAmount+=Number(this.orderList[j].Count);
//this.totalPrice=Number(this.order.TotalPrice);
}
  
  }
 
//this.bussinssService.getRestaurantDetails_(this.order.RestaurantID).subscribe((res:any)=>{
  this.restaurant=this.bussinssService.restaurant;
  if(this.restaurant!=null && this.restaurant.LongImage!=null)
  this.Image=this.restaurant.LongImage;
  {
    for(let i=0;i<this.restaurant.listSetting.length;i++)
  {
    if(this.restaurant.listSetting[i].Text=="משלוח")
    {
      if(this.restaurant.listSetting[i].Show==1)
      {
      localStorage.DELIVERY=1;
      this.checkBox=1;
      this.IsDelivery=1;
      }
    }
    if(this.restaurant.listSetting[i].Text=="איסוף עצמי")
    {
      if(this.restaurant.listSetting[i].Show==1)
      {
      if(this.checkBox==0)
      {
      this.checkBox=2;
      localStorage.DELIVERY=2;
      }
      this.isPickUp=1;
      }
    }
    if(this.restaurant.listSetting[i].Text=="ישיבה במקום")
    {
      if(this.restaurant.listSetting[i].Show==1)
      {
      if(this.checkBox==0)
      {
      this.checkBox=3;
      localStorage.DELIVERY=3;
      }
      this.IsSetting=1;
      }
    }
  }
  }
  this.restaurantItem=this.restaurant.MenuList;
  this.startCollectionType();
  this.showProgress=false;
  //  });
}
      
else
{
this.showProgress=false;
this.proxy.showError("ההזמנה שלך ריקה","");
this.orderService.restaurant=null;
this.dataService.price.next("0 - פריטים");
this.changePrice.emit();
sessionStorage.CATEGORY_FILTER="";
let url: string = "/restaurant/" + this.orderService.restaurant.NameEn;
this.router.navigateByUrl(url);
//this.router.navigate(['../restaurant']);
}}
this.dataService.clickPayment.next(true);
    this.clickPayment.emit();
  //  });
    this.dataService.showSearch.next(false);
    this.changByFood.emit();
    this.dataService.byFood.next(true);
    this.changByFood.emit();
    }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOutOfBoundsComponent, {
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.dialog.closeAll();
      this.animal = result;
    });
  }

  onBlur()
{
 if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
  window.scroll(0,0);
}
  
  editItem(order:Order,position:number,pos1:number)
  {
    let pos=0;
    {
      this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
        this.restaurant=res;
        if(this.restaurant.LongImage!=null)
        this.Image=this.restaurant.LongImage;
        this.restaurantItem=this.restaurant.MenuList;
       for(let i=0;i<this.restaurantItem.length;i++)
       {
         if(this.restaurantItem[i].ID==order.MenuID)
         pos=Number(i);
     }
    this.orderService.restaurant=null;
    localStorage.edit="true";
    localStorage.MENU_ID=order.MenuID;
    localStorage.PRICE=this.totalPrice;
    localStorage.position=position;
    localStorage.pos=pos;
    if(this.innerWidth<=480)
         this.router.navigate(['toppings2']);
         else
         this.router.navigate(['toppings']);
    });
  }
  }

  delete(order:Order)
  {
      let reset = confirm("האם אתה בטוח שברצונך למחוק את המנה ");
      if (reset)
      { 
        this.orderService.addToBagOrder(order.ID,this.order.OrderID,order.MenuNumber).subscribe((resOK:any)=>
          
    {
      if (resOK.responseMessage == 'OK')
        {    
      this.orderService.getOrderDetails(false).subscribe((res:any)=>
      {
      this.order=res;
      if(this.IsDelivery==1)
      this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
      else
      this.allPrice=Number(this.order.TotalPrice);

      this.updateYourOrder=true;
      this.ngOnInit();
    });
      //  this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
         // order.ID,0,1,null,1,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
          //   for(let i=0;i<this.orderService.restaurant.MenuList.length;i++)
          //   {
          //   if(this.orderService.restaurant.MenuList[i].ID==order.MenuID)
          //   {
          //   this.orderService.restaurant.MenuList[i].Rate=0;
          //   this.orderService.restaurant.MenuList[i].ChangeFood=[];
          //   }
          // }
         
          }
        
          });
          
        }
    else{ 
    }
  }

  collectionFormClick(i)
  {
    this.startCollectionType();
if(i==0)
{
this.checkBox=1;
localStorage.DELIVERY=1;
//this.orderService.getOrderDetails(false).subscribe((res:any)=>{
 // this.order=res;
this.priceDelivery=Number(this.order.DeliveryPrice);
this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
//});
}
else if(i==1)
  {
this.checkBox=2;
localStorage.DELIVERY=2;
this.priceDelivery=0;
this.allPrice=Number(this.order.TotalPrice);
  }
else if(i==2)
{
this.checkBox=3;
localStorage.DELIVERY=3;
this.priceDelivery=0;
this.allPrice=Number(this.order.TotalPrice);
}
this.dataService.clickPayment.next(true);
this.clickPayment.emit();
  }

  startCollectionType()
  {
    this.checkBox=0;
    localStorage.DELIVERY=0;
  }

  limitLines(event: InputEvent, maxLines: number) {
    let text = (event.target as HTMLTextAreaElement).value;
    if (text.length > 0) {
      const lineCount = 1 + text.replace(/[^\n]/g, '').length;
      if (lineCount > maxLines) {
        const textArray = text.split('\n');
        const newText = textArray.reduce((result, line, lineNum, array) => {
          if (lineNum < maxLines) {
            return result.concat('\n').concat(line);
          }
          return result.concat(line);
        });
        (event.target as HTMLTextAreaElement).value = newText;
      }
    }
  }

  payment(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    if(this.checkBox==0)
    this.proxy.showError("עליך לבחור אחת  מאפשרויות השירות: \n משלוח, איסוף עצמי או ישיבה במקום","");
    else 
    {
    if(this.note=="" || this.note==undefined)
    this.note=null;
    let delivery=0;
    let pickUp=0;
    let sitting=0;
    if(this.checkBox==1)
    delivery=1;
    else if(this.checkBox==2)
    pickUp=1;
    else if(this.checkBox==3)
    sitting=1;
    if(this.order.OrderID>0)
    {
    if(this.note!=null && this.note!="")
    {
    //  if(this.note!=null)
     // {
     // var fields = this.note.split('#');
     // if(fields[0].length==0)
     // {
        this.note=this.note.replace('####', '');
        this.note=this.note.replace('###', '');
        this.note=this.note.replace('##', '');
      this.note=this.note.replace('#', '');
    //  }
  //  }
  }
  if(delivery==1 && this.restaurant.MinimumOrder!='' && Number(this.order.TotalPrice) < Number(this.restaurant.MinimumOrder))
  {
    this.proxy.showError("לא הגעת למינימום הזמנה של "+this.restaurant.MinimumOrder+" ₪"+" (ללא דמי משלוח)","");
  }
  else
      this.orderService.setUpdateOrder1(this.order.OrderID,Number(delivery),Number(pickUp),Number(sitting),1,this.note,1).subscribe((res:any)=>{
        if(res.error==false)
        {
          localStorage.ADDRESS="";
          localStorage.paymentBy=1;
          localStorage.isClickPayment="false";
          localStorage.phone="";
          localStorage.orderName="";
          localStorage.email="";
          localStorage.street="";
          localStorage.enter="";
          localStorage.floor="";
          localStorage.numberHoush="";
          localStorage.nvCardNumber="";
          localStorage.cvv="";
          localStorage.date="";
          localStorage.identityNumber="";
          localStorage.nvCardholderName="";
          if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
          {
           // localStorage.paymentBy="";
           localStorage.ADDRESS2="";
           localStorage.LAT2="";
           localStorage.LONG2="";
          this.router.navigate(["../payment"]);
      //    this.router.navigate(["../securePayment"]);
          }
          else
          {
            localStorage.PRICE_GIFTCARD=0;
        this.router.navigate(["../securePayment"]);
          }
        }
        else if(res.error==true && res.responseMessage=="the delivery out of bounds")
        this.openDialog();
        });
      } 
    else
    this.proxy.showError("ההזמנה שלך ריקה","");
  }
  }

  

  backPress()
  {
  //  sessionStorage.CATEGORY_FILTER="";
   // sessionStorage.back="true";
   // if(this.previousUrl=="/restaurant")
  // this.router.navigate(['../../../restaurant'], { replaceUrl: true });
    //this.router.navigate(["../../restaurant"], { replaceUrl: true });


    sessionStorage.backYourOrder="true";
    //window.history.back();
    //else
    {
  let nameEn="";
  if(this.orderService.order!=null)
  nameEn=this.orderService.order.RestaurantNameEn
    {
      localStorage.back='true';
      if(sessionStorage.deeplink=="")
      sessionStorage.CATEGORY_FILTER="";
      let url: string = "/restaurant/" + nameEn;
    this.router.navigateByUrl(url);
    }
    }
  }

  // backPress()
  // {
  //   sessionStorage.backYourOrder="true";
  //   //window.history.back();
  //   //else
  //   {
  // let nameEn="";
  // if(this.orderService.order!=null)
  // nameEn=this.orderService.order.RestaurantNameEn
  //   {
  //     localStorage.back='true';
  //     if(sessionStorage.deepLink=="")
  //     sessionStorage.CATEGORY_FILTER="";
  //     let url: string = "/restaurant/" + nameEn;
  //   this.router.navigateByUrl(url);
  //   }
  //   }
  // }

  // @HostListener("window:resize", [])
  // private onResize() {
  //   this.detectScreenSize();
  // }

  // private detectScreenSize() {
  //   //console.log('size: ',window.matchMedia("(max-width: 480px)") )
  //   if(window.matchMedia("(max-width: 480px)").matches==true)
  //   {
  //     this.dataService.show.next(true);
  //     this.changeShowPrice.emit();
  //   }
  //   else
  //   {
  //     this.dataService.show.next(false);
  //     this.changeShowPrice.emit();
  //   } 
  // }

  onFocusOutEvent(event: any){
    //console.log(event.target.value);
    localStorage.NOTE=this.note;
    window.scroll(0,0);
 }
  
  changeOnFood(position:number,MenuID:number){
    // let pos=0;
    // this.orderService.getOrderAdditon(MenuID,pos,position).subscribe((res:any)=>{
    //   this.orderService.getOrderDetails(false).subscribe((res:any)=>{     
    //   });
    // });
  }
}


export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}