<div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold; width: 100vw;">
    <form class="form-signin" [formGroup]="registerForm"> 
      <div class="form-group">
    
        <div style="text-align: center;">
        <br> 
        <img src="assets/images/Signin_Logo_1.png" />
        <br>
        <img src="assets/images/Signin_Logo_2.png"/>
      </div>
        <div style="margin-top: 10px;">
            <div class="txt-input" >
                <img style="position: absolute; top: 0; margin-top: 5px;" src="assets/images/Signin_Icon_1.png"/>
                  <input style="margin-right:20px ;height: 21px;" matInput formControlName="userMail" placeholder="מייל" type="email" required>
                </div>
            <br>
            <br>
                <div class="txt" ></div>
                <span *ngIf="isValidInput('userFullName')" style="color: #ff8585; font-size: 8px;">שדה חובה</span>
                </div>
                <br> 
                <div>
                    <div class="txt-input" >
                        <img style="position: absolute; top: 0; margin-top: 5px;" src="assets/images/Signin_Icon_2.png"/>
                          <input style="margin-right:20px; height: 21px;"  matInput formControlName="userPassword" placeholder="סיסמא" type="password" required>                     
                    </div>
                    <br>
                    <br>
                        <div class="txt"></div>
                        <span *ngIf="isValidInput('userPassword')" style="color: #ff8585; font-size: 8px;">שדה חובה</span>
                        </div>  
                        <p (click)="forgotPassword()" style="padding-left: 25px; cursor: pointer; color: white; font-size: 13px;
                        text-align: left; cursor: pointer;" class="forgot">שכחתי סיסמא</p>
                  <br>
                  <br>
                    <div (click)="continue()" class="keep" [class.isDisabled]="registerForm.valid" style="position: relative;">
                      <img class="button" src="assets/images/Signin_Button_1.png">               
                      <span class="centered" style="color: #29c066;">המשך</span>
                    </div>
                    <div  (click)="createAccount()" class="keep create" style="margin-top: 20px;">
                      <img class="button" src="assets/images/Signin_Button_2.png">               
                      <span class="centered">צור חשבון</span>
                    </div>
                  </div>          
    </form>
  </div>

