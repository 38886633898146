import { Component, OnInit ,Input, Inject} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyOrder, OrderHistory } from 'src/app/models/order';
import { OpenningHours, Restaurant } from 'src/app/models/restaurant';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { OrdersService } from 'src/app/services/order-service';
import { PopupErrorComponent } from '../popup-error/popup-error.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-history-order-item',
  templateUrl: './history-order-item.component.html',
  styleUrls: ['./history-order-item.component.scss']
})
export class HistoryOrderItemComponent implements OnInit {
 require: any
 FileSaver = require('file-saver');
 @Input() orderItem:OrderHistory;
 order:MyOrder;
 dayTime="סגור";
 restaurant:Restaurant=new Restaurant;
 openningHours:OpenningHours[]=[];
 showProgress=false;

  constructor( @Inject(DOCUMENT) private document: Document ,private router: Router,private proxy:AppProxy,private matDialog:MatDialog ,private orderService:OrdersService,private bussinssService: BussinssService) { }

  ngOnInit(): void {}

  orderAgain()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
      this.showProgress=true;
    this.orderService.setNewOrderByHistory(this.orderItem.OrderID).subscribe((res:any)=>{
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      // localStorage.ID=this.order.RestaurantID;
      this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
        this.restaurant=res;
        let id;
        id=this.orderItem.RestaurantID;
        this.bussinssService.getOpenningHours(id).subscribe((res:any[])=>{
          if(this.orderItem.RestaurantID==null )
        this.dayTime="";
        else
        {
          this.openningHours=res;
          let b=0;
          for(let i=0;i<this.openningHours.length;i++)
          {
          
            if(i==new Date().getDay())
            {
              if(new Date().getHours()<=5)
              {
                 if(i>0)
                 {
                i=i-1
                b=1;
                 }
                 else
                 {
                 i=6;
                 b=7;
                 }
              }
              if(this.openningHours[i].DayTime!='' && this.openningHours[i].DayTime!='0:00-0:00' && this.openningHours[i].DayTime!='00:00-00:00')
           {
             var fields = this.openningHours[i].DayTime.split('-');
             if(this.proxy.isOpen(fields[0],fields[1],b))
              this.dayTime=this.openningHours[i].DayTime;
              else
              this.dayTime="סגור";
           }
            else
            this.dayTime="סגור";
            i=this.restaurant.ListDay.length;
            }
          }}
          this.showProgress=false;
          if(this.dayTime=="סגור")
         // this.openDialog();
          this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
          else
      this.router.navigate(["../yourOrder"]);
        });
      });
    });
  });
}
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PopupErrorComponent, { disableClose: true });
  }

  downloadPdf() {
    const formattedDocLink = this.orderItem.DocLink.replace(/ /g, '');
    const url = new URL(formattedDocLink);

    if (url) {
      this.document.defaultView?.open(url.toString(), '_blank');
    } else {
      console.error('Invalid URL');
    }


   // this.orderService.getDownloadInvoice(this.orderItem.OrderID).subscribe((res:any)=>{
    //const pdfUrl = 'assets/sample.pdf';
    //const pdfName = 'your_pdf_file';
   // if(this.orderItem.ReceptionFile!=null)
  //  this.FileSaver.saveAs(this.orderItem.ReceptionFile, pdfName);
  //  else
    //this.proxy.showSucceeded("החשבונית נשלחה \n בהצלחה למייל שלך","");
   // });
  }
}
