<div class="page" style="min-height: 595px; position: relative;" >
  <div style="font-family: OpenSansHebrewRegular">
<div class="top" style="display: flex; justify-content: center; position: relative;cursor: pointer;font-family: OpenSansHebrewRegular;">
<div class="note"  style="background-repeat: no-repeat; height: 176px; width: 74.5%;">
    <img class="img" [src]="Image"  style="height: 100% ;width: 100%;">
</div>
</div>

  <div  style="width: 100%; direction: rtl; margin-bottom: 20px;"  >
    <div class="order" style="direction: rtl;font-size: 14px; margin-right: 20px;">
      <div class="d-flex">
      <div class="align-self-lg-center" style="margin-left: 10px; font-size: 17px;" >ההזמנה שלך</div>
     <span class="name" style="position: absolute; left: 15%;">{{Name}}</span>
    </div>
    <img style="height: 1px; width: 95%; margin-left: 20px;" src="assets/images/Restorant_Page_Pay_Line.png">
      <div>
        <div *ngIf="IsDelivery"  class="d-flex delivery" style="width: 100%; margin-top: 10px; text-align: right;">
            <img src="assets/images/Order_Food_Icon_1.png">  
            <span class="col-9">משלוח</span> 
           <img class="align-self-center" style="cursor: pointer;" (click)="collectionFormClick(0)" [src]="checkBox==1 ? collectionFormCheckedOn :collectionFormCheckedOff">
           </div>
           <div *ngIf="isPickUp" class="d-flex pickUp" style="width: 100%; margin-top: 10px; text-align: right;">
            <img src="assets/images/Order_Food_Icon_2.png">  
            <span class="col-9">איסוף עצמי</span>
           <img class="align-self-center" style="cursor: pointer;" (click)="collectionFormClick(1)" [src]="checkBox==2 ? collectionFormCheckedOn :collectionFormCheckedOff">
           </div>
           <div *ngIf="IsSetting" class="d-flex pickUp" style="width: 100%; margin-top: 10px; text-align: right;">
            <img src="assets/images/Order_Food_Icon_3.png">  
            <span class="col-9">ישיבה במקום</span>
           <img class="align-self-center" style="cursor: pointer;" (click)="collectionFormClick(2)"  [src]="checkBox==3 ? collectionFormCheckedOn :collectionFormCheckedOff">
           </div>
          </div>
          <img style="height: 1px; width: 95%; margin-left: 20px;" src="assets/images/Restorant_Page_Pay_Line.png">
          </div>

          <!-- מכאן מתחיל הויו הירוק -->
  <div *ngIf="orderList.length>0" class="container borderGreen" style="background-color: #29c066 ;color: white; height: auto; width: 320px; font-size: 14px;
  position: absolute; border:none; left: 10%; padding-bottom: 0px; margin-top: 28px;">
  <div class="container" style="margin-top: 5px;margin-bottom: 15px; height: 120px;
  width: 90%; color: #b7b7b7;
  background-color: white;">
  <div class="d-flex" style="direction: rtl;font-size: 14px;">
    <div>
      <div *ngIf="IsDelivery"  class="d-flex" style="width: 200px; margin-top: 10px; text-align: right;">
          <img src="assets/images/Order_Food_Icon_1.png">  
          <span class="col-8">משלוח</span> 
         <img class="align-self-center" style="cursor: pointer;" (click)="collectionFormClick(0)" [src]="checkBox==1 ? collectionFormCheckedOn :collectionFormCheckedOff">
         </div>
         <div *ngIf="isPickUp" class="d-flex" style="width: 200px; margin-top: 10px; text-align: right;">
          <img src="assets/images/Order_Food_Icon_2.png">  
          <span class="col-8">איסוף עצמי</span>
         <img class="align-self-center" style="cursor: pointer;" (click)="collectionFormClick(1)" [src]="checkBox==2 ? collectionFormCheckedOn :collectionFormCheckedOff">
         </div>
         <div *ngIf="IsSetting" class="d-flex" style="width: 200px; margin-top: 10px; text-align: right;">
          <img src="assets/images/Order_Food_Icon_3.png">  
          <span class="col-8">ישיבה במקום</span>
         <img class="align-self-center" style="cursor: pointer;" (click)="collectionFormClick(2)"  [src]="checkBox==3 ? collectionFormCheckedOn :collectionFormCheckedOff">
         </div>
        </div>
        </div>
  </div> 
<div class="d-flex " style="direction: rtl;">
    <div class="image0">
    <div style="text-align: right; margin-right: 7px;">
      <div class="align-self-lg-center" style="margin-left: 10px; font-size: 17px;" >ההזמנה שלך</div>
<img  style="margin-top: 10px; height: 1px; width: 95%;" src="assets/images/Pay_Line_1.png">
  </div> 
  <div *ngFor="let c of orderList ; let ind = index">
  <div class="d-flex" style="width: 200px; margin-top: 10px; text-align: right;font-size: 14px;">
 <span class="col-10" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{c.Name}}</span>
 <div class="col-3"></div>
 <div class="col-5">{{c.Price}} ₪</div>
</div> 
</div>
<div >
</div>
</div> 
</div>

<div class="d-flex m-auto" style="direction: rtl;
position: relative; top: 40%;">
<div class="align-self-lg-center" style="margin-right: 15px; margin-top: 25px;" >סה"כ פריטים</div>
<div style="direction: rtl; margin-top: 25px;" class="mr-lg-auto align-self-lg-center">
  <span> {{totalAmount}}</span>
</div>
</div>
<div *ngIf="priceDelivery>0 && checkBox==1" class="d-flex m-auto" style="direction: rtl;
position: relative;">
<div class="align-self-lg-center" style="margin-right: 15px;" >סה"כ משלוח</div>
<div style="direction: rtl;" class="mr-lg-auto align-self-lg-center">
  <span>{{priceDelivery}} ₪</span>
</div>
</div>
<div class="d-flex m-auto" style="direction: rtl; font-weight: bold; position: relative; top: 15%;">
<div  (click)="payment()" class="align-self-lg-center cart" style="cursor: pointer;">
  <img src="assets/images/Restorant_Page_Pay_Button.png">               
  <span class="centered" style="width: 100%;">המשך לתשלום</span>
</div>
<div style="direction: rtl;" class="mr-lg-auto align-self-lg-center">
  <span>סה"כ {{allPrice}} ₪</span>
</div>
</div>
</div>
<!-- 
כאן נגמר הויו -->
<mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>

<!-- כאן מתחיל הפור -->
<div class="d-flex flex-wrap m-auto col-lg-offset-3 note" style="justify-self:right; margin-top: 10px;  direction: rtl; " [appResponsiveWidth] = "1314" >
 
  <div *ngFor="let o of orderList; let i = index" class="delivery" style="margin-top: 10px; margin-left: 10px;">
    <div class="border" style="margin-top: 20px;
    height: auto; width: 340px; color: #979797;font-size: 14px;" [appResponsiveWidth] = "340" [minWidth] = "330" >
    <div class="d-flex" style="direction: rtl;">
      <div >
     <img (click)="changeOnFood(i,c.MenuID)" style="width: 90px; height: 90px;" [src]="o.ImgFile"/>
    </div>
      <div style="text-align: right; margin-right: 7px; width: 130%;">
    <div (click)="changeOnFood(i,c.MenuID)" class="d-flex" style="width: 115%; margin-top: 10px; text-align: right;color: black;">
        <span style="font-weight: bold; font-size: 16px;
        width: 50px; overflow: hidden; text-overflow: ellipsis;" class="col8">{{o.Name}}</span>
 
        <div class="order col-3" style="margin-bottom: 10px; text-align: end; color: #29c066;" >
          <span>{{o.Price}} ₪</span>
          </div>
       </div>
       <span *ngIf="o.InviteName!=null"><small>*</small>{{(o.InviteName)}}</span>
<div >
</div>
</div> 
</div>
<div  class="borderGreen" style="margin-bottom: 10px; text-align: end;">
<span>{{o.Price}} ₪</span>
</div>
<div class="delete" style="margin-bottom: 30px; position: absolute; left: 15px;">
  <img *ngIf="o.IsAddition==1" (click)="editItem(o,o.MenuNumber,i)" class="align-self-center align-self-lg-start" style="cursor: pointer;" src="assets/images/Edit_Mana.png">  
 <img (click)="delete(o)" class="align-self-center align-self-lg-start" style="margin-right: 5px;cursor: pointer;" src="assets/images/Delete_Mana.png"> 
</div>
 <img class="borderGreen" style="margin-top: 20px; height: 1px; width: 100%;" src="assets/images/Restorant_Page_Pay_Line.png">
<div class="d-flex m-auto flex-wrap" style="direction: rtl;">
      <div  *ngFor="let m of o.listAddition" style=" margin-top: 8px; width: 100%;">
      <div style="margin-right: 4px; color: gray; font-weight: bold; text-align: right;">{{m.TextType}}</div>
      <div *ngFor="let i of m.listA" style="text-align: start; margin-right: 5px;">
     <img src="assets/images/Order_Food_Ve_Icon.png" >      
      <span style="margin-right: 3px; color: #bbbbbb;">{{i.Food }} </span>
      <span *ngIf="i.Price>0" style="margin-right: 10px; color: #bbbbbb;">{{i.Price}} ₪</span>
      </div>
      </div>
</div>
<img class="order" style="margin-top: 20px; height: 1px; width: 100%;" src="assets/images/Restorant_Page_Pay_Line.png">

</div>
</div>
  </div>
  <!-- כאן נגמר הפור -->

<div class="note" style="display: flex; width: 50%; margin-right: 3.5%;">
<div class="noteIcon" style="right: 14%; position: absolute; margin-top: 20px;
direction: rtl; color: #9b9b9b; text-align: right;">
<img src="assets/images/Order_Food_Edit_Icon.png">
<span style="margin-right: 5px; font-size: 14px;">הערות למשלוח</span>
</div>
  <textarea (focusout)="onFocusOutEvent($event)" (blur)='onBlur()' class="container noteInput" style="margin-bottom: 20px; margin-top: 45px; min-height: 120px; width: 60%; color: gray; overflow: hidden;
  text-align: right; direction: inherit; font-weight: 100; font-size: 15px; max-lines: 4;" type="text" maxlength="40"
  required name="note" mdInput [(ngModel)]="note" rows="4" (input)="limitLines($event, 4)"></textarea>  
</div>
</div>
</div>






