<div class="page">
 <div class="d-flex flex-wrap container" style="margin-top: 50px;">
<div *ngFor="let c of searchList; let i = index" class="top" style="font-family: OpenSansHebrewRegular ; cursor: pointer; margin-left: 30px;  margin-bottom: 20px;">
  <div class="btn-group-vertical location">
<div (click)="category(c.Search)" style="cursor: pointer;" class="category">
<img src="assets/images/Search_Word_Green.png" style="cursor: pointer;" class="img">               
<span (click)="category(c.Search)"  style="cursor: pointer;
width: 100%; text-align: center;" class="centered">{{c.Search}}</span>
</div>
</div>
</div>
</div>
</div> 

