import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appResponsiveWidth]'
})
export class ResponsiveWidthDirective implements OnInit {
  @Input('appResponsiveWidth') baseWidth: number;
  @Input() minWidth?: number;
  @Input() maxWidth?: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.updateResponsiveWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateResponsiveWidth();
  }

  private updateResponsiveWidth(): void {
    let responsiveWidth = (this.baseWidth * window.innerWidth) / 1800;

    if (this.minWidth !== undefined && responsiveWidth < this.minWidth) {
      responsiveWidth = this.minWidth;
    }

    if (this.maxWidth !== undefined && responsiveWidth > this.maxWidth) {
      responsiveWidth = this.maxWidth;
    }

    this.renderer.setStyle(this.el.nativeElement, 'width', `${responsiveWidth}px`);
  }
}
