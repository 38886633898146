import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCardNumberFormatter]'
})
export class CardNumberFormatterDirective {

  private readonly MAX_LENGTH = 16;

  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    // Remove all non-numeric characters
    let numericValue = value.replace(/\D/g, '');
    
    // Limit the length to the max length
    if (numericValue.length > this.MAX_LENGTH) {
      numericValue = numericValue.substring(0, this.MAX_LENGTH);
    }

    // Format the number with spaces every 4 digits
    const formattedValue = numericValue.replace(/(\d{4})(?=\d)/g, '$1 ').trim();

    // Update the input value and form control value
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    this.control.control.setValue(formattedValue, { emitEvent: false });
  }
};