import { Component, OnInit ,ViewChild ,ElementRef} from '@angular/core';
import { Payments } from 'src/app/models/payments';
import { FormGroup ,FormControl ,Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { OrdersService } from 'src/app/services/order-service';
import { Payment, UserEditProfile } from 'src/app/models/user';
import { AppProxy } from 'src/app/services/app-proxy';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IFrameCreditCardComponent } from '../iframe-credit-card/iframe-credit-card.component';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-gift-card-payment',
  templateUrl: './gift-card-payment.component.html',
  styleUrls: ['./gift-card-payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class GiftCardPaymentComponent implements OnInit {
  forFriendImg='assets/images/Pay_Page_Chack_Box_On.png';
  forMeImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isForFriend:boolean=true;
  payments:Payments[];
  paymentForm:FormGroup;
  valid:boolean=false;
  isKeepCard=true;
  sum: string="";
  phone:string;
  paymentCardNumber=3;
  keepCardImg='assets/images/Pay_Page_Chack_Box_On.png';
  date = new FormControl(moment());
  userEditProfile:UserEditProfile;
  month:string="";
  year:string="";
  date1:string="";
  token:string=null;
  methodsPayment:Payment=new Payment;
  @ViewChild('dp')
  public dateElementRef: ElementRef;
  showProgress=false;
  error:string="יש להזין את כל הפרטים";
  textGreen=true;
  @ViewChild("cvv") cvvElement: ElementRef;
  @ViewChild("year") yearElement: ElementRef;
  @ViewChild("identity")identityElement:ElementRef;
  dialogRef: MatDialogRef<IFrameCreditCardComponent>;
  onKey(event: any) { // without type info
    this.proxy.hideKeyboard(event);
  }

  chosenYearHandler(normalizedYear: Moment) {
    //this.hideKeyboard(normalizedYear);

    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.year=""+normalizedYear.year();
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.month=""+Number(normalizedMonth.month()+1);
    datepicker.close();
    this.date.value._i="";
  }

  constructor(private router: Router,private proxy:AppProxy,private userService:UserService ,private fb: FormBuilder,private matDialog:MatDialog) { }

  ngOnInit(): void {
    //if(this.isForFriend==true)
    //this.createFormForFriend();
   // else
    this.createForm();
    this.proxy.scrollToTop();
    this.userService.getUserCreditCard().subscribe((res:any)=>{
      if(res!=null)
      {
    this.methodsPayment=res;
   
    this.methodsPayment.card_cvv=null;
    this.date = new FormControl(moment(this.methodsPayment.CardDate,"MM/YY"));
    this.paymentForm.get("month").setValue(this.methodsPayment.CardDate.slice(0,2));
    this.paymentForm.get("year").setValue(this.methodsPayment.CardDate.slice(2));
    this.methodsPayment.CardDate = this.methodsPayment.CardDate.slice(0, 2) + "/" + this.methodsPayment.CardDate.slice(2);
    this.date1=this.methodsPayment.CardDate;

   
      }
      else
      this.date1="";
    });
    this.textGreen=(this.paymentForm.valid==false || (( this.paymentForm.valid==true && this.paymentForm.value.nvPhone==undefined && this.isForFriend==true)));
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid;
  }

  isValidInputPhone(fieldName): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid;
  }
  
  forFriend(){
    this.isForFriend=true;
    this.forFriendImg='assets/images/Pay_Page_Chack_Box_On.png';
    this.forMeImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCardNumber=3;
    //this.ngOnInit();
  }

  forMe()
  {
    //this.createForm();
    this.isForFriend=false;
    this.forMeImg='assets/images/Pay_Page_Chack_Box_On.png';
    this.forFriendImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCardNumber=2;
    //this.ngOnInit();
  }

  backPress()
  {
    window.history.back();
  }

  keepCard(){
    if(this.isKeepCard==true)
    {
      this.isKeepCard=false;
      this.keepCardImg='assets/images/Pay_Page_Chack_Box_Off.png'; 
    }
    else
    {
      this.isKeepCard=true;
      this.keepCardImg='assets/images/Pay_Page_Chack_Box_On.png'; 
    }
      }

      createForm() {
        this.paymentForm = this.fb.group({
          nvSum:  ["", Validators.required],
          nvPhone: [""],
          nvCardholderName:  ["", Validators.required],
          nvCardNumber: ["",[Validators.required, Validators.minLength(19)]],
          month: ["", [Validators.required, Validators.maxLength(2)]],
          year: ["", [Validators.required, Validators.maxLength(2)]],
          cvv:  ["", [Validators.required, Validators.maxLength(3),Validators.minLength(3)]],
          identityNumber:  ["", [Validators.required, Validators.maxLength(9),Validators.minLength(9)]],
    
        });



        // this.paymentForm = new FormGroup({
        //   nvSum: new FormControl("", Validators.required),
        //   nvPhone: new FormControl(""),
        //   nvCardholderName: new FormControl("", Validators.required),
        //   nvCardNumber: new FormControl("", Validators.required),
        //   monthDate: new FormControl("", [Validators.required, Validators.maxLength(2)]),
        //   yearDate: new FormControl("", [Validators.required, Validators.maxLength(2)]),
        //    cvv: new FormControl("",[Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
        //    identityNumber: new FormControl("",[Validators.required, Validators.minLength(9), Validators.maxLength(9)])
        // });

        this.paymentForm.get("month").valueChanges
        .pipe(filter((value: string) => value.length === 2))
        .subscribe(() => 
        {
          this.yearElement.nativeElement.focus();
          this.paymentForm.get("year").setValue("");
        }
          );
  
      this.paymentForm.get("year").valueChanges
        .pipe(filter((value: string) => value.length === 2))
        .subscribe(() => this.cvvElement.nativeElement.focus());

        this.paymentForm.get("cvv").valueChanges
        .pipe(filter((value: string) => value.length === 3))
        .subscribe(() => this.identityElement.nativeElement.focus());
      }

      // createFormForFriend() {
      //   this.paymentForm = new FormGroup({
      //     nvSum: new FormControl("", Validators.required),
      //     nvPhone: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
      //     nvCardholderName: new FormControl("", Validators.required),
      //     nvCardNumber: new FormControl("", Validators.required),
      //     date: new FormControl(""),
      //      cvv: new FormControl("",[Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
      //      identityNumber: new FormControl("",[Validators.required, Validators.minLength(9), Validators.maxLength(9)])
      //   });
      // }

      clearMonth(){
        this.paymentForm.get("month").setValue("");
      }
      
      clearYear(){
        this.paymentForm.get("year").setValue("");
      }

      payment()
      {
        if(localStorage.SMS=="")
       this.proxy.showError("חובה להכניס קוד","");
       else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
        this.router.navigate(['../mainHome']);
        else
        {
        this.valid=true;
        if(this.paymentForm.value.nvSum==null ||this.paymentForm.value.nvSum=="")
        {
        this.error="יש להזין את סכום התו קניה";
        this.proxy.showError("יש להזין את סכום התו קניה","");
        }
        else if( this.isForFriend==true && (this.paymentForm.value.nvPhone==null ||this.paymentForm.value.nvPhone==""))
        {
        this.error="חובה להכניס מספר טלפון";
        this.proxy.showError("חובה להכניס מספר טלפון","");

        }
        else if(this.paymentForm.valid)
        {
          if(this.isForFriend==false || this.isForFriend==true && this.phone!=null && this.phone!=undefined)
         
         { // if(this.date1=="") 
          this.showProgress=true;
          var last2 = this.year.slice(-2);
          if(this.month.length==1)
          this.month="0"+this.month;
          this.date1=this.month+last2;
          if(this.date1=="" &&   this.date.value._i!="")
          this.date1=this.date.value._i;
          this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
          if(this.paymentForm.value.nvCardNumber.startsWith("xxx"))
          this.token=this.methodsPayment.card_token;
          //this.date1=this.paymentForm.value.date.replace("/","");
          
          let phone=null;
          if(this.phone!=null && this.phone!=undefined)
          {
             phone=this.phone;
             this.userService.setGiftCard(Number(this.sum),phone,1,this.paymentForm.value.nvCardNumber,this.date1.replace("/","")).subscribe((res:any)=>{
              if(res!=null)
              {


             this.userService.setUserCreditCard(true,this.proxy.md5_(this.proxy.SECRET_KEY+localStorage.USER_ID),this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
             this.paymentForm.value.cvv,this.paymentForm.value.identityNumber, this.paymentForm.value.nvCardholderName,this.token,this.isKeepCard,true,Number(this.sum),null, phone).subscribe((res:any)=>{
            this.showProgress=false;
            if(res.error==false)
            {

             
                this.showProgress=false;
                 
                this.dialogRef =  this.matDialog.open(IFrameCreditCardComponent,{data : {myurl:res.responseMessage} 
                  ,  disableClose: true 
                } );
                this.dialogRef.afterClosed().subscribe(result => {
                  //console.log('The dialog was closed');
                  // Here you can handle what to do after the dialog is closed
                  // For example, you can check the result or perform any necessary actions
                  if (result == "true") 
                      this.userService.setGiftCard(Number(this.sum),phone,0,this.paymentForm.value.nvCardNumber,this.date1.replace("/","")).subscribe((res:any)=>{
                if(res!=null)
                {
                  sessionStorage.sum=this.sum;    
            this.router.navigate(['giftCardSucsess']);
                }            
            else
            this.showProgress=false;
               });
              
                  // else
                   // this.isClickPayment=false;
                  
                })
              
              }

            
              else
              this.showProgress=false;
           });
          }
          else
          this.showProgress=false;
             });
          }
            else if(phone==null)
             {
              this.userService.getUserDetails(1).subscribe((res:any)=>{
                this.userEditProfile=res;
                this.userService.setGiftCard(Number(this.sum),this.userEditProfile.userPhone,1,this.paymentForm.value.nvCardNumber,this.date1.replace("/","")).subscribe((res:any)=>{
                  if(res!=null)
                  {
                  this.userService.setUserCreditCard(true,this.proxy.md5_(this.proxy.SECRET_KEY+localStorage.USER_ID),this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
                  this.paymentForm.value.cvv,this.paymentForm.value.identityNumber, this.paymentForm.value.nvCardholderName,this.token,this.isKeepCard,true,Number(this.sum),null, phone).subscribe((res:any)=>{
                    this.showProgress=false;
                    if(res.error==false)
                    {


                      this.dialogRef =  this.matDialog.open(IFrameCreditCardComponent,{data : {myurl:res.responseMessage} 
                        ,  disableClose: true 
                      } );
                      this.dialogRef.afterClosed().subscribe(result => {
                        //console.log('The dialog was closed');
                        // Here you can handle what to do after the dialog is closed
                        // For example, you can check the result or perform any necessary actions
                        if (result == "true") 

                        this.userService.setGiftCard(Number(this.sum),this.userEditProfile.userPhone,0,this.paymentForm.value.nvCardNumber,this.date1.replace("/","")).subscribe((res:any)=>{
                        if(res!=null)
                    this.router.navigate(['giftCardSucsess']);            
                     else
                    this.showProgress=false;
                      });})
                       }
                      else
                      this.showProgress=false;
                   });
                  }
                  else
                  this.showProgress=false;
                     });
          });
        }}
      else
      this.proxy.showError("חובה להכניס מספר טלפון","");
    }
    else
    this.proxy.showError("חובה להכניס את כל הפרטים","");
      }
        //this.router.navigate(['../delivery']);   
      }

      onBlur()
      {
       if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }
}
