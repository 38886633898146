<div class="wrapper" style="font-family: OpenSansHebrewBold">
    <form *ngIf="getNewPassword==false" class="form-signin"  [formGroup]="newPasswordForm"> 
        <img (click)="exit()" src="assets/images/Popup_1_Close_Button.png" style="cursor: pointer" />
        <div style="text-align: center; margin-top: 40px; font-size: 18px;">
      <span > הזן מספר טלפון נייד, לאיפוס </span>
      <br>
      <span >SMS סיסמא ב </span>
      </div>
      <div class="d-flex" style="margin-top: 90px;">
                <div style="margin-left: auto; margin-right: auto;">
                <div class="txt-input" >
                    <input matInput formControlName="nvPhone" type="tel" style="margin-top: 5px;font-size: 16px; height: auto;
                    font-weight: normal; text-align: center;" required>
                </div>
                <br>
                <br>
                    <div class="txt" style="width: 230px;" ></div> 
                </div>
            </div>
                <br> 
                        <span *ngIf="newPasswordForm.valid==false"  style="color: black; font-size: 11px; text-align: left; font-size: 10px; position: absolute;
                        left: 18%;" > יש להזין מספר תקין (ספרות בלבד) *</span>
                  <br>
                  <br>
                    <div (click)="newPassword()" class="btm" style="background-image: url(assets/images/Signin_Button_1.png); cursor: pointer; margin-top: 25%; cursor: pointer; width: 80%;">
                      <p (click)="newPassword()" class="p" style="cursor: pointer; color: #29c066; cursor: pointer; font-size: 13px;
                      " [class.isDisabled]="newPasswordForm.valid">שלח</p>
                    </div>          
    </form>
    <form *ngIf="getNewPassword==true" class="form-signin"> 
        <img (click)="exit()" src="assets/images/Popup_1_Close_Button.png" style="cursor: pointer" />
        <div style="text-align: center; margin-top: 40px; font-size: 18px;">
            <img src="assets/images/Popup_1_Icon.png">
            <br>
            <br>
      <span > סיסמא חדשה נשלחה אליך </span>
      <br>
      <span >.לשחזור סיסמא ,SMS ב </span>
      </div>
                  <br>
                    <div (click)="closeDialog()" class="btm"  style="background-image: url(assets/images/Signin_Button_1.png); margin-top: 30%; cursor: pointer">
                      <p class="p" style="color: #29c066; right: 40%;">מגניב תודה!</p>
                    </div>          
    </form>
  </div>

