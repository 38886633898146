<div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold;">
    <form class="form-signin"> 
      <div class="form-group">
      <div style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;" >השלמת תשלום</div>
      <br>
      <br>
      <div class="payment" style="padding: 15px;   text-align: center;
      color: black;     white-space: pre-line;  direction: rtl;
      font-size: 18px;">סה"כ תשלום ההזמנה: {{price}}   ₪</div>
    <div style="padding: 15px;   text-align: center;
    color: black;     white-space: pre-line; direction: rtl;
    font-size: 18px;" step="0.01">שולם עד כה {{price2 | number :'1.1' }} ₪ </div>
        <div style="padding: 15px;   text-align: center;
        color: black;     white-space: pre-line;  direction: rtl;
        font-size: 18px;" step="0.01"> הסכום ליתרה: {{ price3 | number :'1.1'  }} ₪ </div>
                <br> 
                <div>
                  <div  class="btm" style="font-size: 15px;">
                    <p (click)="continue()"  class="p" style="cursor: pointer;color: #41c777; font-weight: bold ;">המשך</p>
                  </div> 
                  </div> 
                  </div>         
    </form>
  </div>
