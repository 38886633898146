import { Component, OnInit ,Input ,HostListener ,Output ,EventEmitter} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Category, CategoryItem} from 'src/app/models/category';
import { ChangeFood} from 'src/app/models/restaurant'
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-restaurant-category',
  templateUrl: './restaurant-category.component.html',
  styleUrls: ['./restaurant-category.component.scss']
})
export class RestaurantCategoryComponent implements OnInit {
  @Input()items: CategoryItem[];
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  buffer: CategoryItem[] = [];
  loading: boolean;
  restaurantsItem:CategoryItem[]=[];
  restaurantsItemAll:CategoryItem[]=[];
  category:Category;
  changeFood:ChangeFood[];
  id:number=-1;
  text:string;
  categoryName="";
  num=0;
  page:number=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  categoryId:number;
  showProgress=false;
  previousUrl:string;
  private routeSub: Subscription;
  random:number;
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }

  backPress()
{
  localStorage.back='true'
  //if(this.bussinssService.restaurantCategoryList!=null)
  //this.bussinssService.restaurantCategoryList=null;
  let a=location.pathname;
 // let page=location.href;
   // let a=page.split('/')[3]
//    if(a.startsWith("/toppings") || a=="/toppings2")
// {
//   //history.back()
//    this.router.navigate(["../../mainHome"]);
//    //this.backPress();
// }
// else if(a=="/search" )
// this.router.navigate(["../search"]);
//    else
//   //history.back()
//   this.router.navigate(["/mainHome"]);
} 

  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    if(this.restaurantsItem.length>0)
    this.addItems();
  }

  appendItems() {
    if(this.restaurantsItem.length>0)
    this.addItems();
  }

  addItems() {
    if(location.pathname!="/mainHome")
    {
    this.page =this.page+1;
    if(this.id>-1)
    {
      this.bussinssService.getMainList(5,this.id,this.page, this.random).subscribe((res:any[])=>{
        for(let i=0;i<res.length;i++)
        {
        this.restaurantsItem.push(res[i]);
        }
    });
  }
  else
  {
    this.bussinssService.getMenuBySearch(this.text,this.page,false).subscribe((res:any[])=>{
      for(let i=0;i<res.length;i++)
      {
      this.restaurantsItem.push(res[i]);
      }
    });
  }
  }
}

  constructor(private router: Router,private proxy:AppProxy, private route: ActivatedRoute,private dataService:DataService, private bussinssService:BussinssService) {
    // router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   //console.log('prev:', event.url);
    //   this.previousUrl = event.url;
    //   if(localStorage.back=='true')
    //   {
    //     localStorage.back='false';
    //   if(this.previousUrl.startsWith("/restaurant") && this.router.url=="/restaurant/")
    //   {
   
    //    let url: string = "/restaurantCategory/" + localStorage.ID_CATEGORY;
    //    this.router.navigateByUrl(url);
    //   }
      // if(this.previousUrl.startsWith("/restaurantCategory") && this.router.url=="/restaurantCategory/")
      // {
      //  let url: string = "/restaurantCategory/" + localStorage.ID;
      //  this.router.navigateByUrl(url);
      // }
      
    //  if(this.previousUrl.startsWith("/mainHome") && this.router.url.startsWith("/mainHome"))
    //  this.router.navigate(["/mainHome"]);
   // }
  // });
   }

  ngOnInit(): void {
    localStorage.back='false';
    localStorage.Category="2";
    window.scroll(0,0);
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    sessionStorage.CATEGORY_FILTER=0;
    this.page=1;
    this.text = this.dataService.search.getValue() || {};
    this.dataService.search.subscribe(data=>{
      this.text=data;
  if(this.text!="")
    {
      this.showProgress=true;
      this.bussinssService.getMenuBySearch(this.text,this.page,true).subscribe((res:any[])=>{
        this.restaurantsItem=res;
        this.bussinssService.setGlobalSearch(this.text);
        this.showProgress=false;
        this.categoryName=this.text;
        this.category={
          "Name": this.categoryName,
          "ID": 33,
          "categoryItems":this.restaurantsItem,
          "isCheck":true,
          "categoryType":3
        }
  
      });
    }
    });
    // this.routeSub = this.route.params.subscribe(params => {
    //   //console.log(params) //log the entire params object
    //   this.id=params['id']//log the value of id
    // });
   
   //  if(localStorage.ID_CATEGORY!="0")
 // {
 // this.id=Number(localStorage.ID_CATEGORY);
 if(this.text=="")
 this.text=localStorage.TEXT
  this.routeSub = this.route.params.subscribe(params => {
    //console.log(params) //log the entire params object
    let urlId=params['id'];
    sessionStorage.Category=urlId;
  this.bussinssService.getCategoryIDByEnName(urlId).subscribe((res:any)=>{
    if(res!=null)
    {
    this.id=Number(res);

   //if(this.id>-1)
    
     // localStorage.TEXT=null;
     this.random = Math.floor((Math.random() * 20) + 1);
    this.bussinssService.getMainList(5,this.id,this.page,this.random).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
      {
      this.categoryName=this.restaurantsItem[0].Category;
      this.category={
        "Name": this.restaurantsItem[0].Category,
        "ID": 33,
        "categoryItems":this.restaurantsItem,
        "isCheck":true,
        "categoryType":3
      }
    }
    else
    this.proxy.showError("לא נמצאו עסקים\n בקטגוריה זו","");
    });
  }
  else
  // if(this.text!="")
  {
    // this.routeSub = this.route.params.subscribe(params => {
    //   //console.log(params) //log the entire params object
    //   this.text=params['id']//log the value of id
    //   localStorage.TEXT=this.text;
    // });

   // this.text=localStorage.TEXT_SEARCH;
   //if(this.text!=undefined && this.text!=null)
   {
    this.bussinssService.getMenuBySearch(this.text,this.page,false).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
      {
      this.bussinssService.setGlobalSearch(this.text);
      if(this.bussinssService.categoryName!=null)
      this.categoryName=this.bussinssService.categoryName;
      this.category={
        "Name": this.categoryName,
        "ID": 33,
        "categoryItems":this.restaurantsItem,
        "isCheck":true,
        "categoryType":3
      }
    }
    });
  }
  // else
  // {
  //   this.id=localStorage.ID_CATEGORY;
  //   this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
  //     this.restaurantsItem=res;
  //     if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
  //     {
  //     this.categoryName=this.restaurantsItem[0].Category;
  //     this.category={
  //       "Name": this.restaurantsItem[0].Category,
  //       "ID": 33,
  //       "categoryItems":this.restaurantsItem,
  //       "isCheck":true,
  //       "categoryType":3
  //     }}});
    
  
  //   if(localStorage.TEXT_SEARCH!=null)
  //   this.text=localStorage.TEXT_SEARCH;
  // }
  }
  //else
//{
  // this.id=localStorage.ID_CATEGORY;
  // this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
  //   this.restaurantsItem=res;
  //   if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
  //   {
  //   this.categoryName=this.restaurantsItem[0].Category;
  //   this.category={
  //     "Name": this.restaurantsItem[0].Category,
  //     "ID": 33,
  //     "categoryItems":this.restaurantsItem,
  //     "isCheck":true,
  //     "categoryType":3
  //   }}});
  

  if(localStorage.TEXT_SEARCH!=null)
  this.text=localStorage.TEXT_SEARCH;
//}
});

});
////if(localStorage.TEXT_SEARCH!=null)
//this.text=localStorage.TEXT_SEARCH;
  } 

  onClickCategory(id:number){
   }
}
