import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PasswordComponent } from '../password/password.component';
import { User, UserEditProfile } from 'src/app/models/user';
import { UserService} from '../../services/user-service';
import { DataService } from 'src/app/services/data.service';
import { AppProxy } from 'src/app/services/app-proxy';
import { LoginPhoneVarificationComponent } from '../login-phone-varification/login-phone-varification.component';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  public registerForm: FormGroup;
  user:User;
  valid:boolean=false;
  loginObj;
  userEditProfile:UserEditProfile;
  innerWidth:any;

   constructor(private route: ActivatedRoute, private router: Router,private proxy:AppProxy,private dataService:DataService,private matDialog:MatDialog,private userService: UserService) {}

   forgotPassword(){
    this.openPasswordDialog();
   }

  createAccount()
  {
      //let url: string = "register";
      //this.router.navigateByUrl(url);

   //this.router.navigate(['../register'], { relativeTo: this.route });
     this.router.navigate(['./register']);
     this.local()
     this.matDialog.closeAll();
     sessionStorage.ACCESS_TOKEN = "null"

  }

  local(){
   localStorage.ADDRESS="";
    localStorage.LAT="";
    localStorage.LONG="";
    localStorage.ADDRESS2="";
    localStorage.LAT2="";
    localStorage.LONG2="";
    localStorage.password="";
    localStorage.phone="";
     localStorage.orderName="";
     localStorage.email="";
     localStorage.street="";
     localStorage.enter="";
     localStorage.floor="";
     localStorage.numberHoush="";
  }

  continue()
  {
    let name = this.registerForm.controls['userMail'].value;
    let pass = this.registerForm.controls['userPassword'].value;
     this.userService.login(name,pass).subscribe((res:any)=>{
     this.matDialog.closeAll();
     this.userService.getUserDetails(1).subscribe((res:any)=>{
      this.userEditProfile=res;
      this.userService.setUserPhone(this.userEditProfile.userPhone).subscribe((res:any)=>{
       //  if(this.innerWidth<=480)
         //  this.router.navigate(['../loginPhoneVarification']);
         //  else
          this.openDialog();
      });
     });
     });
  }

  close()
  {
    this.dataService.state.next("התחבר");
    this.state.emit();
   // this.matDialog.closeAll();
   // this.router.navigate(['../mainHome']);
    //if(this.innerWidth)
      this.openEnterDialog();
  }

  openDialog() {
    this.matDialog.closeAll();
    this.matDialog.open(LoginPhoneVarificationComponent,{ disableClose: true });
  }

  openEnterDialog() {
    this.matDialog.closeAll();
    if(this.innerWidth<=480)
    this.matDialog.open(PopupEnter2Component, { disableClose: true });
    else
    this.matDialog.open(PopupEnterComponent, { disableClose: true });
  }

  openPasswordDialog() {
    this.matDialog.closeAll();
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PasswordComponent,{ disableClose: true });
  }

  createForm() {
    this.registerForm = new FormGroup({
      userMail: new FormControl("", Validators.required),
      userPassword: new FormControl("", [Validators.required, Validators.minLength(8),Validators.maxLength(20)]), 
    });
  }

  ngOnInit(): void {
    this.innerWidth=window.innerWidth;
    this.user = new User();
    this.createForm();
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.registerForm.controls[fieldName].invalid;
  }
}
