<div class="borderGreen" style="background-image: url(assets/images/BG_2.png);
background-size: contain; height: 540px;">
<div  class="container">
    <div class="body" style="font-family: OpenSansHebrewBold">
        <img class="align-baseline icon"  src="assets/images/Popup_Logo.png">
        <h1 class="fontSize" style="color: #41c777; margin-top: 10px;">
             מדיניות פרטיות ותקנון
        </h1>
        <iframe style="height: 265px ; width: 100%;" width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
      <div  (click)="backPress()" class="button" style="cursor: pointer;">הבנתי אני מאשר</div>
    </div>
  </div>
</div>

