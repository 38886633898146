<div class="page" style="min-height: 478px;">
<div class="registerbtn_" style="font-family: OpenSansHebrewRegular;">
    <!-- <div (click)="backPress()" class="toolbar" style="cursor: pointer;"> -->
    <!-- <img (click)="backPress()" src="assets/images/Right_Arrow_White.png" style="cursor: pointer;"> -->
<h1 (click)="backPress()" style="position: inherit; text-align: center;
padding-top: 15px; color: white; font-family: OpenSansHebrewBold;" >רכוש גיפט קארד</h1>
<!-- </div> -->
</div> 
<!-- <div class="d-flex">
    <div class="m-auto" style="width: 73%; text-align: right; font-weight: bold;"> -->
        <form class="form-signin" [formGroup]="paymentForm">
            <div>
            <div class="keep d-flex m-auto " style="width: 73%; font-weight: bold; font-family: OpenSansHebrewBold;">
        <img class="priceButton" style="margin: auto;
        display: flex; align-items: center;
        color: white;" src="assets/images/Buy_Gift_Card_Input_Mony@3x.png">               
        <input name="nvSum" autofocus [(ngModel)]="sum" formControlName="nvSum" style="font-size: 17px; padding: 5px; border: none; height: 30px;
        width: 67% !important; outline: none; box-shadow: none; color: black;"  class="btn price" placeholder="הזן סכום" inputmode="numeric"
                  type="tel"/>
      </div>
<div class="d-flex m-auto width" style="width: 57%; direction: rtl; text-align: right; font-family: OpenSansHebrewBold;">
        <div class="col5" style="font-size: 13px;">
            <img src="assets/images/Pay_Page_Number_Box_Green.png" />
            <div class="caption post-content">
                <span style="color: white;">1</span>
            </div>
            <span style="font-weight: bold;">שלח גיפט קארד לחבר</span>
            </div>
    <div class="align-self-center">
        <img (click)="forFriend()" style="cursor: pointer;background-size: contain;" [src]="forFriendImg">
    </div>
</div>
<div class="d-flex m-auto width" style=" font-family: OpenSansHebrewBold; width: 58%; direction: rtl; text-align: right;padding: .75rem 0rem;">
    <div class="col5 alert-dismissible" style="font-size: 13px;">      
        <span class="forMe" style="font-weight: bold;">גיפט קארד עבורי</span>
        </div>
<div>
    <img (click)="forMe()" style="cursor: pointer;background-size: contain;" [src]="forMeImg">
</div>
</div>
<div class="d-flex"  style="margin-top: 15px; margin-bottom: 10px; font-family: OpenSansHebrewBold;" >
    <img class="m-auto" style="width: 72.3%; " src="assets/images/Pay_Page_Line.png">
</div>
<div *ngIf="isForFriend" class="d-flex m-auto width" style="font-family: OpenSansHebrewBold; width: 56.5%; direction: rtl; text-align: right;">
    <div class="col5" style="font-size: 13px;">
        <img src="assets/images/Pay_Page_Number_Box_Green.png" />
        <div class="caption post-content">
            <span style="color: white;">2</span>
        </div>
        <span style="font-weight: bold;">הזן מספר טלפון לשליחת</span>
        <div  style="font-size: 13px; font-weight: bold;">    
        <span style="padding-right: 2.3rem;">הגיפט קארד לחבר</span>
        </div>
        </div>
</div>
<div *ngIf="isForFriend" class="d-flex carousel-item-next " style="font-family: OpenSansHebrewBold; width: 38.5%; font-size: 20px;
font-weight: bold; direction: rtl;">
    <div class="phone" style="margin-top: 15px; margin-bottom: 10px;" >
        <input name="phone" [(ngModel)]="phone" formControlName="nvPhone"  minlength="9" maxlength="10"  style="height: auto; text-align: center; color: black;
        font-size: 16px;" type="tel" autofocus/>
        <img class="m-auto" src="assets/images/Buy_Gift_Card_Line_2@3x.png">
    </div>
    <!-- <div style="margin-top: 15px; margin-bottom: 10px; width: 172px;" >
    <input style="height: auto; text-align: center; color: black;
    font-size: 16px; width: 172px; width: 240px;" value="+972"/>
    <img class="m-auto" src="assets/images/Buy_Gift_Card_Line_3@3x.png">
</div> -->

</div>


<div *ngFor="let p of payments ; let ind = index ">
    <div class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 71.5%; direction: rtl; text-align: right;padding: .75rem 0rem;">
        <div class="col5 alert-dismissible" style="font-size: 13px;">  
            <span style="font-weight: bold;">{{p.text}}</span>
            </div>
        <div class="align-self-center checkBox">
        <img (click)="paymentsBy(ind)" style="cursor: pointer;background-size: contain;" [src]="p.imageNumber==1 ? paymentsOn : paymentsOff">
        </div>
        </div>
</div>
<div  class="container" style=" font-family: OpenSansHebrewBold; height: 40%; font-size: 13px; background-size: 100% 100%;
background-image: url(assets/images/Buy_Gift_Card_BG_2@3x.png)">
  
       
  <mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
        <div class="d-flex m-auto " style="width: 97%; direction: rtl; text-align: right;">
            <div class="col5" style="font-size: 13px;">
                <img src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div class="caption post-content">
                    <span style="color: white;">{{paymentCardNumber}}</span>
                </div>
                <span style="font-weight: bold;">תשלום באשראי</span>
                </div>
        </div>
<div class="bg-img d-flex" style="padding-right:1rem;">
    <div class="col6" style="font-size: 13px;">
    <div >
   <div class="d-flex">     
<p style="margin-bottom: 5px; padding-right: 2px;">שם בעל הכרטיס</p>
   </div>
   <div class="d-flex">
    <input [(ngModel)]="methodsPayment.UserName" autocomplete="off"  matInput formControlName="nvCardholderName" [ngClass]=" !isValidInput('nvCardholderName') ? 'input-street' : 'input-street-error' " type="text" required autofocus>
</div>
</div>
<div>
<div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
<p class="col6" style=" padding-right: 2px;">מספר הכרטיס</p>
</div>
<div class="d-flex"> 
    <input  [(ngModel)]="methodsPayment.CardNumber" autocomplete="off"  matInput formControlName="nvCardNumber" [ngClass]=" !isValidInput('nvCardNumber') ? 'input-street' : 'input-street-error' " required appCardNumberFormatter autofocus  dir="ltr" >
</div>
</div>
<div style="margin-top: 5px;">
    <div class="d-flex" style="margin-bottom: 5px;" >
        <p class="col-6" style="padding-right: 2px;">תוקף</p>
        <p class="col-6">cvv</p>
        </div>
        <div style="display: flex">   
            <!-- <mat-form-field appearance="outline">
                <input autocomplete="off" matInput [matDatepicker]="dp" (click)="dp.open()" class="box" [formControl]="date"  >
            
                <mat-datepicker #dp
                                startView="multi-year"
                                (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)"
                                panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field> -->
              <!-- <mat-form-field appearance="outline">
                <input readonly="readonly" inputmode='none' (onfocus)="onBlur()" (keyup)="onKey($event)" autocomplete="off" matInput [matDatepicker]="dp" (click)="dp.open()" class="box" [formControl]="date"  >
            
                <mat-datepicker #dp
                                startView="multi-year"
                                (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)"
                                panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field> -->
              <div class="d-flex">
                <input #year inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="yy" style="margin-right: 3px;" (click)="clearYear()"
             [ngClass]=" !isValidInput('year') ? 'box' : 'box-error' " formControlName="year"/> 
              <input #month inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="MM" style="margin-right: 3px;" (click)="clearMonth()"
              [ngClass]=" !isValidInput('month') ? 'box' : 'box-error' " formControlName="month"/> 
              </div>
              <input #cvv inputmode="numeric" pattern="[0-9]*" type="tel" style="margin-right: 3px;" 
               name="methodsPayment.card_cvv" [ngClass]=" !isValidInput('cvv') ? 'box' : 'box-error' " formControlName="cvv"/> 
            </div> 
            <div class="d-flex">     
                <p style="margin-bottom: 5px; padding-right: 2px;">מספר תעודת זהות של בעל הכרטיס</p>
                   </div>
                   <div class="d-flex">
                    <input #identity [(ngModel)]="methodsPayment.UserIdentity"  inputmode="numeric"
                    pattern="[0-9]*" type="tel" name="methodsPayment.UserIdentity" autocomplete="off"  matInput formControlName="identityNumber" [ngClass]=" !isValidInput('identityNumber') ? 'input-street' : 'input-street-error' " required autofocus>
                </div>
        </div>
 <br>
  <img (click)="keepCard()" [src]="keepCardImg" style="cursor: pointer;">
  <span style="margin-right: 5px; font-size: 13px;">שמור את הכרטיס שלי</span>
  <br>
  <br>
  <span *ngIf="paymentForm.valid==false"  style="color: #ff8585;">{{error}}</span>
  <div (click)="payment()"  class="wrapper payment" style="cursor: pointer; position: absolute; bottom: 0; left: 0;">
    <img *ngIf="paymentForm.valid==true" src="assets/images/Delivery_Popup_Ok_Button.png">  
    <img *ngIf="paymentForm.valid==false" src="assets/images/Delivery_Popup_Cancel_Button.png"> 
    <div   [class.isDisabled]="paymentForm.valid" class="caption post-content pay" style="cursor: pointer; margin: -27px 39% 12px;">
          <span style="color: white !important;" [ngStyle]="paymentForm.valid==false  && {'color':'#29c067'}">תשלום</span>
    </div>  
</div>
</div>
<div class="col-12" style="position:absolute; bottom:-15%; left:10%;">
    <div (click)="payment()"  class="wrapper" style="cursor: pointer; position: absolute; bottom: 0; left: 0;">
        <img *ngIf="paymentForm.valid==true" src="assets/images/Delivery_Popup_Ok_Button.png">  
        <img *ngIf="paymentForm.valid==false" src="assets/images/Delivery_Popup_Cancel_Button.png"> 
        <div   [class.isDisabled]="paymentForm.valid" class="caption post-content " style="cursor: pointer; margin: -27px 39% 12px;">
          <span style="color: white !important;" [ngStyle]="paymentForm.valid==false  && {'color':'#29c067'}">תשלום</span>
      </div>  
  </div>
  </div>
</div>
</div>

</div>
</form> 
</div>

