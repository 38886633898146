import { Component, EventEmitter, HostListener, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { OrdersService } from 'src/app/services/order-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { MyOrder } from 'src/app/models/order';

@Component({
  selector: 'app-end-order',
  templateUrl: './end-order.component.html',
  styleUrls: ['./end-order.component.scss']
})
export class EndOrderComponent implements OnInit {
  showsIframe: boolean = true;
  @Output() changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  order: MyOrder;
  myurl: string = "https://baliapp.co.il/baliRestaurants/Restaurant.aspx?iD=";
  @ViewChild('myIframe', { static: false }) iframe: ElementRef;
  onPopState(event) {
    this.router.navigate(['../mainHome']);
  }
  width:number;
  Height:string;
  Height1:string;
  iframeHeight:string;
  iframeHeight1:string;
  isSuccess: boolean = false;
  urlSafe: SafeResourceUrl;

  constructor(
    private matDialog: MatDialog,
    private dataService: DataService,
    private proxy: AppProxy,
    private orderService: OrdersService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private zone: NgZone
  ) {}

  
  ngOnInit(): void {

   const i = (this.orderService.order != null && this.orderService.order.OrderID != null) ? this.orderService.order.OrderID : 0

    this.matDialog.closeAll();
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
   
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.myurl+String(i));
    this.iframeHeight = `${window.outerHeight * 0.85}px`;
    this.Height = `${window.outerHeight * 0.9}px`;
    this.iframeHeight1 = `${window.outerHeight * 0.7}px`;
    this.Height1 = `${window.outerHeight * 0.8}px`
    this.width = window.outerWidth ;
  }



setIframeHeight(frame) {
          
  var iframeElement = frame as HTMLIFrameElement;
  var iframeHeight = iframeElement.contentWindow.document.documentElement.scrollHeight;
       var windowHeight = iframeHeight + 50; // Adding additional padding to the window height\n    
     window.resizeTo(window.outerWidth, windowHeight);
     iframeElement.height =  iframeHeight + 50+ "px"

}

 getDocHeight(doc) {
  doc = doc || document;
  var body = doc.body,
    html = doc.documentElement;
  var height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);
  return height;
}

height: number = 0;



/** Hide the scrollbar of the iFrames content and set to full height to it */
onHtmlDataLoad(event: Event): void {
 

  }

  ok(): void {
    sessionStorage.deeplink2 = "";
    sessionStorage.deeplink = "";
    this.router.navigate(['../mainHome']);
  }
}
