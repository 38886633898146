import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import {MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  getNewPassword:boolean=false;
  valid:boolean=false;
  newPasswordForm:FormGroup;

  constructor(private matDialog:MatDialog, private proxy:AppProxy,private router: Router ,private userService:UserService) { }

  newPassword()
  {
    this.valid=true;
    if(this.newPasswordForm.valid)
  {
    this.userService.resetPasswordSMSRequest(this.newPasswordForm.value.nvPhone).subscribe((res:any)=>{
      if(res.error==false && res.responseMessage=="ok")
      this.getNewPassword=true;
      else
        this.proxy.showError("מספר טלפון לא קיים במערכת","");
    });
  }
  else
  this.proxy.showError("בעיה במספר הטלפון","");
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.newPasswordForm.controls[fieldName].invalid;
  }

  createForm() {
    this.newPasswordForm = new FormGroup({
      nvPhone: new FormControl("", Validators.required),});
  }

  closeDialog()
  {
    this.matDialog.closeAll(); 
    this.router.navigate(["../mainHome"]);
  }

  ngOnInit(): void {
    this.createForm();
  }

  exit()
  {
    if(this.getNewPassword==false)
    window.history.back();
      else if(this.getNewPassword==true)
      {
        this.valid=false;
      this.getNewPassword=false;
  }
  }
}
