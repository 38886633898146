<div class="txt-input borderGreen"
 style="background-image: url(assets/images/Popup_BG.png); background-repeat: no-repeat;">
 <div style="margin-top: 35px;">
   <img src="assets/images/Popup_Signup_Button.png" (click)="signUp()">
   <img src="assets/images/Popup_No_Sign_Button.png" (click)="noSign()">
   <!-- <img src="assets/images/Popup_Doanlaod_App_Button.png"> -->
   <!-- <div class="d-flex d-lg-block">
    <img style="cursor: auto;" src="assets/images/Popup_Apple_Icon.png" />
    <img style="margin-right:40px; cursor: auto;" src="assets/images/Popup_Google_Icon.png"/> -->
    <!-- <a href="https://apps.apple.com/us/app/%D7%91%D7%90-%D7%9C%D7%99-%D7%91%D7%A7%D7%9C%D7%99%D7%A7/id1582050632"></a>
    <a href="https://play.google.com/store/apps/details?id=com.appgate.balihazmanot"></a> -->
   <!-- </div> -->
</div>
</div>
