import { Component, OnInit ,EventEmitter  ,Output } from '@angular/core';
import { Category, CategoryItem,  } from 'src/app/models/category';
import { ChangeFood} from 'src/app/models/restaurant'
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reserved-restaurants',
  templateUrl: './reserved-restaurants.component.html',
  styleUrls: ['./reserved-restaurants.component.scss']
})
export class ReservedRestaurantsComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  restaurantsItem:CategoryItem[];
  category:Category;
  changeFood:ChangeFood[];
  page:number=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  id:number=-1;
  text:string;

  constructor(private proxy:AppProxy, private bussinssService: BussinssService ,private dataService:DataService) { }

  ngOnInit(): void {
    this.proxy.scrollToTop();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.changeFood=[
      {
        "ID":1,
        "Name":"ללא בוטנים",
        "Text":"לחץ לשינויים ",
        "toppings":[]
      },
      {
        "ID":2,
        "Name":"ללא כוסברה",
        "Text":"לחץ לשינויים ",
        "toppings":[]
      },
      {
        "ID":3,
        "Name":"ללא בצל ירוק",
        "Text":"לחץ לשינויים ",
        "toppings":[]
      },
      {
        "ID":4,
        "Name":"תוספת איטריות",
        "Text":"לחץ לשינויים ",
        "toppings":[]
      },
    ]
    this.bussinssService.getFavoriteBusiness().subscribe((res:any)=>{
      this.restaurantsItem=res;
    for(let i=0;i<this.bussinssService.categoryItemFavoriteList.length;i++)
    {
      this.bussinssService.categoryItemFavoriteList[i].IsFevorite=1;
    }
    this.category={
      "Name": "בתי עסק שמורים",
      "ID":5,
        "categoryItems":this.bussinssService.categoryItemFavoriteList,
        "isCheck":true,
        "categoryType":1,
    }
  });  
  }

  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    this.addItems();
  }

  appendItems() {
    this.addItems();
  }

  addItems() {
    this.page=Number(this.page)+1;
    if(this.id>-1)
    {
      this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
        for(let i=0;i<res.length;i++)
        {
        this.restaurantsItem.push(res[i]);
        }
    });
  }
  else
  {
    this.bussinssService.getMenuBySearch(this.text,this.page,false).subscribe((res:any[])=>{
      for(let i=0;i<res.length;i++)
      {
      this.restaurantsItem.push(res[i]);
      }
    });
  }
  }
}
