<div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold;">

        <img (click)="close()" src="assets/images/Popup_1_Close_Button.png" />
        <div style="text-align: center;">
        <br> 
        <img class="img"  [src]="url"  style="border-radius: 10px; padding: 1px;"/>
      </div>
       
  </div>

