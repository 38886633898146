import { Component, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-inactivity-popup',
  templateUrl: './user-inactivity-popup.component.html',
  styleUrls: ['./user-inactivity-popup.component.scss']
})
export class UserInactivityPopupComponent implements OnInit {

  constructor(private matDialog:MatDialog, private router: Router) { }

  ngOnInit(): void {
  }

  // ok()
  // {
  //   sessionStorage.refrash="false"
  //   sessionStorage.refrash2="true"
  //   this.matDialog.closeAll();
  //   let url: string = "/mainHome"
  //   this.router.navigateByUrl(url);
  //     // this.router.navigate(["../../mainHome"]);

  // }

  ok()
  {
   // sessionStorage.deeplink='';
    this.matDialog.closeAll();
    this.router.navigate(["../mainHome"]);
  }
}

