<div #list class="page" style="position: relative; height: auto; margin-bottom: 197px; margin-top: -50px;"  [ngStyle]="num==2 && {'height': innerHeight + 'px'}">
  <div style="display: flex; justify-content: center; position: relative;cursor: pointer;font-family: OpenSansHebrewRegular;">
<div class="width"  style="background-repeat: no-repeat; height: 176px; width: 74.5%;">
    <img class="img" [src]="Image"  style=" height: 80% ;width: 100%;">
</div>
</div>
<img class="img400" [src]="Image400" style="position: absolute; display: none; width: 100%; top: 0;">
    <div class="d-flex height" style="width: 73%; position: relative; right: 0; left: 0; margin: auto;">
    <div style="font-size: 18px; text-align: left; color: #373737; width: 100%;
    font-weight: bold;" [ngStyle]="num==2 && {'height': innerHeight2 + 'px'}" class="dFlex col3">
      <div class="header" style="height: 320px;">
        <div class="foodName" style="font-size: 18px; text-align: center; color: #373737; direction: rtl;
        font-weight: bold; position: relative; padding-bottom: 10px;">{{foodName}}</div>
        <img class="blockquote-footer" [src]="ImageFood!='' ? ImageFood : null"  style="height: 60%; margin: auto; border-radius: 10px;"/>
    
        <div class="foodDescription" style="font-size: 14px; text-align: center; direction: rtl; margin-top: 10px;
        white-space: pre-line; font-weight: 500;"> {{ foodDescription }}</div>
      </div>
<div class="d-flex img400 img4" style="margin-top: 20px;">
      <div  class="keep">
        <img class="img400" style="height: 40px;" src="assets/images/price_box_food.png">               
        <span class="priceText img400"  style="color: #29c065; direction: rtl;
        font-size: 16px; position: absolute; left: 33%; top: 25%;">{{totalFoodAmount}} ₪</span>
      </div> 
      <div *ngIf="toppings.length>2" class="d-flex col-12" style="position: absolute; justify-content: center; direction: rtl;">
<div *ngFor="let item of toppings ;let i = index" >
      <div class="mr-lg-auto border2 img400" *ngIf="i%2==0" >
        <img class="button align-self-center"  [src]="i+2<=position || i+1<=position ? pointOn : pointOff" >  
        <img *ngIf="i+1!=toppings.length && i+2!=toppings.length" style="width: 15px;" class="align-self-center" src="assets/images/point_line.png"/>
      </div>
    </div>
  </div>
  </div>
    
<div class="border" style="width: 100%; color:#373737; font-weight: bold; height: auto;
        float: center; float: center; direction: rtl;position: relative;
        border: none !important; box-shadow: none; min-height: 250px;" >
    <div  class="keep img col4" style="margin-left: 35px; margin-bottom: 10px;
    top: 8px; position: absolute; left: 0; cursor: auto;">
      <img style="height: 40px;" src="assets/images/price_box_food.png">               
      <span class="buttonText"  style="color: #29c065; direction: rtl;
      font-size: 16px; position: absolute; left: 30%; top: 25%;">{{totalFoodAmount}} ₪</span>
    </div> 
          <div class="display"  style="text-align: center; color: #575658;font-size: 15px; margin-top: 20px;" [ngStyle]="positionPage==1 && {'margin-right':'200px'}"> {{foodName}}</div>
 <div [ngClass]="num==2 ? 'img400' :'d-flex'" style="margin-top: 10px;">
<div style="position: relative;" class="scroller" [ngClass]="continuedNum==1 ? innerWidth<=480 ? 'listHeight' : 'listHeight2': 'listHeight'" [ngStyle]="num==2 && {'height': innerHeight2 + 'px'}">
          <div  *ngFor="let item of toppings ;let i = index" style="text-align: right;" [ngStyle]="num==2 && {'width':'100%'}">
            <div *ngIf="i%2==0 " style="text-align: center;" [ngStyle]="i==1 && {'margin-top': '10px'}">
<span style="font-size: 15px; line-height: 2.5;">{{item.TypeTxt}}</span>
<div style="font-size: 10px; color: #29c065;" *ngIf="item.CountAdditionFree>0 && item.CountAdditionFree!=null">מקבלים {{item.CountAdditionFree}} חינם</div>  

<div class="d-flex" *ngFor="let topping of item.listA; let ind=index" style="margin-top: 5px; text-align: right;" >
  <div class="col-md-12 text" style="cursor: pointer; position: relative; direction: rtl; ">
    <img (click)="selectItem(i,ind,true)" class="button align-self-center" [src]="topping.IsChoose==0 ? checkboxOff : checkboxOn">          

    <span  (click)="selectItem(i,ind,true)" style="overflow: hidden; align-self: center!important;
    text-overflow: ellipsis; margin-right: 10px; margin-bottom: 3px;font-size: 12px;
    font-family: 'OpenSansHebrewRegular'; ">&#x200F; {{ topping.Food }}</span>
    <!-- <span (click)="selectItem(i,ind,true)" *ngIf="topping.Price!=null && topping.Price!=0" style="margin-right: 5px; font-size: 12px;font-family: 'OpenSansHebrewRegular';">{{topping.PriceText}}</span> -->
      <span (click)="selectItem(i,ind,true)" *ngIf="topping.Price!=null && topping.Price!=0 && (item.CountAdditionFree<=item.CountChoose || item.CountAdditionFree==0 )" style="margin-right: 5px; font-size: 12px;font-family: 'OpenSansHebrewRegular';">{{topping.PriceText}}</span>


</div>
<div *ngIf="item.IsFree==1" style="position: absolute; font-size: 12px; font-family: 'OpenSansHebrewRegular';" class="mr-lg-auto" [ngClass]="item.listA.length<=scrollList ? 'left4' : 'left3'">
  <img (click)="minus(i,ind)" class="align-self-center"  src="assets/images/minus_button.png" style="cursor: pointer; margin-left: 5px;"/>
  <span style="margin-right: 5px; margin-left: 8px;">{{topping.CountChoose}}</span>
  <img (click)="plus(i,ind)" class="align-self-center" src="assets/images/plus_button.png" style="cursor: pointer;"/>
</div>
</div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex back" [ngClass]="continuedNum==0 ? 'bottom1' :'bottom2'">
        <div class="display col-md-3" (click)="back()" style="margin-right: 10px; cursor: pointer; margin-bottom: 10px; margin-top: 10px; position: absolute; right: 0;">
          <img class="button" src="assets/images/Tosafot_Next_Button.png">               
          <span class="buttonText" style="color: white;
          font-size: 16px; position: absolute; left:40%; top: 25%;">חזרה</span>
        </div> 
<div class="d-flex img carousel-item-prev" style="bottom: -15px; position: relative;">
  <span style="font-size: 13px; width: -webkit-fill-available; padding-top: 3px;">שם למנה:</span>
  <input class="container" name="inviteName" [(ngModel)]="inviteName" style="color: black;">
               </div>
        <div (click)="continue()" class="continued img"  style="cursor: pointer; margin-left: 35px; margin-bottom: 10px; margin-top: 10px; position: absolute; left: 0;">
<img  class="button" src="assets/images/Tosafot_Next_Button.png">               
<span class="buttonText"  style="color: white;
font-size: 16px; position: absolute; left: 24%; top: 25%;" >סיום</span>
</div> 
</div>
      <!-- <div style="height: 100px;">
              <div *ngFor="let item of toppingsPage ;let i = index" >
             
                <div *ngIf="i < 2 " style="font-size: 15px; "  [ngClass]="i!=0 ? 'top22' : 'top2'" >{{item.TypeTxt}}</div>
              
                 <div *ngIf="i < 2 ">
                  <div class="d-flex" *ngFor="let topping of item.listA; let ind=index" style="text-align: right;  margin-top: 6px;" [ngStyle]="ind==0 && {'margin-top':'20px'}"> 
                    <div (click)="selectItem(i,ind,true)"  class="col-md-12" style="position: relative; direction: rtl; ">
                      <img class="button align-self-center" [src]="topping.IsChoose==0 ? checkboxOff : checkboxOn">          

                      <span style="overflow: hidden; align-self: center!important;
                      text-overflow: ellipsis; margin-right: 10px; margin-bottom: 3px;font-size: 12px;
                      font-family: 'OpenSansHebrewRegular';" >&#x200F; {{ (topping.Food.length>35)? (topping.Food | slice:0:35)+'..':(topping.Food) }}</span>
                      <span *ngIf="topping.Price!=null && topping.Price!=0 " style="margin-right: 5px; font-size: 12px;font-family: 'OpenSansHebrewRegular';">{{topping.PriceText}}</span>
            

              </div>
                  <div *ngIf="item.IsFree==1" style="position: absolute; font-size: 12px; font-family: 'OpenSansHebrewRegular';" class="mr-lg-auto" [ngClass]="item.listA.length<=scrollList ? 'left4' : 'left3'">
                    <img (click)="plus(i,ind)" class="align-self-center" src="assets/images/plus_button.png" style="cursor: pointer;"/>
                    <span style="margin-right: 5px; margin-left: 8px;">{{topping.CountChoose}}</span>
                    <img (click)="minus(i,ind)" class="align-self-center"  src="assets/images/minus_button.png" style="cursor: pointer; margin-left: 5px;"/>
                  </div>
                </div>
              </div>
                </div>
              </div> -->
       
           
                <!-- <td class="display" *ngIf="toppingsPage[i + 1]!=null" style="font-size: 15px; text-align: center; position: absolute; right: 53.5%;" [ngStyle]="i!=0 && {'top':'50%'}">{{toppingsPage[i + 1].TypeTxt}}</td>   
          
                <div class="display" *ngIf="toppingsPage[i + 1]!=null" style="overflow: auto; position: absolute; right: 50%; height: 115px; margin-top: 10px; ; top: 12%" [ngStyle]="i!=0 && {'top':'55%'}">
                  <div  class="d-flex" *ngFor="let topping of toppingsPage[i + 1].listA; let ind=index" style="text-align: right; width: 310px; margin-top: 7px;"> 
                 <div class="col-md-9" style="position: relative; right: -15px;">
                    <img   (click)="selectItem(i+1,ind,true)" class="button align-self-center" [src]="topping.IsChoose==0 ? checkboxOff : checkboxOn">       
                  <span style="direction: rtl; overflow: hidden; align-self: center!important;
                  text-overflow: ellipsis; margin-right: 10px; margin-bottom: 3px;font-size: 12px;
                   font-family: 'OpenSansHebrewRegular';" > {{topping.Food}}</span>
                  <span *ngIf="topping.Price!=null && topping.Price!=0 && toppingsPage[i+1].Count==1" style="margin-right: 5px; font-size: 12px;font-family: 'OpenSansHebrewRegular';">(+ {{topping.Price}}  ₪ )</span>
 
                 </div>
                 <div *ngIf="toppingsPage[i + 1].Count>1" style="position: absolute; left: 0; font-size: 12px; font-family: 'OpenSansHebrewRegular';" class="mr-lg-auto col-5">
                    <img (click)="minus(i+1,ind)" class="align-self-center"  src="assets/images/minus_button.png" style="cursor: pointer;"/>
                    <span style="margin-right: 5px; margin-left: 5px;">{{topping.CountChoose}}</span>
                    <img (click)="plus(i+1,ind)" class="align-self-center"  src="assets/images/plus_button.png" style="cursor: pointer;"/>
                    <span *ngIf="topping.Price!=null && topping.Price!=0" style="margin-right: 5px;" >{{topping.Price}}  ₪</span>
                  </div>
                          </div>
              </div> -->
      
              <div style="width: 50%;"  [ngStyle]="num==2 && {'display':'none'}" class="scroller">
                <div *ngFor="let item of toppings ;let i = index" style="text-align: right;" [ngStyle]="num==2 && {'width':'100%'}">
                  <div *ngIf="i%2!=0 "style="text-align: center;"  [ngStyle]="i==3 && {'margin-top': '10px'}">
      <span style="font-size: 15px; line-height: 2.5;">{{item.TypeTxt}}</span>
      <div style="font-size: 10px; color: #29c065;" *ngIf="item.CountAdditionFree>0 && item.CountAdditionFree!=null">מקבלים {{item.CountAdditionFree}} חינם</div>  

      <div  class="d-flex" *ngFor="let topping of item.listA; let ind=index" style="margin-top: 5px; text-align: right;" >
        <div class="col-md-12 text" style="cursor: pointer; position: relative; direction: rtl; ">
          <img (click)="selectItem(i,ind,true)" class="button align-self-center" [src]="topping.IsChoose==0 ? checkboxOff : checkboxOn">          
      
          <span (click)="selectItem(i,ind,true)" style="overflow: hidden; align-self: center!important;
          text-overflow: ellipsis; margin-right: 10px; margin-bottom: 3px;font-size: 12px;
          font-family: 'OpenSansHebrewRegular';" >&#x200F; {{ topping.Food }}</span>
    <span (click)="selectItem(i,ind,true)" *ngIf="topping.Price!=null && topping.Price!=0 && (item.CountAdditionFree<=item.CountChoose || item.CountAdditionFree==0 )" style="margin-right: 5px; font-size: 12px;font-family: 'OpenSansHebrewRegular';">{{topping.PriceText}}</span>
      
      
      </div>
      <div *ngIf="item.IsFree==1" style="position: absolute; font-size: 12px; font-family: 'OpenSansHebrewRegular';" class="mr-lg-auto" [ngClass]="item.listA.length<=scrollList ? 'left4' : 'left3'">
        <img (click)="minus(i,ind)" class="align-self-center"  src="assets/images/minus_button.png" style="cursor: pointer; margin-left: 5px;"/>
        <span style="margin-right: 5px; margin-left: 8px;">{{topping.CountChoose}}</span>
        <img (click)="plus(i,ind)" class="align-self-center" src="assets/images/plus_button.png" style="cursor: pointer;"/>

      </div>
      </div>
                </div>
              </div>

            </div>


            </div>
            <!-- <div >
             <div  *ngIf="continuedNum==1" class="continued_ img400 alert-dismissible" style="margin-top: 80px;
             left: 0;" >
        <span style="font-size: 13px;">שם למנה:</span>
        <input #food class="container" name="inviteName2" (focus)="scrollTo()" (focus)="scrollTo()"  (blur)= 'onBlur()' [(ngModel)]="inviteName2" style="color: black;">
              </div> 
             <div (click)="continue()" class="continued img400 " style="margin-top: 10px;
             left: 0;" >
              <img  class="button" src="assets/images/Tosafot_Next_Button.png">               
              <span   class="buttonText"  style="color: white;
              font-size: 16px; position: absolute; left: 40%; top: 25%;">{{continued}}</span>
              </div> 
            </div> -->
      <!-- <div class="col-12 d-flex back" [ngClass]="continuedNum==0 ? 'bottom1' : 'bottom2'" style="position: absolute;">
        <div class="display col-md-3" (click)="back()" style="margin-right: 10px; cursor: pointer; margin-bottom: 10px; margin-top: 10px; position: absolute; right: 0;">
          <img class="button" src="assets/images/Tosafot_Next_Button.png">               
          <span class="buttonText"  style="color: white;
          font-size: 16px; position: absolute; left:40%; top: 25%;">חזרה</span>
        </div> 
<span *ngIf="continuedNum==0"  class="col-md-5 img"></span>
<div *ngIf="continuedNum==1" class="d-flex img carousel-item-prev" style="bottom: -15px; position: relative;">
  <span style="font-size: 13px; width: -webkit-fill-available; padding-top: 3px;">שם למנה:</span>
  <input class="container" name="inviteName" [(ngModel)]="inviteName" style="color: black;">
  
               </div>
 <span class="col-md-2"></span> -->
        <!-- <div (click)="continue()" class="continued img"  style="cursor: pointer; margin-left: 35px; margin-bottom: 10px; margin-top: 10px; position: absolute; left: 0;">
<img  class="button" src="assets/images/Tosafot_Next_Button.png">               
<span class="buttonText"  style="color: white;
font-size: 16px; position: absolute; left: 40%; top: 25%;" >{{continued}}</span>
</div>  -->

</div>
  </div>
</div>
</div>
    
    
    
