 <div class="d-flex topPage" style="width: 73%;  bottom: 0; overflow: hidden; font-weight: bold;color: black; font-family: OpenSansHebrewRegular;">
  <footer class="footer d-flex ">
    <div class="d-flex">
      <div style="width: 100%; position:absolute; bottom: 100%; margin-bottom: 15px; ">
        <span class="align-bottom" style="font-size: 16px; color: black; font-family: OpenSansHebrewBold;">הורידו את האפליקציה שלנו:</span> 
        <a href="https://apps.apple.com/us/app/%D7%91%D7%90-%D7%9C%D7%99-%D7%91%D7%A7%D7%9C%D7%99%D7%A7/id1582050632"><img style="margin-right:10px; cursor: pointer;" src="assets/images/Apple_Store_Button.png" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.appgate.balihazmanot"><img  style="margin-right:15px; cursor: pointer;"  src="assets/images/Google_Store_Button.png"/></a>
    </div>
  </div>
      <div class="m-auto" >
        <div class="row d-flex m-auto" >
          <div class="top footer-column" style="background-color: #f5f5f5;">
          <img style="background-size: contain; background-size: contain;
          height:90px; margin-right: 2.1rem;" src="assets/images/Popup_Logo.png" [ngStyle]="token != 'order' && {'margin-right':'10rem'}"/>
             <div class="bottom_bar d-flex footer-column" style="direction: rtl; background-color: #f5f5f5; color: #000; bottom: 0; width: 100%;" [ngStyle]="token != 'order' && {'margin-right':'8rem'}">
  
              <a class="privacyPolicy" style="font-family: OpenSansHebrewBold; cursor: pointer; font-size: 13px; color: black; text-decoration: underline;" (click)="openDialog()"
              target="_blank" rel="noopener">מדיניות פרטיות ותקנון</a>
    

      </div> 
          </div>
          <div class="menu" (click)="menu()">
            <img style="cursor: pointer; margin-right: 10px; width: 40px;" src="assets/images/Menu_bottom_bar.png"/>
          <span style="margin-top: 8px; margin-right: 10px;" >תפריט</span>
          <span class="footer-column" style="margin-top: 25px; font-size: 13px;">  כל הזכויות שמורות ל "בא לי בקליק" </span>           
          </div>
          <div class="eat" (click)="eat()">
            <img style="height: 80px; position: absolute; bottom: 30px;" src="assets/images/home_button.png"/>
          <span style="margin-top: 60px; margin-right: 7px;" >מה בא לי לאכול?</span>

          </div>
          <div class="search" (click)="search()">
            <img style="cursor: pointer; margin-right: 10px;" src="assets/images/Search_button.png"/>
          <span style="margin-top: 8px;" >חיפוש</span>
          </div>
          <div class="footer-column" [ngClass]="token == 'order' ? 'col-md-3' : 'footer-column2'">
            <ul *ngIf="token == 'order'" class="nav flex-column">
              <li class="nav-item">
                <span class="footer-title" style="font-family: OpenSansHebrewBold">אזור אישי:</span>
              </li>
              <li class="nav-item" >
                <span (click)="orderHistory()" style="margin-top: 7px; cursor: pointer;"  class="nav-link" href="#">היסטורית הזמנות</span>
              </li>
              <li class="nav-item">
                <span (click)="reservedRestaurants()" style="cursor: pointer;" class="nav-link" href="#">בתי עסק שמורים</span>
              </li>
              <!-- <li class="nav-item">
                <span (click)="shippingHistory()" style="cursor: pointer;"  class="nav-link" href="#">היסטורית משלוחים</span>
              </li> -->
              <li class="nav-item">
                <span (click)="paymentInfo()" style="cursor: pointer;" class="nav-link" href="#"> אמצעי תשלום שלי</span>
              </li>
              <li class="nav-item">
                <span (click)="giftCard()" style="cursor: pointer;" class="nav-link" href="#">Gift Card</span>
              </li>
            </ul>
          </div>
          <div  class="col-md-4 footer-column">
            <ul class="nav flex-column">
              <li class="nav-item">
                <span class="footer-title" style="font-family: OpenSansHebrewBold;">לינקים:</span>
              </li>
              <!-- <li *ngIf="token == 'order'" class="nav-item"  style="margin-top: 7px;">
                <a [routerLink]="'../delivery'" style="cursor: pointer;" class="nav-link" href="#">הזמן משלוח</a>
              </li> -->
              <li class="nav-item" [ngStyle]="token != 'order' && {'margin-top':'7px'}">
                <span (click)="contact()" style="cursor: pointer;" class="nav-link" >צור קשר</span>
              </li>
              <li *ngIf="token == 'order'" class="nav-item">
                <span (click)="editProfil()" style="cursor: pointer;" class="nav-link" >עריכת פרופיל</span>
              </li>
              <li class="nav-item">
                <span  (click)="logOut()" style="cursor: pointer;" class="nav-link" href="#">{{outOrIn}}</span>
              </li>
            </ul>
            <div style="margin-top: 25px;" [ngStyle]="token != 'order' && {'margin-top':'78px'}">
              <span class="footer-column" style="font-size: 13px;">  כל הזכויות שמורות ל "בא לי בקליק" </span>  
            </div>
          </div>
          <div class="col-md-3 footer-column">
            <ul class="nav flex-column" >
              <li class="nav-item">
                <span style="font-family: OpenSansHebrewBold;">בואו לעבוד איתנו:</span>
              </li>
              <li class="nav-item" style="margin-top: 7px;">
                  <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Eilat.bali@gmail.com" class="nav-link"><i class="fas fa-comments"></i>לשליחים</a>
              </li>
              <li class="nav-item">
                <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Eilat.bali@gmail.com" class="nav-link"><i class="fas fa-comments"></i>לבתי העסק</a>
              </li>
              <li class="nav-item">
                <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Eilat.bali@gmail.com" class="nav-link"><i class="fas fa-envelope"></i>חברות וארגונים</a>
              </li>
              <li class="nav-item">
                <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Eilat.bali@gmail.com" class="nav-link"><i class="fas fa-star"></i>משרות</a>
              </li>
            </ul>
            <div style="margin-right: 120px; display: none;">
              <a href="https://css-tricks.com"><img style="margin-left:10px;" src="assets/images/Bottom_Bar_facebook.png"/></a>
              <a href="https://css-tricks.com"><img style="margin-left:10px;" src="assets/images/Bottom_Bar_instagram.png"/></a>
              <a href="https://css-tricks.com"><img style="margin-left:10px;" src="assets/images/Bottom_Bar_linkedin.png"/></a>
              <a href="https://css-tricks.com"><img style="margin-left:10px;" src="assets/images/Bottom_Bar_twitter.png"/></a>           
      </div>
          </div>
        </div>
      </div> 

   
    </footer>
</div>