import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-out-of-bounds',
  templateUrl: './dialog-out-of-bounds.component.html',
  styleUrls: ['./dialog-out-of-bounds.component.scss']
})
export class DialogOutOfBoundsComponent implements OnInit {

  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

 ok()
  {
    if (this.router.url == '/map')
    {
      this.dialog.closeAll();
      return
    }
    if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
    this.router.navigate(["../payment"]);
    else
    this.router.navigate(["../securePayment"]);
    this.dialog.closeAll();
  }

}

