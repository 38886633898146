<div class="page" style="min-height: 580px;">
    <div class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
    <div class=" d-flex m-auto header" style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
        <span class="col-6" style="text-align: right;" >ההזמנה שלך</span>
        <span  class="col-6" >{{totalPrice}} ₪</span>
    </div>
    </div>
    <div *ngIf="isDelivery==1" class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
    <div class=" d-flex m-auto header" style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
        <span class="col-6" style="text-align: right;" >דמי משלוח</span>
        <span  class="col-6" >{{deliveryPrice}} ₪</span>
    </div>
    </div>
    <div *ngIf="isDelivery==1" class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
        <div class=" d-flex m-auto header" style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
            <span class="col-6" style="text-align: right;" >סה״כ</span>
            <span  class="col-6" >{{order.Balance}} ₪</span>
        </div>
        </div>
    
    <div class="d-flex"  style="margin-top: 10px; margin-bottom: 10px;" >
        <img class="m-auto header" style="width: 73%; " src="assets/images/Pay_Page_Line.png">
    </div>
    <!-- <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto " style="width: 57.5%; direction: rtl; text-align: right;font-family: OpenSansHebrewBold">
            <div class="col-5" style="font-size: 14px;">
                <img src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div class="caption post-content">
                    <span style="color: white;">1</span>
                </div>
                <span style="font-weight: bold;">משלוח לכתובת שלי</span>
                </div>
        <div class="align-self-center">
            <img (click)="myAddress()" style="cursor: pointer;background-size: contain; " [src]="myAddressImg">
        </div>
    </div> -->

    <!-- <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 59%; direction: rtl; text-align: right;">
        <div style="font-size: 14px; padding-right: 6.5rem !important;">
           
            <span style="font-weight: bold;">{{myAddressText}}</span>
            </div>
    </div> -->
    <!-- <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 59%; direction: rtl; text-align: right;padding: .35rem 0rem;">
        <div class="col-5"  style="font-size: 14px; padding-right: 6.5rem !important;"> 
            <span style="font-weight: bold;">משלוח לכתובת אחרת</span>
            </div>
    <div>
        <img (click)="anotherAddress()" style="cursor: pointer;background-size: contain;" [src]="anotherAddressImg">
    </div>
    </div>
    <div *ngIf="isAnotherAddress && byGiftCard==false" class="container" style="margin-bottom: 5px; font-size: 14px;font-family: OpenSansHebrewRegular">
        <form class="form-signin" [formGroup]="addressForm" >
        <div class="bg-img">
            <div >
           <div class="d-flex">     
    <span style="padding-right: 2px;">רחוב כולל מספר בית</span>
           </div>
    <div class="d-flex">     
        <div class="map-container" >
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>   
          <input #search id="pac-input" style="margin-top: 5px;"  [(ngModel)]="address.nvStreet" matInput formControlName="street" name="nvStreet"  [ngClass]="!isValidInputAddress('street') ? 'input-street' : 'input-street-error' "  type="text" required>       
    </div> 

    </div>
        <div>
        </div>
        <div>
            <div class="d-flex" style="margin-bottom: 5px;">
                <span class="col-4" style="padding-right: 2px;">כניסה</span>
                <span class="col-4" >קומה</span>
                <span class="col-4">דירה</span>
                </div>
                <div class="d-flex">
                    <input  matInput formControlName="nvEntry" style="margin-left: 25px;"   class="input-street"  type="text">
                    <input  matInput formControlName="nvfloor" style="margin-left: 25px;" class="input-street" type="number">
                    <input matInput formControlName="nvNumberHoush" class="input-street"  type="number">     
                </div>
                </div>
                <div class="settings_list"></div>
    </div> 
    </form >
    </div> -->
    
    <div class="container" style="height: 40%; font-size: 14px; font-family: OpenSansHebrewRegular">
        <!-- <div *ngIf="showProgress==true" class="loader"></div> -->
        <div *ngIf="inCash == true" class="d-flex m-auto" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold">
            <div class="col5" style="font-size: 14px;">
                <!-- <div class="caption post-content">
                    <span style="color: white;">1</span>
                </div> -->
                <span  style="font-weight: bold;">תשלום במזומן</span>
                </div>
        <div  class="align-self-center">
            <img (click)="cashPayment()" style="cursor: pointer;background-size: contain;" [src]="cashPaymentImg">
        </div>
        </div>

        <div *ngIf="onCredit==true"  class="d-flex" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold ; margin-top: 10px;">
            <div class="col5" style="font-size: 14px;"> 
                <span style="font-weight: bold;">תשלום באשראי</span>
                </div>
        <div  class="align-self-center">
            <img (click)="creditPayment()" style="cursor: pointer;background-size: contain;" [src]="paymentCreditImg">
        </div>
        </div>

        <!-- <div *ngFor="let p of payments ; let ind = index ">
            <div class="d-flex m-auto " style="direction: rtl; text-align: right;padding: .30rem 0rem; font-family: OpenSansHebrewBold">
                <div class="col-5" style="font-size: 14px; padding-right: 5rem !important;">  
                    <span style="font-weight: bold;">{{p.text}}</span>
                    </div>
                <div class="align-self-center">
                <img (click)="paymentsBy(ind)" style="cursor: pointer;background-size: contain;" [src]="p.imageNumber==1 ? paymentsOn : paymentsOff">
                </div>
                </div>
        </div> -->

        <div>
        <form  class="form-signin" [formGroup]="paymentForm" style="font-family: OpenSansHebrewRegular; margin-right: 3px;">
    <div class="bg-img dFlex" >
        <div *ngIf="isCreditPayment==true" class="col6" style="margin-right: 7px;">
        <div >
       <div class="d-flex">     
    <span style="margin-bottom: 5px; padding-right: 2px;">שם בעל הכרטיס</span>
       </div>
       <div class="d-flex">
        <input autocomplete="off"  #ft01 (keyup.enter)="keytab($event)"  [(ngModel)]="methodsPayment.UserName"  matInput formControlName="nvCardholderName" [ngClass]="!isValidInput('nvCardholderName') ? 'input-street' : 'input-street-error' " type="text" required>
    </div>
    </div>
    <div>
    <div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
    <span class="col6" style=" padding-right: 2px;">מספר הכרטיס</span>
    </div>
    
    <div class="d-flex"> 
        <!-- <app-card-input (cardNumberChange)="onCardNumberChange($event)"></app-card-input> -->

        <input autocomplete="off"
         type="tel" [(ngModel)]="methodsPayment.CardNumber" (keydown.enter)="handleKeyDown()" matInput formControlName="nvCardNumber" [ngClass]="!isValidInput('nvCardNumber') ? 'input-street' : 'input-street-error'" required appCardNumberFormatter dir="ltr">
       
    </div>
    </div>
    <div style="margin-top: 5px;">
        <div class="d-flex">
            <span class="col-6" style="padding-right: 2px;">תוקף</span>
            <span>cvv</span>
            </div>
            <div style="display: flex"> 
            <div class="d-flex">
                <input #year inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="yy" style="margin-right: 3px;" (click)="clearYear()"  
             [ngClass]=" !isValidInput('year') ? 'box' : 'box-error' " formControlName="year"/> 
              <input #month inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="MM" style="margin-right: 3px;" (click)="clearMonth()"  
              [ngClass]=" !isValidInput('month') ? 'box' : 'box-error' " formControlName="month"/> 
              </div>
              <input #cvv inputmode="numeric" pattern="[0-9]*" type="tel" style="margin-right: 3px;"  
              [ngClass]=" !isValidInput('cvv') ? 'box' : 'box-error' " required formControlName="cvv"/> 
            </div>                         
</div>
<div class="d-flex">     
<span style="margin-bottom: 5px; padding-right: 2px;">מספר תעודת זהות של בעל הכרטיס</span>
   </div>
   <div class="d-flex">
    <input autocomplete="off" #ft01 (keyup.enter)="keytab($event)"  matInput formControlName="identityNumber" [(ngModel)]="methodsPayment.UserIdentity" [ngClass]="!isValidInput('identityNumber') ? 'input-street' : 'input-street-error' " inputmode="numeric"
    pattern="[0-9]*" type="tel" required >
</div>  
   
    </div>
    <br>
    <span *ngIf="paymentForm.valid==false" class="error"  style="margin-right: 12px; color: #ff8585; bottom: -40px; position: absolute;">יש להזין את כל פרטי החובה</span>
    <div class="col6" style="margin-right: 20px;">
        <div >
       <div class="d-flex">     
    <span style="margin-bottom: 5px; padding-right: 2px;">שם המזמין</span>
       </div>
       <div class="d-flex">
        <input matInput [(ngModel)]="address.nvOrderName"  autocomplete="off" formControlName="nvOrderName" [(ngModel)]="address.nvOrderName" [ngClass]="!isValidInput('nvOrderName') ? 'input-street' : 'input-street-error' " required >
    </div>
    </div>
    <div *ngIf="IsDelivery==1">
    <div  class="d-flex" style="margin-bottom: 5px;
    margin-top: 5px;">
    <span class="col6" style=" padding-right: 2px;">כתובת</span>
    </div>
  
    
    <!-- <div class="d-flex">     
        <div class="map-container" >
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>    -->
          <input  (keyup.enter)="keytab($event)" *ngIf="IsDelivery==1"  [(ngModel)]="address.nvStreet" (focus)="openMap()" (click)="openMap()" matInput formControlName="nvStreet" [ngClass]="!isValidInput('nvStreet') ? 'input-street' : 'input-street-error' " required> 
          <input  #search (keyup.enter)="keytab($event)" *ngIf="IsDelivery==0"  [(ngModel)]="address.nvStreet" (focus)="openMap()" (click)="openMap()" matInput formControlName="nvStreet" class="input-street">             
        <!-- </div>  -->
    </div>
    <div *ngIf="IsDelivery==1">
        <div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
            <span class="col-4" style="padding-right: 2px;">כניסה</span>
            <span class="col-4" >קומה</span>
            <span class="col-4">דירה</span>
            </div>
            <div class="d-flex">
                <input  #ft01 (keyup.enter)="keytab($event)" [(ngModel)]="address.nvEnter"  matInput formControlName="nvEnter" style="margin-left: 25px;"   class="input-street"  type="text">
                <input  #ft01 (keyup.enter)="keytab($event)" [(ngModel)]="address.nvfloor"  matInput formControlName="nvfloor" style="margin-left: 25px;" class="input-street" type="number">
                <input  #ft01 (keyup.enter)="keytab($event)" [(ngModel)]="address.nvHouseNumber" matInput formControlName="nvNumberHoush" class="input-street"  type="number">     
            </div>
            </div>
    <div class="d-flex" style="margin-top: 5px;">     
        <span style="margin-bottom: 5px; padding-right: 2px;">טלפון</span>
           </div>
           <div class="d-flex">

            <input  #ft01 (keyup.enter)="keytab($event)" [(ngModel)]="address.nvPhone"  matInput minlength="10" maxlength="10" formControlName="nvPhone" [ngClass]="!isValidInput('nvPhone') ? 'input-street' : 'input-street-error'" inputmode="numeric"
            pattern="[0-9]*" type="tel" required >
        </div>
<div class="d-flex" style="margin-top: 5px;">     
<span style="margin-bottom: 5px; padding-right: 2px;">אימייל (אופציונלי לקבלת חשבונית)</span>
   </div>
   <div class="d-flex">
    <input matInput  [(ngModel)]="address.nvEmail" (keydown.enter)="handleKeyDown()" formControlName="nvEmail" [ngClass]="!isValidInput('nvEmail') ? 'input-street' : 'input-street-error' " type="email">
</div>  
    </div>
    </div>
    </form> 
    </div>
    <div class="keep" [class.isDisabled]="paymentForm.valid">
        <img *ngIf="paymentForm.valid==true" src="assets/images/Delivery_Popup_Ok_Button.png">  
        <img *ngIf="paymentForm.valid==false" src="assets/images/Delivery_Popup_Cancel_Button.png"> 
                   
        <span  (click)="payment()" class="d-flex btn" style="justify-content:space-around; align-items: center; align-content: center; font-size: 14px; font-family: OpenSansHebrewRegular;  width: 300px; color: rgb(255, 255, 255);" [ngStyle]="paymentForm.valid==false && {'color':'#29c067'}">
            <span style=" margin-right: 20px; "> המשך לתשלום</span>
                <span style="margin-left: 20px;">{{order.Balance}} ₪</span>

        </span>
      </div>
    </div>
    </div>
    

    
    <!-- <div class="lds-dual-ring"></div> -->
  
    