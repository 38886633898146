import { Component, OnInit ,ViewChild ,ElementRef , NgZone ,Output ,EventEmitter} from '@angular/core';
import { FormGroup,FormControl ,Validators ,FormBuilder} from '@angular/forms';
import { Router } from '@angular/router'
import { Delivery } from 'src/app/models/delivery';
import { OrdersService } from 'src/app/services/order-service';
import { CustomValidators } from 'ng2-validation';
import { AppProxy } from 'src/app/services/app-proxy';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-delivery',
  providers: [DatePipe],
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent{
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  levelDelivery:number=0;
  deliveryForm:FormGroup;
  delivery:Delivery;
  valid:boolean=false;
  orderDelivery:boolean=false;
  orderButtom:boolean=true;
 mm : string ;
 lat: string="";
 lng: string="";
 lat2: string="";
 lng2: string="";
 zoom = 1;
 address_: string;
 private geoCoder;
 Address:string;
 isChangeAdress=0;
 options={
  componentRestrictions:{
    country:["isr"]
  }
};
@ViewChild('search')
public searchElementRef: ElementRef;
@ViewChild('search2')
public searchElementRef2: ElementRef;

months = [
        { val: '01' , name: 'Jan' },
        { val: '02' , name: 'Feb' },
        { val: '03' , name: 'Mar' },
        { val: '04' , name: 'Apr' },
        { val: '05' , name: 'May' },
        { val: '06',  name: 'Jun' },
        { val: '07' , name: 'Jul' },
        { val: '08' , name: 'Aug' },
        { val: '09',  name: 'Sep' },
        { val: '10',  name: 'Oct' },
        { val: '11' , name: 'Nov' },
        { val: '12' , name: 'Dec' }
    ];

  constructor(private dataService:DataService,private router: Router, private orderService:OrdersService,
    private ngZone: NgZone,private fb: FormBuilder,private proxy:AppProxy ,private datepipe: DatePipe ,private mapsAPILoader: MapsAPILoader){
    this.delivery=new Delivery();
  }


    
  ngOnInit(): void {
    this.createForm();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    if(localStorage.Delivery!="-1")
    this.levelDelivery=localStorage.Delivery;
    if(this.levelDelivery==3)
    this.orderButtom=true;
    else
    this.orderButtom=false;
 
    this.mapsAPILoader.load().then(() => { 
      var options = {
        componentRestrictions: {country: "IL"}
       }; 
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

       //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = ""+(place.geometry.location.lat());
          this.lng =""+(place.geometry.location.lng());
          this.Address=place.name+" "+place.vicinity;
          this.zoom = 12;
          this.isChangeAdress=1;
          this.delivery.Origin=this.Address;
        }); 
      });

      const autocomplete2 = new google.maps.places.Autocomplete(this.searchElementRef2.nativeElement,options);
      autocomplete2.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete2.getPlace();

       //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat2 = ""+(place.geometry.location.lat());
          this.lng2 =""+(place.geometry.location.lng());
          this.Address=place.name+" "+place.vicinity;
          this.zoom = 12;
          this.isChangeAdress=1;
          this.delivery.Destination=this.Address;
        }); 
      });
    });
  }

  limitLines(event: InputEvent, maxLines: number) {
    let text = (event.target as HTMLTextAreaElement).value;
    if (text.length > 0) {
      const lineCount = 1 + text.replace(/[^\n]/g, '').length;
      if (lineCount > maxLines) {
        const textArray = text.split('\n');
        const newText = textArray.reduce((result, line, lineNum, array) => {
          if (lineNum < maxLines) {
            return result.concat('\n').concat(line);
          }
          return result.concat(line);
        });
        (event.target as HTMLTextAreaElement).value = newText;
      }
    }
  }

  order()
  {
    if(sessionStorage.ACCESS_TOKEN  == "null")
    this.router.navigate(['../mainHome']);
    else
    {
    this.valid=true;
    if(this.deliveryForm.valid)
    {
    if(this.levelDelivery==0)
    {
      if(this.lat=="" || this.lat2=="")
this.proxy.showError("חובה לבחור כתובת מגוגל","");
      else
      {
      let dateFormat=new Date();
      var hours = this.delivery.DeliveryTime.substring(0, this.delivery.DeliveryTime.indexOf(":"));
      var minutes = this.delivery.DeliveryTime.split(":").pop();
     // var minutes = this.delivery.DeliveryTime.substring(1, this.delivery.DeliveryTime.indexOf(":"));
      dateFormat.setHours(Number(hours));
      dateFormat.setMinutes(Number(minutes));
    //   var datePipe = new DatePipe('en-US');
    // var dateTime = datePipe.transform(this.delivery.DeliveryTime, 'YYYY-MM-DDTHH:MM:SS');
    //   var moment = require('moment')
    // let dateNow = moment().format('YYYY-MM-DDTHH:MM:SS')
    //   let date=new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString()
      //this.proxy.getTimeSpan()
      this.orderService.setNewDelivery(this.delivery.Origin,this.delivery.Destination,this.datepipe.transform(dateFormat, "yyyy-MM-dd'T'HH:mm:ss"),this.delivery.Phone,this.delivery.Description).subscribe((res:any)=>{
        this.orderDelivery=res;
    if(this.orderDelivery==true)
      this.levelDelivery=1;
     
   // else if(localStorage.Delivery!=-1)
   // {
    
});}
}

else if(this.levelDelivery==1)
    {
      this.orderService.setDeliveryStatus(1).subscribe((res:any)=>{
        this.orderDelivery=res;
      if(this.orderDelivery==true)
   // this.levelDelivery=2;
   this.router.navigate(['../mainHome']);
    });
  }
    // else if(this.levelDelivery==2)
    //   this.orderService.setDeliveryStatus(3).subscribe((res:any)=>{
    //     this.orderDelivery=res;
    //     if(this.orderDelivery==true)
    //     this.router.navigate(['../mainHome']);
    //   });
   // }
   // else{
    //  this.router.navigate(["../mainHome"]);
   // }
  }}
  if(this.levelDelivery == 0 || this.levelDelivery==2)
  this.orderButtom=true;
  else
  this.orderButtom=false;
  }

  cancel()
  {
    this.valid=false;
    this.orderService.setDeliveryStatus(2).subscribe((res:any)=>{
      this.orderDelivery=res;
      if(this.orderDelivery==true)
    this.levelDelivery=0;
  });}

  createForm() {
    // this.deliveryForm = new FormGroup({
    //   nvCollectionAddress: new FormControl("", Validators.required),
    //   nvShippingAddress: new FormControl("", Validators.required),
    //   nvDeliveryTime: new FormControl("", Validators.required),
    //   nvPhoneInquiries: new FormControl("", Validators.required),
    //   nvShippingDescription: new FormControl("", Validators.required),
    // });

    this.deliveryForm = this.fb.group({ // create nested formgroup to pass to child
      nvCollectionAddress: [, [Validators.required]],
      nvShippingAddress: [, [Validators.required]],
      nvDeliveryTime: [, [Validators.required]],
      nvPhoneInquiries: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),

      // nvPhoneInquiries: [, [Validators.required]],
     // nvPhoneInquiries:[,[Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$'),Validators.required]],
      nvShippingDescription: [, [Validators.required]],
  });
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.deliveryForm.controls[fieldName].invalid ;
    // && (this.registerForm.controls[fieldName].dirty || this.registerForm.controls[fieldName].touched)
    ;
  }
}
