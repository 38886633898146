import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-error',
  templateUrl: './popup-error.component.html',
  styleUrls: ['./popup-error.component.scss']
})
export class PopupErrorComponent implements OnInit {
  message:string;
  innerWidth:any;

  constructor(private matDialog:MatDialog,@Inject(MAT_DIALOG_DATA) private data: {message: string}) { }

  ngOnInit(): void {
    this.message=this.data.message;
    this.innerWidth = window.innerWidth;
  }

  closeDialog()
  {
    this.matDialog.closeAll();
  }

}
