<div class="page" style="min-height: 575px; margin-bottom: 20px;">
    <div class="d-flex" style="margin-top: 20px; font-family: OpenSansHebrewBold">
    <div class=" d-flex m-auto widthHeader" style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
        <span class="col-5" style="text-align: right;" >ההזמנה שלך</span>
        <span  class="col-7" >{{totalPrice}} ₪</span>
    </div>
    </div>
    <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
    <div class=" d-flex m-auto widthHeader" style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
        <span class="col-5" style="text-align: right;" >דמי משלוח</span>
        <span  class="col-7" >{{deliveryPrice}} ₪</span>
    </div>
    </div>

    <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
        <div class=" d-flex m-auto widthHeader" style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
            <span class="col-5" style="text-align: right;" >סה״כ</span>
            <span  class="col-7" >{{order.Balance}} ₪</span>
        </div>
        </div>

    <div class="d-flex"  style="margin-top: 10px; margin-bottom: 10px;" >
        <img class="m-auto widthHeader" style="width: 73%; " src="assets/images/Pay_Page_Line.png">
    </div>
    <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto widthHeader" style="width: 61.5%; direction: rtl; text-align: right;font-family: OpenSansHebrewBold">
            <div class="col5"  style="font-size: 14px;">
                <img src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div class="caption post-content">
                    <span style="color: white;">1</span>
                </div>
                <span style="font-weight: bold;">משלוח לכתובת שלי</span>
                </div>
        <div class="align-self-center checkBox">
            <img (click)="myAddress()" style="cursor: pointer;background-size: contain; " [src]="myAddressImg">
        </div>
    </div>

    <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto widthHeader" style="font-family: OpenSansHebrewBold; width: 59%; direction: rtl; text-align: right;">
        <div class="myAddress" style="font-size: 14px; padding-right: 3.5rem">
           
            <span style="font-weight: bold;">{{myAddressText}}</span>
            </div>
    </div>
    <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto widthHeader" style="font-family: OpenSansHebrewBold; width: 59%; direction: rtl; text-align: right;padding: .35rem 0rem;">
        <div class="col5"  style="font-size: 14px; padding-right: 6.5rem"> 
            <span class="anotherAddress" style="font-weight: bold;">משלוח לכתובת אחרת</span>
            </div>
    <div>
        <img class="checkBox" (click)="anotherAddress()" style="cursor: pointer;background-size: contain;" [src]="anotherAddressImg">
    </div>
    </div>
    <div *ngIf="isAnotherAddress==2 && byGiftCard==false" class="container" style="margin-bottom: 5px; font-size: 14px;font-family: OpenSansHebrewRegular">
        <form class="form-signin" [formGroup]="addressForm" >
        <div class="bg-img">
            <div >
           <div class="d-flex">     
    <span style="padding-right: 2px;">רחוב כולל מספר בית</span>
           </div>
    <div class="d-flex">     
        <div class="map-container" >
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>   
          <input #search id="pac-input" style="margin-top: 5px;" (click)="openMap()"  [(ngModel)]="address.nvStreet" matInput formControlName="street" name="nvStreet"  [ngClass]="!isValidInputAddress('street') ? 'input-street' : 'input-street-error' "  type="text" required>       
    </div> 

    </div>
        <div>
        </div>
        <div>
            <div class="d-flex" style="margin-bottom: 5px;">
                <span class="col-4" style="padding-right: 2px;">כניסה</span>
                <span class="col-4" >קומה</span>
                <span class="col-4">דירה</span>
                </div>
                <div class="d-flex">
                    <input  matInput formControlName="nvEnter" style="margin-left: 25px;"   class="input-street"  type="text">
                    <input  matInput formControlName="nvfloor" style="margin-left: 25px;" class="input-street" type="number">
                    <input matInput formControlName="nvNumberHoush" class="input-street"  type="number">     
                </div>
                </div>
                <div class="settings_list"></div>
    </div> 
    </form >
    </div>
    
    <div class="container" style="height: 40%; font-size: 14px; font-family: OpenSansHebrewRegular">
        <mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
        align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
        <div *ngIf="byGiftCard==false && inCash==true" class="d-flex m-auto" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold">
            <div class="col1" style="font-size: 14px;">
                <img src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div class="caption post-content">
                    <span style="color: white;">2</span>
                </div>
                <span  style="font-weight: bold; padding-right: 2px;">תשלום במזומן</span>
                </div>
        <div  class="align-self-center">
            <img class="checkBox" (click)="cashPayment()" style="cursor: pointer;background-size: contain;" [src]="cashPaymentImg">
        </div>
        </div>

        <div *ngIf="onCredit==true" class="d-flex m-auto" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold ;">
            <div class="col5" style="font-size: 14px;">
               <img *ngIf="byGiftCard!=false" src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div *ngIf="byGiftCard!=false" class="caption post-content">
                    <span style="color: white;">2</span>
                </div>  
                <span style="font-weight: bold; " [ngClass]="byGiftCard==false? 'col3' :'col4'">תשלום באשראי</span>
                </div>
        <div  class="align-self-center checkBox">
            <img (click)="creditPayment()" style="cursor: pointer;background-size: contain;" [src]="paymentCreditImg">
        </div>
        </div>

        <div *ngFor="let p of payments ; let ind = index ">
            <div class="d-flex m-auto " style="direction: rtl; text-align: right;padding: .30rem 0rem; font-family: OpenSansHebrewBold">
                <div class="col5 paymentRight" style="font-size: 14px; padding-right: 5rem;">  
                    <span style="font-weight: bold;">{{p.text}}</span>
                    </div>
                <div class="align-self-center">
                <img class="checkBox" (click)="paymentsBy(ind)" style="cursor: pointer;background-size: contain;" [src]="p.imageNumber==1 ? paymentsOn : paymentsOff">
                </div>
                </div>
        </div>
        <div>
        <form *ngIf="isCreditPayment==true" class="form-signin" [formGroup]="paymentForm" style="font-family: OpenSansHebrewRegular; margin-right: 3px;">
    <div class="bg-img d-flex">
        <div class="col6" style="margin-right: 7px;">
        <div >
       <div class="d-flex">     
    <span style="margin-bottom: 5px; padding-right: 2px;">שם בעל הכרטיס</span>
       </div>
       <div class="d-flex">
        <input autocomplete="off"  matInput formControlName="nvCardholderName" [ngClass]="!isValidInput('nvCardholderName') ? 'input-street' : 'input-street-error' " type="text" required [(ngModel)]="methodsPayment.UserName">
    </div>
    </div>
    <div>
    <div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
    <span class="col-6" style=" padding-right: 2px;">מספר הכרטיס</span>
    </div>
    
    <div class="d-flex"> 
       
        <input   type="tel"  [(ngModel)]="methodsPayment.CardNumber" autocomplete="off"  matInput formControlName="nvCardNumber" 
        [ngClass]=" !isValidInput('nvCardNumber') ? 'input-street' : 'input-street-error' " required autofocus appCardNumberFormatter dir="ltr">
        <!-- <input autocomplete="off"
        pattern="[0-9]*" type="tel" [(ngModel)]="methodsPayment.CardNumber" (keydown.enter)="handleKeyDown()" matInput formControlName="nvCardNumber" 
        [ngClass]="!isValidInput('nvCardNumber') ? 'input-street' : 'input-street-error'" 
        required appCardNumberFormatter> -->

        
    </div>
    </div>
    <div style="margin-top: 5px;">
        <div class="d-flex">
            <span class="col-6" style="padding-right: 2px;">תוקף</span>
            <span>cvv</span>
            </div>
            <div style="display: flex">   
                <div class="d-flex">
                    <input #year inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="yy" style="margin-right: 3px;" (click)="clearYear()"  
                 [ngClass]=" !isValidInput('year') ? 'box' : 'box-error' " formControlName="year"/> 
                  <input #month inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="MM" style="margin-right: 3px;"
                  [ngClass]=" !isValidInput('month') ? 'box' : 'box-error' " formControlName="month" (click)="clearMonth()"/> 
                  </div>
                  <input #cvv inputmode="numeric" pattern="[0-9]*" type="tel" style="margin-right: 3px;" 
                  name="methodsPayment.card_cvv" [ngClass]=" !isValidInput('cvv') ? 'box' : 'box-error' " required formControlName="cvv"/> 
                </div> 
                                       
</div>
<div class="d-flex">     
<span style="margin-bottom: 5px; padding-right: 2px;">מספר תעודת זהות של בעל הכרטיס</span>
   </div>
   <div class="d-flex">
    <input autocomplete="off"  inputmode="numeric"
    pattern="[0-9]*" type="tel" matInput formControlName="identityNumber" [ngClass]="!isValidInput('identityNumber') ? 'input-street' : 'input-street-error' " type="text" required [(ngModel)]="methodsPayment.UserIdentity" name="methodsPayment.UserIdentity">
</div>  
<br>

      <img (click)="keepCard()" [src]="keepCardImg" style="cursor: pointer;">
      <span style="margin-right: 5px;">שמור את הכרטיס שלי</span>
      <br>

      <span *ngIf="paymentForm.valid==false"  style="color: #ff8585">יש להזין את כל פרטי האשראי</span>
    </div>
    </div>
    </form> 
    </div>
        <div class="keep" [class.isDisabled]="paymentForm.valid">
         
            <img *ngIf="(paymentForm.valid==false && isDelivery==0 && paymentBy!=0) || (paymentForm.valid==false && paymentBy==1)" src="assets/images/Delivery_Popup_Cancel_Button.png">   
            <img *ngIf="paymentForm.valid==true || (isDelivery!=0 && paymentBy!=1)" src="assets/images/Delivery_Popup_Ok_Button.png">             
            <span (click)="payment()" class="d-flex btn" style="justify-content:space-around; align-items: center; align-content: center; font-size: 14px; font-family: OpenSansHebrewRegular; width: 300px; color: white;" [ngStyle]="(paymentForm.valid==false && isDelivery==0 && paymentBy!=0) || (paymentForm.valid==false && paymentBy==1) && {'color':'#29c067'}">
            <span style=" margin-right: 20px; "> המשך לתשלום</span>
            <span style="margin-left: 20px;">{{order.Balance}} ₪</span>
        </span>
        </div>
    </div>
    </div>
    

    
    <!-- <div class="lds-dual-ring"></div> -->
  
    