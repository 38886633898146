import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { from } from 'rxjs';
import { OrderHistoryComponent } from '../app/components/order-history/order-history.component'
import { AppComponent } from './app.component';
import { CategoryComponent } from './components/category/category.component';
import { ContactComponent } from './components/contact/contact.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { LoginComponent } from './components/login/login.component';
import { MainHomeComponent } from './components/main-home/main-home.component';
import { PasswordComponent } from './components/password/password.component';
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { RegisterComponent } from './components/register/register.component';
import { ReservedRestaurantsComponent } from './components/reserved-restaurants/reserved-restaurants.component';
import { RestaurantCategoryComponent } from './components/restaurant-category/restaurant-category.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { ReviewComponent } from './components/review/review.component';
import { SearchComponent } from './components/search/search.component';
import { SecurePaymentComponent } from './components/secure-payment/secure-payment.component';
import { ShippingHistoryComponent } from './components/shipping-history/shipping-history.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { YourOrderComponent } from './components/your-order/your-order.component';
import { ToppingsComponent } from './components/toppings/toppings.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { GiftCardPaymentComponent } from './components/gift-card-payment/gift-card-payment.component';
import { LoginPhoneVarificationComponent } from './components/login-phone-varification/login-phone-varification.component';
import { GiftCardSucsessComponent } from './components/gift-card-sucsess/gift-card-sucsess.component';
import { CodeWorkerComponent } from './components/code-worker/code-worker.component';
import { EndOrderComponent } from './components/end-order/end-order.component';
import { MainListComponent } from './components/main-list/main-list.component';
import { MapComponent } from './components/map/map.component';
import { ApplicationStateService } from './services/application-state-service';
import { MenuComponent } from './components/menu/menu.component';
import { Login2Component } from './components/login2/login2.component';
import { LoginPhoneVarification2Component } from './components/login-phone-varification2/login-phone-varification2.component';
import { TermsOfUse2Component } from './components/terms-of-use3/terms-of-use3.component';
import { Password2Component } from './components/password2/password2.component';
import { PopupEnterComponent } from './components/popup-enter/popup-enter.component';
import { PopupEnter2Component } from './components/popup-enter2/popup-enter2.component';
import { VerificationCode2Component } from './components/verification-code2/verification-code2.component';
import { PaymentComponent } from './components/payment/payment.component';
import { Topping2Component } from './components/topping2/topping2.component';
import { Map2Component } from './components/map2/map2.component';
import { SectionsMetadata } from './services/sections_metadata';


const routes: Routes = [
  { path:'restaurant/:id',component:RestaurantComponent
   , data: {
  //   title: 'Title for First Component',
  //   descrption: 'Description of First Component',
  //   robots: 'noindex, nofollow',
  //   ogTitle: 'Description of First Component for social media',
   }
  // children: [ 
  // // { path: '', component: RestaurantComponent },
  // { path:'toppings' ,component:ToppingsComponent},
  // { path:'toppings2' ,component:Topping2Component}]
},
  { path:'restaurant',component:RestaurantComponent,},
  { path:'orderHistory', component: OrderHistoryComponent },
  { path:'mainHome', component: MainHomeComponent },
  { path:'category', component: CategoryComponent },
  { path:'reservedRestaurants',component: ReservedRestaurantsComponent},
  { path:'shippingHistory',component: ShippingHistoryComponent},
  { path:'paymentInfo',component: PaymentInfoComponent},
  { path:'delivery',component: DeliveryComponent},
  { path:'contact',component: ContactComponent},
  { path:'register', component:RegisterComponent },
  { path:'yourOrder',component:YourOrderComponent},
  { path:'search', 
    data: SectionsMetadata.meta,
    component:SearchComponent},
  { path:'password',component:Password2Component},
  { path:'login',component:Login2Component},
  { path:'loginPhoneVarification',component:LoginPhoneVarification2Component},
  { path:'review',component:ReviewComponent},
  { path:'payment',component:PaymentComponent,
  children: [ {
     path: '', component: PaymentComponent },
   { path:'map' ,component:MapComponent},]},
  { path:'securePayment',component:SecurePaymentComponent},
  { path:'termsUse',component:TermsOfUse2Component},


   { path:'restaurantCategory',component:RestaurantCategoryComponent},
  { path:'restaurantCategory/:id',component:RestaurantCategoryComponent},
  {path:'toppings',component:ToppingsComponent},
  {path:'toppings2',component:Topping2Component},
  {path:'giftCard',component:GiftCardComponent},
  {path:'giftCardPayment',component:GiftCardPaymentComponent},
  {path:'giftCardSucsess' ,component:GiftCardSucsessComponent},
  {path:'codeWorker' , component:CodeWorkerComponent},
  {path:'endOrder' ,component:EndOrderComponent},
  {path:'mainList' ,component:MainListComponent},
  {path:'map' ,component:MapComponent},
  {path:'map2' ,component:Map2Component},
  {path:'menu' ,component:MenuComponent},
  {path:'popupEnter' ,component:PopupEnter2Component},
  {path:'verificationCode' ,component:VerificationCode2Component },

  { path: '', pathMatch: 'full', component: AppComponent }
];


@NgModule({
 // imports: [RouterModule.forRoot(routes)],
// imports: [RouterModule.forRoot(routes, {useHash: true})],
//imports: [RouterModule.forRoot(routes, {useHash: true ,onSameUrlNavigation: 'reload'}),],
imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule {
 }
