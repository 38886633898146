import { Component, OnInit, ChangeDetectionStrategy, ViewChild, VERSION } from '@angular/core';
import { Category, CategoryItem, ListImages } from 'src/app/models/category';
import { ChangeFood, OpenningHours } from 'src/app/models/restaurant'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user-service';
import { BussinssService } from 'src/app/services/bussinss-service';
import { AppProxy } from 'src/app/services/app-proxy';
//import { SlickModule } from 'ngx-slick';
import { SliderComponent } from '../slider/slider.component';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { CarouselModule } from 'primeng/carousel';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder } from 'src/app/models/order';
import { PopupOrderComponent } from '../popup-order/popup-order.component';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  stars: number[] = [1, 2, 3, 4, 5];
  changeFood: ChangeFood[];
  responsiveOptions;
  innerWidth: any;

  constructor(private orderService: OrdersService, public gallery: Gallery, public lightbox: Lightbox, private proxy: AppProxy, config: NgbCarouselConfig, private userService: UserService, private bussinssService: BussinssService, private matDialog: MatDialog, private router: Router, private app: AppComponent , private metaService: Meta) {
    this.addTag();
    config.interval = 1500;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw new Error('Method not implemented.');
  }

  categoresItem: CategoryItem[];
  categoresItemLastMenu: CategoryItem[] = [];
  categores: Category[];
  currentIndex: number = 0;
  private activeIndex: number = 0;
  items: GalleryItem[];
  Rate = new Array<number>(3);
  imageData = data;
  priceTotal = "0-פריטים"
  price = 0;
  order: MyOrder;
  isClick = false;
  listImages: ListImages;
  imagesSlide: string[] = [];
  openningHours: OpenningHours[] = [];
  dayTime = "";
  showProgress = false;

  payment() {

  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    nextArrow: '<button class="btn-next" (click)="next()">next</button>\n',
    prevArrow: '<button class="btn-prev" (click)="prev()">prev</button>\n',
    arrows: true,
    dots: true,
  };

  public invokeAutoSuggest(ev: Event) {
  }

  public nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.categoresItem.length - 1 ? ++this.activeIndex : this.activeIndex;
  }
  public prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  addTag() {
    this.metaService.addTag({ name: 'description', content: 'Article Description' });
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:title', content: 'Content Title for social media' });
  }

  openDialog() {

    //if(localStorage.ACCESS_TOKEN  == "null" || localStorage.ACCESS_TOKEN  == null || localStorage.ACCESS_TOKEN  == "")
    // {
    // localStorage.ACCESS_TOKEN  = "null";
    // const dialogConfig = new MatDialogConfig();
    // this.matDialog.open(LoginComponent, { disableClose: true });
    // if(this.innerWidth<=480)
    //this.router.navigate(['../popupEnter' ,{ disableClose: true }] );
    //else
    //{
    //const dialogConfig = new MatDialogConfig();
    if(sessionStorage.dialog=="false" || sessionStorage.dialog==undefined)
    {
     sessionStorage.dialog="true"
    if(sessionStorage.deeplink=="")
    {
      //console.log("PopupEnterComponent");
      
   // this.matDialog.closeAll();
    if (this.innerWidth <= 480)
      this.matDialog.open(PopupEnter2Component);
    else
      this.matDialog.open(PopupEnterComponent);
    }
  }
    //}
  }

  imgSlide(pos: number) {
    if(sessionStorage.ACCESS_TOKEN  != undefined && sessionStorage.ACCESS_TOKEN  != "null"  &&  localStorage.SMS_CODE!="")
   {
     var fields = this.categoresItemLastMenu[pos].Header.split('=');
    let orderId = Number(fields[1]);
    this.bussinssService.getOpenningHours(orderId).subscribe((res: any[]) => {
        this.openningHours = res;
        let b = 0;
        for (let i = 0; i < this.openningHours.length; i++) {
          if (i == new Date().getDay()) {
            if (new Date().getHours() <= 5) {
              //console.log("i", i + new Date().getDay());
              if (i > 1) {
                i = i - 1
                b = 1;
              }
              else {
                i = 6;
                b = 7;
              }
            }
            
            if (this.openningHours[i] != null && this.openningHours[i].DayTime != '' && this.openningHours[i].DayTime != '0:00-0:00' && this.openningHours[i].DayTime != '00:00-00:00') {
              var fields = this.openningHours[i].DayTime.split('-');
              if (this.proxy.isOpen(fields[0], fields[1], b))
                this.dayTime = this.openningHours[i].DayTime;
              else
                this.dayTime = "סגור";
            }
            else
              this.dayTime = "סגור";
            i = this.openningHours.length;
          }
        }
      if (this.dayTime == "סגור")
        this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה", "");
      //this.openDialog(); 
      else {
        this.orderService.getOrderDetails(false).subscribe((res: any) => {
          this.order = res;
          localStorage.ID = orderId;
          // this.router.navigate(['../restaurantCategory']);
          if (this.order.OrderID == 0 && (this.order.list == null || this.order.list.length == 0)) {
      
            sessionStorage.CATEGORY_FILTER = "";
            this.bussinssService.getBusinessEnName(orderId).subscribe((res: any) => {
              let nameEn=res;
             
    
            let url: string = "/restaurant/" + nameEn;
            this.router.navigateByUrl(url);
            });
           // this.router.navigate(['../restaurant']);
          }
          else {
            this.matDialog.open(PopupOrderComponent, {
              data: { OrderID: this.order.OrderID },
            });
          }
        });
      }
    });
   }
   else
  {
   if (this.innerWidth <= 480)
      this.matDialog.open(PopupEnter2Component);
    else
      this.matDialog.open(PopupEnterComponent);
  }
  }

  more(i: number) {
    if (localStorage.SMS == "")
      this.proxy.showError("חובה להכניס קוד", "");
    // else if (sessionStorage.ACCESS_TOKEN == "null" || localStorage.SMS_CODE == "")
    //   this.proxy.showError("אינך רשום למערכת", "");
    else {
      this.bussinssService.restaurantCategoryList = this.categores[i].categoryItems;
      this.bussinssService.categoryName = this.categores[i].Name;
      localStorage.TEXT_SEARCH = "all";

      localStorage.ID = i;
      if (this.bussinssService.restaurantCategoryList.length > 0)
        this.router.navigate(['../../mainList'])
    }
  }

  ngOnInit(): void {
    window.scroll(0,0);
    localStorage.Category = "1";
    this.showProgress = true;
    this.innerWidth = window.innerWidth;
    if (sessionStorage.ACCESS_TOKEN == "null" || sessionStorage.ACCESS_TOKEN == null || sessionStorage.ACCESS_TOKEN == "") {
      sessionStorage.ACCESS_TOKEN = "null";
      //  if(this.innerWidth<=440)
      // this.router.navigate(['../login']);
      // this.router.navigate(['../popupEnter']);

      // else
    
     // this.openDialog();
    }
   // sessionStorage.deeplink='';
    //this.slides = this.chunk(this.cards, 4);
    localStorage.TEXT_SEARCH = "";
    this.categoresItemLastMenu = [];
    this.bussinssService.getListImages().subscribe((res: any) => {
      this.categores = res;
      if (this.categores != null && this.categores[0] != null)
        // for(let i=0;i<this.categores[0].categoryItems.length;i++)
        // {
        //   if(this.categoresItemLastMenu.length<4)
        //   this.categoresItemLastMenu.push(this.categores[0].categoryItems[i]);
        // }
        this.categoresItemLastMenu = this.categores[0].categoryItems;
      localStorage.TEXT_SEARCH = "";
      let page=1;
      this.bussinssService.getCategoryList(page).subscribe((res: any) => {
        this.categores = res;

      //for (let i = 2; i < 5; i++)
       //   if (i != 3)

          for (let i = 2; i < 8; i++)
           if (i != 3 && i != 5)  
              this.bussinssService.getMainList(i, 0, 1, Math.floor((Math.random() * 20) + 1)).subscribe((res: any) => {
                this.categores = res;
              });
   
        // });
      });
      this.showProgress = false;
    });
    window.scroll(0, 0);

    // this.changeFood=[
    //   {
    //     "ID":1,
    //     "Name":"ללא בוטנים",
    //     "Text":"לחץ לשינויים ",
    //     "toppings":[]
    //   },
    //   {
    //     "ID":2,
    //     "Name":"ללא כוסברה",
    //     "Text":"לחץ לשינויים ",
    //     "toppings":[]
    //   },
    //   {
    //     "ID":3,
    //     "Name":"ללא בצל ירוק",
    //     "Text":"לחץ לשינויים ",
    //     "toppings":[]
    //   },
    //   {
    //     "ID":4,
    //     "Name":"תוספת איטריות",
    //     "Text":"לחץ לשינויים ",
    //     "toppings":[]
    //   },
    // ]

    this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));


    /** Lightbox Example */

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);
  }
}
const data = [
  {
    srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
    previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
    previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
    previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
    previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
  }
];
