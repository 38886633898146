import { Component, OnInit ,EventEmitter ,Input ,Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { OrdersService } from 'src/app/services/order-service';
import { UserService } from 'src/app/services/user-service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { AppComponent } from 'src/app/app.component';
import { CategoryComponent } from '../category/category.component';
import { DataService } from 'src/app/services/data.service';
import { MyOrder } from 'src/app/models/order';
import { Restaurant } from 'src/app/models/restaurant';
import { DialogOutOfBoundsComponent } from '../dialog-out-of-bounds/dialog-out-of-bounds.component';
import { CategoryItem } from 'src/app/models/category';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { PopupLogOutComponent } from '../popup-log-out/popup-log-out.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  searchText:String;
  text: string;
  @Output()change: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  logInOrOut:string;
  price;
  widthScreen;
  priceTotal="0 - פריטים";
  order:MyOrder;
  show=true;
  header=true;
  show400=true;
  searchByFood=false;
  showSearch=false;
  restaurant:Restaurant=new Restaurant;
  animal: string;
  name: string;
  innerWidth: any;
  restaurantsItem:CategoryItem[];
  showProgress=false;
  token="";
  clickPayment=false;
  deepLink=false;


  constructor( private router: Router,private proxy:AppProxy,private dataService:DataService,private matDialog:MatDialog,private bussinssService: BussinssService,private orderService:OrdersService,private userService:UserService) {}

  ngOnInit(): void {
    this.deepLink = this.dataService.deepLink.getValue() || {};
    this.dataService.deepLink.subscribe(data=>{
      this.deepLink=data;
    });
    this.text = this.dataService.text.getValue() || {};
    this.dataService.text.subscribe(data=>{
      this.text=data;
    });
    this.clickPayment = this.dataService.clickPayment.getValue() || {};
    this.dataService.clickPayment.subscribe(data=>{
      this.clickPayment=data;
    });

    this.token = this.dataService.token.getValue() || {};
    this.dataService.token.subscribe(data=>{
      this.token=data;
    });
    this.searchByFood = this.dataService.byFood.getValue() || {};
    this.dataService.byFood.subscribe(data=>{
      this.searchByFood=data;
    });
    this.showSearch = this.dataService.showSearch.getValue() || {};
    this.dataService.showSearch.subscribe(data=>{
      this.showSearch=data;
    });

    
  this.logInOrOut = this.dataService.state.getValue() || {};
  this.dataService.state.subscribe(data=>{
    this.logInOrOut=data;
    // if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
    // this.logInOrOut="התחבר";
    // else
    // this.logInOrOut="התנתק";
  });
  this.priceTotal = this.dataService.price.getValue() || {};
  this.dataService.price.subscribe(data=>{
    this.priceTotal=data;
  });

  this.show = this.dataService.show.getValue() || {};
  this.dataService.show.subscribe(data=>{
    this.show=data;
  });

  this.header = this.dataService.show.getValue() || {};
  this.dataService.header.subscribe(data=>{
    this.header=data;
  });

 // this.show=localStorage.SHOW;
  // if(location.hash=="#/restaurant")
  // this.show=true;
  // if(location.hash=="#/yourOrder")
  // this.show=false;
    this.widthScreen=this.widthScreen;
   // this.price=AppComponent.price;
   // this.priceTotal==AppComponent.priceTotal;
    this.bussinssService.getRestaurantDetails(localStorage.ID,false,false,false,null,null).subscribe((res:any)=>{
      this.restaurant=res;
    });
    // if(localStorage.ACCESS_TOKEN  == "null")
    // this.logInOrOut="התחבר";
    // else
    // this.logInOrOut="התנתק";
  }

  connect(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    this.router.navigate(['../register']);
  }

  orderHistory()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../orderHistory']);
  }

  shippingHistory(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../shippingHistory']);
  }

  paymentInfo(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../paymentInfo']);
  }

  reservedRestaurants(){
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../reservedRestaurants']);
  }

  delivery()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../delivery']);
  }

  search()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
   this.openEnterDialog();
    else
    this.router.navigate(['../search']);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    // this.matDialog.open(LoginComponent, { disableClose: true });
    this.matDialog.open(LoginComponent, { disableClose: true });
  }

  openEnterDialog() {
    if(this.innerWidth<=480)
    this.matDialog.open(PopupEnter2Component, { disableClose: true });
    else
    this.matDialog.open(PopupEnterComponent, { disableClose: true });
  }

  openOutOfBoundsDialog(): void {
    const dialogRef = this.matDialog.open(DialogOutOfBoundsComponent, {
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.matDialog.closeAll();
      this.animal = result;
    });
  }

  payment()
  {
    // let page=location.href;
    // let a=page.split('/')[3]
    let a = location.pathname;
    if(this.clickPayment==true)
    if(a.startsWith("/restaurant"))
    {
      this.showProgress=true;
      this.clickPayment=false;
      this.orderService.restaurant=this.bussinssService.restaurant;
   // if(this.priceTotal!="0 - פריטים")
   //|| this.orderService.order.TotalPrice!=null )
   //naomi change החזרתי מקור
   if (this.priceTotal!="0 - פריטים") 
    {
      let canContinue = true;
           this.orderService.getOrderDetails(false).subscribe((res:any)=>{
       this.order=res;
     // this.order=this.orderService.order;
      if(this.order!=null && this.order.list!=null)
      for(let i=0;i<this.order.list.length;i++)
      {
        let orderList=this.order.list[i];
         // for(let j=0;j<this.restaurant.MenuList.length;j++) {
            //  let categoryItemFood = this.restaurant.MenuList[j];
              if (orderList.IsAdditionRequired == 1)
              canContinue = false;
              if(orderList.listAddition.length>0)
              canContinue = true;
                  //if (orderList.MenuID == categoryItemFood.ID)
                    //  if(categoryItemFood.IsAdditionRequired==1) {
                  
                      //    if (orderList.listAddition.length==0) {
                            
                            //  j=this.restaurant.MenuList.length;
                        //  }
                    // }
      //    }
      }
      if(canContinue==true)
      {
        this.show=false;
        this.dataService.show.next(true);
        this.changeShowPrice.emit();
        this.showProgress=false;
       // this.clickPayment=false;
        this.clickPayment=true;
        this.router.navigate(["../yourOrder"]);
       
      }
    else
    {
      this.showProgress=false;
    this.proxy.showError("לא בחרת \n את כל תוספות החובה","");
    }
    });
}
    else
    {
      this.showProgress=false;
    this.proxy.showError("ההזמנה ריקה","");
    }
}
else if(a=="/yourOrder")
{
  // this.router.navigate(['../mainHome']);
  if(localStorage.DELIVERY==0)
  this.proxy.showError("עליך לבחור אחת  מאפשרויות השירות: \nמשלוח, איסוף עצמי או ישיבה במקום","");
  else 
  {
   
  if(localStorage.NOTE=="" || localStorage.NOTE==undefined)
  localStorage.NOTE=null;
  let delivery=0;
  let pickUp=0;
  let sitting=0;
  if(localStorage.DELIVERY==1)
  delivery=1;
  else if(localStorage.DELIVERY==2)
  pickUp=1;
  else if(localStorage.DELIVERY==3)
  sitting=1;
  if(Number(delivery)==1 && this.bussinssService.restaurant.MinimumOrder!='' && Number(this.order.TotalPrice) < Number(this.bussinssService.restaurant.MinimumOrder))
  {
    this.proxy.showError("לא הגעת למינימום הזמנה של "+this.bussinssService.restaurant.MinimumOrder+" ₪"+"\n(ללא דמי משלוח)","");
  }
  else
  {
  if(this.order==null)
{
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    this.showProgress=true;
    let note=localStorage.NOTE;
    if(note!=null && note!="")
    {
        note=note.replace('####', '');
        note=note.replace('###', '');
        note=note.replace('##', '');
        note=note.replace('#', '');
  }
    this.orderService.setUpdateOrder1(this.order.OrderID,Number(delivery),Number(pickUp),Number(sitting),1,note,1).subscribe((res:any)=>{
      if(res.error==false)
      {
        //localStorage.ADDRESS="";
        localStorage.paymentBy=1;
        localStorage.isClickPayment="false";
        localStorage.phone="";
        localStorage.orderName="";
        localStorage.email="";
        localStorage.street="";
        localStorage.enter="";
        localStorage.floor="";
        localStorage.numberHoush="";
        localStorage.nvCardNumber="";
        localStorage.cvv="";
        localStorage.date="";
        localStorage.identityNumber="";
        localStorage.nvCardholderName="";
            // localStorage.paymentBy="";
            localStorage.ADDRESS2="";
            localStorage.LAT2="";
            localStorage.LONG2="";
            localStorage.ADDRESS="";
            localStorage.LAT="";
            localStorage.LONG="";
            this.showProgress=false;
        if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
        this.router.navigate(["../payment"]);
        else
      this.router.navigate(["../securePayment"]);
      }
      else if(res.error==true && res.responseMessage=="the delivery out of bounds")
      {
        this.showProgress=false;
      this.openOutOfBoundsDialog();
      }
      });
});
}
 else if(this.order!=null &&  this.order.OrderID>0)
  {
    this.showProgress=true;
    let note=localStorage.NOTE;
    if(note!=null && note!="")
    {
        note=note.replace('####', '');
        note=note.replace('###', '');
        note=note.replace('##', '');
        note=note.replace('#', '');
  }
    this.orderService.setUpdateOrder1(this.order.OrderID,Number(delivery),Number(pickUp),Number(sitting),1,note,1).subscribe((res:any)=>{
      if(res.error==false)
      {
        //localStorage.ADDRESS="";
        localStorage.paymentBy=1;
        localStorage.isClickPayment="false";
        localStorage.phone="";
        localStorage.orderName="";
        localStorage.email="";
        localStorage.street="";
        localStorage.enter="";
        localStorage.floor="";
        localStorage.numberHoush="";
        localStorage.nvCardNumber="";
        localStorage.cvv="";
        localStorage.date="";
        localStorage.identityNumber="";
        localStorage.nvCardholderName="";
            // localStorage.paymentBy="";
            localStorage.ADDRESS2="";
            localStorage.LAT2="";
            localStorage.LONG2="";
            localStorage.ADDRESS="";
            localStorage.LAT="";
            localStorage.LONG="";
            this.showProgress=false;
        if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
        this.router.navigate(["../payment"]);
        else
      this.router.navigate(["../securePayment"]);
      }
      else if(res.error==true && res.responseMessage=="the delivery out of bounds")
      {
        this.showProgress=false;
      this.openOutOfBoundsDialog();
      }
      });
}
    
  else
  {
    this.showProgress=false;
 // this.proxy.showError("ההזמנה שלך ריקה","");
  }
}
  }
}
// else
// {
//   this.show=false;
//   this.dataService.show.next(false);
//   this.changeShowPrice.emit();
//   this.router.navigate(["../yourOrder"])
// }
  }

  inOrOut()
  {
    if(sessionStorage.ACCESS_TOKEN != "null" &&  localStorage.oneTimeOrderOrOrder!='oneTimeOrder')
    {
      this.matDialog.open(PopupLogOutComponent, {
      });
    }
    else{
      this.innerWidth = window.innerWidth;
      if( localStorage.oneTimeOrderOrOrder!='oneTimeOrder')
      {
       // if(this.innerWidth<=480)
    //this.router.navigate(["../popupEnter"])
    //else
    this.openEnterDialog();
      }
    else
    {
     // if(this.innerWidth<=480)
   // this.router.navigate(["../login"])
    //else
    this.openDialog();
    }
    }
  }


  // editProfil()
  // {
  //   if(localStorage.ACCESS_TOKEN  == "null")
  //   this.proxy.showError("אינך רשום למערכת","");
  //   else
  //   this.router.navigate(['../register']);
  // }
  @HostListener('keydown', ['$event']) onKeyDown(e) {
    if ((e.which == 13 || e.keyCode == 13)) {
        e.preventDefault();
        if (e.srcElement.nextElementSibling) {
            e.srcElement.nextElementSibling.focus();
        }
        else{
            //console.log('close keyboard');
            this.proxy.hideKeyboard(e);
        }
        return;
    }
  }

  searchByTextFood()
  {
    if(this.text && this.text!="" && this.searchByFood==true )
    {
      let id;
      if(this.bussinssService.restaurant.ID!=undefined)
      id=this.bussinssService.restaurant.ID;
      else
      id=localStorage.ID;
     localStorage.ID=this.bussinssService.restaurant.ID;
      this.bussinssService.getBusinessMenuBySearch(id,this.text,1).subscribe((res:any[])=>{
        this.restaurant.MenuList=res;
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        if(this.restaurant.MenuList==null || this.restaurant.MenuList.length==0)
        {
          this.showProgress=false;
          this.proxy.showError("לא נמצאו מנות\n במסעדה זו","");
          this.text="";
        }
        else
        {
     localStorage.TEXT_SEARCH=this.text;
   //  localStorage.ID="0";
     this.dataService.search.next(this.text);
     this.change.emit();
     localStorage.ID=id;
     this.bussinssService.getBusinessEnName(id).subscribe((res: any) => {
      let nameEn=res;
      this.dataService.endToppings.next(true);
      this.change.emit();
    let url: string = "/restaurant/" + nameEn;
     this.router.navigateByUrl(url);
    this.text="";
    });
  }
  });
}
  }

  searchByText()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    if(this.text && this.text!="" && this.searchByFood==false)
    {
      this.showProgress=true;
      this.searchByFood=true;
     this.bussinssService.getMenuBySearch(this.text,1,false).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if(this.restaurantsItem.length==0)
      {
        this.showProgress=false;
        this.searchByFood=false;
        this.proxy.showError("לא נמצאו עסקים\n בקטגוריה זו","");
        this.text="";
      }
      else
      {
           this.dataService.search.next(this.text);
           this.change.emit();
           localStorage.TEXT_SEARCH=this.text;
           localStorage.TEXT=this.text;
           localStorage.ID="0";
           localStorage.ID_CATEGORY="0"
           this.showProgress=false;
           let url: string = "/restaurantCategory/" + this.text
           this.router.navigateByUrl(url);
           this.text="";
           //this.router.navigate(['../restaurantCategory']);  
      } 
     });
    }
  }

  mainHome()
  {
    // if(localStorage.SMS=="")
    // this.proxy.showError("חובה להכניס קוד","");
    // else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    //  this.proxy.showError("אינך רשום למערכת","");
    //  else
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
 this.router.navigate(['../mainHome']);
  }

  menu()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    this.dataService.showSearch.next(false);
    this.changByFood.emit();
    this.priceTotal="0 - פריטים";
    localStorage.PRICE=0;
    localStorage.PRICE_TOTAL=this.priceTotal;
    this.dataService.price.next(this.priceTotal);
    this.changePrice.emit();
    // if(localStorage.SMS=="")
    // this.proxy.showError("חובה להכניס קוד","");
    // else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    //  this.proxy.showError("אינך רשום למערכת","");
    //let page=location.href;
   // let a=page.split('/')[3]
    let a=location.pathname;
     if(a=="/menu")
    window.history.back();
      else
    this.router.navigate(['../menu']);
  }
}
