import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { MyOrder } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { isUndefined } from 'util';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupGiftcardComponent } from '../popup-giftcard/popup-giftcard.component';
import { DialogOutOfBoundsComponent } from '../dialog-out-of-bounds/dialog-out-of-bounds.component';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  selector: 'app-code-worker',
  templateUrl: './code-worker.component.html',
  styleUrls: ['./code-worker.component.scss']
})
export class CodeWorkerComponent implements OnInit {
  stars:number[]=[1,2,3,4,5];
  orderFinal:boolean=true;
codeWorker:string=null;
order:MyOrder;
animal: string;
name: string;

  constructor(private matDialog:MatDialog,private router: Router ,private proxy:AppProxy,private orderService:OrdersService){}

  ngOnInit(): void {
  }

  star()
  {

  }

  continue()
  {
    if(this.codeWorker!="" && this.codeWorker!=null)
    {
      this.openLoader();
      //this.proxy.showError("קוד שגוי","");
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        let price=this.order.TotalPrice;
       // this.router.navigate(['../restaurantCategory']);
        if(this.order.OrderID!=0) 
        {
          this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            this.order=res;
            let BudgetSum=Number(this.order.BudgetSum);
            let status=1;
            let price=Number(this.order.TotalPrice);
            if(this.order.IsDelivery==1)
            price+=Number(this.order.DeliveryPrice)
           // let price=Number(this.order.Balance);
            if(BudgetSum>Number(price))
            status=2;
            if(BudgetSum>0)
            {
              let IsFromLink=0;
              if(sessionStorage.deeplink==this.order.RestaurantID && status==2)
              IsFromLink=1;
              this.orderService.setUpdateOrderEmploye(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,
                status,null,this.order.IsAddress,this.order.Address,this.order.Lat+"",this.order.Lng+"",
                1,this.codeWorker,IsFromLink).subscribe((res:any)=>{
                  if(res.error==false)
                {
                  localStorage.MENU_ID=0;
  
                  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
                    this.order=res;
                  //let price=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
                 // let price=Number(this.order.Balance)
                  if(BudgetSum>Number(price) || Number(this.order.Balance)<=0)
                  this.router.navigate(['../endOrder']);
                  else
                  {
                   this.matDialog.closeAll();
                    let iPay=Number(Number(price)-Number(this.order.Balance));
                  this.matDialog.open(PopupGiftcardComponent, {
                    data: { price: price ,price2:iPay , payBy:"קוד עובד"},
                });
              }});
                }
                  else if(res.error==true && res.responseMessage=="the delivery out of bounds")
                  {
                      this.matDialog.closeAll();
                      this.openOutOfBoundsDialog();
                  }
                  else{
                    this.matDialog.closeAll();
                    this.codeWorker="";
                    this.proxy.showError("קוד עובד שגוי","");
                  }
          });
        }
          else
          {
            this.matDialog.closeAll();
          this.proxy.showError("אין לך יתרה בקוד עובד","");
          }
          });
         
        
          }
          else
          {
            this.matDialog.closeAll();
          this.proxy.showError("ההזמנה שלך ריקה","");
          }
    });
  }
    else
    this.proxy.showError("חובה למלא קוד עובד","");
  }

  cancel(){
    window.history.back();
  }

  openLoader()
  {
    this.matDialog.open(LoaderComponent, { disableClose: true });
  }

  openOutOfBoundsDialog(): void {
    const dialogRef = this.matDialog.open(DialogOutOfBoundsComponent, {
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.matDialog.closeAll();
      this.animal = result;
    });
  }
}

