import { Component, OnInit ,EventEmitter ,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderHistory } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';
import { DataService } from 'src/app/services/data.service';
import { OrdersService } from 'src/app/services/order-service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  orders:OrderHistory[]= new Array();
  filteredItems:OrderHistory[]= new Array();
  searchText:String;
  text: string;

  constructor(private proxy: AppProxy ,private route: ActivatedRoute,private orderService:OrdersService ,private dataService:DataService) { }

  assignCopy(){
    this.filteredItems = Object.assign([], this.orders);
 }

  filterItem(value){
    this.orderService.GetHistoryOrders(value).subscribe((res:any)=>{
      this.orders=res;
      this.assignCopy();
    });
 }

  ngOnInit(): void {
    this.proxy.scrollToTop();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.orderService.GetHistoryOrders(null).subscribe((res:any)=>{
      this.orders=res;
      this.assignCopy();
  });
  }
}