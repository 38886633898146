

  
  <!-- <div class="body" style="font-family: OpenSansHebrewBold"> -->
   
<!--       
        <iframe  *ngIf="showsIframe"style="height: 700px ; width: 100%; " width="100%" frameBorder="0" [src]="urlSafe" scrolling="yes">
       
        </iframe> -->
     
        <!-- <div class="keep"  (click)="ok()" style="margin-top: 160px; left: 25%; background-color: aquamarine;">
          <img src="assets/images/Delivery_Popup_Ok_Button.png">               
          <span style="font-size: 14px;" class="btn">מגניב(:</span>
        </div> -->
        <!-- <div class="body" style="font-family: OpenSansHebrew;  display: flex;
       margin-top: 30%;
        justify-content: center;  flex-flow: column;">
        <span style="margin-right: 5px; font-size: 40px;">{{title}}</span>
        <span style="margin-right: 5px; font-size: 20px; font-weight: normal; margin-top: 7%;">{{myText}}</span>
      </div>
        <div *ngIf="showsButton" (click)="backPress()" class="button" style="cursor: pointer; ">להמשך</div>
      </div> -->
     
    <!-- </div> -->
    
    <div class="page" >
      <div class="d-flex flex-wrap container" style="justify-content : center; font-family: OpenSansHebrewBold ; margin-top: 3px; " [style.height]="width<=480?Height :Height1 ">
              <div class="d-flex flex-wrap"  style="justify-content : center; width: 100%; color:#41c777 ;margin-top: 8px; font-size: 40px; font-weight: bold;  " scrolling="no">
                <iframe  class="pag" *ngIf="showsIframe" style="height: auto ; width: 100%;  " width="100%" frameBorder="0" [src]="urlSafe" scrolling="yes" [style.height]= "width<=480?iframeHeight : iframeHeight1 " >
       
                </iframe> 
                <div class="d-flex DivBtn"    >
                <div class="d-flex keep" style="align-self: center; " (click)="ok()"  >
                                
                    <!-- <span style="font-size: 32px;" class="btn">מגניב(:</span>
                  </div>  -->

                  <!-- <div class=" d-flex backBtnDiv" (click)="ok()"> -->
                    <span class="backBtn " >חזרה לתפריט הראשי</span>
                </div>
                  </div>
                  <!-- <div class="d-flex" style="position: absolute; right: 20%;" > -->
     
               </div>  
              </div>
               <br>  
              
                 <!-- <div> -->
                  <!-- <div  class="left " style="bottom: 45%;" >                       -->
                      <!-- <div class="keep"  (click)="ok()">
                       <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                       <span style="font-size: 14px;" class="btn">אישור</span>
                     </div> -->
      
                     <!-- <div class="left keep" (click)="ok()" style=" margin-top: 12px;font-family: OpenSansHebrewRegular; width: max-content;">
                      <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                      <span class="btn">שלח</span> -->
          
              <!-- </div>  -->
               <!-- </div>     -->
             
                 <!-- </div>                     -->
          <!-- </div>  -->
        </div>



