<div class="fontSize" style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold; font-size: 18px;">
    <form class="form-signin"> 
      <div class="form-group">
      <div class="header" style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;" >יציאה</div>
      <br>
      <br class="br">
 
        <div class="body" style="padding: 15px; text-align: center; font-size: 16px;
        color: black;"> האם אתה בטוח שברצונך להתנתק מהאתר?</div>
                <br> 
                  <div  class="btm" style="font-size: 15px;">
                    <p (click)="cancel()"  class="p" style="cursor: pointer;color: #41c777; font-weight: bold ;">ביטול</p>
                    <p (click)="ok()"  class="p2">אישור</p>
                  </div>              
                  </div>         
    </form>
  </div>





