<div class="page">
  <mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
  align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
 <app-top-header-green style="font-family: OpenSansHebrewBold;" customTitle={{categoryName}} ></app-top-header-green>
        <div class="d-flex flex-wrap m-auto width" >
                <div class="d-flex flex-wrap" style="font-family: OpenSansHebrewRegular; direction: rtl;"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onUp()">
                   <div *ngFor="let i of restaurantsItem; let ind = index" class="listLocation">
              <app-category-item  (change)="ngOnInit()" (click)="onClickCategory(i.RstaurantID)"  style="cursor: pointer;" [categoryItem]="i" [category]="category" [indexList]="ind" [restaurantCategory]="8"></app-category-item>
                </div>
            </div>
            </div>