<div class="borderGreen" style="background-image: url(assets/images/BG_2.png);
background-size: contain; height: 556px;">

    <form class="form-signin">   
        <div style="text-align: center; ">
            <img src="assets/images/Popup_Logo.png">
            <br>
            <br>
            <span style="color: #41c777; font-size: 20px; font-weight:bold ;" >הכנס קוד לתשלום</span>
            <br>
            <br>
            <input class="container" name="codeWorker" [(ngModel)]="codeWorker" style="color: black;">
      </div>
                  <br>
                    <div  class="btm" style="margin-top: 30%; font-size: 17px;">
                      <p (click)="continue()" class="p" style="cursor: pointer;color: #29c066; font-weight: bold ;">אישור</p>
                      <p (click)="cancel()" class="p" style="left: 20% ; right: auto !important; cursor: pointer;color: #29c066; font-weight: bold ;">ביטול</p>
                    </div>          
    </form>
</div>

