<div #border class="container" >
  <div  class="container2"  [style.height]="Height" >
  <agm-map #map [latitude]="lat_" [longitude]="long" [zoom]="zoom" (centerChange)="centerChanged($event)"  (mapReady)="onMapReady($event)"  >
    <agm-polygon [visible]="true" [paths]="paths" [draggable]="true" [strokeColor]="options.fillColor" [strokeWeight]="2" [fillColor]=options.fillColor  [fillOpacity]="0.3">
    </agm-polygon>
    
  </agm-map>

  <div class="d-flex">     
      <input style="position: fixed;" #search id="pac-input" (blur)='onBlur()' (focus)="delete()" name="address" [(ngModel)]="Address.nvStreet" matInput  class="input-street place"  type="text" required>       
  </div>   
  <div class="custom-marker">
    <img src="assets/images/map_point.png" alt="Custom Marker" />
  </div>
  </div>
    <span (click)="select()" class="select">בחר</span>
  </div> 