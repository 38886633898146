<div class="wrapper" style="font-family: OpenSansHebrewBold">
    <form *ngIf="getNewPassword==false" class="form-signin"  [formGroup]="newPasswordForm"> 
        <div class="font" style="text-align: center; margin-top: 40px; font-size: 18px;">
      <span > .sms הזן את הקוד שנשלח אליך ב</span>


      <span >הקוד פג תוקף תוך 30 שניות </span>
      </div>
      <div class="d-flex" style="margin-top: 75px;">
        <div class="code" style="width: 35px; margin-left: 42px;" >
            <div class="txt-input" style="width: 35px; align-items: center;" >
                <input #code1 type="tel" maxlength="1" pattern="\d*"  formControlName="code1" style="max-width: 35px; margin-top: 5px;font-size: 16px;
                font-weight: normal; text-align: center; height: 30px;" [style.color]="'white'" required>
            </div>
     
                <div class="txt" style="width: 35px;"></div>
                </div>
                <div style="width: 35px; margin-left: 5%;" >
                    <div class="txt-input" style="width: 35px;">
                        <input #code2 maxlength="1"
                         type="tel"  formControlName="code2" pattern="\d*" style="margin-top: 5px; font-size: 16px;
                        font-weight: normal; text-align: center; height: 30px;" [style.color]="'white'" required>
                    </div>
            

                        <div class="txt" style="width: 35px;"></div>
                        </div>
                        <div style="width:35px; margin-left: 5%;" >
                            <div class="txt-input" style="width: 35px;" >
                                <input maxlength="1" #code3 type="tel" pattern="\d*" formControlName="code3" style="margin-top: 5px;font-size: 16px;
                                font-weight: normal; text-align: center; height: 30px;" [style.color]="'white'" required>
                            </div>
                        
                            
                                <div class="txt" style="width:35px;"></div>
                                </div>
                                <div style="width: 35px; margin-left: 5%;" >
                <div class="txt-input" style="width: 35px;" >
                    <input  type="tel"  #code4 maxlength="1" pattern="\d*" formControlName="code4" (input)="newPassword()" style="margin-top: 5px;font-size: 16px;
                    font-weight: normal; text-align: center; height: 30px;" [style.color]="'white'" required>
                </div>
        


                <div class="txt" style="width: 35px;"></div>
                </div>
            </div>
         
            <div class="d-flex" style="margin-top: 15px;">
                <img class="icon" (click)="reSendSmsCode()" style="width: 10px;
                margin-top: 5px;" src="assets/images/Left_Arrow.png">
                        <span class="again" (click)="reSendSmsCode()" style="color: #334a3c; margin-right: 15px;
                        font-size: 15px;  position: absolute; left: 44%; cursor: pointer;"  [ngStyle]="error==2 && {'color': 'pink' }"><u>{{notGetSendAgain}}</u></span>
            </div>


                    <div (click)="newPassword()" class="btm" style="background-image: url(assets/images/Signin_Button_1.png);cursor: pointer; margin-top: 25%; cursor: pointer">
                      <p (click)="newPassword()" class="p" style="cursor: pointer; color: #29c066; cursor: pointer; font-size: 14px;" [class.isDisabled]="newPasswordForm.valid">שלח</p>
                    </div>          
    </form>
    </div>
