import { Injectable } from "@angular/core";
 import { DatePipe } from '@angular/common';
import {HttpHeaders, HttpHandler, HttpEvent,HttpClient ,HttpEventType}
    from '@angular/common/http';
import 'rxjs';
import { Observable, throwError } from 'rxjs';
import { Restaurant } from "../models/restaurant";
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from "@angular/material/dialog";
import { PopupErrorComponent } from "../components/popup-error/popup-error.component";
import { LoaderComponent } from "../loader/loader.component";

 export enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Delete = "DELETE",
    Options = "OPTION",
    Head = "HEAD",
    Patch = "PATCH"
}

@Injectable()
export class AppProxy{
//baseQAUrl="https://test.baliapp.co.il/api/"
 // bitUrl="http://localhost:8080/gama-pay/"
  bitUrl="http://localhost:4200/gama-pay/"
 baseQAUrl = 'https://baliapp.co.il/api/';
  public fileServer='https://cp.baliapp.co.il/files/'
  public static User='User/';
  public static Bussinss='Bussinss/';
  public static waitVisible = true;
  static formatsDateTest:String='dd/MM/yyyy HH:mm:ss';
  static dateNow : Date;
  static dateNowISO;
  static dateNowMilliseconds;
  headers:Map<String, String>;
  map: Map<String, String>;
  SECRET_KEY='BaliappgCSi3yGvwu5pqvYGu6gGRYcf6U6JvRkHgQ5NtZaW';
  // SECRET_KEY='StadicomAagjJQMwDicFRa0tqzY1hhuN2ieCQ6';
   //=V@gb.cvb
  ACCESS_TOKEN=null;
  showProgress=false;

  regexIso8601 = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2}))?)?)?)?$/;
  a;
  static datePipe_;
  static pipe = new DatePipe('en-US'); // Use your own locale
  static now = Date.now();
  myFormattedDate = AppProxy.pipe.transform(AppProxy.now, 'dd/MM/yyyy HH:mm:ss');
  md5_ = require('md5') 
  num=0;

  constructor(private http: HttpClient,private toastr: ToastrService,private matDialog:MatDialog) {
  }

  scrollToTop(): void {
    setTimeout(() => window.scroll(0, 0), 0);
  }

   getBaseUrl() {
      return this.baseQAUrl;
  }

  public static getTimeSpan():String
  {
   return this.datePipe_.transform(this.dateNow, 'yyyy-MM-dd T HH:mm:ss')
  }

  //create request headers
  private createRequestHeader_() {
    let headers = new HttpHeaders();
    headers = headers.append("RequestTimeStemp",this.myFormattedDate);
    this.a=this.md5_(this.SECRET_KEY + this.myFormattedDate);
     headers = headers.append("RequestTimeStempHash",this.a);
     if(sessionStorage.ACCESS_TOKEN !=null)
     headers =headers.append('accessToken',  sessionStorage.ACCESS_TOKEN )
    return headers;
  }

  showHTMLMessage(message, title){
    this.toastr.success(message, title, {
      enableHtml :  true,
    })
  }

  showError(message, title){
   // this.matDialog.closeAll();
    this.matDialog.open(PopupErrorComponent, {
      data: { message: message },
    });
    //this.toastr.clear();
   // this.toastr.error(message, title);
}

showSucceeded(message, title){
  //this.toastr.clear();
  //this.toastr.success(message, title);
  this.showError(message,title);
}

isOpen(startTime:string,endTime:string,back:number)
{
  var dt = new Date();//current Date that gives us current Time also
  var s =  startTime.split(':');
  if(back>0)
    dt.setDate(dt.getDate()-1);
  var from = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(),
                     parseInt(s[0]), parseInt(s[1]),parseInt("00"));
  var e =  endTime.split(':');
  var to = new Date(dt.getFullYear(), dt.getMonth(),
  dt.getDate(),parseInt(e[0]), parseInt(e[1]),parseInt("00"));
 return (to > from && dt >= from && dt <= to || to < from && (dt >= from || dt <= to)) ;
}

round(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
    return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

openLoader()
{
  this.matDialog.open(LoaderComponent, { disableClose: true });
}

hideKeyboard(element) {
  element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
  element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
  setTimeout(function() {
      element.blur();  //actually close the keyboard
      // Remove readonly attribute after keyboard is hidden.
      element.removeAttr('readonly');
      element.removeAttr('disabled');
  }, 100);
}

public executeUserPut<T>(
  url: string,
  postData: any,
  method: HttpMethod = HttpMethod.Put,
): Observable<T> {
  return Observable.create(observer => {
    const met = HttpMethod[method];
    let headers = this.createRequestHeader_();
    this.http.request<T>(method, url, { body: postData, observe: 'response', headers: headers }).subscribe(
      (event: HttpEvent<T>) => {
        switch (event.type) {
          case HttpEventType.Response:
            observer.next(event.body as T);
        }
      },
      err => {   
        if (err.error && err.error.text) {
          observer.error(err);
        }
      }
    );
  });
}

  public executePost<T>(
    url: string,
    postData: any,
    method: HttpMethod = HttpMethod.Post,
  ): Observable<T> {
    return Observable.create(observer => {
      const met = HttpMethod[method];
      let headers = this.createRequestHeader_();
      this.http.request<T>(method, url, { body: postData, observe: 'response', headers: headers }).subscribe(
        (event: HttpEvent<T>) => {
          switch (event.type) {
            case HttpEventType.Response:
              observer.next(event.body as T);
          }
        },
        err => {   
          if (err.error && err.error.text) {
            observer.error(err);
          }
        }
      );
    });
  }

  public execute<T>(
    url: string,
    method: HttpMethod = HttpMethod.Get,
  ): Observable<T> {
    return Observable.create(observer => {
      let headers = this.createRequestHeader_();
    this.http.request<T>(method, url, { observe: 'response' , headers: headers }).subscribe(
        (event: HttpEvent<T>) => {
          switch (event.type) {
            case HttpEventType.Response:
              observer.next(event.body as T);
          }
        },
        err => {
          if (err.error && err.error.text) {
            observer.error(err);
          }
        }
     );
    });
  }

  public executePut<T>(
    url: string,
    method: HttpMethod = HttpMethod.Put,
  ): Observable<T> {
    return Observable.create(observer => {
      let headers = this.createRequestHeader_();
    this.http.request<T>(method, url, { observe: 'response' , headers: headers }).subscribe(
        (event: HttpEvent<T>) => {
          switch (event.type) {
            case HttpEventType.Response:
              observer.next(event.body as T);
          }
        },
        err => {
          if (err.error && err.error.text) {
            observer.error(err);
          }
        }
     );
    });
  }

  private convertData(data, isPost) {
    if (!(data instanceof Object) || data instanceof Date) {
      let prop = data;
      // parse string date
      if (
        isPost === false &&
        (prop instanceof String || typeof prop === "string") &&
        prop.indexOf("/Date(") > -1
      ) {
        let date = prop.substring(
          prop.indexOf("(") + 1,
          prop.indexOf("+") != -1 ? prop.indexOf("+") : prop.indexOf(")")
        );
        prop = new Date(parseInt(date));
      } else if (isPost && prop instanceof Date) {
        // convert to string date
        let d = Date.UTC(
          prop.getFullYear(),
          prop.getMonth(),
          prop.getDate(),
          prop.getHours(),
          prop.getMinutes()
        );
        prop =
        //d.toString() === "NaN" || d === NaN - naomi
          d.toString() === "NaN" || Number.isNaN(d)
            ? null
            : "/Date(" +
            Date.UTC(
              prop.getFullYear(),
              prop.getMonth(),
              prop.getDate(),
              prop.getHours(),
              prop.getMinutes()
            ) +
            ")/";
      }
      return prop;
    }

    // parse array / object
    let isArr = data instanceof Array;
    let arrayData = [];
    let objectData = {};

    if (data) {
      Object.keys(data).forEach(key => {
        // dictionary
        if (
          !isPost &&
          isArr &&
          data[key] &&
          Object.keys(data[key]).length === 2 &&
          data[key].Key &&
          data[key].Value != null
        ) {
          arrayData[data[key].Key] = this.convertData(data[key].Value, isPost);
        } else if (isArr) {
          // array
          arrayData.push(this.convertData(data[key], isPost));
        } else {
          // object
          objectData[key] = this.convertData(data[key], isPost);
        }
      });
    }
    return isArr ? arrayData : objectData;
  }

}


