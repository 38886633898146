<div style="align-items: center; width: 100%; border: none; font-family: OpenSansHebrewBold; display: inline-block;">
  <div class="form-signin" > 
<img class="txt-input" src="assets/images/Botoom_Bar_Logo.png"
 style="position: absolute; right: 0; left: 0; margin: auto;">
 <div style="position: relative;
 top: 112px;display: inline-block;
 text-align: center;">
   <img src="assets/images/Popup_Signup_Button.png" (click)="signUp()">
   <img src="assets/images/Popup_No_Sign_Button.png" (click)="noSign()">
   <a href="https://baliapp.co.il/Share" style=" cursor: pointer;"><img src="assets/images/Popup_Doanlaod_App_Button.png"></a>
   <div class="m-auto">
    <img style="margin-right: 52px; cursor: auto;" src="assets/images/Popup_Google_Icon.png"/>
    <img style="cursor: auto;" src="assets/images/Popup_Apple_Icon.png" />

   </div>
</div>
</div>
</div>
