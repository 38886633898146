import { Component, OnInit ,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';

@Component({
  selector: 'app-popup-giftcard',
  templateUrl: './popup-giftcard.component.html',
  styleUrls: ['./popup-giftcard.component.scss']
})

// const formatter = new Intl.NumberFormat('en-US', {
//   minimumFractionDigits: 2,      
//   maximumFractionDigits: 2,
// });

export class PopupGiftcardComponent implements OnInit {
  price;
  price2;
  price3:number;
  payBy="גיפט קארד";

  constructor(private router: Router,private proxy:AppProxy, @Inject(MAT_DIALOG_DATA) private data: {price: number,price2:number,payBy: string},private matDialog:MatDialog) { }


  
  ngOnInit(): void {
    this.price=this.proxy.round(this.data.price,2);
    this.price2=this.proxy.round(this.data.price2,2);
    this.price3=Number(Number(this.price)-Number(this.price2));
    this. payBy=this.data. payBy;
  }

  continue()
  {
    this.matDialog.closeAll();
    localStorage.PRICE_GIFTCARD=this.price2;
    localStorage.PRICE_TOTAL=this.price;
    this.router.navigate(['../securePayment']); 
  }

   

}
