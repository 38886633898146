import { Component, OnInit , EventEmitter ,ViewChild ,ElementRef, Output, HostListener} from '@angular/core';
import { FormGroup,FormControl,Validators, NumberValueAccessor ,FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { UserService } from 'src/app/services/user-service';
import { Router } from '@angular/router';
import { filter } from "rxjs/operators";
import { LoginComponent } from '../login/login.component';
import { DataService } from 'src/app/services/data.service';
import { MyOrder } from 'src/app/models/order';
import { OrdersService } from 'src/app/services/order-service';
import { OneTimeOrderService } from 'src/app/services/oneTimeOrder.service';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()progress: EventEmitter<number> = new EventEmitter<number>();
  getNewPassword:boolean=false;
  valid:boolean=false;
  newPasswordForm:FormGroup;
  code1:number=null;
  code2:number=null;
  code3:number=null;
  code4:number=null;
  code:string=null;
  form: FormGroup;
  notGetSendAgain="לא קיבלתי, שלח שוב";
  @ViewChild("code1") code1Element: ElementRef;
  @ViewChild("code2") code2Element: ElementRef;
  @ViewChild("code3") code3Element: ElementRef;
  @ViewChild("code4") code4Element: ElementRef;
  innerWidth: any;
  showProgress=false;
  order:MyOrder;
  token:string=null;
  isClickPayment=false;
  date1:string="";
  clickoutHandler: Function;
  error=1;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.backPress();
  }

  // @HostListener('window:keyup.esc') onKeyUp() {
  //   this.dialogRef.close();
  // }

  // @HostListener('document:click', ['$event'])
  // clickout(event) {

  //     this.closeDialogFromClickout(event);
 
  // }

  // closeDialogFromClickout(event: MouseEvent) {
  //     this.dialogRef.close();
  // }

  constructor(private router: Router,private fb: FormBuilder,private oneTimeOrderService:OneTimeOrderService ,private orderService:OrdersService,private dialogRef: MatDialogRef<VerificationCodeComponent> ,private matDialog:MatDialog,private bussinssService: BussinssService,
    private userService:UserService ,private proxy:AppProxy,private dataService:DataService) {
      dialogRef.disableClose = true;
    /*
      Subscribe to events emitted when the backdrop is clicked
      NOTE: Since we won't actually be using the `MouseEvent` event, we'll just use an underscore here
      See https://stackoverflow.com/a/41086381 for more info
    */
    // dialogRef.backdropClick().subscribe(() => {
    //   // Close the dialog
    //   dialogRef.close();
    // })
     
  }

    inputFocusEmitter = new EventEmitter<string>();

    ngOnInit(): void {
      localStorage.isClickPayment="true"
      this.dataService.show.next(false);
      this.changeShowPrice.emit();
      this.dataService.byFood.next(false);
      this.changByFood.emit();
      this.createForm();
       if(localStorage.ERROR_PASSWORD==1)
       {
       this.notGetSendAgain="קוד שגוי. הזן שנית";
       localStorage.ERROR_PASSWORD=0;
       this.error=2;
       }
       else
    {
      this.notGetSendAgain="לא קיבלתי, שלח שוב";
      this.error=1;
    }
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
   
      });
    }

    backPress()
  {
  //   this.matDialog.closeAll();
  //   if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
  //   this.router.navigate(["../payment"])
  //   else
  //  this.router.navigate(["../securePayment"])

   this.dialogRef.close(1);
  }

  openDialog(): void {

    //להעביר ל
    //case 2
    // this.matDialog.closeAll();
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose=true;
    // const dialogRef = this.matDialog.open(VerificationCodeComponent, {
    //   backdropClass: 'cdk-overlay-transparent-backdrop',
    //   hasBackdrop: false,
    //   disableClose:false
    // });

    this.dialogRef.close(2)
  }

  newPassword()
  {
    this.showProgress=true;
 
    this.valid=true;
    if(this.newPasswordForm.valid)
    {
      this.openLoader();
    this.getNewPassword=true;
    }
    this.code=this.newPasswordForm.value.code1+this.newPasswordForm.value.code2+this.newPasswordForm.value.code3+this.newPasswordForm.value.code4+"";
    if(this.code1Element!=null && this.code2Element!=null && this.code3Element!=null && this.code4Element!=null && this.code!=null)
    {
      this.oneTimeOrderService.setOrderSmsConfirmation(this.order.OrderID,this.code,this.innerWidth).subscribe((res:any)=>{
      if(res.error==false && res.responseMessage=="ok")
      {
    //     this.dataService.showProgress.next(true);
    //     this.progress.emit();
    //     localStorage.isClickPayment=true;
    //     let IsFromLink=0;
    //     if(sessionStorage.deeplink==this.order.RestaurantID)
    //     IsFromLink=1;
    //     if(localStorage.paymentBy=="1")
    //     {   
    //     this.date1=localStorage.date;
    //         if(!localStorage.nvCardNumber.startsWith("x"))
    //         this.token=null;
    //       this.oneTimeOrderService.setOrderPayment(localStorage.nvCardNumber,localStorage.date.replace("/",""),
    //       localStorage.cvv,localStorage.identityNumber,localStorage.nvCardholderName,Number(this.order.Balance)).subscribe((res:any)=>
    //       {
    //        if(res.error==true)
    //        {
    //         this.showProgress=false;
    //         this.isClickPayment=false;
    //         this.matDialog.closeAll();
    //         this.proxy.showError(res.responseMessage,"");
    //        }
    //        else
    //       {
         
    //         if(this.order.IsDelivery==1)
    //         {
    //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.order.Address,this.order.Lat+"",this.order.Lng+"",IsFromLink).subscribe((res:any)=>
    //           {
                
             
    //                 this.router.navigate(['../endOrder']);
    //                 this.noItem();
     
    //                });
    //               }
    //                 else
    //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,IsFromLink).subscribe((res:any)=>
    //                 {
    //                   this.router.navigate(['../endOrder']);
    //                   this.noItem();
   
    //         });
    //       }
    //        });
    //     }
    //    else 
    //   {
    //     if(this.order.IsDelivery==1)
    //     {
    //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.order.Address,this.order.Lat+"",this.order.Lng+"",IsFromLink).subscribe((res:any)=>
    //     {
    //       this.router.navigate(['../endOrder']);
    //       this.noItem();
          
    //          });
    //         }
    //           else
    //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,IsFromLink).subscribe((res:any)=>
    //           {
    //             this.router.navigate(['../endOrder']);
    //             this.noItem();
    //   }); 
    // }

    this.dialogRef.close(3)
      }
       else
       {
        // this.ngOnInit();
        localStorage.SMS_CODE2="";
         localStorage.ERROR_PASSWORD=1;
         this.newPasswordForm.value.code1="";
         this.newPasswordForm.value.code2="";
         this.newPasswordForm.value.code3="";
         this.newPasswordForm.value.code4="";
         this.notGetSendAgain="קוד שגוי. הזן שנית";
       

         //לבדוק!!
      //  this.dialogRef.close();
      //  this.router.navigate(["../payment"]);
       this.openDialog();  
       }
    });
  }
  else
  this.proxy.showError("יש למלא את כל מספרי הקוד","");
  }

  reSendSmsCode()
  {
    this.oneTimeOrderService.sendConfirmationSms(this.order.OrderID , +this.order.TotalPrice ,localStorage.paymentBy == 0 ? 1 : 0).subscribe((res:any)=>{
      this.proxy.showSucceeded("הקוד נשלח אליך","");
    });
  }

  openLoader()
  {
    this.matDialog.open(LoaderComponent, { disableClose: true });
  }

  // noItem()
  // {
  //   let priceTotal="0 - פריטים";
  //   localStorage.PRICE=0;
  //   localStorage.PRICE_TOTAL=priceTotal;
  //   this.dataService.price.next(priceTotal);
  //   this.changePrice.emit();
  //   this.showProgress=false;
  //   this.dataService.showProgress.next(false);
  //   this.progress.emit();
  //   this.matDialog.closeAll();
  // }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.newPasswordForm.controls[fieldName].invalid;
  }

  openDialogLogin() {
    this.innerWidth = window.innerWidth;
    //if(this.innerWidth<=480)
   // this.router.navigate(['../login']);
    //else
    {
    if(sessionStorage.ACCESS_TOKEN  == "null")
   // {
   // const dialogConfig = new MatDialogConfig();
    this.matDialog.open(LoginComponent, { disableClose: true });
   // }
  }
  }

  createForm() {
    this.newPasswordForm = this.fb.group({
      code1: ["", [Validators.required, Validators.maxLength(1)]],
      code2: ["", [Validators.required, Validators.maxLength(1)]],
      code3: ["", [Validators.required, Validators.maxLength(1)]],
      code4: ["", [Validators.required, Validators.maxLength(1)]],
    });

    this.newPasswordForm.get("code1").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code2Element.nativeElement.focus());

    this.newPasswordForm.get("code2").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code3Element.nativeElement.focus());

      this.newPasswordForm.get("code3").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code4Element.nativeElement.focus());

      // this.newPasswordForm.get("code4").valueChanges
      // .pipe(filter((value: string) => value.length === 1))
      // .subscribe(() => this.newPassword());
  }


  openLoginDialog()
  {
    const dialogConfig = new MatDialogConfig();
  }

  exit()
  {
    if(this.getNewPassword==false)
      this.openLoginDialog();
      else if(this.getNewPassword==true)
      {
        this.valid=false;
        this.getNewPassword=false;
      }
  }
}
