import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-popup-log-out',
  templateUrl: './popup-log-out.component.html',
  styleUrls: ['./popup-log-out.component.scss']
})
export class PopupLogOutComponent implements OnInit {
  @Output()state: EventEmitter<number> = new EventEmitter<number>();

  constructor(private matDialog:MatDialog, private router: Router,private dataService:DataService) { }

  ngOnInit(): void {
  }

  cancel(){
    this.matDialog.closeAll();
    this.dataService.state.next("התנתק");
    this.state.emit();
    
  }

  //naomi change
  ok()
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    this.matDialog.closeAll();
    this.dataService.state.next("התחבר");
    localStorage.removeItem("PRICE_TOTAL")
    localStorage.removeItem("USER_ID")
    localStorage.removeItem("localStorage.ID")
    localStorage.oneTimeOrderOrOrder =  'oneTimeOrder'
    
 
    this.dataService.token.next('oneTimeOrder');
    this.state.emit();
    sessionStorage.ACCESS_TOKEN = null;
    this.router.navigate(["../mainHome"])
  }
}
