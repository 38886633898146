import { Component, OnInit , EventEmitter ,ViewChild ,ElementRef, HostListener, Output} from '@angular/core';
import { FormGroup,FormControl,Validators, NumberValueAccessor ,FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { UserService } from 'src/app/services/user-service';
import { Router } from '@angular/router';
import { filter } from "rxjs/operators";
import { LoginComponent } from '../login/login.component';
import { DataService } from 'src/app/services/data.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-login-phone-varification',
  templateUrl: './login-phone-varification.component.html',
  styleUrls: ['./login-phone-varification.component.scss']
})
export class LoginPhoneVarificationComponent implements OnInit {
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  getNewPassword:boolean=false;
  valid:boolean=false;
  newPasswordForm:FormGroup;
  code1:number=null;
  code2:number=null;
  code3:number=null;
  code4:number=null;
  code:string=null;
  form: FormGroup;
  notGetSendAgain="לא קיבלתי שלח שוב";
  @ViewChild("code1") code1Element: ElementRef;
  @ViewChild("code2") code2Element: ElementRef;
  @ViewChild("code3") code3Element: ElementRef;
  @ViewChild("code4") code4Element: ElementRef;
  innerWidth: any;
  error=1;
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  constructor( private myLocation: Location ,private router: Router,private fb: FormBuilder,private dialogRef: MatDialogRef<LoginPhoneVarificationComponent> ,private matDialog:MatDialog,private bussinssService: BussinssService,
    private userService:UserService ,private proxy:AppProxy,private dataService:DataService) {
       dialogRef.disableClose = true;
     }

  inputFocusEmitter = new EventEmitter<string>();

  openDialog(): void {
    this.matDialog.closeAll();
    const dialogRef = this.matDialog.open(LoginPhoneVarificationComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: false,
    });
  }

  hideKeyboard(element) {
    element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
    element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
    setTimeout(function() {
        element.blur();  //actually close the keyboard
        // Remove readonly attribute after keyboard is hidden.
        element.removeAttr('readonly');
        element.removeAttr('disabled');
    }, 100);
}

hideKeyboard_() {
  //this set timeout needed for case when hideKeyborad
  //is called inside of 'onfocus' event handler
  setTimeout(function() {
    //creating temp field
    var field = document.createElement('input');
    field.setAttribute('type', 'text');
    //hiding temp field from peoples eyes
    //-webkit-user-modify is nessesary for Android 4.x
    field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
    document.body.appendChild(field);

    //adding onfocus event handler for out temp field
    field.onfocus = function(){
      //this timeout of 200ms is nessasary for Android 2.3.x
      setTimeout(function() {

        field.setAttribute('style', 'display:none;');
        setTimeout(function() {
          document.body.removeChild(field);
          document.body.focus();
        }, 14);

      }, 200);
    };
    //focusing it
    field.focus();

  }, 50);
}

newPassword()
  {
    this.valid=true;
    if(this.newPasswordForm.valid)
    {
    this.getNewPassword=true;
    
    this.code=this.newPasswordForm.value.code1+this.newPasswordForm.value.code2+this.newPasswordForm.value.code3+this.newPasswordForm.value.code4+"";
    if(this.code1Element!=null && this.code2Element!=null && this.code3Element!=null && this.code4Element!=null && this.code!=null)
    {
    this.userService.setUserSmsConfirmation(this.code,null).subscribe((res:any)=>{
      if(res.error==false && res.responseMessage=="ok")
      {
        localStorage.SMS_CODE=this.code;
        if(localStorage.SMS=="")
        this.proxy.showSucceeded("התעדכן נתוני המשתמש","");
        localStorage.SMS=this.code;
        this.dataService.state.next("התנתק");
        this.state.emit();
        this.dataService.token.next('order');
        localStorage.oneTimeOrderOrOrder='order'
        if(sessionStorage.deeplink=='' || sessionStorage.deeplink=='null' || sessionStorage.deeplink=='undefined')
       {

        this.router.navigate([this.router.url]).then(() => {
          window.location.reload();
        });
        this.matDialog.closeAll();

        
        // this.router.navigate(["../mainHome"]);
       }
        else
        {
          this.matDialog.closeAll();
        //  let a=location.pathname;
          //let url: string =  a
         // this.router.navigateByUrl(url);
         
        }
      }
       else
       {
         localStorage.ERROR_PASSWORD=1;
         if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
         // this.hideKeyboard(this.code1Element);
          var field = document.createElement('input');
field.setAttribute('type', 'text');
document.body.appendChild(field);

setTimeout(function() {
    field.focus();
    setTimeout(function() {
        field.setAttribute('style', 'display:none;');
    }, 50);
}, 50);
        }
          this.notGetSendAgain="קוד שגוי. הזן שנית";
        //  this.newPasswordForm.value.code1="";
        //  this.newPasswordForm.value.code2="";
        //  this.newPasswordForm.value.code3="";
        //  this.newPasswordForm.value.code4="";
        //  this.matDialog.closeAll();
          localStorage.SMS_CODE==""
         this.router.navigate(["../mainHome"]);

         this.openDialog();
       }
    });
  }
  else
  {
    this.dialogRef.close();
  this.proxy.showError("יש למלא את כל מספרי הקוד","");
  }
}
  }

  reSendSmsCode()
  {
    this.userService.reSendSmsCode().subscribe((res:any)=>{
      this.proxy.showSucceeded("הקוד נשלח אליך","");
    });
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.newPasswordForm.controls[fieldName].invalid;
  }

  openDialogLogin() {
    this.innerWidth = window.innerWidth;
   // if(this.innerWidth<=480)
   // this.router.navigate(['../login']);
   // else
    {
    if(sessionStorage.ACCESS_TOKEN  == "null")
   //{
    //const dialogConfig = new MatDialogConfig();
    this.matDialog.open(LoginComponent, { disableClose: true });
   // }
  }
  }

  createForm() {
    this.newPasswordForm = this.fb.group({
      code1: ["", [Validators.required, Validators.maxLength(1)]],
      code2: ["", [Validators.required, Validators.maxLength(1)]],
      code3: ["", [Validators.required, Validators.maxLength(1)]],
      code4: ["", [Validators.required, Validators.maxLength(1)]],
    });

    this.newPasswordForm.get("code1").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code2Element.nativeElement.focus());

    this.newPasswordForm.get("code2").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code3Element.nativeElement.focus());

      this.newPasswordForm.get("code3").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code4Element.nativeElement.focus());

      // this.newPasswordForm.get("code4").valueChanges
      // .pipe(filter((value: string) => value.length === 1))
      // .subscribe(() => this.newPassword());
  }

  closeDialog()
  {
    this.matDialog.closeAll(); 
     this.openDialogLogin();
  }

  ngOnInit(): void {
    this.createForm();
    localStorage.SMS_CODE="";
    if(localStorage.ERROR_PASSWORD==1)
    {
    this.notGetSendAgain="קוד שגוי. הזן שנית";
    localStorage.ERROR_PASSWORD=0;
    this.error=2;
    }
    else
    {
    this.notGetSendAgain="לא קיבלתי שלח שוב";
    this.error=1
    ;
    }
  }
  
  openLoginDialog()
  {
    const dialogConfig = new MatDialogConfig();
  }

  exit()
  {
    if(this.getNewPassword==false)
      this.openLoginDialog();
      else if(this.getNewPassword==true)
      {
        this.valid=false;
        this.getNewPassword=false;
      }
  }
}
