import { Component, OnInit , EventEmitter ,ViewChild ,ElementRef, Output} from '@angular/core';
import { FormGroup,FormControl,Validators, NumberValueAccessor ,FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { UserService } from 'src/app/services/user-service';
import { Router } from '@angular/router';
import { filter } from "rxjs/operators";
import { LoginComponent } from '../login/login.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login-phone-varification2',
  templateUrl: './login-phone-varification2.component.html',
  styleUrls: ['./login-phone-varification2.component.scss']
})
export class LoginPhoneVarification2Component implements OnInit {
  @Output()state: EventEmitter<number> = new EventEmitter<number>();
  getNewPassword:boolean=false;
  valid:boolean=false;
  newPasswordForm:FormGroup;
  code1:number=null;
  code2:number=null;
  code3:number=null;
  code4:number=null;
  code:string=null;
  form: FormGroup;
  notGetSendAgain="לא קיבלתי שלח שוב";
  @ViewChild("code1") code1Element: ElementRef;
  @ViewChild("code2") code2Element: ElementRef;
  @ViewChild("code3") code3Element: ElementRef;
  @ViewChild("code4") code4Element: ElementRef;
  innerWidth: any;
  error=1;
  

  constructor(private router: Router,private fb: FormBuilder ,private matDialog:MatDialog,private bussinssService: BussinssService,
    private userService:UserService ,private proxy:AppProxy,private dataService:DataService) {
      // dialogRef.disableClose = true;
    /*
      Subscribe to events emitted when the backdrop is clicked
      NOTE: Since we won't actually be using the `MouseEvent` event, we'll just use an underscore here
      See https://stackoverflow.com/a/41086381 for more info
    */
    // dialogRef.backdropClick().subscribe(() => {
    //   // Close the dialog
    //   dialogRef.close();
    // })
     }

  newPassword()
  {
    this.valid=true;
    if(this.newPasswordForm.valid)
    {
    this.getNewPassword=true;
    }
    this.code=this.newPasswordForm.value.code1+this.newPasswordForm.value.code2+this.newPasswordForm.value.code3+this.newPasswordForm.value.code4+"";
    if(this.code1Element!=null && this.code2Element!=null && this.code3Element!=null && this.code4Element!=null && this.code!=null)
    {
    this.userService.setUserSmsConfirmation(this.code,this.innerWidth).subscribe((res:any)=>{
      if(res.error==false && res.responseMessage=="ok")
      {
        localStorage.SMS_CODE=this.code;
        if(localStorage.SMS=="")
        this.proxy.showSucceeded("התעדכן נתוני המשתמש","");
        localStorage.SMS=this.code;
        this.dataService.state.next("התנתק");
        this.state.emit();
        localStorage.oneTimeOrderOrOrder='order'
        if(sessionStorage.deeplink=='' || sessionStorage.deeplink=='null' || sessionStorage.deeplink=='undefined')
        this.router.navigate(["../mainHome"]);
        else
        {
          this.matDialog.closeAll();
          let a=location.pathname;
          let url: string =  a
          this.router.navigateByUrl(url);
        }
     // this.matDialog.closeAll(); 
      }
       else
       {
         this.ngOnInit();
        localStorage.SMS_CODE="";
         localStorage.ERROR_PASSWORD=1;
        //  this.notGetSendAgain="קוד שגוי. הזן שנית";
        //  this.newPasswordForm.value.code1="";
        //  this.newPasswordForm.value.code2="";
        //  this.newPasswordForm.value.code3="";
        //  this.newPasswordForm.value.code4="";
     //    this.dataService.token.next('order');
         //this.router.navigate(['../mainHome']);
         this.router.navigate(["../loginPhoneVarification"]);
       
       }
    });
  }
  else
  this.proxy.showError("יש למלא את כל מספרי הקוד","");
  }

  reSendSmsCode()
  {
    this.userService.reSendSmsCode().subscribe((res:any)=>{
      this.proxy.showSucceeded("הקוד נשלח אליך","");
    });
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.newPasswordForm.controls[fieldName].invalid;
  }

  createForm() {
    this.newPasswordForm = this.fb.group({
      code1: ["", [Validators.required, Validators.maxLength(1)]],
      code2: ["", [Validators.required, Validators.maxLength(1)]],
      code3: ["", [Validators.required, Validators.maxLength(1)]],
      code4: ["", [Validators.required, Validators.maxLength(1)]],
    });

    this.newPasswordForm.get("code1").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code2Element.nativeElement.focus());

    this.newPasswordForm.get("code2").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code3Element.nativeElement.focus());

      this.newPasswordForm.get("code3").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.code4Element.nativeElement.focus());

      // this.newPasswordForm.get("code4").valueChanges
      // .pipe(filter((value: string) => value.length === 1))
      // .subscribe(() => this.newPassword());
  }

  ngOnInit(): void {
    this.proxy.scrollToTop();
    this.createForm();
    this.innerWidth = window.innerWidth;
    localStorage.SMS_CODE="";
    this.error=1;
    if(localStorage.ERROR_PASSWORD==1)
    {
    this.notGetSendAgain="קוד שגוי. הזן שנית";
    localStorage.ERROR_PASSWORD=0;
    this.error=2;
    }
    else
    {
    this.notGetSendAgain="לא קיבלתי שלח שוב";
    this.error=1;
    }
  }

  // openLoginDialog()
  // {
  //   const dialogConfig = new MatDialogConfig();
  // }

  exit()
  {
    //if(this.getNewPassword==false)
     // this.openLoginDialog();
      //else
       if(this.getNewPassword==true)
      {
        this.valid=false;
        this.getNewPassword=false;
      }
  }
}
