<div class="page" >
 <div class="d-flex flex-wrap container" style="margin-top: 25px; font-family: OpenSansHebrewRegular;margin-top: 50px;">
                                            <div class="d-flex" style="width: 90%;" >
                                                <div  style="left: 25%; margin-top: 20px; position: absolute;" class="col-md-6 left">
                                                    <form style="display: inline-block; font-size: 15px;" [formGroup]="contactForm" class="form-signin">
                                                    <br>
                                                    <div>
                                                        <div class="txt-input" >
                                                            <img class="align-self-center icon" src="assets/images/Contact_Icon_1.png" style="padding-left: 10px;"/>
                                                            <input matInput formControlName="nvName" type="text" style="font-size: 15px; height: 25px;" required placeholder="שם מלא"/>
                                                           
                                                        </div>
                                                        <br>
                                                        <br>
                                                        
                                                                <div class="txt" [ngClass]="!isValidInput('nvName') ? 'line-gray_' : 'line-pink'"></div>
                                                                <span *ngIf="isValidInput('nvName')" style="color: #ff8585; font-size: 9px; display: flex;">שדה חובה</span> 
                                                            </div>  
                                                            <br>
                                                        <div>
                                                        <div class="txt-input" >
                                                            <img class="align-self-center icon" src="assets/images/Contact_Icon_2.png" style="padding-left: 10px;"/>
                                                            <input matInput formControlName="nvEmail" style="font-size: 15px; height: 25px;" required placeholder="כתובת אמייל" type="email"/>
                                                        </div>
                                                        <br>
                                                        <br>
                                                        <div class="txt" [ngClass]="!isValidInputMail('nvEmail') ? 'line-gray_' : 'line-pink'"></div>
                                                        <span *ngIf="isValidInput('nvEmail')" style="color: #ff8585; font-size: 9px; display: flex;">{{errorMail}}</span> 
                                                            </div>  
                                                            <br>
                                                                           <div>
                                                                                <div class="txt-input" >
                                                                                    <img class="align-self-center icon" style="height: 14px; padding-left: 15px;" src="assets/images/Contact_Icon_3.png"/>
                                                                                    <input matInput formControlName="nvPhone" minlength="9" maxlength="10" style="font-size: 15px; height: 25px;" required placeholder="טלפון" type="tel"/>
                                                                                </div>
                                                                                <br>
                                                                                <br>
                                                                                <div class="txt" [ngClass]="!isValidInputPhone('nvPhone') ? 'line-gray_' : 'line-pink'"></div>
                                                                                <span *ngIf="isValidInput('nvPhone')" style="color: #ff8585; font-size: 9px; display: flex;">{{errorPhone}}</span> 
                                                                                    </div>  
                                                                                    <br>  
                                                                                     <div>
                                                                                        <div class="txt-input message" >
                                                                                            <img class="align-self-center icon" src="assets/images/Contact_Icon_4.png" style="padding-left: 10px; margin-bottom: 45px;"/>                    
                                                                                                <textarea style="font-weight: 100; font-size: 15px;" formControlName="nvMessage" type="text" required name="nvMessage" mdInput placeholder="השאר לנו הודעה" rows="4" (input)="limitLines($event, 4)"></textarea>                         
                                                                                            </div>
                                                                                        <br>
                                                                                        <br>
                                                                                        <br>
                                                                                        <br>
                                                                                                    <div class="txt" [ngClass]="!isValidInput('nvMessage') ? 'line-gray_' : 'line-pink'"></div>
                                                                                                    <span *ngIf="isValidInput('nvMessage')" style="color: #ff8585; font-size: 9px; display: flex;">שדה חובה</span> 
                                                                                            </div>
                                                                                            <!-- </div>      -->
                                                                                            <span *ngIf="contactForm.valid==false" class="valid"  style="color: #ff8585; display: flex; font-size: 13px;">* יש להזין את כל הפרטים</span>                                                                                                          
                                                                                          <div class="d-flex col-11 img2" style="margin-top: 30px;">
                                                                                            <div class="left keep col-8 img2  " (click)="send()" style="font-family: OpenSansHebrewRegular; width: max-content;">
                                                                                                <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                                                                                                <span class="btn">שלח</span>
                                                                                        </div> 
                                                                                        <a class="col-lg-3 img2" href="https://wa.me/message/YFNFSHH7HZBVD1" style="position: absolute; left: 0;"><img style="width: 140px;" src="assets/images/WHATSAPP GREEN.png"></a>    
                                                                                    </div>
                                                                                   

                                                                                    </form>
                                                                                    <div class="left keep col-8 img400" (click)="send()" style="font-family: OpenSansHebrewRegular; width: max-content;margin-right: 22%;">
                                                                                        <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                                                                                        <span class="btn">שלח</span>
                                                                                </div> 
                                                                                    <a class="img400" href="https://wa.me/message/YFNFSHH7HZBVD1" style="position: absolute; margin-top: 20px;
                                                                                    left: 22% ;"><img style="width: 140px;" src="assets/images/WHATSAPP GREEN.png"></a>    
                                                               </div>   
                                                <div class="col-md-4 logo" style="position: initial;">
                                   <img style="margin-top: 25px;"  src="assets/images/Delivery_Popup_Logo.png">
                                </div>
                                                                                            
                                                </div>                                           
                                    </div> 
                                    </div>
                         
                            
                
                
                
                
                
                
                
