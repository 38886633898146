<div class="fontSize" style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold; font-size: 18px;">
    <form class="form-signin"> 
      <div class="form-group">
      <div class="header" style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;" >הזמנה פתוחה</div>
      <br>
      <br class="br">
      <div  style="padding: 15px;   text-align: center;
      color: black;
      ">יש לך הזמנה פתוחה </div>
        <div class="body" style="padding: 15px; text-align: center; font-size: 16px;
        color: black;"> האם אתה מעוניין להמשיך לתשלום או להתחיל מחדש</div>
                <br> 
                  <div  class="btm" style="font-size: 15px;">
                    <p (click)="end()"  class="p" style="cursor: pointer;color: #41c777; font-weight: bold ;">המשך לתשלום</p>
                    <p (click)="cancel()"  class="p2">להתחיל מחדש</p>
                  </div>              
                  </div>         
    </form>
  </div>





